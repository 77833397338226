// File: /Users/ekreminan/Documents/Projects/killit_v3/frontend/src/components/Discovery/ImportToCMDB.tsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Checkbox,
  FormControlLabel,
  Alert,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { discoveryService } from '../../services/discoveryService';
import { RawScanData } from '../../types/discovery';

// This is a placeholder component - in a real implementation, you would need to:
// 1. Create an API endpoint to handle the import process
// 2. Add methods to the discoveryService to call this endpoint
// 3. Implement the actual mapping and import logic

const ImportToCMDB: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [scanData, setScanData] = useState<RawScanData[]>([]);
  const [selectedScanId, setSelectedScanId] = useState<string>('');
  const [importOptions, setImportOptions] = useState({
    createMissing: true,
    updateExisting: true,
    createRelationships: true
  });
  const [activeStep, setActiveStep] = useState(0);
  const [importResult, setImportResult] = useState<any>(null);

  const steps = ['Select Scan Data', 'Configure Import Options', 'Review & Import', 'Results'];

  useEffect(() => {
    loadProcessedScanData();
  }, []);

  const loadProcessedScanData = async () => {
    setLoading(true);
    try {
      const response = await discoveryService.getAllScanData({
        status: 'processed',
        limit: 100
      });
      setScanData(response.data);
    } catch (error) {
      console.error('Failed to load processed scan data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
    if (activeStep === 2) {
      // This is where you would call the API to start the import process
      // For now, we'll just simulate it
      simulateImport();
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const simulateImport = () => {
    setLoading(true);
    // Simulate API call delay
    setTimeout(() => {
      setImportResult({
        success: true,
        created: 5,
        updated: 3,
        relationships: 8,
        errors: [],
        ciIds: [
          '60f1b2c3d4e5f6a7b8c9d0e1',
          '61f1b2c3d4e5f6a7b8c9d0e2',
          '62f1b2c3d4e5f6a7b8c9d0e3'
        ]
      });
      setLoading(false);
    }, 2000);
  };

  const getSelectedScanData = () => {
    return scanData.find(item => item._id === selectedScanId);
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Select Scan Data to Import
            </Typography>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="scan-data-select-label">Scan Data</InputLabel>
              <Select
                labelId="scan-data-select-label"
                value={selectedScanId}
                onChange={(e) => setSelectedScanId(e.target.value as string)}
                label="Scan Data"
                disabled={loading}
              >
                {scanData.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item.hostname || item.ipAddress} - {item.scanId} ({new Date(item.scanTimestamp).toLocaleString()})
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Only processed scan data is available for import
              </FormHelperText>
            </FormControl>
          </Box>
        );
      
      case 1:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Configure Import Options
            </Typography>
            <Paper sx={{ p: 2, mt: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={importOptions.createMissing}
                    onChange={(e) => setImportOptions({
                      ...importOptions,
                      createMissing: e.target.checked
                    })}
                  />
                }
                label="Create new CIs for devices not found in CMDB"
              />
              <FormHelperText>
                If enabled, new CIs will be created for devices that don't exist in the CMDB
              </FormHelperText>
              
              <FormControlLabel
                control={
                  <Checkbox
                    checked={importOptions.updateExisting}
                    onChange={(e) => setImportOptions({
                      ...importOptions,
                      updateExisting: e.target.checked
                    })}
                  />
                }
                label="Update existing CIs with new scan data"
                sx={{ mt: 2 }}
              />
              <FormHelperText>
                If enabled, existing CIs will be updated with the latest scan data
              </FormHelperText>
              
              <FormControlLabel
                control={
                  <Checkbox
                    checked={importOptions.createRelationships}
                    onChange={(e) => setImportOptions({
                      ...importOptions,
                      createRelationships: e.target.checked
                    })}
                  />
                }
                label="Create relationships between CIs"
                sx={{ mt: 2 }}
              />
              <FormHelperText>
                If enabled, relationships between CIs will be created based on the scan data
              </FormHelperText>
            </Paper>
          </Box>
        );
      
      case 2:
        const selectedData = getSelectedScanData();
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Review Import Configuration
            </Typography>
            <Alert severity="info" sx={{ mb: 2 }}>
              Please review the import configuration before proceeding. This operation will modify data in your CMDB.
            </Alert>
            <Paper sx={{ p: 2 }}>
              <Typography variant="subtitle1">Selected Scan Data</Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" color="text.secondary">IP Address</Typography>
                  <Typography variant="body1">{selectedData?.ipAddress}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" color="text.secondary">Hostname</Typography>
                  <Typography variant="body1">{selectedData?.hostname || 'N/A'}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" color="text.secondary">Scan ID</Typography>
                  <Typography variant="body1">{selectedData?.scanId}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" color="text.secondary">Scan Date</Typography>
                  <Typography variant="body1">{new Date(selectedData?.scanTimestamp || '').toLocaleString()}</Typography>
                </Grid>
              </Grid>
              
              <Divider sx={{ my: 2 }} />
              
              <Typography variant="subtitle1">Import Options</Typography>
              <List dense>
                <ListItem>
                  <ListItemText 
                    primary="Create new CIs for devices not found in CMDB"
                    secondary={importOptions.createMissing ? 'Enabled' : 'Disabled'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText 
                    primary="Update existing CIs with new scan data"
                    secondary={importOptions.updateExisting ? 'Enabled' : 'Disabled'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText 
                    primary="Create relationships between CIs"
                    secondary={importOptions.createRelationships ? 'Enabled' : 'Disabled'}
                  />
                </ListItem>
              </List>
            </Paper>
          </Box>
        );
      
      case 3:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Import Results
            </Typography>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
                <CircularProgress />
              </Box>
            ) : importResult ? (
              <>
                {importResult.success ? (
                  <Alert severity="success" sx={{ mb: 2 }}>
                    Import completed successfully
                  </Alert>
                ) : (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    Import failed
                  </Alert>
                )}
                
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" color="primary">
                          {importResult.created}
                        </Typography>
                        <Typography variant="body1">
                          New CIs Created
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" color="secondary">
                          {importResult.updated}
                        </Typography>
                        <Typography variant="body1">
                          Existing CIs Updated
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    
                  </Grid>
                </Grid>
              </> 
            ) : null}
          </Box>
        );
      
      default:
        return null;
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {renderStepContent()}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        {activeStep > 0 && (
          <Button onClick={handleBack}>Back</Button>
        )}
        {activeStep < steps.length - 1 && (
          <Button onClick={handleNext}>Next</Button>
        )}
      </Box>
    </Box>
  );
};

export default ImportToCMDB;
import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Chip,
  CircularProgress,
} from '@mui/material';
import { Add as AddIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import api from '../../api/axios';
import NewRule from './NewRule';
import RuleDetail from './RuleDetail';
import { Rule } from '../../types/rule';

const RuleList: React.FC = () => {
  const [rules, setRules] = useState<Rule[]>([]);
  const [loading, setLoading] = useState(true);
  const [isNewRuleOpen, setIsNewRuleOpen] = useState(false);
  const [selectedRuleId, setSelectedRuleId] = useState<string | null>(null);
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const fetchRules = async () => {
    setLoading(true);
    try {
      const response = await api.get('/rules');
      setRules(response.data);
    } catch (error) {
      console.error('Error fetching rules:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRules();
  }, []);

  const handleRuleClick = (rule: Rule) => {
    if (rule._id) {
      setSelectedRuleId(rule._id);
      setIsDetailOpen(true);
    }
  };

  const handleDetailClose = () => {
    setSelectedRuleId(null);
    setIsDetailOpen(false);
  };

  const handleNewRuleClose = () => {
    setIsNewRuleOpen(false);
  };

  const handleSuccess = () => {
    fetchRules();
  };

  const getExecutionColor = (type: string) => {
    switch (type) {
      case 'ai':
        return 'primary';
      case 'manual':
        return 'secondary';
      default:
        return 'default';
    }
  };

  const getModeColor = (mode: string) => {
    switch (mode) {
      case 'sync':
        return 'success';
      case 'async':
        return 'warning';
      default:
        return 'default';
    }
  };

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h5">Rules</Typography>
        <Box>
          <IconButton
            onClick={fetchRules}
            sx={{ mr: 1 }}
            title="Refresh"
          >
            <RefreshIcon />
          </IconButton>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setIsNewRuleOpen(true)}
          >
            New Rule
          </Button>
        </Box>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Trigger</TableCell>
                <TableCell>Execution</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rules.map((rule) => (
                <TableRow
                  key={rule._id}
                  onClick={() => handleRuleClick(rule)}
                  sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'action.hover' } }}
                >
                  <TableCell>{rule.name}</TableCell>
                  <TableCell>{rule.description}</TableCell>
                  <TableCell>
                    <Chip
                      label={`${rule.trigger?.type || 'unknown'} ${rule.trigger?.objectType || 'unknown'}`}
                      size="small"
                      color="info"
                    />
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Chip
                        label={rule.execution?.type || 'unknown'}
                        size="small"
                        color={getExecutionColor(rule.execution?.type || '')}
                        sx={{ mr: 1 }}
                      />
                      <Chip
                        label={rule.execution?.mode || 'N/A'}
                        size="small"
                        color={rule.execution?.mode ? getModeColor(rule.execution.mode) : 'default'}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={rule.isActive ? 'Active' : 'Inactive'}
                      size="small"
                      color={rule.isActive ? 'success' : 'default'}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <NewRule
        open={isNewRuleOpen}
        onClose={handleNewRuleClose}
        onSuccess={handleSuccess}
      />

      <RuleDetail
        open={isDetailOpen}
        onClose={handleDetailClose}
        ruleId={selectedRuleId}
        onSuccess={handleSuccess}
      />
    </Box>
  );
};

export default RuleList;

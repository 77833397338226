import React from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Dialog,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { 
  WorkflowTemplate,
  PriorityType,
  TaskType,
  FieldType
} from '../../types/workflow';
import WorkflowTemplateForm from './WorkflowTemplateForm';
import workflowTemplateService from '../../services/workflowTemplateService';
import { enqueueSnackbar } from '../../utils/snackbar';

interface NewWorkflowTemplateProps {
  open?: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onTemplateUpdated?: () => void;
  template?: WorkflowTemplate | null;
  isStandalone?: boolean;
}

const NewWorkflowTemplate: React.FC<NewWorkflowTemplateProps> = ({
  open = true,
  onClose,
  onSuccess,
  onTemplateUpdated,
  template,
  isStandalone = false,
}) => {
  const handleSubmit = async (values: any) => {
    try {
      console.log('Raw form values:', values);

      // Transform the data to match the WorkflowTemplate type
      const templateData: Omit<WorkflowTemplate, '_id'> = {
        name: values.name || '',
        description: values.description || '',
        category: values.category === 'Standart' ? 'Standard' : values.category || '',
        version: values.version || '1.0.0',
        sla: {
          duration: Number(values.sla?.duration) || 24,
          priority: values.sla?.priority || PriorityType.MEDIUM
        },
        businessCase: {
          description: values.businessCase?.description || '',
          priority: Number(values.businessCase?.priority) || 1,
          keywords: Array.isArray(values.businessCase?.keywords) ? values.businessCase.keywords : [],
          examples: Array.isArray(values.businessCase?.examples) ? values.businessCase.examples : [],
          conditions: []
        },
        tasks: Array.isArray(values.tasks) ? values.tasks.map((task: any) => ({
          id: task.id || '',
          name: task.name || '',
          type: task.type || TaskType.ACTION,
          description: task.description || '',
          assignee: {
            type: task.assigneeType?.toLowerCase() || 'user',
            value: task.assigneeId || ''
          },
          duration: Number(task.duration) || 24,
          priority: task.priority || PriorityType.MEDIUM,
          relationship: {
            parentTasks: Array.isArray(task.relationship?.parentTasks) ? task.relationship.parentTasks : [],
            childTasks: Array.isArray(task.relationship?.childTasks) ? task.relationship.childTasks : [],
            conditions: Array.isArray(task.relationship?.conditions) ? task.relationship.conditions : []
          }
        })) : [],
        customFields: Array.isArray(values.customFields) ? values.customFields.map((field: any) => ({
          id: field.id || '',
          name: field.name || '',
          type: field.type || FieldType.TEXT,
          label: field.label || '',
          description: field.description || '',
          required: Boolean(field.required),
          readOnly: Boolean(field.readOnly),
          validation: {
            minLength: field.validation?.minLength ? Number(field.validation.minLength) : undefined,
            maxLength: field.validation?.maxLength ? Number(field.validation.maxLength) : undefined,
            min: field.validation?.min ? Number(field.validation.min) : undefined,
            max: field.validation?.max ? Number(field.validation.max) : undefined,
            pattern: field.validation?.pattern
          },
          options: Array.isArray(field.options) ? field.options : [],
          referenceConfig: field.referenceConfig
        })) : []
      };

      console.log('Transformed template data:', JSON.stringify(templateData, null, 2));
      
      if (template?._id || template?.id) {
        const updateData = { ...templateData, _id: template._id || template.id! };
        await workflowTemplateService.updateWorkflowTemplate(template._id || template.id!, updateData);
        enqueueSnackbar('Template updated successfully', { variant: 'success' });
      } else {
        await workflowTemplateService.createWorkflowTemplate(templateData);
        enqueueSnackbar('Template created successfully', { variant: 'success' });
      }
      
      onTemplateUpdated?.();
      onSuccess?.();
      onClose();
    } catch (error: any) {
      console.error('Error saving template:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Error saving template';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  };

  const content = (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
        <DialogTitle sx={{ p: 0 }}>
          {template ? 'Edit Workflow Template' : 'New Workflow Template'}
        </DialogTitle>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <WorkflowTemplateForm
          initialValues={template || undefined}
          onSubmit={handleSubmit}
          loading={false}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </>
  );

  return isStandalone ? (
    <Box sx={{ maxWidth: 800, margin: '0 auto', pt: 2 }}>{content}</Box>
  ) : (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      {content}
    </Dialog>
  );
};

export default NewWorkflowTemplate;

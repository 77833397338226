import React, { useCallback, useEffect, useMemo } from 'react';
import ReactFlow, {
  Node,
  Edge,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  Connection,
  MarkerType,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { TaskDefinition } from '../../types/workflow';
import { Box, Paper } from '@mui/material';
import TaskNode from './nodes/TaskNode';

interface WorkflowDiagramProps {
  tasks: TaskDefinition[];
  readonly?: boolean;
  onTasksChange?: (tasks: TaskDefinition[]) => void;
}

const nodeTypes = {
  taskNode: TaskNode,
};

const calculateNodePosition = (tasks: TaskDefinition[], taskId: string, visited = new Set<string>()) => {
  if (visited.has(taskId)) return { x: 0, y: 0 };
  visited.add(taskId);

  const task = tasks.find(t => t.id === taskId);
  if (!task) return { x: 0, y: 0 };

  const parentTasks = task.relationship.parentTasks || [];
  let level = 0;
  let column = 0;

  if (parentTasks.length === 0) {
    // Root node
    level = 0;
    column = tasks.filter(t => !t.relationship.parentTasks?.length).indexOf(task);
  } else {
    // Get max level of parents + 1
    level = Math.max(...parentTasks.map(parentId => {
      const parentPos = calculateNodePosition(tasks, parentId, visited);
      return parentPos.y / 200;
    })) + 1;

    // Find siblings at this level
    const siblings = tasks.filter(t => 
      t.relationship.parentTasks?.some(p => task.relationship.parentTasks.includes(p))
    );
    column = siblings.indexOf(task);
  }

  return {
    x: column * 400,
    y: level * 200
  };
};

const WorkflowDiagram: React.FC<WorkflowDiagramProps> = ({ tasks, readonly = false, onTasksChange }) => {
  // Convert tasks to nodes with calculated positions
  const initialNodes: Node[] = useMemo(() => tasks.map((task) => {
    const position = calculateNodePosition(tasks, task.id);
    return {
      id: task.id,
      type: 'taskNode',
      position,
      data: {
        ...task,
        label: task.name,
      },
      style: {
        width: 250,
        fontSize: '12px',
      },
    };
  }), [tasks]);

  // Convert task relationships to edges
  const initialEdges: Edge[] = useMemo(() => tasks.flatMap((task) => {
    const childTasks = task.relationship.childTasks || [];
    return childTasks
      .filter((childId): childId is string => typeof childId === 'string' && childId !== null)
      .map((childId) => ({
        id: `${task.id}-${childId}`,
        source: task.id,
        target: childId,
        type: 'smoothstep',
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
        style: { strokeWidth: 2 },
      }));
  }), [tasks]);

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback(
    (params: Connection) => {
      if (!params.source || !params.target) return;
      
      setEdges((eds) => addEdge({ ...params, type: 'smoothstep', markerEnd: { type: MarkerType.ArrowClosed } }, eds));
      
      if (onTasksChange) {
        const updatedTasks: TaskDefinition[] = tasks.map(task => {
          if (task.id === params.source) {
            const newChildTasks = [...(task.relationship.childTasks || []), params.target]
              .filter((id): id is string => typeof id === 'string' && id !== null);
            
            return {
              ...task,
              relationship: {
                ...task.relationship,
                childTasks: newChildTasks,
              },
            };
          }
          if (task.id === params.target) {
            const newParentTasks = [...(task.relationship.parentTasks || []), params.source]
              .filter((id): id is string => typeof id === 'string' && id !== null);
            
            return {
              ...task,
              relationship: {
                ...task.relationship,
                parentTasks: newParentTasks,
              },
            };
          }
          return task;
        });
        onTasksChange(updatedTasks);
      }
    },
    [setEdges, tasks, onTasksChange]
  );

  useEffect(() => {
    setNodes(initialNodes);
    setEdges(initialEdges);
  }, [initialNodes, initialEdges, setNodes, setEdges]);

  return (
    <Paper sx={{ height: 600, width: '100%' }}>
      <Box sx={{ height: '100%' }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={readonly ? undefined : onConnect}
          nodeTypes={nodeTypes}
          fitView
          attributionPosition="bottom-left"
          nodesDraggable={!readonly}
          nodesConnectable={!readonly}
          elementsSelectable={!readonly}
          panOnDrag={readonly}
          zoomOnScroll={true}
          panOnScroll={false}
          selectionOnDrag={false}
          preventScrolling={true}
          minZoom={0.1}
          maxZoom={2}
        >
          <Controls 
            showInteractive={true}
          />
          <Background />
        </ReactFlow>
      </Box>
    </Paper>
  );
};

export default WorkflowDiagram;
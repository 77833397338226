import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Chip,
  Grid,
  LinearProgress,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import { 
  Close as CloseIcon, 
  Edit as EditIcon,
} from '@mui/icons-material';
import { SelectChangeEvent } from '@mui/material/Select';
import api from '../../api/axios';
import CreateWorkflowModal from './CreateWorkflowModal';
import Notes from '../common/Notes';
import WorkflowDiagram from '../WorkflowTemplates/WorkflowDiagram';
import { TaskDefinition, AssigneeType, PriorityType } from '../../types/workflow';

interface Task {
  _id: string;
  workflowInstanceId: string;
  templateId: string;
  taskDefinitionId: string;
  name: string;
  description?: string;
  type: 'approval' | 'action' | 'notification' | 'manual';
  status: 'pending' | 'in_progress' | 'completed' | 'rejected' | 'cancelled';
  assignee?: {
    type: 'User' | 'Team';
    userId?: string;
    teamId?: string;
  };
  duration: number;
  priority: 'low' | 'medium' | 'high' | 'critical';
  startDate?: string;
  dueDate?: string;
  completedDate?: string;
  relationship?: {
    parentTasks: Array<string | { _id: string }>;
    childTasks: Array<string | { _id: string }>;
    conditions?: Array<{
      parentTaskId: string;
      condition: {
        field: string;
        operator: string;
        value: any;
      };
    }>;
  };
}

interface WorkflowTemplate {
  _id: string;
  name: string;
  description: string;
  tasks: TaskDefinition[];
}

interface WorkflowInstance {
  _id: string;
  templateId: WorkflowTemplate;
  name: string;
  description?: string;
  priority: string;
  startDate: string;
  endDate?: string;
  dueDate?: string;
  progress: number;
  tasks: Task[];
  createdBy: {
    _id: string;
    name: string;
    email: string;
  };
}

interface Request {
  _id: string;
  description: string;
  subject: string;
  status: string;
  priority: string;
  type?: string;
  category?: string;
  requestor: {
    _id: string;
    name: string;
    email: string;
  };
  team?: {
    _id: string;
    name: string;
    description: string;
  };
  workflowInstance?: WorkflowInstance;
  createdAt: string;
  aiAnalysis?: Record<string, string>;
  requestNumber?: string;
}

interface RequestDetailProps {
  open: boolean;
  requestId: string | null;
  onClose: () => void;
  onRequestUpdated: () => void;
}

const RequestDetail: React.FC<RequestDetailProps> = ({
  open,
  requestId,
  onClose,
  onRequestUpdated,
}) => {
  const [request, setRequest] = useState<Request | null>(null);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [teams, setTeams] = useState<Array<{ _id: string; name: string; description: string }>>([]);
  const [formData, setFormData] = useState({
    description: '',
    status: '',
    priority: '',
    type: '',
    category: '',
    subject: '',
    team: '',
  });
  const [showWorkflowModal, setShowWorkflowModal] = useState(false);

  const fetchTeams = useCallback(async () => {
    try {
      const response = await api.get('/teams');
      setTeams(response.data);
    } catch (error) {
      console.error('Error fetching teams:', error);
    }
  }, []);

  const fetchRequest = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(`/requests/${requestId}`);
      setRequest(response.data);
      if (response.data.workflowInstance) {
        // Fetch workflow tasks
        const tasksResponse = await api.get(`/workflows/${response.data.workflowInstance._id}/tasks`);
        setRequest(prev => ({
          ...prev!,
          workflowInstance: {
            ...prev!.workflowInstance!,
            tasks: tasksResponse.data
          }
        }));
      }
      
      // Also update form data when request is fetched
      setFormData({
        description: response.data.description,
        status: response.data.status,
        priority: response.data.priority,
        type: response.data.type || '',
        category: response.data.category || '',
        subject: response.data.subject || '',
        team: response.data.team?._id || '',
      });
    } catch (error) {
      console.error('Error fetching request:', error);
    } finally {
      setLoading(false);
    }
  }, [requestId]);

  useEffect(() => {
    if (requestId) {
      fetchRequest();
    }
  }, [requestId, fetchRequest]);

  useEffect(() => {
    if (open && requestId) {
      fetchTeams();
    }
  }, [open, requestId, fetchTeams]);

  useEffect(() => {
    // Only proceed if we have both request and workflowInstance
    if (!request?.workflowInstance || activeTab !== 2) return;

    const workflowInstance = request.workflowInstance; // Store in variable to satisfy TS
    console.log('Workflow Instance:', workflowInstance);

    // Fetch tasks when workflow tab is active
    const fetchWorkflowTasks = async () => {
      try {
        const tasksResponse = await api.get(`/workflow-instances/${workflowInstance._id}/tasks`);
        setRequest(prev => {
          if (!prev) return prev;
          return {
            ...prev,
            workflowInstance: {
              ...prev.workflowInstance!,
              tasks: tasksResponse.data
            }
          };
        });
      } catch (error) {
        console.error('Error fetching workflow tasks:', error);
      }
    };

    fetchWorkflowTasks();
  }, [request, activeTab]);

  const handleClose = () => {
    setRequest(null);
    setIsEditing(false);
    onClose();
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    if (request) {
      setFormData({
        description: request.description,
        status: request.status,
        priority: request.priority,
        type: request.type || '',
        category: request.category || '',
        subject: request.subject || '',
        team: request.team?._id || '',
      });
    }
  };

  const handleSave = async () => {
    if (!requestId) return;
    setLoading(true);
    try {
      await api.patch(`/requests/${requestId}`, formData);
      onRequestUpdated();
      onClose();
    } catch (error) {
      console.error('Error updating request:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'new': return 'info';
      case 'in_progress': return 'warning';
      case 'resolved': return 'success';
      case 'closed': return 'default';
      default: return 'default';
    }
  };

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case 'critical': return 'error';
      case 'high': return 'warning';
      case 'medium': return 'info';
      case 'low': return 'success';
      default: return 'default';
    }
  };

  const handleStartWorkflow = () => {
    setShowWorkflowModal(true);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  // Convert workflow instance tasks to diagram format
  const getWorkflowTasks = (tasks: Task[] | undefined): TaskDefinition[] => {
    if (!tasks) return [];
    console.log('Converting tasks:', tasks);
    
    return tasks.map(task => ({
      id: task._id,
      name: task.name,
      description: task.description || '',
      type: task.type,
      assigneeType: task.assignee?.type === 'User' ? AssigneeType.USER : 
                   task.assignee?.type === 'Team' ? AssigneeType.TEAM : AssigneeType.ROLE,
      assigneeId: task.assignee?.userId || task.assignee?.teamId || '',
      relationship: {
        parentTasks: task.relationship?.parentTasks?.map(pt => 
          typeof pt === 'string' ? pt : pt._id
        ) || [],
        childTasks: task.relationship?.childTasks?.map(ct => 
          typeof ct === 'string' ? ct : ct._id
        ) || []
      },
      duration: task.duration || 0,
      status: task.status,
      dueDate: task.dueDate ? new Date(task.dueDate) : undefined,
      priority: task.priority === 'low' ? PriorityType.LOW :
               task.priority === 'medium' ? PriorityType.MEDIUM :
               task.priority === 'high' ? PriorityType.HIGH :
               task.priority === 'critical' ? PriorityType.CRITICAL :
               undefined
    }));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          bgcolor: 'background.paper',
          boxShadow: 24,
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        borderBottom: 1,
        borderColor: 'divider',
        pb: 2
      }}>
        <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
          Request Details
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {!isEditing && (
            <>
              <IconButton 
                onClick={handleEdit}
                size="small"
                sx={{ ml: 1 }}
              >
                <EditIcon />
              </IconButton>
              <Button
                startIcon={<CloseIcon />}
                variant="contained"
                color="primary"
                onClick={handleStartWorkflow}
                disabled={!!request?.workflowInstance || loading}
                sx={{
                  borderRadius: 2,
                  textTransform: 'none',
                  bgcolor: 'primary.main',
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                  px: 2
                }}
              >
                Start Workflow
              </Button>
            </>
          )}
          <IconButton onClick={onClose} size="small" sx={{ ml: 1 }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Details" />
          <Tab label="Notes" />
          <Tab label="Workflow" disabled={!request?.workflowInstance} />
        </Tabs>
      </Box>

      <DialogContent sx={{ pt: 3 }}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight={400}>
            <CircularProgress />
          </Box>
        ) : request ? (
          <>
            {activeTab === 0 && (
              <Grid container spacing={3}>
                {isEditing ? (
                  // Edit Mode
                  <>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="description"
                        label="Description"
                        multiline
                        rows={4}
                        value={formData.description}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="subject"
                        label="Subject"
                        value={formData.subject}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                          name="status"
                          value={formData.status}
                          label="Status"
                          onChange={handleChange}
                        >
                          <MenuItem value="new">New</MenuItem>
                          <MenuItem value="in_progress">In Progress</MenuItem>
                          <MenuItem value="resolved">Resolved</MenuItem>
                          <MenuItem value="closed">Closed</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel>Priority</InputLabel>
                        <Select
                          name="priority"
                          value={formData.priority}
                          label="Priority"
                          onChange={handleChange}
                        >
                          <MenuItem value="low">Low</MenuItem>
                          <MenuItem value="medium">Medium</MenuItem>
                          <MenuItem value="high">High</MenuItem>
                          <MenuItem value="critical">Critical</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel>Type</InputLabel>
                        <Select
                          name="type"
                          value={formData.type}
                          label="Type"
                          onChange={handleChange}
                        >
                          <MenuItem value="incident">Incident</MenuItem>
                          <MenuItem value="rfc">RFC</MenuItem>
                          <MenuItem value="rfi">RFI</MenuItem>
                          <MenuItem value="bug">Bug</MenuItem>
                          <MenuItem value="development">Development</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel>Category</InputLabel>
                        <Select
                          name="category"
                          value={formData.category}
                          label="Category"
                          onChange={handleChange}
                        >
                          <MenuItem value="">Not set</MenuItem>
                          <MenuItem value="My Computer">My Computer</MenuItem>
                          <MenuItem value="Network">Network</MenuItem>
                          <MenuItem value="Software">Software</MenuItem>
                          <MenuItem value="Hardware">Hardware</MenuItem>
                          <MenuItem value="Access">Access</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Team</InputLabel>
                        <Select
                          name="team"
                          value={formData.team}
                          label="Team"
                          onChange={handleChange}
                        >
                          <MenuItem value="">Unassigned</MenuItem>
                          {teams.map(team => (
                            <MenuItem key={team._id} value={team._id}>
                              {team.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                ) : (
                  // View Mode
                  <>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Description
                      </Typography>
                      <Typography variant="body1">
                        {request.description}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Subject
                      </Typography>
                      <Typography variant="body1">
                        {request.subject || '-'}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Status
                      </Typography>
                      <Chip
                        label={request.status}
                        color={getStatusColor(request.status)}
                        size="small"
                        sx={{ textTransform: 'capitalize' }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Priority
                      </Typography>
                      <Chip
                        label={request.priority}
                        color={getPriorityColor(request.priority)}
                        size="small"
                        sx={{ textTransform: 'capitalize' }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Type
                      </Typography>
                      <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                        {request.type || '-'}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Category
                      </Typography>
                      <Typography variant="body1">
                        {request.category || '-'}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Assigned Team
                      </Typography>
                      <Typography variant="body1">
                        {request.team?.name || '-'}
                      </Typography>
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    Requestor
                  </Typography>
                  <Typography variant="body1">
                    {request.requestor.name} ({request.requestor.email})
                  </Typography>
                </Grid>

                {request.workflowInstance && (
                  <Grid item xs={12}>
                    <Box sx={{ 
                      mt: 2, 
                      p: 2, 
                      bgcolor: 'action.hover',
                      borderRadius: 1
                    }}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Active Workflow
                      </Typography>
                      <Typography variant="body1">
                        {request.workflowInstance.templateId.name}
                      </Typography>
                      <LinearProgress 
                        variant="determinate" 
                        value={request.workflowInstance.progress} 
                        sx={{ mt: 1, borderRadius: 1 }}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            )}

            {activeTab === 1 && (
              <Box sx={{ mt: 2 }}>
                <Notes 
                  entityType="request" 
                  entityId={request._id} 
                />
              </Box>
            )}

            {activeTab === 2 && request.workflowInstance && (
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={3}>
                  {/* Workflow Info */}
                  <Grid item xs={12}>
                    <Box sx={{ mb: 3 }}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Workflow Name
                      </Typography>
                      <Typography variant="body1">
                        {request.workflowInstance.templateId.name}
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                          Progress
                        </Typography>
                        <LinearProgress 
                          variant="determinate" 
                          value={request.workflowInstance.progress} 
                          sx={{ borderRadius: 1 }}
                        />
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          {request.workflowInstance.progress}% Complete
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  {/* Workflow Diagram */}
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Workflow Diagram
                    </Typography>
                    <Paper sx={{ height: 400, p: 2 }}>
                      <WorkflowDiagram 
                        tasks={getWorkflowTasks(request.workflowInstance.tasks)}
                        readonly={true}
                      />
                    </Paper>
                  </Grid>

                  {/* Task List */}
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Tasks
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Task Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Assignee</TableCell>
                            <TableCell>Due Date</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {request.workflowInstance.tasks?.map((task) => (
                            <TableRow key={task._id}>
                              <TableCell>{task.name}</TableCell>
                              <TableCell>
                                <Chip
                                  label={task.status}
                                  color={task.status === 'completed' ? 'success' : 'warning'}
                                  size="small"
                                />
                              </TableCell>
                              <TableCell>
                                {task.assignee ? 
                                  `${task.assignee.type}: ${task.assignee.userId || task.assignee.teamId}` 
                                  : '-'}
                              </TableCell>
                              <TableCell>
                                {task.dueDate ? new Date(task.dueDate).toLocaleDateString() : '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Box>
            )}
          </>
        ) : null}
      </DialogContent>

      {isEditing && activeTab === 0 && (
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSave} variant="contained" disabled={loading}>
            Save Changes
          </Button>
        </DialogActions>
      )}

      {/* Workflow Creation Modal */}
      {request && (
        <CreateWorkflowModal
          open={showWorkflowModal}
          onClose={() => setShowWorkflowModal(false)}
          requestId={request._id}
          requestNumber={request.requestNumber || ''}
          onSuccess={fetchRequest}
        />
      )}
    </Dialog>
  );
};

export default RequestDetail;

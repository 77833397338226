import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import { Calendar } from '../../../types/calendar';
import calendarService from '../../../services/calendarService';
import CalendarForm from './CalendarForm';

const CalendarList: React.FC = () => {
  const [calendars, setCalendars] = useState<Calendar[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState<Calendar | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchCalendars = async () => {
    try {
      setLoading(true);
      const data = await calendarService.getAll();
      setCalendars(data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch calendars');
      console.error('Error fetching calendars:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCalendars();
  }, []);

  const handleFormSuccess = () => {
    fetchCalendars();
  };

  const handleFormClose = () => {
    setIsFormOpen(false);
    setSelectedCalendar(null);
  };

  const getScheduleLabel = (calendar: Calendar) => {
    const { scheduleType, workingDays } = calendar;
    
    switch (scheduleType) {
      case '24/7':
        return <Chip label="24/7 Operation" color="primary" />;
      case '8/5':
        return <Chip label="8/5 Operation" color="secondary" />;
      case 'custom': {
        const workingDaysCount = workingDays.filter(d => d.isWorking).length;
        return (
          <Chip 
            label={`Custom (${workingDaysCount} days)`} 
            color="default"
            title={workingDays
              .filter(d => d.isWorking)
              .map(d => `${d.day}: ${d.workingHours.start}-${d.workingHours.end}`)
              .join('\n')
            }
          />
        );
      }
      default:
        return <Chip label="Unknown Schedule" color="error" />;
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ height: '100%', width: '100%', p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h5" component="h1">
          Calendars
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setSelectedCalendar(null);
            setIsFormOpen(true);
          }}
        >
          Create Calendar
        </Button>
      </Box>

      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Schedule</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? calendars.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : calendars
              ).map((calendar) => (
                <TableRow key={calendar._id}>
                  <TableCell>{calendar.name}</TableCell>
                  <TableCell>{calendar.description}</TableCell>
                  <TableCell>{getScheduleLabel(calendar)}</TableCell>
                  <TableCell>
                    <Chip 
                      label={calendar.isActive ? 'Active' : 'Inactive'} 
                      color={calendar.isActive ? 'success' : 'default'} 
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setSelectedCalendar(calendar);
                        setIsFormOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={calendars.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}

      <CalendarForm
        open={isFormOpen}
        onClose={handleFormClose}
        onSuccess={handleFormSuccess}
        calendar={selectedCalendar || undefined}
      />
    </Box>
  );
};

export default CalendarList;

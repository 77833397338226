import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Button,
  Chip,
  Grid,
  CircularProgress,
  Tabs,
  Tab,
  Alert,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
  Snackbar
} from '@mui/material';
import {
  ArrowBack as BackIcon,
  PlayArrow as ProcessIcon,
  ImportExport as ImportIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';
import { format, isValid } from 'date-fns';
import { discoveryService } from '../../services/discoveryService';
import { RawScanData } from '../../types/discovery';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scan-data-tabpanel-${index}`}
      aria-labelledby={`scan-data-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const STATUS_OPTIONS = [
  { value: 'pending', label: 'Pending', color: 'warning' },
  { value: 'processing', label: 'Processing', color: 'info' },
  { value: 'processed', label: 'Processed', color: 'primary' },
  { value: 'analyzed', label: 'Analyzed', color: 'secondary' },
  { value: 'completed', label: 'Completed', color: 'success' },
  { value: 'failed', label: 'Failed', color: 'error' }
];

const ScanDataDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [scanData, setScanData] = useState<RawScanData | null>(null);
  const [tabValue, setTabValue] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState<{
    hostname: string;
    status: string;
    notes: string;
  }>({
    hostname: '',
    status: '',
    notes: ''
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error' | 'info' | 'warning'
  });

  const loadScanData = async (forceLoadRawData?: boolean) => {
    if (!id) return;
    
    setLoading(true);
    try {
      const response = await discoveryService.getScanDataById(id, forceLoadRawData);
      setScanData(response.data);
      setEditData({
        hostname: response.data.hostname || '',
        status: response.data.status || 'pending',
        notes: response.data.notes || ''
      });
    } catch (error) {
      console.error('Failed to load scan data details:', error);
      showSnackbar('Failed to load scan data details', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadScanData();
  }, [id]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleProcessScan = async () => {
    if (!id) return;
    
    try {
      await discoveryService.updateScanDataStatus(id, 'processing');
      loadScanData();
      showSnackbar('Scan processing started', 'info');
    } catch (error) {
      console.error('Failed to process scan data:', error);
      showSnackbar('Failed to process scan data', 'error');
    }
  };

  const handleDeleteScan = async () => {
    if (!id) return;
    
    if (window.confirm('Are you sure you want to delete this scan data?')) {
      try {
        await discoveryService.deleteScanData(id);
        showSnackbar('Scan data deleted successfully', 'success');
        navigate('/cmdb/discovery/scan-data');
      } catch (error) {
        console.error('Failed to delete scan data:', error);
        showSnackbar('Failed to delete scan data', 'error');
      }
    }
  };

  const handleEnterEditMode = () => {
    if (scanData) {
      setEditData({
        hostname: scanData.hostname || '',
        status: scanData.status || 'pending',
        notes: scanData.notes || ''
      });
      setEditMode(true);
    }
  };

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  const handleSaveChanges = async () => {
    if (!id) return;
    
    try {
      setLoading(true);
      await discoveryService.updateScanData(id, {
        hostname: editData.hostname,
        status: editData.status,
        notes: editData.notes
      });
      await loadScanData();
      setEditMode(false);
      showSnackbar('Scan data updated successfully', 'success');
    } catch (error) {
      console.error('Failed to update scan data:', error);
      showSnackbar('Failed to update scan data', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleEditChange = (field: string, value: string) => {
    setEditData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const getStatusColor = (status: string) => {
    const statusOption = STATUS_OPTIONS.find(opt => opt.value === status);
    return statusOption?.color || 'default';
  };

  const formatDateSafely = (dateString: string | null | undefined): string => {
    if (!dateString) return 'N/A';
    
    const date = new Date(dateString);
    if (!isValid(date)) return 'Invalid date';
    
    return format(date, 'yyyy-MM-dd HH:mm:ss');
  };

  const showSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({
      ...prev,
      open: false
    }));
  };

  if (loading && !scanData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!scanData) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">Scan data not found</Alert>
        <Button
          startIcon={<BackIcon />}
          onClick={() => navigate('/cmdb/discovery/scan-data')}
          sx={{ mt: 2 }}
        >
          Back to Scan Data List
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Button
          startIcon={<BackIcon />}
          onClick={() => navigate('/cmdb/discovery/scan-data')}
        >
          Back to Scan Data List
        </Button>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {!editMode && (
            <>
              <Button
                variant="outlined"
                startIcon={<EditIcon />}
                onClick={handleEnterEditMode}
              >
                Edit
              </Button>
              {scanData.status === 'pending' && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<ProcessIcon />}
                  onClick={handleProcessScan}
                >
                  Process
                </Button>
              )}
              {(scanData.status === 'processed' || scanData.status === 'analyzed') && (
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<ImportIcon />}
                  onClick={() => navigate(`/cmdb/discovery/import/${scanData._id}`)}
                >
                  Import to CMDB
                </Button>
              )}
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteScan}
              >
                Delete
              </Button>
            </>
          )}
          {editMode && (
            <>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={handleSaveChanges}
              >
                Save Changes
              </Button>
              <Button
                variant="outlined"
                startIcon={<CancelIcon />}
                onClick={handleCancelEdit}
              >
                Cancel
              </Button>
            </>
          )}
        </Box>
      </Box>

      <Paper sx={{ mb: 3 }}>
        <Box sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>
            Scan Data Details
            {loading && <CircularProgress size={20} sx={{ ml: 2 }} />}
          </Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" color="text.secondary">
                IP Address
              </Typography>
              <Typography variant="body1">{scanData.ipAddress}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" color="text.secondary">
                Hostname
              </Typography>
              {editMode ? (
                <TextField
                  size="small"
                  fullWidth
                  value={editData.hostname}
                  onChange={(e) => handleEditChange('hostname', e.target.value)}
                />
              ) : (
                <Typography variant="body1">{scanData.hostname || 'N/A'}</Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" color="text.secondary">
                Scan ID
              </Typography>
              <Typography variant="body1">{scanData.scanId}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" color="text.secondary">
                Status
              </Typography>
              {editMode ? (
                <FormControl fullWidth size="small">
                  <Select
                    value={editData.status}
                    onChange={(e) => handleEditChange('status', e.target.value)}
                  >
                    {STATUS_OPTIONS.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <Chip
                  label={STATUS_OPTIONS.find(s => s.value === scanData.status)?.label || scanData.status}
                  color={getStatusColor(scanData.status) as any}
                  size="small"
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" color="text.secondary">
                Scan Date
              </Typography>
              <Typography variant="body1">
                {formatDateSafely(scanData.scanTimestamp)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" color="text.secondary">
                Processed Date
              </Typography>
              <Typography variant="body1">
                {scanData.processedAt
                  ? formatDateSafely(scanData.processedAt)
                  : 'Not processed yet'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" color="text.secondary">
                Analyzed Date
              </Typography>
              <Typography variant="body1">
                {scanData.analyzedAt
                  ? formatDateSafely(scanData.analyzedAt)
                  : 'Not analyzed yet'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" color="text.secondary">
                Completed Date
              </Typography>
              <Typography variant="body1">
                {scanData.completedAt
                  ? formatDateSafely(scanData.completedAt)
                  : 'Not completed yet'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="text.secondary">
                Notes
              </Typography>
              {editMode ? (
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  value={editData.notes}
                  onChange={(e) => handleEditChange('notes', e.target.value)}
                />
              ) : (
                <Typography variant="body1">
                  {scanData.notes || 'No notes'}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Paper>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="scan data tabs">
                  <Tab label="Raw Data" id="scan-data-tab-0" aria-controls="scan-data-tabpanel-0" />
                  {scanData.processingErrors && scanData.processingErrors.length > 0 && (
                    <Tab label="Processing Errors" id="scan-data-tab-1" aria-controls="scan-data-tabpanel-1" />
                  )}
                  {scanData.relatedCIs && scanData.relatedCIs.length > 0 && (
              <Tab label="Related CIs" id={`scan-data-tab-${scanData.processingErrors && scanData.processingErrors.length > 0 ? 2 : 1}`} 
                  aria-controls={`scan-data-tabpanel-${scanData.processingErrors && scanData.processingErrors.length > 0 ? 2 : 1}`} />
            )}
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <Typography variant="subtitle1" gutterBottom>
            Raw JSON Data
          </Typography>
          {scanData.rawData ? (
            <Paper
              elevation={0}
              sx={{
                p: 2,
                bgcolor: 'grey.100',
                maxHeight: '500px',
                overflow: 'auto',
                fontFamily: 'monospace',
                whiteSpace: 'pre-wrap',
                fontSize: '0.875rem'
              }}
            >
              {JSON.stringify(scanData.rawData, null, 2)}
            </Paper>
          ) : (
            <Box sx={{ mt: 2 }}>
              <Alert severity="info">
                Raw data is not included in the response. 
                <Button 
                  color="primary" 
                  size="small" 
                  onClick={() => loadScanData(true)}
                  sx={{ ml: 1 }}
                >
                  Load Raw Data
                </Button>
              </Alert>
            </Box>
          )}
        </TabPanel>
        {scanData.processingErrors && scanData.processingErrors.length > 0 && (
          <TabPanel value={tabValue} index={1}>
            <Typography variant="subtitle1" gutterBottom>
              Processing Errors
            </Typography>
            <Alert severity="error" sx={{ mb: 2 }}>
              The following errors occurred during processing:
            </Alert>
            <Paper
              elevation={0}
              sx={{
                p: 2,
                bgcolor: 'grey.100',
                maxHeight: '500px',
                overflow: 'auto'
              }}
            >
              <ul>
                {scanData.processingErrors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </Paper>
          </TabPanel>
        )}
        {scanData.relatedCIs && scanData.relatedCIs.length > 0 && (
           <TabPanel value={tabValue} index={scanData.processingErrors && scanData.processingErrors.length > 0 ? 2 : 1}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="subtitle1">
                Related Configuration Items
              </Typography>
              {editMode && (
                <Button 
                  variant="outlined" 
                  size="small"
                  onClick={() => {
                    // This would open a dialog to add CIs
                    showSnackbar('This functionality will be implemented by the rule engine', 'info');
                  }}
                >
                  Manage Related CIs
                </Button>
              )}
            </Box>
            <Paper
              elevation={0}
              sx={{
                p: 2,
                bgcolor: 'grey.100',
                maxHeight: '500px',
                overflow: 'auto'
              }}
            >
              <Box component="ul" sx={{ pl: 2 }}>
                {scanData.relatedCIs.map((ciId, index) => (
                  <Box 
                    component="li" 
                    key={index}
                    sx={{ 
                      mb: 1, 
                      display: 'flex', 
                      alignItems: 'center',
                      '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' }
                    }}
                  >
                    <Button
                      variant="text"
                      onClick={() => navigate(`/cmdb/items/${ciId}`)}
                      sx={{ textTransform: 'none' }}
                    >
                      {ciId}
                    </Button>
                    {editMode && (
                      <IconButton 
                        size="small" 
                        color="error"
                        onClick={() => {
                          // This would remove the CI from the relatedCIs array
                          showSnackbar('This functionality will be implemented by the rule engine', 'info');
                        }}
                        sx={{ ml: 1 }}
                      >
                        <CancelIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                ))}
              </Box>
            </Paper>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary">
                These Configuration Items were created or updated based on this scan data. 
                The relationship is maintained by the rule engine.
              </Typography>
            </Box>
          </TabPanel>
        )}
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
      />
    </Box>
  );
};

export default ScanDataDetail;
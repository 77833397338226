import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Paper,
  Chip,
  CircularProgress,
  Alert,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Edit as EditIcon, Close as CloseIcon } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getKnowledgeBase, updateKnowledgeBase, createKnowledgeBase } from '../../services/knowledgeBaseService';

interface KnowledgeBase {
  _id: string;
  title: string;
  content: string;
  keywords: string[];
  createdBy: {
    name: string;
    email: string;
  };
  updatedBy?: {
    name: string;
    email: string;
  };
  createdAt: string;
  updatedAt: string;
}

interface FormValues {
  title: string;
  content: string;
  keywords: string[];
}

interface KnowledgeBaseDetailProps {
  open: boolean;
  knowledgeBaseId: string | null;
  onClose: () => void;
  onKnowledgeBaseUpdated: () => void;
}

const validationSchema = Yup.object({
  title: Yup.string().required('Title is required'),
  content: Yup.string().required('Content is required'),
  keywords: Yup.array().of(Yup.string()),
});

const KnowledgeBaseDetail: React.FC<KnowledgeBaseDetailProps> = ({
  open,
  knowledgeBaseId,
  onClose,
  onKnowledgeBaseUpdated,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentKeyword, setCurrentKeyword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [knowledgeBase, setKnowledgeBase] = useState<KnowledgeBase | null>(null);

  const formik = useFormik<FormValues>({
    initialValues: {
      title: '',
      content: '',
      keywords: [],
    },
    validationSchema,
    enableReinitialize: false,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        setError(null);

        const payload = {
          title: values.title.trim(),
          content: values.content.trim(),
          keywords: values.keywords.map(k => k.trim()).filter(k => k),
        };

        if (knowledgeBaseId) {
          await updateKnowledgeBase(knowledgeBaseId, {
            ...payload,
            updatedBy: localStorage.getItem('userId') || null
          });
        } else {
          await createKnowledgeBase({
            ...payload,
            createdBy: localStorage.getItem('userId')
          });
        }
        
        onKnowledgeBaseUpdated();
        setIsEditing(false);
        onClose();
      } catch (error: any) {
        console.error('Error saving knowledge base:', error);
        const errorMessage = error.response?.data?.error || 'Error saving knowledge base';
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    },
  });

  const fetchData = useCallback(async () => {
    if (knowledgeBaseId) {
      try {
        setLoading(true);
        setError(null);
        const response = await getKnowledgeBase(knowledgeBaseId);
        const data = response.data;
        setKnowledgeBase(data);
        
        if (isEditing) {
          formik.setValues({
            title: data.title || '',
            content: data.content || '',
            keywords: Array.isArray(data.keywords) ? data.keywords : []
          }, false);
        }
      } catch (error: any) {
        console.error('Error fetching knowledge base:', error);
        const errorMessage = error.response?.data?.error || 'Error fetching knowledge base';
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  }, [knowledgeBaseId, isEditing]);

  useEffect(() => {
    if (open) {
      if (knowledgeBaseId) {
        fetchData();
      } else {
        // New entry
        setKnowledgeBase(null);
        setIsEditing(true);
        formik.resetForm();
      }
    } else {
      setKnowledgeBase(null);
      setIsEditing(false);
      setError(null);
      formik.resetForm();
    }
  }, [open, knowledgeBaseId]);

  const handleKeywordKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && currentKeyword.trim()) {
      event.preventDefault();
      const newKeyword = currentKeyword.trim();
      if (!formik.values.keywords.includes(newKeyword)) {
        formik.setFieldValue('keywords', [...formik.values.keywords, newKeyword]);
      }
      setCurrentKeyword('');
    }
  };

  const handleDeleteKeyword = (keywordToDelete: string) => {
    formik.setFieldValue(
      'keywords',
      formik.values.keywords.filter((keyword: string) => keyword !== keywordToDelete)
    );
  };

  const handleEdit = () => {
    setIsEditing(true);
    if (knowledgeBase) {
      formik.setValues({
        title: knowledgeBase.title,
        content: knowledgeBase.content,
        keywords: knowledgeBase.keywords
      });
    }
  };

  const handleClose = () => {
    setIsEditing(false);
    onClose();
  };

  const renderContent = () => {
    if (loading && !knowledgeBase) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
          <CircularProgress />
        </Box>
      );
    }

    if (!isEditing && knowledgeBase) {
      return (
        <>
          <DialogTitle>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">{knowledgeBase.title}</Typography>
              <Box>
                <IconButton onClick={handleEdit} color="primary" title="Edit">
                  <EditIcon />
                </IconButton>
                <IconButton onClick={handleClose} color="default" title="Close">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box mb={3}>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Created by {knowledgeBase.createdBy.name} on {new Date(knowledgeBase.createdAt).toLocaleDateString()}
                {knowledgeBase.updatedBy && 
                  ` • Last updated by ${knowledgeBase.updatedBy.name} on ${new Date(knowledgeBase.updatedAt).toLocaleDateString()}`
                }
              </Typography>
            </Box>

            <Box mb={3}>
              {knowledgeBase.keywords.map((keyword, index) => (
                <Chip
                  key={index}
                  label={keyword}
                  sx={{ mr: 1, mb: 1 }}
                />
              ))}
            </Box>

            <Box 
              dangerouslySetInnerHTML={{ __html: knowledgeBase.content }} 
              sx={{ 
                '& a': { color: 'primary.main' },
                '& img': { maxWidth: '100%' }
              }}
            />
          </DialogContent>
        </>
      );
    }

    return (
      <>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">
              {knowledgeBaseId ? 'Edit Knowledge Base Entry' : 'New Knowledge Base Entry'}
            </Typography>
            <IconButton onClick={handleClose} color="default" title="Close">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <form id="knowledge-base-form" onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="title"
                  name="title"
                  label="Title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="keyword-input"
                  label="Add Keywords (Press Enter)"
                  value={currentKeyword}
                  onChange={(e) => setCurrentKeyword(e.target.value)}
                  onKeyPress={handleKeywordKeyPress}
                  disabled={loading}
                />
                <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {formik.values.keywords.map((keyword: string, index: number) => (
                    <Chip
                      key={index}
                      label={keyword}
                      onDelete={() => handleDeleteKeyword(keyword)}
                      disabled={loading}
                    />
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <ReactQuill
                  theme="snow"
                  value={formik.values.content}
                  onChange={(content: string) => formik.setFieldValue('content', content)}
                  modules={{
                    toolbar: [
                      [{ 'header': [1, 2, false] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                      ['link', 'code'],
                      ['clean']
                    ],
                  }}
                  style={{ height: '300px', marginBottom: '50px' }}
                />
                {formik.touched.content && formik.errors.content && (
                  <Typography color="error" variant="caption">
                    {formik.errors.content}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsEditing(false)}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            form="knowledge-base-form"
            variant="contained"
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          height: '90vh',
        },
      }}
    >
      {renderContent()}
    </Dialog>
  );
};

export default KnowledgeBaseDetail;

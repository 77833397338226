import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Typography,
  Box,
  Chip,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import api from '../../api/axios';
import ConfirmDialog from '../common/ConfirmDialog';
// Remove imports for TeamDetail and NewTeam as they are no longer rendered here

interface User {
  _id: string;
  name: string;
  email: string;
}

interface Team {
  _id: string;
  name: string;
  description: string;
  members: User[];
}

// --- Add Props Interface ---
interface TeamListProps {
  onTeamSelect: (teamId: string) => void;
  onAddNewTeam: () => void;
  selectedTeamId?: string | null;
  isDetailViewOpen?: boolean; // Add the new prop
}
// --- End Props Interface ---

const TeamList: React.FC<TeamListProps> = ({ onTeamSelect, onAddNewTeam, selectedTeamId, isDetailViewOpen = false }) => { // Destructure the new prop, default to false
  const [teams, setTeams] = useState<Team[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState<Team | null>(null); // Rename state for clarity
  // Remove detailDialogOpen and newTeamDialogOpen states

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    try {
      const response = await api.get('/teams');
      setTeams(response.data);
    } catch (error) {
      console.error('Error fetching teams:', error);
    }
  };

  // --- Modify handleRowClick ---
  const handleRowClick = (team: Team) => {
    onTeamSelect(team._id); // Call prop function with ID
  };
  // --- End Modify handleRowClick ---

  const handleDelete = async (teamId: string) => {
    try {
      await api.delete(`/teams/${teamId}`);
      setTeams(teams.filter((team) => team._id !== teamId));
      setDeleteDialogOpen(false);
      setTeamToDelete(null); // Use renamed state
    } catch (error) {
      console.error('Error deleting team:', error);
    }
  };

  const handleDeleteClick = (event: React.MouseEvent, team: Team) => {
    event.stopPropagation(); // Prevent row click
    setTeamToDelete(team); // Use renamed state
    setDeleteDialogOpen(true);
  };

  // --- Modify handleEditClick ---
  const handleEditClick = (event: React.MouseEvent, team: Team) => {
    event.stopPropagation(); // Prevent row click
    onTeamSelect(team._id); // Call prop function to show detail view
    // The detail view itself should handle entering edit mode
  };

  const getTeamSizeColor = (memberCount: number) => {
    if (memberCount >= 10) return 'error';
    if (memberCount >= 5) return 'warning';
    if (memberCount >= 3) return 'success';
    return 'primary';
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5">Teams</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={onAddNewTeam} // Call prop function
        >
          Add Team
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {/* Conditionally render other header cells */}
              {!isDetailViewOpen && (
                <>
                  <TableCell>Description</TableCell>
                  <TableCell>Members</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {teams.map((team) => (
              <TableRow
                key={team._id}
                onClick={() => handleRowClick(team)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: 'action.hover' },
                  // Optional: Highlight selected row
                  backgroundColor: selectedTeamId === team._id ? 'action.selected' : 'inherit'
                 }}
              >
                <TableCell>{team.name}</TableCell>
                {/* Conditionally render other data cells */}
                {!isDetailViewOpen && (
                  <>
                    <TableCell>{team.description}</TableCell>
                    <TableCell>
                      <Chip
                        label={`${team.members.length} members`}
                        color={getTeamSizeColor(team.members.length)}
                        size="small"
                      />
                      {team.members.slice(0, 3).map((member) => (
                        <Chip
                          key={member._id}
                          label={member.name}
                          size="small"
                          variant="outlined"
                          sx={{ ml: 1 }}
                        />
                      ))}
                      {team.members.length > 3 && (
                        <Chip
                          label={`+${team.members.length - 3}`}
                          size="small"
                          variant="outlined"
                          sx={{ ml: 1 }}
                        />
                      )}
                    </TableCell>
                  </>
                )}
                {/* Conditionally render Actions cell */}
                {!isDetailViewOpen && (
                  <TableCell align="right">
                    <IconButton
                      size="small"
                      title="Edit Team" // Add title for accessibility
                      onClick={(e) => handleEditClick(e, team)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      title="Delete Team" // Add title for accessibility
                      onClick={(e) => handleDeleteClick(e, team)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Remove TeamDetail Dialog */}

      <ConfirmDialog
        open={deleteDialogOpen}
        title="Delete Team"
        content={`Are you sure you want to delete ${teamToDelete?.name}?`} // Use renamed state
        onConfirm={() => teamToDelete && handleDelete(teamToDelete._id)} // Use renamed state
        onCancel={() => {
          setDeleteDialogOpen(false);
          setTeamToDelete(null); // Use renamed state
        }}
      />

      {/* Remove NewTeam Dialog */}
    </Box>
  );
};

export default TeamList;

import React, { memo } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { Card, CardContent, Typography, Chip, Box } from '@mui/material';
import {
  CheckCircle as ApprovalIcon,
  PlayArrow as ActionIcon,
  Notifications as NotificationIcon,
  AccessTime as DurationIcon,
  Flag as PriorityIcon
} from '@mui/icons-material';

const getTaskIcon = (type: string) => {
  switch (type) {
    case 'approval':
      return <ApprovalIcon fontSize="small" color="primary" />;
    case 'notification':
      return <NotificationIcon fontSize="small" color="info" />;
    default:
      return <ActionIcon fontSize="small" color="action" />;
  }
};

const getPriorityColor = (priority: string) => {
  switch (priority) {
    case 'high':
      return 'error';
    case 'medium':
      return 'warning';
    case 'low':
      return 'success';
    default:
      return 'default';
  }
};

const TaskNode = ({ data }: NodeProps) => {
  return (
    <>
      <Handle type="target" position={Position.Left} />
      <Card sx={{ 
        width: '100%',
        border: '1px solid',
        borderColor: 'primary.main',
        borderRadius: 1,
        '&:hover': {
          boxShadow: 2,
        }
      }}>
        <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
            {getTaskIcon(data.type)}
            <Typography variant="body2" component="div" sx={{ 
              fontWeight: 'medium',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
              {data.name}
            </Typography>
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, flexWrap: 'wrap' }}>
            <Chip
              icon={<DurationIcon fontSize="small" />}
              label={`${data.duration}h`}
              size="small"
              variant="outlined"
              sx={{ height: 20, '& .MuiChip-label': { px: 1, fontSize: '0.7rem' } }}
            />
            <Chip
              icon={<PriorityIcon fontSize="small" />}
              label={data.priority}
              size="small"
              color={getPriorityColor(data.priority)}
              sx={{ height: 20, '& .MuiChip-label': { px: 1, fontSize: '0.7rem' } }}
            />
          </Box>
        </CardContent>
      </Card>
      <Handle type="source" position={Position.Right} />
    </>
  );
};

export default memo(TaskNode);
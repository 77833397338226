import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  IconButton,
  FormHelperText
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { AssociatedCollection } from '../../types/cmdb';
import { collectionService } from '../../services/collectionService';
import { ciTypeService } from '../../services/ciTypeService';
import { useSnackbar } from 'notistack';

interface CITypeCollectionAssociationsProps {
  ciTypeId: string;
}

export const CITypeCollectionAssociations: React.FC<CITypeCollectionAssociationsProps> = ({ ciTypeId }) => {
  const [collections, setCollections] = useState<AssociatedCollection[]>([]);
  const [availableCollections, setAvailableCollections] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState<string>('');
  const [formData, setFormData] = useState<Partial<AssociatedCollection>>({
    name: '',
    model: '',
    label: '',
    description: '',
    displayOrder: 0,
    enabled: true
  });
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // Load associated collections and available collections
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Get available collections
        const availableResponse = await collectionService.getAvailableCollections();
        console.log('Available collections:', availableResponse);
        setAvailableCollections(availableResponse);
        
        // Get CI Type details including associated collections
        const ciTypeData = await ciTypeService.getType(ciTypeId);
        console.log('CI Type data:', ciTypeData);
        console.log('Associated collections from CI Type:', ciTypeData.associatedCollections);
        setCollections(ciTypeData.associatedCollections || []);
      } catch (error) {
        console.error('Error loading collections:', error);
        enqueueSnackbar('Failed to load collections', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, [ciTypeId, enqueueSnackbar]);

  // Handle form input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name as string]: value
    });
  };

  // Handle collection selection
  const handleCollectionSelect = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    setSelectedCollection(selectedValue);
    
    const selected = availableCollections.find(c => c.model === selectedValue);
    console.log('Selected collection from available collections:', selected);
    
    if (selected) {
      // Ensure we use the exact model name as defined in the backend
      const formDataUpdate = {
        name: selected.name,
        model: selected.model, // This preserves the exact case
        label: selected.label,
        description: selected.description || '',
        displayOrder: 0,
        enabled: true
      };
      
      console.log('Setting form data with exact model name:', formDataUpdate);
      setFormData(formDataUpdate);
    }
  };

  // Associate a new collection
  const handleAssociateCollection = async () => {
    try {
      setLoading(true);
      console.log('Associating collection with data:', formData);
      console.log('CI Type ID:', ciTypeId);
      
      // Use the collectionService to associate the collection
      const response = await collectionService.associateCollection(ciTypeId, formData);
      console.log('Association response:', response);
      
      if (response && response.associatedCollections) {
        console.log('New associated collections:', response.associatedCollections);
        setCollections(response.associatedCollections);
      } else {
        console.warn('No associatedCollections in response:', response);
        // Force a refresh to get the latest data
        const refreshedData = await ciTypeService.getType(ciTypeId);
        console.log('Refreshed CI Type data:', refreshedData);
        if (refreshedData.associatedCollections) {
          console.log('Refreshed associated collections:', refreshedData.associatedCollections);
          setCollections(refreshedData.associatedCollections);
        }
      }
      
      setModalOpen(false);
      setFormData({
        name: '',
        model: '',
        label: '',
        description: '',
        displayOrder: 0,
        enabled: true
      });
      setSelectedCollection('');
      enqueueSnackbar('Collection associated successfully', { variant: 'success' });
      
      // Force a page refresh to ensure all components are updated
      window.location.reload();
    } catch (error) {
      console.error('Error associating collection:', error);
      enqueueSnackbar('Failed to associate collection', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // Remove a collection association
  const handleRemoveCollection = async (collectionId: string) => {
    try {
      setLoading(true);
      console.log(`Removing collection with ID ${collectionId} from CI Type ${ciTypeId}`);
      
      await ciTypeService.removeAssociatedCollection(ciTypeId, collectionId);
      console.log('Collection removed successfully');
      
      setCollections(collections.filter(c => c._id !== collectionId));
      enqueueSnackbar('Collection association removed', { variant: 'success' });
      
      // Refresh the CI Type data in the parent component
      if (window.location.pathname.includes('/cmdb/types/')) {
        // Force a page refresh to ensure all components are updated
        window.location.reload();
      }
    } catch (error) {
      console.error('Error removing collection association:', error);
      enqueueSnackbar('Failed to remove collection association', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // Filter out already associated collections
  const getFilteredCollections = () => {
    const associatedModels = collections.map(c => c.model);
    return availableCollections.filter(c => !associatedModels.includes(c.model));
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">Associated Collections</Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => setModalOpen(true)}
          disabled={getFilteredCollections().length === 0 || loading}
        >
          Associate Collection
        </Button>
      </Box>
      
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Label</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Display Order</TableCell>
              <TableCell>Enabled</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collections.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No collections associated
                </TableCell>
              </TableRow>
            ) : (
              collections.map((collection) => (
                <TableRow key={collection._id}>
                  <TableCell>{collection.label}</TableCell>
                  <TableCell>{collection.model}</TableCell>
                  <TableCell>{collection.description}</TableCell>
                  <TableCell>{collection.displayOrder}</TableCell>
                  <TableCell>{collection.enabled ? 'Yes' : 'No'}</TableCell>
                  <TableCell>
                    <IconButton 
                      color="error" 
                      onClick={() => handleRemoveCollection(collection._id!)}
                      disabled={loading}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      
      {/* Associate Collection Dialog */}
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Associate Collection</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Collection</InputLabel>
              <Select
                value={selectedCollection}
                onChange={handleCollectionSelect}
                label="Collection"
                disabled={loading}
              >
                {getFilteredCollections().map((collection) => (
                  <MenuItem key={collection.model} value={collection.model}>
                    {collection.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select a collection to associate</FormHelperText>
            </FormControl>
            
            <TextField
              fullWidth
              margin="dense"
              name="name"
              label="Name"
              value={formData.name}
              onChange={handleInputChange}
              disabled={loading}
              sx={{ mb: 2 }}
            />
            
            <TextField
              fullWidth
              margin="dense"
              name="label"
              label="Label"
              value={formData.label}
              onChange={handleInputChange}
              disabled={loading}
              sx={{ mb: 2 }}
            />
            
            <TextField
              fullWidth
              margin="dense"
              name="description"
              label="Description"
              value={formData.description}
              onChange={handleInputChange}
              multiline
              rows={2}
              disabled={loading}
              sx={{ mb: 2 }}
            />
            
            <TextField
              fullWidth
              margin="dense"
              name="displayOrder"
              label="Display Order"
              type="number"
              value={formData.displayOrder}
              onChange={handleInputChange}
              disabled={loading}
              sx={{ mb: 2 }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} disabled={loading}>
            Cancel
          </Button>
          <Button 
            onClick={handleAssociateCollection} 
            variant="contained" 
            color="primary"
            disabled={!selectedCollection || loading}
          >
            Associate
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
import api from './api';
import { CIType } from '../types/cmdb';

class CITypeService {
  async getAllTypes() {
    console.log('Fetching all CI types...');
    const response = await api.get('/cmdb/types');
    console.log('Received CI types:', response.data);
    return response.data;
  }

  async getType(id: string) {
    console.log(`Fetching CI type with ID: ${id}`);
    try {
      const response = await api.get(`/cmdb/types/${id}`);
      console.log('Raw CI type response data:', response.data);
      
      // Check if associatedCollections exists
      if (response.data.associatedCollections) {
        console.log('Associated collections found:', response.data.associatedCollections);
      } else {
        console.warn('No associatedCollections property in response data');
      }
      
      console.log('Received CI type details:', {
        id: response.data._id,
        name: response.data.name,
        customFields: response.data.customFields.map((f: any) => ({
          name: f.name,
          label: f.label,
          type: f.type,
          required: f.required,
          validation: f.validation
        }))
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching CI type:', error);
      throw error;
    }
  }

  async createType(data: Partial<CIType>) {
    const response = await api.post('/cmdb/types', data);
    return response.data;
  }

  async updateType(id: string, data: Partial<CIType>) {
    const response = await api.put(`/cmdb/types/${id}`, data);
    return response.data;
  }

  async deleteType(id: string) {
    const response = await api.delete(`/cmdb/types/${id}`);
    return response.data;
  }

  // Remove an associated collection
  async removeAssociatedCollection(ciTypeId: string, collectionId: string) {
    const response = await api.delete(`/cmdb/citypes/${ciTypeId}/collections/${collectionId}`);
    return response.data;
  }
}

export const ciTypeService = new CITypeService();

import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  CircularProgress,
  Alert,
  SelectChangeEvent,
  Typography,
  IconButton
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import reportService from '../../services/reportService';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

interface ReportFormValues {
  name: string;
  description: string;
  category: string;
  visualization: {
    type: string;
    options: Record<string, any>;
  };
  query: {
    collections: string[];
    aggregation: any[];
    filters: Array<{
      field: string;
      type: string;
      label: string;
      defaultValue: any;
      options?: any[];
    }>;
  };
}

interface ReportData extends ReportFormValues {
  _id: string;
}

const CATEGORIES = ['requests', 'workflows', 'tasks', 'teams', 'users'] as const;
const VISUALIZATION_TYPES = ['bar', 'line', 'pie', 'table', 'card', 'gauge'] as const;

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  category: yup.string().oneOf(CATEGORIES, 'Invalid category').required('Category is required'),
  visualization: yup.object({
    type: yup.string().oneOf(VISUALIZATION_TYPES, 'Invalid visualization type').required('Visualization type is required'),
  }),
});

interface ReportFormProps {
  initialData?: ReportData;
  onClose: () => void;
  onSuccess: () => void;
  useAI?: boolean;
}

// Helper function to get default query configuration based on category and visualization type
const getDefaultQuery = (category: string, visualizationType: string) => {
  switch (category) {
    case 'requests':
      return {
        collections: ['requests'],
        aggregation: [
          {
            $group: {
              _id: '$category',
              value: { $count: {} }
            }
          },
          {
            $sort: { value: -1 }
          }
        ],
        filters: []
      };
    // Add more cases for other categories as needed
    default:
      return {
        collections: [category],
        aggregation: [],
        filters: []
      };
  }
};

// Helper function to get default visualization options
const getDefaultVisualizationOptions = (type: string) => {
  const baseOptions = {
    xAxisKey: '_id',
    yAxisKey: 'value'
  };

  switch (type) {
    case 'bar':
      return {
        ...baseOptions,
        layout: 'vertical',
        height: 400
      };
    case 'line':
      return {
        ...baseOptions,
        height: 400
      };
    case 'pie':
      return {
        ...baseOptions,
        nameKey: '_id',
        dataKey: 'value',
        height: 400
      };
    default:
      return baseOptions;
  }
};

const ReportForm: React.FC<ReportFormProps> = ({
  initialData,
  onClose,
  onSuccess,
  useAI = false,
}) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: initialData || {
      name: '',
      description: '',
      category: CATEGORIES[0],
      visualization: {
        type: VISUALIZATION_TYPES[0],
        options: getDefaultVisualizationOptions(VISUALIZATION_TYPES[0])
      },
      query: getDefaultQuery(CATEGORIES[0], VISUALIZATION_TYPES[0])
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setError('');
        setLoading(true);

        if (useAI) {
          // For AI generation, we only need the description
          await reportService.generateReport(values.description);
          onSuccess();
        } else {
          // For manual creation/update
          const updatedValues = {
            ...values,
            query: getDefaultQuery(values.category, values.visualization.type),
            visualization: {
              ...values.visualization,
              options: getDefaultVisualizationOptions(values.visualization.type)
            }
          };

          if (initialData) {
            await reportService.updateTemplate(initialData._id, updatedValues);
          } else {
            await reportService.createTemplate(updatedValues);
          }
          onSuccess();
        }
      } catch (err) {
        console.error('Error saving report:', err);
        setError('Failed to save report template');
      } finally {
        setLoading(false);
      }
    },
  });

  // Update query and visualization options when category or visualization type changes
  const handleCategoryChange = (event: SelectChangeEvent) => {
    const newCategory = event.target.value;
    formik.setFieldValue('category', newCategory);
    formik.setFieldValue('query', getDefaultQuery(newCategory, formik.values.visualization.type));
  };

  const handleVisualizationChange = (event: SelectChangeEvent) => {
    const newType = event.target.value;
    formik.setFieldValue('visualization.type', newType);
    formik.setFieldValue('visualization.options', getDefaultVisualizationOptions(newType));
    formik.setFieldValue('query', getDefaultQuery(formik.values.category, newType));
  };

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={(formik.touched.name && formik.errors.name) as string}
          />
        </Grid>

        {useAI ? (
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="description"
              name="description"
              label="What would you like to analyze?"
              placeholder="e.g., Show me which teams have the most high-priority incidents in the last month"
              multiline
              rows={4}
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={
                (formik.touched.description && formik.errors.description) as string ||
                "Describe what you want to analyze and I'll generate the appropriate report"
              }
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="description"
                name="description"
                label="Description"
                multiline
                rows={4}
                value={formik.values.description}
                onChange={formik.handleChange}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={(formik.touched.description && formik.errors.description) as string}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="category-label">Category</InputLabel>
                <Select
                  labelId="category-label"
                  id="category"
                  name="category"
                  value={formik.values.category}
                  onChange={handleCategoryChange}
                  error={formik.touched.category && Boolean(formik.errors.category)}
                  label="Category"
                >
                  {CATEGORIES.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category.charAt(0).toUpperCase() + category.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="visualization-type-label">Visualization Type</InputLabel>
                <Select
                  labelId="visualization-type-label"
                  id="visualization.type"
                  name="visualization.type"
                  value={formik.values.visualization.type}
                  onChange={handleVisualizationChange}
                  error={
                    formik.touched.visualization?.type &&
                    Boolean(formik.errors.visualization?.type)
                  }
                  label="Visualization Type"
                >
                  {VISUALIZATION_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Query Configuration Section */}
            <Grid item xs={12}>
              <Box sx={{ border: 1, borderColor: 'divider', p: 2, borderRadius: 1 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>Query Configuration</Typography>
                
                {/* Collections */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="collections-label">Collections</InputLabel>
                  <Select
                    labelId="collections-label"
                    id="query.collections"
                    name="query.collections"
                    multiple
                    value={formik.values.query.collections}
                    onChange={formik.handleChange}
                    label="Collections"
                  >
                    {CATEGORIES.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Aggregation Pipeline */}
                <TextField
                  fullWidth
                  id="query.aggregation"
                  name="query.aggregation"
                  label="Aggregation Pipeline"
                  multiline
                  rows={4}
                  value={JSON.stringify(formik.values.query.aggregation, null, 2)}
                  onChange={(e) => {
                    try {
                      const pipeline = JSON.parse(e.target.value);
                      formik.setFieldValue('query.aggregation', pipeline);
                    } catch (err) {
                      // Invalid JSON, don't update
                    }
                  }}
                  placeholder={`[
  {
    "$group": {
      "_id": "$category",
      "count": { "$sum": 1 }
    }
  },
  {
    "$sort": { "count": -1 }
  }
]`}
                  sx={{ mb: 2 }}
                />

                {/* Filters */}
                <Box>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>Filters</Typography>
                  {formik.values.query.filters.map((filter, index) => (
                    <Box key={index} sx={{ mb: 2, display: 'flex', gap: 2 }}>
                      <TextField
                        label="Field"
                        value={filter.field}
                        onChange={(e) => {
                          const newFilters = [...formik.values.query.filters];
                          newFilters[index].field = e.target.value;
                          formik.setFieldValue('query.filters', newFilters);
                        }}
                        size="small"
                      />
                      <FormControl size="small">
                        <InputLabel>Type</InputLabel>
                        <Select
                          value={filter.type}
                          onChange={(e) => {
                            const newFilters = [...formik.values.query.filters];
                            newFilters[index].type = e.target.value;
                            formik.setFieldValue('query.filters', newFilters);
                          }}
                          label="Type"
                        >
                          <MenuItem value="string">String</MenuItem>
                          <MenuItem value="number">Number</MenuItem>
                          <MenuItem value="date">Date</MenuItem>
                          <MenuItem value="boolean">Boolean</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        label="Label"
                        value={filter.label}
                        onChange={(e) => {
                          const newFilters = [...formik.values.query.filters];
                          newFilters[index].label = e.target.value;
                          formik.setFieldValue('query.filters', newFilters);
                        }}
                        size="small"
                      />
                      <IconButton
                        onClick={() => {
                          const newFilters = formik.values.query.filters.filter((_, i) => i !== index);
                          formik.setFieldValue('query.filters', newFilters);
                        }}
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => {
                      const newFilters = [...formik.values.query.filters];
                      newFilters.push({
                        field: '',
                        type: 'string',
                        label: '',
                        defaultValue: null
                      });
                      formik.setFieldValue('query.filters', newFilters);
                    }}
                    variant="outlined"
                    size="small"
                  >
                    Add Filter
                  </Button>
                </Box>
              </Box>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              {initialData ? 'Update' : useAI ? 'Generate Report' : 'Create'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportForm;
import React, { useEffect, useState } from 'react';
import { useFormik, FormikErrors } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Tab,
  Tabs,
  FormControlLabel,
  Switch,
  IconButton,
  Autocomplete,
  FormHelperText,
  Card,
  CardContent,
  FormGroup
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import {
  FormWorkflowTemplate,
  TaskDefinition,
  User,
  Team,
  CustomField,
  FieldType,
  PriorityType,
  AssigneeType,
  TaskType
} from '../../types/workflow';
import workflowTemplateService from '../../services/workflowTemplateService';
import WorkflowDiagram from './WorkflowDiagram';

interface WorkflowTemplateFormProps {
  initialValues?: Partial<FormWorkflowTemplate>;
  onSubmit: (values: FormWorkflowTemplate) => void;
  onCancel?: () => void;
  loading?: boolean;
}

interface TaskOption {
  id: string;
  label: string;
}

const defaultValues: FormWorkflowTemplate = {
  name: '',
  description: '',
  category: '',
  version: '1.0.0',
  sla: {
    duration: 24,
    priority: PriorityType.MEDIUM
  },
  businessCase: {
    description: '',
    priority: 1,
    keywords: [],
    examples: [],
    conditions: []
  },
  tasks: [],
  customFields: []
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`workflow-tabpanel-${index}`}
      aria-labelledby={`workflow-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `workflow-tab-${index}`,
    'aria-controls': `workflow-tabpanel-${index}`,
  };
}

const WorkflowTemplateForm: React.FC<WorkflowTemplateFormProps> = ({
  initialValues: propInitialValues,
  onSubmit,
  onCancel,
  loading = false,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState<User[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const [loadingData, setLoadingData] = useState(false);

  const formInitialValues = propInitialValues ? {
    ...defaultValues,
    ...propInitialValues
  } : defaultValues;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingData(true);
        const [usersData, teamsData] = await Promise.all([
          workflowTemplateService.getUsers(),
          workflowTemplateService.getTeams()
        ]);
        setUsers(usersData);
        setTeams(teamsData);
      } catch (error) {
        console.error('Error fetching data:', error);
        enqueueSnackbar('Error fetching data', { variant: 'error' });
      } finally {
        setLoadingData(false);
      }
    };
    fetchData();
  }, [enqueueSnackbar]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const getAssigneeOptions = (type: string): TaskOption[] => {
    switch (type) {
      case AssigneeType.USER:
        return users.map(user => ({
          id: user._id,
          label: `${user.firstName} ${user.lastName}`.trim() || user.email
        }));
      case AssigneeType.TEAM:
        return teams.map(team => ({
          id: team._id,
          label: team.name
        }));
      default:
        return [];
    }
  };

  const handleAssigneeChange = (index: number, value: TaskOption | null) => {
    formik.setFieldValue(`tasks.${index}.assigneeId`, value?.id || '');
  };

  const handleAssigneeTypeChange = (index: number, newType: string) => {
    formik.setFieldValue(`tasks.${index}.assigneeType`, newType);
    formik.setFieldValue(`tasks.${index}.assigneeId`, '');
  };

  const defaultTask: TaskDefinition = {
    id: `task_${Date.now()}`,
    name: '',
    type: TaskType.ACTION,
    description: '',
    assigneeType: AssigneeType.USER,
    assigneeId: '',
    duration: 0,
    priority: PriorityType.MEDIUM,
    relationship: {
      parentTasks: [],
      childTasks: []
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string(),
    category: Yup.string().required('Category is required'),
    version: Yup.string(),
    sla: Yup.object().shape({
      duration: Yup.number().required('Duration is required').min(1, 'Duration must be at least 1 hour'),
      priority: Yup.string().required('Priority is required')
    }),
    businessCase: Yup.object().shape({
      description: Yup.string(),
      priority: Yup.number().required('Priority is required').min(1, 'Priority must be at least 1'),
      keywords: Yup.array().of(Yup.string()),
      examples: Yup.array().of(Yup.string()),
      conditions: Yup.array().of(Yup.string())
    }),
    tasks: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required('Task ID is required'),
        name: Yup.string().required('Task name is required'),
        type: Yup.string().required('Task type is required'),
        description: Yup.string().required('Description is required'),
        assigneeType: Yup.string().required('Assignee type is required'),
        assigneeId: Yup.string().required('Assignee is required'),
        duration: Yup.number().required('Duration is required').min(0, 'Duration cannot be negative'),
        priority: Yup.string().required('Priority is required'),
        relationship: Yup.object().shape({
          parentTasks: Yup.array().of(Yup.string()).default([]),
          childTasks: Yup.array().of(Yup.string()).default([])
        }).default({ parentTasks: [], childTasks: [] })
      })
    ),
    customFields: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        name: Yup.string(),
        label: Yup.string(),
        type: Yup.string(),
        description: Yup.string().nullable(),
        required: Yup.boolean().default(false),
        readOnly: Yup.boolean().default(false),
        validation: Yup.object().nullable(),
        options: Yup.array(),
        referenceConfig: Yup.object().nullable()
      })
    )
  });

  const formik = useFormik({
    initialValues: {
      ...formInitialValues,
      customFields: formInitialValues.customFields || [],
      tasks: formInitialValues.tasks || []
    },
    validationSchema,
    onSubmit: (values) => {
      console.log('Form submitted with values:', values);
      onSubmit(values);
    },
    validateOnMount: false,
    validateOnChange: true,
    validateOnBlur: true
  });

  useEffect(() => {
    console.log('Form values:', formik.values);
    console.log('Form errors:', formik.errors);
    console.log('Form touched:', formik.touched);
    console.log('Is form valid:', formik.isValid);
    console.log('Is form dirty:', formik.dirty);
  }, [formik.values, formik.errors, formik.touched, formik.isValid, formik.dirty]);

  const handleAddTask = () => {
    const newTask = {
      ...defaultTask,
      id: `task_${Date.now()}`,
      assigneeType: AssigneeType.USER,
      assigneeId: ''
    };
    const newTasks = [...formik.values.tasks, newTask];
    formik.setFieldValue('tasks', newTasks);
  };

  const handleRemoveTask = (index: number) => {
    const newTasks = formik.values.tasks.filter((_, i) => i !== index);
    formik.setFieldValue('tasks', newTasks);
  };

  const getTaskError = (index: number, field: string): string => {
    const errors = formik.errors.tasks?.[index] as FormikErrors<TaskDefinition> | undefined;
    if (!errors) return '';

    const fieldPath = field.split('.');
    let error: any = errors;
    
    for (const path of fieldPath) {
      error = error?.[path];
      if (!error) return '';
    }

    return typeof error === 'string' ? error : '';
  };

  const hasTaskError = (index: number, field: string): boolean => {
    const error = getTaskError(index, field);
    return Boolean(error);
  };

  const handleTaskRelationshipChange = (taskIndex: number, isParent: boolean, newValues: TaskOption[]) => {
    const updatedTasks = [...formik.values.tasks];
    const currentTask = updatedTasks[taskIndex];
    const newIds = newValues.map(v => v.id);

    if (isParent) {
      currentTask.relationship.parentTasks = newIds;
      updatedTasks.forEach(task => {
        if (newIds.includes(task.id)) {
          if (!task.relationship.childTasks.includes(currentTask.id)) {
            task.relationship.childTasks = [...task.relationship.childTasks, currentTask.id];
          }
        } else {
          task.relationship.childTasks = task.relationship.childTasks.filter(id => id !== currentTask.id);
        }
      });
    } else {
      currentTask.relationship.childTasks = newIds;
      updatedTasks.forEach(task => {
        if (newIds.includes(task.id)) {
          if (!task.relationship.parentTasks.includes(currentTask.id)) {
            task.relationship.parentTasks = [...task.relationship.parentTasks, currentTask.id];
          }
        } else {
          task.relationship.parentTasks = task.relationship.parentTasks.filter(id => id !== currentTask.id);
        }
      });
    }

    formik.setFieldValue('tasks', updatedTasks);
  };

  const getFieldError = (index: number, field: string): string => {
    const errors = formik.errors.customFields?.[index] as FormikErrors<CustomField> | undefined;
    if (!errors) return '';

    const fieldPath = field.split('.');
    let error: any = errors;
    
    for (const path of fieldPath) {
      error = error?.[path];
      if (!error) return '';
    }

    return typeof error === 'string' ? error : '';
  };

  const handleAddCustomField = () => {
    const newField: CustomField = {
      id: `field_${Date.now()}`,
      name: '',
      label: '',
      type: FieldType.TEXT,
      description: '',
      required: false,
      readOnly: false,
      validation: {},
      options: [],
      referenceConfig: {
        objectType: '',
        displayField: ''
      }
    };

    formik.setFieldValue('customFields', [...(formik.values.customFields || []), newField]);
  };

  const handleCustomFieldsChange = (index: number, field: string, value: any) => {
    const updatedFields = [...(formik.values.customFields || [])];
    updatedFields[index] = {
      ...updatedFields[index],
      [field]: value
    };
    formik.setFieldValue('customFields', updatedFields);
  };

  const handleRemoveCustomField = (index: number) => {
    const updatedFields = (formik.values.customFields || []).filter((_, i) => i !== index);
    formik.setFieldValue('customFields', updatedFields);
  };

  return (
    <form id="workflow-template-form" onSubmit={formik.handleSubmit}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="workflow template tabs">
          <Tab label="SLA & Business Case" {...a11yProps(0)} />
          <Tab label="Tasks" {...a11yProps(1)} />
          <Tab label="Required Fields" {...a11yProps(2)} />
          <Tab label="Flow Diagram" {...a11yProps(3)} />
        </Tabs>
      </Box>

      {activeTab === 0 && (
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                label="Name"
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="description"
                label="Description"
                required
                multiline
                rows={3}
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="category"
                label="Category"
                required
                value={formik.values.category}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.category && Boolean(formik.errors.category)}
                helperText={formik.touched.category && formik.errors.category}
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="version"
                label="Version"
                value={formik.values.version}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.version && Boolean(formik.errors.version)}
                helperText={formik.touched.version && formik.errors.version}
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    SLA Configuration
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="sla.duration"
                        label="Duration (hours)"
                        type="number"
                        required
                        value={formik.values.sla.duration}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.sla?.duration && Boolean(formik.errors.sla?.duration)}
                        helperText={formik.touched.sla?.duration && formik.errors.sla?.duration}
                        disabled={loading}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel>Priority</InputLabel>
                        <Select
                          name="sla.priority"
                          value={formik.values.sla.priority}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          label="Priority"
                          disabled={loading}
                        >
                          <MenuItem value="low">Low</MenuItem>
                          <MenuItem value="medium">Medium</MenuItem>
                          <MenuItem value="high">High</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Business Case
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="businessCase.description"
                        label="Description"
                        required
                        multiline
                        rows={3}
                        value={formik.values.businessCase.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.businessCase?.description &&
                          Boolean(formik.errors.businessCase?.description)
                        }
                        helperText={
                          formik.touched.businessCase?.description &&
                          formik.errors.businessCase?.description
                        }
                        disabled={loading}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="businessCase.keywords"
                        label="Keywords (comma-separated)"
                        value={formik.values.businessCase.keywords.join(', ')}
                        onChange={(e) => {
                          const keywords = e.target.value.split(',').map((k) => k.trim());
                          formik.setFieldValue('businessCase.keywords', keywords);
                        }}
                        error={
                          formik.touched.businessCase?.keywords &&
                          Boolean(formik.errors.businessCase?.keywords)
                        }
                        helperText={
                          formik.touched.businessCase?.keywords &&
                          formik.errors.businessCase?.keywords
                        }
                        disabled={loading}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="businessCase.priority"
                        label="Priority"
                        type="number"
                        required
                        value={formik.values.businessCase.priority}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.businessCase?.priority &&
                          Boolean(formik.errors.businessCase?.priority)
                        }
                        helperText={
                          formik.touched.businessCase?.priority &&
                          formik.errors.businessCase?.priority
                        }
                        disabled={loading}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}

      {activeTab === 1 && (
        <Box>
          <Typography variant="h6" gutterBottom>
            Tasks
          </Typography>
          <Grid container spacing={2}>
            {formik.values.tasks.map((task: TaskDefinition, index: number) => (
              <Grid item xs={12} key={task.id}>
                <Card>
                  <CardContent>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                      <Typography variant="subtitle1">Task {index + 1}</Typography>
                      <IconButton
                        onClick={() => handleRemoveTask(index)}
                        size="small"
                        color="error"
                        disabled={loading}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name={`tasks.${index}.name`}
                          label="Task Name"
                          required
                          value={task.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={hasTaskError(index, 'name')}
                          helperText={getTaskError(index, 'name')}
                          disabled={loading}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required error={hasTaskError(index, 'type')}>
                          <InputLabel>Task Type</InputLabel>
                          <Select
                            name={`tasks.${index}.type`}
                            value={task.type}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            label="Task Type"
                            disabled={loading}
                          >
                            <MenuItem value="action">Action</MenuItem>
                            <MenuItem value="approval">Approval</MenuItem>
                            <MenuItem value="notification">Notification</MenuItem>
                          </Select>
                          {hasTaskError(index, 'type') && (
                            <FormHelperText>{getTaskError(index, 'type')}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          rows={3}
                          name={`tasks.${index}.description`}
                          label="Description"
                          required
                          value={task.description}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={hasTaskError(index, 'description')}
                          helperText={getTaskError(index, 'description')}
                          disabled={loading}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth required error={hasTaskError(index, 'assigneeType')}>
                          <InputLabel>Assignee Type</InputLabel>
                          <Select
                            name={`tasks.${index}.assigneeType`}
                            value={task.assigneeType}
                            onChange={(e) => handleAssigneeTypeChange(index, e.target.value as string)}
                            onBlur={formik.handleBlur}
                            label="Assignee Type"
                            disabled={loadingData}
                          >
                            <MenuItem value={AssigneeType.USER}>User</MenuItem>
                            <MenuItem value={AssigneeType.TEAM}>Team</MenuItem>
                          </Select>
                          {hasTaskError(index, 'assigneeType') && (
                            <FormHelperText>{getTaskError(index, 'assigneeType')}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Autocomplete<TaskOption, false>
                          options={getAssigneeOptions(task.assigneeType)}
                          value={getAssigneeOptions(task.assigneeType).find(
                            option => option.id === task.assigneeId
                          ) || null}
                          onChange={(_, newValue) => handleAssigneeChange(index, newValue)}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label="Assignee"
                              error={Boolean(getTaskError(index, 'assigneeId'))}
                              helperText={getTaskError(index, 'assigneeId')}
                            />
                          )}
                          disabled={!task.assigneeType || loadingData}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          type="number"
                          name={`tasks.${index}.duration`}
                          label="Duration (hours)"
                          required
                          value={task.duration}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={hasTaskError(index, 'duration')}
                          helperText={getTaskError(index, 'duration')}
                          disabled={loading}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FormControl fullWidth required error={hasTaskError(index, 'priority')}>
                          <InputLabel>Priority</InputLabel>
                          <Select
                            name={`tasks.${index}.priority`}
                            value={task.priority}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            label="Priority"
                            disabled={loading}
                          >
                            <MenuItem value="low">Low</MenuItem>
                            <MenuItem value="medium">Medium</MenuItem>
                            <MenuItem value="high">High</MenuItem>
                          </Select>
                          {hasTaskError(index, 'priority') && (
                            <FormHelperText>{getTaskError(index, 'priority')}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete<TaskOption, true>
                          multiple
                          options={formik.values.tasks
                            .filter((t, i) => i !== index)
                            .map(t => ({ id: t.id, label: t.name }))
                          }
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          value={task.relationship?.parentTasks?.map(id => ({
                            id,
                            label: formik.values.tasks.find(t => t.id === id)?.name || id
                          })) || []}
                          onChange={(_, value) => {
                            const updatedTasks = [...formik.values.tasks];
                            updatedTasks[index] = {
                              ...updatedTasks[index],
                              relationship: {
                                ...updatedTasks[index].relationship,
                                parentTasks: value.map(v => v.id)
                              }
                            };
                            formik.setFieldValue('tasks', updatedTasks);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Parent Tasks"
                              error={hasTaskError(index, 'relationship.parentTasks')}
                              helperText={getTaskError(index, 'relationship.parentTasks')}
                            />
                          )}
                          disabled={loading}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete<TaskOption, true>
                          multiple
                          options={formik.values.tasks
                            .filter((t, i) => i !== index)
                            .map(t => ({ id: t.id, label: t.name }))
                          }
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          value={task.relationship?.childTasks?.map(id => ({
                            id,
                            label: formik.values.tasks.find(t => t.id === id)?.name || id
                          })) || []}
                          onChange={(_, value) => {
                            const updatedTasks = [...formik.values.tasks];
                            updatedTasks[index] = {
                              ...updatedTasks[index],
                              relationship: {
                                ...updatedTasks[index].relationship,
                                childTasks: value.map(v => v.id)
                              }
                            };
                            formik.setFieldValue('tasks', updatedTasks);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Child Tasks"
                              error={hasTaskError(index, 'relationship.childTasks')}
                              helperText={getTaskError(index, 'relationship.childTasks')}
                            />
                          )}
                          disabled={loading}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              startIcon={<AddIcon />}
              onClick={handleAddTask}
              variant="outlined"
              color="primary"
              disabled={loading}
            >
              Add Task
            </Button>
          </Box>
        </Box>
      )}

      {activeTab === 2 && (
        <Box sx={{ mt: 2 }}>
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Required Fields</Typography>
            <Button
              startIcon={<AddIcon />}
              onClick={handleAddCustomField}
              variant="outlined"
              color="primary"
              disabled={loading}
            >
              Add Field
            </Button>
          </Box>
          
          <Grid container spacing={2}>
            {formik.values.customFields.map((field, index) => (
              <Grid item xs={12} key={field.id}>
                <Card>
                  <CardContent>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Box sx={{ flex: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              fullWidth
                              label="Field Name"
                              name={`customFields.${index}.name`}
                              required
                              value={field.name}
                              onChange={(e) => handleCustomFieldsChange(index, 'name', e.target.value)}
                              onBlur={formik.handleBlur}
                              error={Boolean(getFieldError(index, 'name'))}
                              helperText={getFieldError(index, 'name')}
                              disabled={loading}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              fullWidth
                              label="Label"
                              name={`customFields.${index}.label`}
                              required
                              value={field.label}
                              onChange={(e) => handleCustomFieldsChange(index, 'label', e.target.value)}
                              onBlur={formik.handleBlur}
                              error={Boolean(getFieldError(index, 'label'))}
                              helperText={getFieldError(index, 'label')}
                              disabled={loading}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth required error={Boolean(getFieldError(index, 'type'))}>
                              <InputLabel>Type</InputLabel>
                              <Select
                                name={`customFields.${index}.type`}
                                value={field.type}
                                onChange={(e) => {
                                  handleCustomFieldsChange(index, 'type', e.target.value);
                                  // Reset type-specific fields when type changes
                                  handleCustomFieldsChange(index, 'options', []);
                                  handleCustomFieldsChange(index, 'referenceConfig', undefined);
                                }}
                                onBlur={formik.handleBlur}
                                label="Type"
                                disabled={loading}
                              >
                                {Object.values(FieldType).map((type) => (
                                  <MenuItem key={type} value={type}>
                                    {type.charAt(0).toUpperCase() + type.slice(1)}
                                  </MenuItem>
                                ))}
                              </Select>
                              {getFieldError(index, 'type') && (
                                <FormHelperText>{getFieldError(index, 'type')}</FormHelperText>
                              )}
                            </FormControl>
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Description"
                              name={`customFields.${index}.description`}
                              value={field.description || ''}
                              onChange={(e) => handleCustomFieldsChange(index, 'description', e.target.value)}
                              onBlur={formik.handleBlur}
                              multiline
                              rows={2}
                              disabled={loading}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <FormGroup row>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={field.required}
                                    onChange={(e) => handleCustomFieldsChange(index, 'required', e.target.checked)}
                                    disabled={loading}
                                  />
                                }
                                label="Required"
                              />
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={field.readOnly}
                                    onChange={(e) => handleCustomFieldsChange(index, 'readOnly', e.target.checked)}
                                    disabled={loading}
                                  />
                                }
                                label="Read Only"
                              />
                            </FormGroup>
                          </Grid>

                          {field.type === FieldType.SELECT && (
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="Options (comma-separated)"
                                value={field.options?.map(opt => opt.label).join(', ') || ''}
                                onChange={(e) => {
                                  const options = e.target.value.split(',').map(opt => ({
                                    value: opt.trim().toLowerCase().replace(/\s+/g, '_'),
                                    label: opt.trim()
                                  }));
                                  handleCustomFieldsChange(index, 'options', options);
                                }}
                                disabled={loading}
                                helperText="Enter options separated by commas"
                              />
                            </Grid>
                          )}

                          {field.type === FieldType.REFERENCE && (
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <FormControl fullWidth>
                                    <InputLabel>Reference Type</InputLabel>
                                    <Select
                                      value={field.referenceConfig?.objectType || ''}
                                      onChange={(e) => {
                                        handleCustomFieldsChange(index, 'referenceConfig', {
                                          ...field.referenceConfig,
                                          objectType: e.target.value
                                        });
                                      }}
                                      label="Reference Type"
                                      disabled={loading}
                                    >
                                      <MenuItem value="user">User</MenuItem>
                                      <MenuItem value="team">Team</MenuItem>
                                      <MenuItem value="asset">Asset</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    fullWidth
                                    label="Display Field"
                                    value={field.referenceConfig?.displayField || ''}
                                    onChange={(e) => {
                                      handleCustomFieldsChange(index, 'referenceConfig', {
                                        ...field.referenceConfig,
                                        displayField: e.target.value
                                      });
                                    }}
                                    disabled={loading}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          )}

                          {(field.type === FieldType.NUMBER || field.type === FieldType.TEXT) && (
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                {field.type === FieldType.NUMBER && (
                                  <>
                                    <Grid item xs={6}>
                                      <TextField
                                        fullWidth
                                        type="number"
                                        label="Min Value"
                                        value={field.validation?.min || ''}
                                        onChange={(e) => {
                                          handleCustomFieldsChange(index, 'validation.min', 
                                            e.target.value ? Number(e.target.value) : undefined);
                                        }}
                                        disabled={loading}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextField
                                        fullWidth
                                        type="number"
                                        label="Max Value"
                                        value={field.validation?.max || ''}
                                        onChange={(e) => {
                                          handleCustomFieldsChange(index, 'validation.max', 
                                            e.target.value ? Number(e.target.value) : undefined);
                                        }}
                                        disabled={loading}
                                      />
                                    </Grid>
                                  </>
                                )}
                                {field.type === FieldType.TEXT && (
                                  <>
                                    <Grid item xs={6}>
                                      <TextField
                                        fullWidth
                                        type="number"
                                        label="Min Length"
                                        value={field.validation?.minLength || ''}
                                        onChange={(e) => {
                                          handleCustomFieldsChange(index, 'validation.minLength', 
                                            e.target.value ? Number(e.target.value) : undefined);
                                        }}
                                        disabled={loading}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextField
                                        fullWidth
                                        type="number"
                                        label="Max Length"
                                        value={field.validation?.maxLength || ''}
                                        onChange={(e) => {
                                          handleCustomFieldsChange(index, 'validation.maxLength', 
                                            e.target.value ? Number(e.target.value) : undefined);
                                        }}
                                        disabled={loading}
                                      />
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                      <Box>
                        <IconButton
                          onClick={() => handleRemoveCustomField(index)}
                          color="error"
                          disabled={loading}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {activeTab === 3 && (
        <TabPanel value={activeTab} index={3}>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Drag between nodes to create connections. Tasks can have multiple parents and children.
            </Typography>
            <Box sx={{ height: 500, border: '1px solid #e0e0e0', borderRadius: 1 }}>
              <WorkflowDiagram 
                tasks={formik.values.tasks} 
                readonly={false}
                onTasksChange={(updatedTasks: TaskDefinition[]) => {
                  formik.setFieldValue('tasks', updatedTasks);
                }}
              />
            </Box>
          </Box>
        </TabPanel>
      )}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        {onCancel && (
          <Button onClick={onCancel} disabled={loading} sx={{ mr: 1 }}>
            Cancel
          </Button>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading || !formik.isValid || !formik.dirty}
        >
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </Box>
    </form>
  );
};

export default WorkflowTemplateForm;

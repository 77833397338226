import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Paper,
  CircularProgress
} from '@mui/material';
import { TableMetadata } from '../../types/table';
import tableService from '../../services/tableService';

const TableDetail: React.FC = () => {
  const { tableId } = useParams<{ tableId: string }>();
  const [table, setTable] = useState<TableMetadata | null>(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);

  const loadTable = useCallback(async () => {
    try {
      const response = await tableService.getTable(tableId!);
      setTable(response.data);
    } catch (error) {
      console.error('Failed to load table:', error);
    } finally {
      setLoading(false);
    }
  }, [tableId]);

  useEffect(() => {
    if (tableId) {
      loadTable();
    }
  }, [tableId, loadTable]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (!table) {
    return (
      <Box p={3}>
        <Typography color="error">Table not found</Typography>
      </Box>
    );
  }

  return (
    <Box>
      {/* Header */}
      <Box mb={3}>
        <Typography variant="h4" gutterBottom>
          {table.label}
        </Typography>
        {table.description && (
          <Typography variant="body1" color="textSecondary">
            {table.description}
          </Typography>
        )}
      </Box>

      {/* Tabs */}
      <Paper sx={{ mb: 3 }}>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Records" />
          <Tab label="Fields" />
          <Tab label="Settings" />
        </Tabs>
      </Paper>

      {/* Content */}
      <Box>
        <Outlet context={{ table }} />
      </Box>
    </Box>
  );
};

export default TableDetail;

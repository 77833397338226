import axios from 'axios';
import { CreateHolidayDto } from './holidayService';

export interface GenerateHolidaysParams {
  country: string;
  year: number;
}

export const aiHolidayService = {
  async generateHolidays(params: GenerateHolidaysParams): Promise<CreateHolidayDto[]> {
    try {
      const response = await axios.post('/api/ai/generate-holidays', params);
      return response.data;
    } catch (error) {
      console.error('Error generating holidays:', error);
      throw error;
    }
  }
};

export default aiHolidayService;

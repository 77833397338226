import React from 'react';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { Box, Typography } from '@mui/material';

interface ChartOptions {
  xAxisKey?: string;
  yAxisKey?: string;
  colors?: string[];
  xAxisLabel?: string;
  yAxisLabel?: string;
  [key: string]: any;
}

interface ReportChartProps {
  type: string;
  data: Array<Record<string, any>>;
  options: ChartOptions;
}

// Modern and vibrant color palette
const defaultColors = [
  '#3498db', // Bright Blue
  '#2ecc71', // Emerald Green
  '#e74c3c', // Alizarin Red
  '#f1c40f', // Sunflower Yellow
  '#9b59b6', // Amethyst Purple
  '#1abc9c', // Turquoise
  '#e67e22', // Carrot Orange
  '#34495e', // Wet Asphalt
  '#7f8c8d', // Concrete Gray
  '#16a085', // Green Sea
];

const ReportChart: React.FC<ReportChartProps> = ({ type, data, options }) => {
  const {
    xAxisKey = '_id',
    yAxisKey = 'value',
    colors = defaultColors,
    xAxisLabel,
    yAxisLabel,
    ...chartOptions
  } = options;

  if (!data || data.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <Typography variant="body1" color="textSecondary">
          No data available
        </Typography>
      </Box>
    );
  }

  const renderChart = () => {
    switch (type.toLowerCase()) {
      case 'bar':
        return (
          <ResponsiveContainer width="100%" height={400}>
            <BarChart 
              data={data} 
              margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
              {...chartOptions}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
              <XAxis 
                dataKey={xAxisKey}
                height={60}
                interval={0}
                tick={(props) => (
                  <g transform={`translate(${props.x},${props.y})`}>
                    <text
                      x={0}
                      y={0}
                      dy={16}
                      textAnchor="end"
                      fill="#666"
                      transform="rotate(-45)"
                      style={{ fontSize: '12px' }}
                    >
                      {props.payload.value}
                    </text>
                  </g>
                )}
                label={{ 
                  value: xAxisLabel,
                  position: 'insideBottom',
                  offset: -10,
                  style: { fontWeight: 'bold' }
                }}
              />
              <YAxis
                width={60}
                label={{
                  value: yAxisLabel,
                  angle: -90,
                  position: 'insideLeft',
                  style: { fontWeight: 'bold' }
                }}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}
                cursor={{ fill: 'rgba(0,0,0,0.05)' }}
              />
              <Legend
                wrapperStyle={{
                  paddingTop: '10px'
                }}
              />
              <Bar
                dataKey={yAxisKey}
                fill={colors[0]}
                radius={[4, 4, 0, 0]} // Rounded corners on top
                maxBarSize={60}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        );

      case 'line':
        return (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={data} {...chartOptions}>
              <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
              <XAxis 
                dataKey={xAxisKey}
                label={{ 
                  value: xAxisLabel || xAxisKey, 
                  position: 'insideBottom',
                  offset: -5,
                  style: { fontWeight: 'bold' }
                }}
                tick={{ fontSize: 12 }}
              />
              <YAxis
                label={{
                  value: yAxisLabel || yAxisKey,
                  angle: -90,
                  position: 'insideLeft',
                  style: { fontWeight: 'bold' }
                }}
                tick={{ fontSize: 12 }}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}
                cursor={{ fill: 'rgba(0,0,0,0.05)' }}
              />
              <Legend
                wrapperStyle={{
                  paddingTop: '10px'
                }}
              />
              <Line 
                name={yAxisLabel || yAxisKey}
                type="monotone" 
                dataKey={yAxisKey} 
                stroke={colors[0]} 
              />
            </LineChart>
          </ResponsiveContainer>
        );

      case 'pie':
        return (
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={data}
                nameKey={xAxisKey}
                dataKey={yAxisKey}
                cx="50%"
                cy="50%"
                outerRadius={150}
                fill="#8884d8"
                label
                {...chartOptions}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index % colors.length]}
                  />
                ))}
              </Pie>
              <Tooltip
                contentStyle={{
                  backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}
                cursor={{ fill: 'rgba(0,0,0,0.05)' }}
              />
              <Legend
                wrapperStyle={{
                  paddingTop: '10px'
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        );

      case 'table':
        return (
          <Box sx={{ overflowX: 'auto' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  {Object.keys(data[0]).map((key) => (
                    <th
                      key={key}
                      style={{
                        padding: '12px',
                        borderBottom: '2px solid #ddd',
                        textAlign: 'left',
                        fontWeight: 'bold'
                      }}
                    >
                      {key}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, i) => (
                  <tr key={i}>
                    {Object.values(row).map((value: any, j) => (
                      <td
                        key={j}
                        style={{
                          padding: '12px',
                          borderBottom: '1px solid #ddd'
                        }}
                      >
                        {typeof value === 'object'
                          ? JSON.stringify(value)
                          : value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        );

      default:
        return (
          <Typography variant="body1" color="error">
            Unsupported chart type: {type}
          </Typography>
        );
    }
  };

  return (
    <Box width="100%" height="100%" minHeight={400}>
      {renderChart()}
    </Box>
  );
};

export default ReportChart;
import axios from 'axios';
import { API_URL } from '../config';

// Get the auth token
const getAuthToken = () => {
  return localStorage.getItem('token');
};

interface TaskResponse {
  tasks: any[];
  pagination: {
    total: number;
    page: number;
    limit: number;
    totalPages: number;
  };
}

const getTasks = async (page = 1, limit = 10): Promise<TaskResponse> => {
  const response = await axios.get(`${API_URL}/tasks`, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`
    },
    params: { page, limit }
  });
  return response.data;
};

const getTask = async (id: string): Promise<any> => {
  const response = await axios.get(`${API_URL}/tasks/${id}`, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`
    }
  });
  return response.data;
};

const getTasksByWorkflowInstance = async (workflowInstanceId: string, page = 1, limit = 10): Promise<TaskResponse> => {
  const response = await axios.get(`${API_URL}/tasks`, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`
    },
    params: { workflowInstanceId, page, limit }
  });
  return response.data;
};

const updateTaskStatus = async (taskId: string, action: 'approve' | 'reject', comment?: string) => {
  const response = await axios.patch(`${API_URL}/tasks/${taskId}/status`, {
    action,
    comment
  }, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`
    }
  });
  return response.data;
};

const createTask = async (taskData: any): Promise<any> => {
  const response = await axios.post(`${API_URL}/tasks`, taskData, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`
    }
  });
  return response.data;
};

const updateTask = async (taskId: string, taskData: any): Promise<any> => {
  const response = await axios.put(`${API_URL}/tasks/${taskId}`, taskData, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`
    }
  });
  return response.data;
};

const deleteTask = async (taskId: string): Promise<void> => {
  await axios.delete(`${API_URL}/tasks/${taskId}`, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`
    }
  });
};

const taskService = {
  getTasks,
  getTask,
  getTasksByWorkflowInstance,
  updateTaskStatus,
  createTask,
  updateTask,
  deleteTask
};

export default taskService;

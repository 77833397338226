import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useLayout } from '../../contexts/LayoutContext'; // Import useLayout hook
import tableService from '../../services/tableService';
import { TableMetadata } from '../../types/table';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Collapse,
  Divider
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Assignment as RequestsIcon,
  AccountTree as WorkflowIcon,
  Task as TaskIcon,
  Rule as RulesIcon,
  Group as TeamsIcon,
  Person as UsersIcon,
  Book as KnowledgeBaseIcon,
  Assessment as ReportsIcon,
  Storage as CMDBIcon,
  Settings as SettingsIcon,
  List as ListIcon,
  TableChart as TableIcon,
  AdminPanelSettings as RolesIcon,
  ViewList as CustomTableIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ExpandLess,
  ExpandMore,
  CalendarToday as CalendarIcon,
  Timer as TimerIcon,
  Tune as TuneIcon,
  NotificationsNone as NotificationsIcon,
  Explore as DiscoveryIcon,
  FindInPage as ScanDataIcon,
  ImportExport as ImportIcon,
  VpnKey as TokenIcon,
  Code as CodeIcon,
  Extension as HelpersIcon
} from '@mui/icons-material';
import { version } from '../../version';

interface MainMenuProps {
  onClose?: () => void; // Keep onClose for mobile drawer
  // Remove isCollapsed and onToggleCollapse props
}

const MainMenu: React.FC<MainMenuProps> = ({ onClose }) => { // Remove props
  const { isSidebarCollapsed, toggleSidebarCollapse } = useLayout(); // Use context
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [userMenuAnchor, setUserMenuAnchor] = useState<null | HTMLElement>(null);
  const [cmdbOpen, setCmdbOpen] = useState(false);
  const [discoveryOpen, setDiscoveryOpen] = useState(false);
  const [tablesOpen, setTablesOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [customTables, setCustomTables] = useState<TableMetadata[]>([]);

  useEffect(() => {
    loadCustomTables();
  }, []);

  const loadCustomTables = async () => {
    try {
      const response = await tableService.getTables();
      const customTablesData = (response.data as TableMetadata[]).filter((table: TableMetadata) => table.type === 'custom');
      console.log('Custom tables loaded:', customTablesData);
      setCustomTables(customTablesData);
    } catch (error) {
      console.error('Failed to load custom tables:', error);
    }
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    if (onClose) {
      onClose();
    }
  };

  const handleCMDBClick = () => {
    setCmdbOpen(!cmdbOpen);
  };

  const handleDiscoveryClick = () => {
    setDiscoveryOpen(!discoveryOpen);
  };

  const handleTablesClick = () => {
    setTablesOpen(!tablesOpen);
  };

  const handleSettingsClick = () => {
    setSettingsOpen(!settingsOpen);
  };

  const handleTableClick = (table: TableMetadata) => {
    console.log('Clicking table:', table);
    if (table.type === 'custom') {
      navigate(`/tables/custom/${table.name}`);
    } else {
      const id = table._id || table.id;
      if (!id) {
        console.error('Table has no ID:', table);
        return;
      }
      navigate(`/tables/${id}`);
    }
    if (onClose) {
      onClose();
    }
  };

  const isSelected = (path: string) => location.pathname.startsWith(path);

  const menuItems = [
    { path: '/dashboard', icon: <DashboardIcon />, label: 'Dashboard' },
    { path: '/requests', icon: <RequestsIcon />, label: 'Requests' },
    { path: '/workflow-templates', icon: <WorkflowIcon />, label: 'Workflow Templates' },
    { path: '/workflow-instances', icon: <WorkflowIcon />, label: 'Workflow Instances' },
    { path: '/list/task', icon: <TaskIcon />, label: 'Tasks' }, // Update path to use GenericList route
    { path: '/rules', icon: <RulesIcon />, label: 'Rules' },
    { path: '/teams', icon: <TeamsIcon />, label: 'Teams' },
    { path: '/list/user', icon: <UsersIcon />, label: 'Users' }, // Corrected path to singular 'user'
    { path: '/knowledge-base', icon: <KnowledgeBaseIcon />, label: 'Knowledge Base' },
    { path: '/reports', icon: <ReportsIcon />, label: 'Reports' },
    { path: '/sla', icon: <CalendarIcon />, label: 'SLA Management' },
  ];

  const settingsMenuItems = [
    { path: '/settings/calendar', icon: <CalendarIcon />, label: 'Calendar' },
    { path: '/settings/holidays', icon: <CalendarIcon />, label: 'Holidays' },
    { path: '/settings/sla', icon: <TimerIcon />, label: 'SLA Configuration' },
    { path: '/settings/system', icon: <TuneIcon />, label: 'System Settings' },
    { path: '/settings/script-execution', icon: <CodeIcon />, label: 'Script Execution' },
    { path: '/settings/helpers', icon: <HelpersIcon />, label: 'Helpers Manager' },
    { path: '/settings/notifications', icon: <NotificationsIcon />, label: 'Notifications' }
  ];

  return (
    <Box sx={{ 
      height: '100vh',
      display: 'flex', 
      flexDirection: 'column',
      background: '#1e2130',
      color: 'rgba(255, 255, 255, 0.9)'
    }}>
      {/* Header Section */}
      <Box sx={{ 
        p: 2, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
        backgroundColor: '#171923',
      }}>
        {/* Conditionally render the Title */}
        {!isSidebarCollapsed && (
          <Typography variant="h6" sx={{
            fontWeight: 500,
            color: 'rgba(255, 255, 255, 0.9)',
            fontSize: '0.95rem',
            flexGrow: 1 // Allow title to take space
          }}>
            Killit v{version}
          </Typography>
        )}
        {/* Toggle button is always visible */}
        <IconButton
          onClick={toggleSidebarCollapse}
          sx={{
            color: 'rgba(255, 255, 255, 0.9)',
            ml: isSidebarCollapsed ? 0 : 'auto', // Adjust margin based on state
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.08)'
            }
          }}
        >
          {isSidebarCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </Box>

      {/* Navigation Menu */}
      <List sx={{ 
        flex: 1, 
        pt: 1, 
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'rgba(255, 255, 255, 0.05)',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'rgba(255, 255, 255, 0.2)',
          borderRadius: '4px',
        }
      }}>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.path}
            onClick={() => handleNavigation(item.path)}
            sx={{
              px: 2,
              py: 0.75,
              mb: 0,
              borderRadius: 0,
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.08)',
              },
              ...(location.pathname === item.path && {
                bgcolor: 'rgba(66, 153, 225, 0.6)',
              })
            }}
          >
            <ListItemIcon sx={{
              color: 'rgba(255, 255, 255, 0.9)',
              minWidth: isSidebarCollapsed ? 0 : 36, // Use context state
              mr: isSidebarCollapsed ? 0 : 2 // Use context state
            }}>
              {item.icon}
            </ListItemIcon>
            {!isSidebarCollapsed && ( // Use context state
              <ListItemText 
                primary={item.label}
                primaryTypographyProps={{
                  sx: { 
                    color: 'rgba(255, 255, 255, 0.9)',
                    fontSize: '0.8rem'
                  }
                }}
              />
            )}
          </ListItem>
        ))}

        <ListItem button onClick={handleCMDBClick} sx={{ borderRadius: 0 }}>
          <ListItemIcon sx={{ 
            color: 'rgba(255, 255, 255, 0.9)',
            minWidth: isSidebarCollapsed ? 0 : 36 // Use context state
          }}>
            <CMDBIcon />
          </ListItemIcon>
          {!isSidebarCollapsed && ( // Use context state
            <>
              <ListItemText 
                primary="CMDB"
                primaryTypographyProps={{
                  sx: { 
                    color: 'rgba(255, 255, 255, 0.9)',
                    fontSize: '0.8rem'
                  }
                }}
              />
              {cmdbOpen ? 
                <ExpandLess sx={{ color: 'rgba(255, 255, 255, 0.9)' }} /> : 
                <ExpandMore sx={{ color: 'rgba(255, 255, 255, 0.9)' }} />
              }
            </>
          )}
        </ListItem>

        <Collapse in={cmdbOpen && !isSidebarCollapsed} timeout="auto" unmountOnExit> {/* Use context state */}
          <List component="div" disablePadding>
            <ListItem 
              button 
              sx={{ pl: 4 }}
              onClick={() => handleNavigation('/cmdb')}
            >
              <ListItemIcon sx={{ 
                color: 'rgba(255, 255, 255, 0.9)',
                minWidth: isSidebarCollapsed ? 0 : 36 // Use context state
              }}>
                <ListIcon />
              </ListItemIcon>
              {!isSidebarCollapsed && ( // Use context state
                <ListItemText 
                  primary="CI List"
                  primaryTypographyProps={{
                    sx: { 
                      color: 'rgba(255, 255, 255, 0.9)',
                      fontSize: '0.8rem'
                    }
                  }}
                />
              )}
            </ListItem>
            <ListItem 
              button 
              sx={{ pl: 4 }}
              onClick={() => handleNavigation('/cmdb/settings')}
            >
              <ListItemIcon sx={{ 
                color: 'rgba(255, 255, 255, 0.9)',
                minWidth: isSidebarCollapsed ? 0 : 36 // Use context state
              }}>
                <SettingsIcon />
              </ListItemIcon>
              {!isSidebarCollapsed && ( // Use context state
                <ListItemText 
                  primary="CI Types"
                  primaryTypographyProps={{
                    sx: { 
                      color: 'rgba(255, 255, 255, 0.9)',
                      fontSize: '0.8rem'
                    }
                  }}
                />
              )}
            </ListItem>
            
            {/* Discovery Menu Item */}
            <ListItem 
              button 
              sx={{ pl: 4 }}
              onClick={handleDiscoveryClick}
            >
              <ListItemIcon sx={{ 
                color: 'rgba(255, 255, 255, 0.9)',
                minWidth: isSidebarCollapsed ? 0 : 36 // Use context state
              }}>
                <DiscoveryIcon />
              </ListItemIcon>
              {!isSidebarCollapsed && ( // Use context state
                <>
                  <ListItemText 
                    primary="Discovery"
                    primaryTypographyProps={{
                      sx: { 
                        color: 'rgba(255, 255, 255, 0.9)',
                        fontSize: '0.8rem'
                      }
                    }}
                  />
                  {discoveryOpen ? 
                    <ExpandLess sx={{ color: 'rgba(255, 255, 255, 0.9)' }} /> : 
                    <ExpandMore sx={{ color: 'rgba(255, 255, 255, 0.9)' }} />
                  }
                </>
              )}
            </ListItem>
            
            {/* Discovery Submenu */}
            <Collapse in={discoveryOpen && !isSidebarCollapsed} timeout="auto" unmountOnExit> {/* Use context state */}
              <List component="div" disablePadding>
                <ListItem 
                  button 
                  sx={{ pl: 6 }}
                  onClick={() => handleNavigation('/cmdb/discovery/scan-data')}
                >
                  <ListItemIcon sx={{ 
                    color: 'rgba(255, 255, 255, 0.9)',
                    minWidth: isSidebarCollapsed ? 0 : 36 // Use context state
                  }}>
                    <ScanDataIcon />
                  </ListItemIcon>
                  {!isSidebarCollapsed && ( // Use context state
                    <ListItemText 
                      primary="Scan Data"
                      primaryTypographyProps={{
                        sx: { 
                          color: 'rgba(255, 255, 255, 0.9)',
                          fontSize: '0.8rem'
                        }
                      }}
                    />
                  )}
                </ListItem>
                <ListItem 
                  button 
                  sx={{ pl: 6 }}
                  onClick={() => handleNavigation('/cmdb/discovery/import')}
                >
                  <ListItemIcon sx={{ 
                    color: 'rgba(255, 255, 255, 0.9)',
                    minWidth: isSidebarCollapsed ? 0 : 36 // Use context state
                  }}>
                    <ImportIcon />
                  </ListItemIcon>
                  {!isSidebarCollapsed && ( // Use context state
                    <ListItemText 
                      primary="Import to CMDB"
                      primaryTypographyProps={{
                        sx: { 
                          color: 'rgba(255, 255, 255, 0.9)',
                          fontSize: '0.8rem'
                        }
                      }}
                    />
                  )}
                </ListItem>
                <ListItem 
                  button 
                  sx={{ pl: 6 }}
                  onClick={() => handleNavigation('/cmdb/discovery/token-management')}
                >
                  <ListItemIcon sx={{ 
                    color: 'rgba(255, 255, 255, 0.9)',
                    minWidth: isSidebarCollapsed ? 0 : 36 // Use context state
                  }}>
                    <TokenIcon />
                  </ListItemIcon>
                  {!isSidebarCollapsed && ( // Use context state
                    <ListItemText 
                      primary="API Tokens"
                      primaryTypographyProps={{
                        sx: { 
                          color: 'rgba(255, 255, 255, 0.9)',
                          fontSize: '0.8rem'
                        }
                      }}
                    />
                  )}
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Collapse>
        {/* We need to add a Software Catalog menu item under CMDB */}
        {/* Add this in the CMDB submenu section of MainMenu.tsx around line 429 */}

        <ListItem 
          button 
          sx={{ pl: 4 }}
          onClick={() => handleNavigation('/cmdb/swcatalog')}
        >
          <ListItemIcon sx={{ 
            color: 'rgba(255, 255, 255, 0.9)',
            minWidth: isSidebarCollapsed ? 0 : 36 // Use context state
          }}>
            <CodeIcon />
          </ListItemIcon>
          {!isSidebarCollapsed && ( // Use context state
            <ListItemText 
              primary="Software Catalog"
              primaryTypographyProps={{
                sx: { 
                  color: 'rgba(255, 255, 255, 0.9)',
                  fontSize: '0.8rem'
                }
              }}
            />
          )}
        </ListItem>          
        <ListItem button onClick={handleTablesClick} sx={{ borderRadius: 0 }}>
          <ListItemIcon sx={{
            color: 'rgba(255, 255, 255, 0.9)',
            minWidth: isSidebarCollapsed ? 0 : 36 // Use context state
          }}>
            <TableIcon />
          </ListItemIcon>
          {!isSidebarCollapsed && ( // Use context state
            <>
              <ListItemText 
                primary="Tables"
                primaryTypographyProps={{
                  sx: { 
                    color: 'rgba(255, 255, 255, 0.9)',
                    fontSize: '0.8rem'
                  }
                }}
              />
              {tablesOpen ? 
                <ExpandLess sx={{ color: 'rgba(255, 255, 255, 0.9)' }} /> : 
                <ExpandMore sx={{ color: 'rgba(255, 255, 255, 0.9)' }} />
              }
            </>
          )}
        </ListItem>

        <Collapse in={tablesOpen && !isSidebarCollapsed} timeout="auto" unmountOnExit> {/* Use context state */}
          <List component="div" disablePadding>
            <ListItem 
              button 
              sx={{ pl: 4 }}
              onClick={() => handleNavigation('/tables')}
            >
              <ListItemIcon sx={{ 
                color: 'rgba(255, 255, 255, 0.9)',
                minWidth: isSidebarCollapsed ? 0 : 36 // Use context state
              }}>
                <ListIcon />
              </ListItemIcon>
              {!isSidebarCollapsed && ( // Use context state
                <ListItemText 
                  primary="All Tables"
                  primaryTypographyProps={{
                    sx: { 
                      color: 'rgba(255, 255, 255, 0.9)',
                      fontSize: '0.8rem'
                    }
                  }}
                />
              )}
            </ListItem>
            {customTables.map((table) => (
              <ListItem
                button
                key={table._id || table.id}
                sx={{ pl: 4 }}
                selected={isSelected(`/tables/custom/${table.name}`)}
                onClick={() => handleTableClick(table)}
              >
                <ListItemIcon sx={{ 
                  color: 'rgba(255, 255, 255, 0.9)',
                  minWidth: isSidebarCollapsed ? 0 : 36 // Use context state
                }}>
                  <CustomTableIcon />
                </ListItemIcon>
                {!isSidebarCollapsed && ( // Use context state
                  <ListItemText 
                    primary={table.name}
                    primaryTypographyProps={{
                      sx: { 
                        color: 'rgba(255, 255, 255, 0.9)',
                        fontSize: '0.8rem'
                      }
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Collapse>

        <ListItem
          button
          onClick={handleSettingsClick}
          sx={{
            pl: 2,
            pr: 2,
            py: 0.75,
            color: 'white',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.05)',
            },
            borderRadius: 0
          }}
        >
          <ListItemIcon sx={{ color: 'inherit', minWidth: 36 }}>
            <SettingsIcon />
          </ListItemIcon>
          {!isSidebarCollapsed && ( // Use context state
            <>
              <ListItemText 
                primary="Settings" 
                primaryTypographyProps={{
                  sx: { 
                    fontSize: '0.8rem', 
                    color: 'rgba(255, 255, 255, 0.9)'
                  }
                }}
              />
              {settingsOpen ? <ExpandLess /> : <ExpandMore />}
            </>
          )}
        </ListItem>

        <Collapse in={settingsOpen && !isSidebarCollapsed} timeout="auto" unmountOnExit> {/* Use context state */}
          <List component="div" disablePadding>
            {settingsMenuItems.map((item) => (
              <ListItem
                button
                key={item.path}
                onClick={() => handleNavigation(item.path)}
                selected={isSelected(item.path)}
                sx={{
                  pl: 4,
                  py: 0.75,
                  color: 'white',
                  '&:hover': {
                    bgcolor: 'rgba(255, 255, 255, 0.05)',
                  },
                  '&.Mui-selected': {
                    bgcolor: 'rgba(66, 153, 225, 0.6)',
                    '&:hover': {
                      bgcolor: 'rgba(66, 153, 225, 0.7)',
                    },
                  },
                  borderRadius: 0
                }}
              >
                <ListItemIcon sx={{ color: 'inherit', minWidth: 36 }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText 
                  primary={item.label} 
                  primaryTypographyProps={{
                    sx: { 
                      fontSize: '0.8rem', 
                      color: 'rgba(255, 255, 255, 0.9)'
                    }
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>

        <Divider sx={{ my: 2, borderColor: 'rgba(255, 255, 255, 0.05)' }} />

        <ListItem button onClick={() => handleNavigation('/roles')} sx={{ borderRadius: 0 }}>
          <ListItemIcon sx={{ 
            color: 'rgba(255, 255, 255, 0.9)',
            minWidth: isSidebarCollapsed ? 0 : 36 // Use context state
          }}>
            <RolesIcon />
          </ListItemIcon>
          {!isSidebarCollapsed && ( // Use context state
            <ListItemText 
              primary="Roles"
              primaryTypographyProps={{
                sx: { 
                  color: 'rgba(255, 255, 255, 0.9)',
                  fontSize: '0.8rem'
                }
              }}
            />
          )}
        </ListItem>

        <ListItem button onClick={() => handleNavigation('/settings')} sx={{ borderRadius: 0 }}>
          <ListItemIcon sx={{ 
            color: 'rgba(255, 255, 255, 0.9)',
            minWidth: isSidebarCollapsed ? 0 : 36 // Use context state
          }}>
            <SettingsIcon />
          </ListItemIcon>
          {!isSidebarCollapsed && ( // Use context state
            <ListItemText 
              primary="Settings"
              primaryTypographyProps={{
                sx: { 
                  color: 'rgba(255, 255, 255, 0.9)',
                  fontSize: '0.8rem'
                }
              }}
            />
          )}
        </ListItem>
      </List>

      {/* User Profile Section */}
      <Box sx={{ 
        p: 2,
        borderTop: '1px solid rgba(255, 255, 255, 0.05)',
      }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            cursor: 'pointer',
            p: 1,
            borderRadius: 1,
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.08)',
            }
          }}
          onClick={(e) => setUserMenuAnchor(e.currentTarget)}
        >
          <Avatar sx={{ 
            bgcolor: 'rgba(255, 255, 255, 0.2)',
            color: 'rgba(255, 255, 255, 0.9)',
            width: 36,
            height: 36
          }}>
            {user?.name?.charAt(0) || 'U'}
          </Avatar>
          {!isSidebarCollapsed && ( // Use context state
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ 
                color: 'rgba(255, 255, 255, 0.9)',
                fontWeight: 500,
                fontSize: '0.8rem'
              }}>
                {user?.name}
              </Typography>
              <Typography sx={{ 
                color: 'rgba(255, 255, 255, 0.7)',
                fontSize: '0.75rem'
              }}>
                {user?.email}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Menu
        anchorEl={userMenuAnchor}
        open={Boolean(userMenuAnchor)}
        onClose={() => setUserMenuAnchor(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 200,
            mt: -1
          }
        }}
      >
        <MenuItem onClick={() => {
          setUserMenuAnchor(null);
          logout();
        }}>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default MainMenu;
// services/tableService.ts

import axios from 'axios';
import { TableMetadata, TableRecord } from '../types/table';

const API_URL = process.env.REACT_APP_API_URL || '/api';

interface QueryParams {
  page?: number;
  limit?: number;
  search?: string;
  filters?: string;
  sort?: string;
}

// Helper to build query parameters
const buildQueryParams = (params: QueryParams = {}): string => {
  const queryParams = new URLSearchParams();
  
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null && value !== '') {
      queryParams.append(key, value.toString());
    }
  });
  
  const queryString = queryParams.toString();
  return queryString ? `?${queryString}` : '';
};

const tableService = {
  // Get all tables
  getTables: async () => {
    return axios.get(`${API_URL}/tables`);
  },
  
  // Get user
  getUser: async (userId: string) => {
    return axios.get(`${API_URL}/users/${userId}`);
  },

  // Create user
  createUser: async (userData: Partial<TableRecord>) => {
    return axios.post(`${API_URL}/users`, userData);
  },

  // Update user
  updateUser: async (userId: string, userData: Partial<TableRecord>) => {
    return axios.put(`${API_URL}/users/${userId}`, userData);
  },

  // Delete user
  deleteUser: async (userId: string) => {
    return axios.delete(`${API_URL}/users/${userId}`);
  },

  // Get table by name
  getTableByName: async (tableName: string) => {
    if (tableName === 'user' || tableName === 'users') {
      return axios.get(`${API_URL}/users/metadata`);
    }
    return axios.get(`${API_URL}/tables/name/${tableName}`);
  },
  
  // Get table by ID
  getTableById: async (tableId: string) => {
    return axios.get(`${API_URL}/tables/${tableId}`);
  },

  // Alias for getTableById (for backward compatibility)
  getTable: async (tableId: string) => {
    return axios.get(`${API_URL}/tables/${tableId}`);
  },
  
  // Create table
  createTable: async (tableData: Partial<TableMetadata>) => {
    return axios.post(`${API_URL}/tables`, tableData);
  },
  
  // Update table
  updateTable: async (tableId: string, tableData: Partial<TableMetadata>) => {
    return axios.put(`${API_URL}/tables/${tableId}`, tableData);
  },
  
  // Delete table
  deleteTable: async (tableId: string) => {
    return axios.delete(`${API_URL}/tables/${tableId}`);
  },
  
  // Get records for users table
  getUsersTableRecords: async (params: QueryParams = {}) => {
    const queryString = buildQueryParams(params);
    return axios.get(`${API_URL}/users/list${queryString}`);
  },

  // Get records for a system table
  getSystemTableRecords: async (tableName: string, params: QueryParams = {}) => {
    const queryString = buildQueryParams(params);
    return axios.get(`${API_URL}/system/${tableName}${queryString}`);
  },
  
  // Get records for a custom table
  getCustomTableRecords: async (tableName: string, params: QueryParams = {}) => {
    const queryString = buildQueryParams(params);
    return axios.get(`${API_URL}/custom-tables/${tableName}/records${queryString}`);
  },
  
  // Get records for a CMDB collection
  getCmdbCollectionRecords: async (tableName: string, params: QueryParams = {}) => {
    const queryString = buildQueryParams(params);
    return axios.get(`${API_URL}/cmdb/${tableName}${queryString}`);
  },
  
  // Get a specific record from a system table
  getSystemTableRecord: async (tableName: string, recordId: string) => {
    return axios.get(`${API_URL}/system/${tableName}/${recordId}`);
  },
  
  // Get a specific record from a custom table
  getCustomTableRecord: async (tableName: string, recordId: string) => {
    return axios.get(`${API_URL}/custom-tables/${tableName}/records/${recordId}`);
  },
  
  // Get a specific record from a CMDB collection
  getCmdbCollectionRecord: async (tableName: string, recordId: string) => {
    return axios.get(`${API_URL}/cmdb/${tableName}/${recordId}`);
  },
  
  // Get records for a table by ID
  getRecords: async (tableId: string, params: QueryParams = {}) => {
    const queryString = buildQueryParams(params);
    return axios.get(`${API_URL}/tables/${tableId}/records${queryString}`);
  },

  // Create a new record
  createRecord: async (tableId: string, recordData: Partial<TableRecord>) => {
    return axios.post(`${API_URL}/tables/${tableId}/records`, recordData);
  },
  
  // Create a new record in a custom table
  createCustomTableRecord: async (tableName: string, recordData: Partial<TableRecord>) => {
    return axios.post(`${API_URL}/custom-tables/${tableName}/records`, recordData);
  },
  
  // Update a record
  updateRecord: async (tableId: string, recordId: string, recordData: Partial<TableRecord>) => {
    return axios.put(`${API_URL}/tables/${tableId}/records/${recordId}`, recordData);
  },
  
  // Update a record in a custom table
  updateCustomTableRecord: async (tableName: string, recordId: string, recordData: Partial<TableRecord>) => {
    return axios.put(`${API_URL}/custom-tables/${tableName}/records/${recordId}`, recordData);
  },
  
  // Delete a record
  deleteRecord: async (tableId: string, recordId: string) => {
    return axios.delete(`${API_URL}/tables/${tableId}/records/${recordId}`);
  },
  
  // Delete a record from a custom table
  deleteCustomTableRecord: async (tableName: string, recordId: string) => {
    return axios.delete(`${API_URL}/custom-tables/${tableName}/records/${recordId}`);
  }
};

export default tableService;
import React, { useState, useEffect } from 'react';
import {
  // Remove Dialog related imports
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  Autocomplete,
  Chip,
  Paper, // Add Paper import
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import api from '../../api/axios';

interface User {
  _id: string;
  name: string;
  email: string;
}

interface FormValues {
  name: string;
  description: string;
  members: User[];
}

interface NewTeamProps {
  // Remove 'open' prop
  onClose: () => void;
  onTeamCreated: () => void;
}

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  members: yup.array().min(1, 'At least one member is required').required('Members are required'),
});

const NewTeam: React.FC<NewTeamProps> = ({ onClose, onTeamCreated }) => { // Remove 'open'
  const [users, setUsers] = useState<User[]>([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get('/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
        setError('Failed to fetch users');
      }
    };
    // Fetch users immediately when the component mounts (since it's only shown when needed)
    fetchUsers();
  }, []); // Empty dependency array means run once on mount

  const formik = useFormik<FormValues>({
    initialValues: {
      name: '',
      description: '',
      members: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        setError('');
        const teamData = {
          ...values,
          members: values.members.map(member => member._id),
        };
        await api.post('/teams', teamData);
        resetForm();
        onTeamCreated();
      } catch (error) {
        console.error('Error creating team:', error);
        setError('Failed to create team');
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    setError('');
    onClose();
  };

  // Remove Dialog wrapper, render content directly
  return (
    <Paper elevation={0} sx={{ p: 0 }}> {/* Use Paper or Box as container */}
      {/* Header */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}
           sx={{ borderBottom: 1, borderColor: 'divider', pb: 1 }}>
        <Typography variant="h6">Create New Team</Typography>
        {/* Add a close/back button if needed, calling onClose */}
         <IconButton onClick={handleClose} size="small" title="Cancel">
           <CloseIcon />
         </IconButton>
      </Box>

      {/* Form Content */}
      <Box component="form" onSubmit={formik.handleSubmit}>
        {error && (
          <Typography color="error" mb={2}>
            {error}
          </Typography>
        )}

        <TextField
          fullWidth
          margin="normal"
          id="name"
          name="name"
          label="Team Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />

        <TextField
          fullWidth
          margin="normal"
          id="description"
          name="description"
          label="Description"
          multiline
          rows={4}
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
        />

        <Autocomplete
          multiple
          id="members"
          options={users}
          value={formik.values.members}
          onChange={(_, newValue) => {
            formik.setFieldValue('members', newValue);
          }}
          getOptionLabel={(option) => `${option.name} (${option.email})`}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                label={option.name}
                {...getTagProps({ index })}
                key={option._id}
                sx={{ m: 0.5 }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Team Members"
              margin="normal"
              error={formik.touched.members && Boolean(formik.errors.members)}
              helperText={formik.touched.members && (formik.errors.members as string)}
            />
          )}
        />

        {/* Action Buttons */}
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
          >
            Create Team
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default NewTeam;

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Divider,
  Card,
  CardContent,
  Chip,
  Tabs,
  Tab,
  FormHelperText,
  IconButton,
  // Stack, // Unused import
  Collapse,
  alpha,
  useTheme
} from '@mui/material';
import { 
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Computer as ComputerIcon,
  Storage as StorageIcon,
  // Domain as DomainIcon, // Unused import
  Router as RouterIcon,
  Description as DescriptionIcon
} from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { ciService } from '../../services/ciService';
import { ciTypeService } from '../../services/ciTypeService';
import { CI, CIType, CustomField } from '../../types/cmdb';
import { useSnackbar } from 'notistack';
import { CIRelationships } from './CIRelationships';

type FormMode = 'create' | 'edit';

interface CIFormProps {
  open?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  initialData?: Partial<CI>;
  mode?: FormMode;
}

interface FormData {
  _id?: string;
  name: string;
  type: string;
  status: CI['status'];
  location: string;
  description: string;
  aiInsights: string;
  customFields: Record<string, any>;
  [key: string]: any; // For direct hardware fields
}

const initialFormData: FormData = {
  name: '',
  type: '',
  status: 'active',
  location: '',
  description: '',
  aiInsights: '',
  customFields: {}
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`ci-form-tabpanel-${index}`}
      aria-labelledby={`ci-form-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const getInitialFieldValue = (field: CustomField) => {
  switch (field.type) {
    case 'number':
      return '';
    case 'boolean':
      return false;
    case 'date':
      return '';
    default:
      return '';
  }
};

// Field definitions - these aren't associated with specific CI types
// They're just to help identify field groups by their purpose
const FIELD_DEFINITIONS = {
  // These arrays contain field names that help identify the nature of fields
  // without tying them to specific CI types
  physical: ['manufacturer', 'model', 'serialNumber', 'assetTag', 'purchaseDate', 'warrantyExpiryDate'],
  computing: ['operatingSystem', 'domain', 'cpuCores', 'ramGB', 'diskGB', 'isVirtual', 'virtualHost'],
  networking: ['ipAddress', 'managementIP', 'deviceType', 'ports', 'managementProtocols']
};
export const CIForm: React.FC<CIFormProps> = ({
  open = true,
  onClose,
  onSuccess,
  initialData,
  mode = 'create'
}) => {
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [tabValue, setTabValue] = useState(0);
  const [expandedSections, setExpandedSections] = useState({
    basic: true,
    hardware: false, // Don't show hardware section by default
    server: false,   // Don't show server section by default
    network: false   // Don't show network section by default
  });

  const [types, setTypes] = useState<CIType[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [selectedType, setSelectedType] = useState<CIType | null>(null);
  const [formData, setFormData] = useState<FormData>(() => {
    if (initialData) {
      // Extract direct fields from initialData if available
      // These fields will be submitted directly rather than as customFields
      const directFields: Record<string, any> = {};
      Object.keys(initialData).forEach(key => {
        // All fields that should be direct properties
        const allDirectFields = [
          ...FIELD_DEFINITIONS.physical,
          ...FIELD_DEFINITIONS.computing,
          ...FIELD_DEFINITIONS.networking
        ];
        
        if (allDirectFields.includes(key)) {
          directFields[key] = initialData[key as keyof typeof initialData];
        }
      });

      return {
        ...initialFormData,
        ...initialData,
        ...directFields,
        type: initialData.type?._id || '',
        customFields: initialData.customFields || {}
      };
    }
    return initialFormData;
  });
  
  // Analyze a CI type to determine which field types it contains
  const shouldShowFields = (ciType: CIType | null) => {
    if (!ciType) {
      console.log('No CI type provided to shouldShowFields, hiding all sections');
      return {
        hasPhysicalFields: false,
        hasComputingFields: false,
        hasNetworkingFields: false
      };
    }

    // Get all field names from both custom fields and default fields
    const allFieldNames = new Set<string>([
      ...ciType.customFields.map(f => f.name)
    ]);
    
    // Also check default fields if they exist
    if (ciType.defaultFields) {
      Object.keys(ciType.defaultFields).forEach(key => allFieldNames.add(key));
    }
    
    // Check for presence of different types of fields
    const hasPhysicalFields = FIELD_DEFINITIONS.physical.some(field => allFieldNames.has(field));
    const hasComputingFields = FIELD_DEFINITIONS.computing.some(field => allFieldNames.has(field));
    const hasNetworkingFields = FIELD_DEFINITIONS.networking.some(field => allFieldNames.has(field));

    console.log(`Field analysis for CI type ${ciType.name} (${ciType._id}):`, {
      hasPhysicalFields,
      hasComputingFields,
      hasNetworkingFields,
      allFields: Array.from(allFieldNames),
      fieldDefinitions: {
        physical: FIELD_DEFINITIONS.physical,
        computing: FIELD_DEFINITIONS.computing,
        networking: FIELD_DEFINITIONS.networking
      }
    });
    
    return {
      hasPhysicalFields,
      hasComputingFields,
      hasNetworkingFields
    };
  };
  
  // Check if hardware section should be shown based on current type
  const shouldShowHardwareSection = () => {
    if (!selectedType) return false;
    return shouldShowFields(selectedType).hasPhysicalFields;
  };

  // Check if server section should be shown based on current type
  const shouldShowServerSection = () => {
    if (!selectedType) return false;
    return shouldShowFields(selectedType).hasComputingFields;
  };

  // Check if network section should be shown based on current type
  const shouldShowNetworkSection = () => {
    if (!selectedType) return false;
    return shouldShowFields(selectedType).hasNetworkingFields;
  };

  const loadTypes = useCallback(async () => {
    try {
      setError(null);
      const data = await ciTypeService.getAllTypes();
      // Filter out abstract types if creating a new CI
      const filteredTypes = mode === 'create' 
        ? data.filter((type: CIType) => !type.isAbstract)
        : data;
      setTypes(filteredTypes);
    } catch (error) {
      console.error('Error loading CI types:', error);
      setError('Failed to load CI types');
      enqueueSnackbar('Failed to load CI types', { variant: 'error' });
    }
  }, [enqueueSnackbar, mode]);

  const loadCI = useCallback(async (ciId: string) => {
    try {
      const data = await ciService.getCI(ciId);
      
      // Extract direct fields from data
      const directFields: Record<string, any> = {};
      Object.keys(data).forEach(key => {
        // All fields that should be direct properties
        const allDirectFields = [
          ...FIELD_DEFINITIONS.physical,
          ...FIELD_DEFINITIONS.computing,
          ...FIELD_DEFINITIONS.networking
        ];
        
        if (allDirectFields.includes(key)) {
          directFields[key] = data[key as keyof typeof data];
        }
      });
      
      setFormData({
        ...initialFormData,
        ...data,
        ...directFields,
        type: data.type._id || '',
        customFields: data.customFields || {}
      });
      
      setSelectedType(data.type);
      
      // Determine which sections to show based on fields present in type
      const fieldsList = shouldShowFields(data.type);
      
      // Set section visibility based on field presence
      setExpandedSections(prev => ({
        ...prev,
        hardware: fieldsList.hasPhysicalFields,
        server: fieldsList.hasComputingFields,
        network: fieldsList.hasNetworkingFields
      }));
    } catch (error) {
      console.error('Error loading CI:', error);
      setError('Failed to load CI');
      enqueueSnackbar('Failed to load CI', { variant: 'error' });
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    loadTypes();
    if (mode === 'edit' && id) {
      loadCI(id);
    }

    // When the form first loads, hide all type-specific sections
    if (!selectedType) {
      console.log('No type selected, hiding all type-specific sections');
      setExpandedSections(prev => ({
        ...prev,
        hardware: false,
        server: false,
        network: false
      }));
    }
  }, [mode, id, loadTypes, loadCI]);
  
  // Handle initial data if provided
  useEffect(() => {
    if (initialData && initialData.type) {
      const loadTypeAndUpdateForm = async () => {
        try {
          const type = await ciTypeService.getType(initialData.type?._id || '');
          setSelectedType(type);
          
          // Determine which sections to show based on fields present in type
          const fieldsList = shouldShowFields(type);
          
          // Set section visibility based on field presence
          setExpandedSections(prev => ({
            ...prev,
            hardware: fieldsList.hasPhysicalFields,
            server: fieldsList.hasComputingFields,
            network: fieldsList.hasNetworkingFields
          }));
        } catch (error) {
          console.error('Error loading initial CI type:', error);
        }
      };
      
      loadTypeAndUpdateForm();
    }
  }, [initialData]);

  const handleTypeChange = async (event: SelectChangeEvent) => {
    const typeId = event.target.value;
    console.log('Type selected:', typeId);
    
    try {
      const type = await ciTypeService.getType(typeId);
      console.log('Loaded CI type:', type);
      setSelectedType(type);
      
      const initialCustomFields: Record<string, any> = {};
      console.log('Initializing custom fields...');
      
      // Process custom fields
      type.customFields.forEach((field: CustomField) => {
        const initialValue = getInitialFieldValue(field);
        console.log(`Setting initial value for ${field.name}:`, {
          fieldType: field.type,
          initialValue,
          required: field.required
        });
        initialCustomFields[field.name] = initialValue;
      });
      
      // Process default fields if they exist
      // Convert default fields to customFields format for consistency
      if (type.defaultFields && Object.keys(type.defaultFields).length > 0) {
        console.log('Processing default fields:', type.defaultFields);
        Object.entries(type.defaultFields).forEach(([fieldName, fieldConfig]: [string, any]) => {
          if (!fieldConfig) return;
          
          // Only add if not already a direct field
          const allDirectFields = [
            ...FIELD_DEFINITIONS.physical,
            ...FIELD_DEFINITIONS.computing,
            ...FIELD_DEFINITIONS.networking
          ];
          const isDirectField = allDirectFields.includes(fieldName);
          
          if (!isDirectField) {
            const initialValue = fieldConfig.type === 'number' ? '' : 
                                fieldConfig.type === 'boolean' ? false : 
                                fieldConfig.type === 'date' ? '' : '';
            
            console.log(`Setting initial value for default field ${fieldName}:`, {
              fieldType: fieldConfig.type,
              initialValue,
              required: fieldConfig.required
            });
            
            initialCustomFields[fieldName] = initialValue;
          }
        });
      }

      console.log('Final custom fields state:', initialCustomFields);

      // Don't rely on base type - only use field detection
      // Determine which sections to show based on fields present in type
      const fieldsList = shouldShowFields(type);
      
      // Set section visibility based on field presence
      setExpandedSections(prev => ({
        ...prev,
        hardware: fieldsList.hasPhysicalFields,
        server: fieldsList.hasComputingFields,
        network: fieldsList.hasNetworkingFields
      }));

      setFormData(prev => {
        const newState = {
          ...prev,
          type: typeId,
          customFields: initialCustomFields
        };
        console.log('Updated form data:', newState);
        return newState;
      });
    } catch (error) {
      console.error('Error loading CI type:', error);
      setError('Failed to load CI type details');
      enqueueSnackbar('Failed to load CI type details', { variant: 'error' });
    }
  };

  const handleCustomFieldChange = (field: CustomField, value: any) => {
    console.log('Custom field change:', {
      field: field.name,
      type: field.type,
      oldValue: formData.customFields[field.name],
      newValue: value
    });

    let processedValue = value;

    switch (field.type) {
      case 'number':
        processedValue = value === '' ? '' : Number(value);
        break;
      case 'boolean':
        processedValue = Boolean(value);
        break;
      case 'date':
        processedValue = value;
        break;
      default:
        processedValue = value;
    }

    setFormData(prev => ({
      ...prev,
      customFields: {
        ...prev.customFields,
        [field.name]: processedValue
      }
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Submitting form with data:', formData);

    try {
      const foundType = types.find(t => t._id === formData.type);
      
      // Log all form data for debugging purposes
      console.log('Submitting form data:', {
        name: formData.name,
        type: formData.type,
        status: formData.status,
        location: formData.location,
        description: formData.description,
        customFields: formData.customFields
      });
      
          // Extract direct fields from formData to submit directly
      const directFields: Record<string, any> = {};
      Object.keys(formData).forEach(key => {
        const allDirectFields = [
          ...FIELD_DEFINITIONS.physical,
          ...FIELD_DEFINITIONS.computing,
          ...FIELD_DEFINITIONS.networking
        ];
        
        if (allDirectFields.includes(key)) {
          if (formData[key] !== undefined && formData[key] !== '') {
            directFields[key] = formData[key];
          }
        }
      });
      
      // Process customFields before submission
      // Make sure to filter out empty values for optional fields and handle default fields
      const processedCustomFields = { ...formData.customFields };
      
      // If we have a selected type, validate and process fields according to their requirements
      if (selectedType) {
        // Process custom fields
        selectedType.customFields.forEach((field: CustomField) => {
          // Skip direct fields that are handled separately
          const allDirectFields = [
            ...FIELD_DEFINITIONS.physical,
            ...FIELD_DEFINITIONS.computing,
            ...FIELD_DEFINITIONS.networking
          ];
          
          if (allDirectFields.includes(field.name)) {
            return;
          }
          
          const value = processedCustomFields[field.name];
          // Remove empty optional fields
          if (!field.required && (value === undefined || value === '' || value === null)) {
            delete processedCustomFields[field.name];
          }
        });
        
        // Process default fields if they exist
        if (selectedType.defaultFields) {
          Object.entries(selectedType.defaultFields).forEach(([fieldName, fieldConfig]: [string, any]) => {
            if (!fieldConfig) return;
            
            // Skip direct fields
            const allDirectFields = [
              ...FIELD_DEFINITIONS.physical,
              ...FIELD_DEFINITIONS.computing,
              ...FIELD_DEFINITIONS.networking
            ];
            
            if (allDirectFields.includes(fieldName)) {
              return;
            }
            
            const value = processedCustomFields[fieldName];
            // Remove empty optional fields
            if (!fieldConfig.required && (value === undefined || value === '' || value === null)) {
              delete processedCustomFields[fieldName];
            }
          });
        }
      }
      
      console.log('Processed custom fields for submission:', processedCustomFields);
      
      // Prepare complete data for submission
      const submitData: Partial<CI> = {
        ...formData,
        ...directFields,
        customFields: processedCustomFields,
        type: foundType || {
          _id: formData.type,
          name: selectedType?.name || '',
          description: selectedType?.description || '',
          customFields: selectedType?.customFields || [],
          baseType: selectedType?.baseType || 'CI',
          parent: selectedType?.parent || null,
          isAbstract: selectedType?.isAbstract || false
        }
      };

      if (mode === 'edit' && id) {
        await ciService.updateCI(id, submitData);
        enqueueSnackbar('CI updated successfully', { variant: 'success' });
      } else {
        await ciService.createCI(submitData);
        enqueueSnackbar('CI created successfully', { variant: 'success' });
      }

      if (onSuccess) {
        onSuccess();
      } else {
        navigate('/cmdb');
      }
    } catch (error) {
      console.error('Error saving CI:', error);
      setError('Failed to save CI');
      enqueueSnackbar('Failed to save CI', { variant: 'error' });
    }
  };

  const handleClose = () => {
    setFormData(initialFormData);
    setSelectedType(null);
    setError(null);
    setTabValue(0);
    setExpandedSections({
      basic: true,
      hardware: false, // Don't show hardware section by default
      server: false,   // Don't show server section by default
      network: false   // Don't show network section by default
    });
    if (onClose) {
      onClose();
    }
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const toggleSection = (section: keyof typeof expandedSections) => {
    setExpandedSections({
      ...expandedSections,
      [section]: !expandedSections[section]
    });
  };

  // Handler for hardware fields
  const handleHardwareFieldChange = (field: string, value: any) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Helper to format date for display (currently unused)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const formatDate = (date: any) => {
    if (!date) return '';
    try {
      return new Date(date).toISOString().split('T')[0];
    } catch (e) {
      return '';
    }
  };
  // Render basic information section
  const renderBasicInfoSection = () => {
    return (
      <Card variant="outlined" sx={{ mb: 3 }}>
        <Box 
          sx={{ 
            p: 2, 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            cursor: 'pointer',
            bgcolor: expandedSections.basic ? alpha(theme.palette.primary.main, 0.05) : 'transparent',
          }}
          onClick={() => toggleSection('basic')}
        >
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <ComputerIcon color="primary" fontSize="small" />
            Basic Information
          </Typography>
          <IconButton size="small">
            {expandedSections.basic ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
        <Divider />
        <Collapse in={expandedSections.basic}>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  required
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={formData.type}
                    onChange={handleTypeChange}
                    label="Type"
                  >
                    {types.map((type) => (
                      <MenuItem key={type._id} value={type._id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={formData.status}
                    onChange={(e) => setFormData({ ...formData, status: e.target.value as CI['status'] })}
                    label="Status"
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                    <MenuItem value="maintenance">Maintenance</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Location"
                  value={formData.location}
                  onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                />
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  multiline
                  rows={2}
                />
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Card>
    );
  };

  // Render hardware information section
  const renderHardwareSection = () => {
    // Don't render anything until a type is selected
    if (!selectedType) {
      console.log('No type selected, not showing hardware section');
      return null;
    }

    // Only show hardware section if the fields are present
    if (!shouldShowHardwareSection()) {
      console.log('Type doesn\'t have hardware fields, not showing hardware section');
      return null;
    }
    
    // If hardware section is not expanded, don't render it
    if (!expandedSections.hardware) {
      console.log('Hardware section not expanded, not showing hardware section');
      return null;
    }
    
    return (
      <Card variant="outlined" sx={{ mb: 3 }}>
        <Box 
          sx={{ 
            p: 2, 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
            bgcolor: expandedSections.hardware ? alpha(theme.palette.primary.main, 0.05) : 'transparent',
          }}
          onClick={() => toggleSection('hardware')}
        >
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <StorageIcon color="primary" fontSize="small" />
            Hardware Information
          </Typography>
          <IconButton size="small">
            {expandedSections.hardware ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
        <Divider />
        <Collapse in={expandedSections.hardware}>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Manufacturer"
                  value={formData.manufacturer || ''}
                  onChange={(e) => handleHardwareFieldChange('manufacturer', e.target.value)}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Model"
                  value={formData.model || ''}
                  onChange={(e) => handleHardwareFieldChange('model', e.target.value)}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Serial Number"
                  value={formData.serialNumber || ''}
                  onChange={(e) => handleHardwareFieldChange('serialNumber', e.target.value)}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Asset Tag"
                  value={formData.assetTag || ''}
                  onChange={(e) => handleHardwareFieldChange('assetTag', e.target.value)}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Purchase Date"
                    value={formData.purchaseDate ? new Date(formData.purchaseDate) : null}
                    onChange={(date) => handleHardwareFieldChange('purchaseDate', date)}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </LocalizationProvider>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Warranty Expiry Date"
                    value={formData.warrantyExpiryDate ? new Date(formData.warrantyExpiryDate) : null}
                    onChange={(date) => handleHardwareFieldChange('warrantyExpiryDate', date)}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Card>
    );
  };

  // Render server-specific section
  const renderServerSection = () => {
    // Don't render anything until a type is selected
    if (!selectedType) {
      console.log('No type selected, not showing server section');
      return null;
    }

    // Only show server section if the type has computing fields
    if (!shouldShowServerSection()) {
      console.log('Type doesn\'t have server fields, not showing server section');
      return null;
    }
    
    // Don't show the section if it's collapsed
    if (!expandedSections.server) {
      console.log('Server section not expanded, not showing server section');
      return null;
    }
    
    return (
      <Card variant="outlined" sx={{ mb: 3 }}>
        <Box 
          sx={{ 
            p: 2, 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
            bgcolor: expandedSections.server ? alpha(theme.palette.primary.main, 0.05) : 'transparent',
          }}
          onClick={() => toggleSection('server')}
        >
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <ComputerIcon color="primary" fontSize="small" />
            Server Information
          </Typography>
          <IconButton size="small">
            {expandedSections.server ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
        <Divider />
        <Collapse in={expandedSections.server}>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="IP Address"
                  value={formData.ipAddress || ''}
                  onChange={(e) => handleHardwareFieldChange('ipAddress', e.target.value)}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Operating System"
                  value={formData.operatingSystem || ''}
                  onChange={(e) => handleHardwareFieldChange('operatingSystem', e.target.value)}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Domain"
                  value={formData.domain || ''}
                  onChange={(e) => handleHardwareFieldChange('domain', e.target.value)}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="CPU Cores"
                  type="number"
                  value={formData.cpuCores || ''}
                  onChange={(e) => handleHardwareFieldChange('cpuCores', e.target.value)}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="RAM (GB)"
                  type="number"
                  value={formData.ramGB || ''}
                  onChange={(e) => handleHardwareFieldChange('ramGB', e.target.value)}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Disk Space (GB)"
                  type="number"
                  value={formData.diskGB || ''}
                  onChange={(e) => handleHardwareFieldChange('diskGB', e.target.value)}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={Boolean(formData.isVirtual)}
                      onChange={(e) => handleHardwareFieldChange('isVirtual', e.target.checked)}
                    />
                  }
                  label="Virtual Machine"
                />
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Card>
    );
  };

  // Render network device section
  const renderNetworkSection = () => {
    // Don't render anything until a type is selected
    if (!selectedType) {
      console.log('No type selected, not showing network section');
      return null;
    }

    // Only show network section if the type has networking fields
    if (!shouldShowNetworkSection()) {
      console.log('Type doesn\'t have network fields, not showing network section');
      return null;
    }
    
    // Don't show the section if it's collapsed
    if (!expandedSections.network) {
      console.log('Network section not expanded, not showing network section');
      return null;
    }
    
    return (
      <Card variant="outlined" sx={{ mb: 3 }}>
        <Box 
          sx={{ 
            p: 2, 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
            bgcolor: expandedSections.network ? alpha(theme.palette.primary.main, 0.05) : 'transparent',
          }}
          onClick={() => toggleSection('network')}
        >
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <RouterIcon color="primary" fontSize="small" />
            Network Device Information
          </Typography>
          <IconButton size="small">
            {expandedSections.network ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
        <Divider />
        <Collapse in={expandedSections.network}>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="IP Address"
                  value={formData.ipAddress || ''}
                  onChange={(e) => handleHardwareFieldChange('ipAddress', e.target.value)}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Management IP"
                  value={formData.managementIP || ''}
                  onChange={(e) => handleHardwareFieldChange('managementIP', e.target.value)}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Device Type</InputLabel>
                  <Select
                    value={formData.deviceType || ''}
                    onChange={(e) => handleHardwareFieldChange('deviceType', e.target.value)}
                    label="Device Type"
                  >
                    <MenuItem value="switch">Switch</MenuItem>
                    <MenuItem value="router">Router</MenuItem>
                    <MenuItem value="firewall">Firewall</MenuItem>
                    <MenuItem value="loadBalancer">Load Balancer</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Ports"
                  type="number"
                  value={formData.ports || ''}
                  onChange={(e) => handleHardwareFieldChange('ports', e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Card>
    );
  };

  // Render custom fields section
  const renderCustomFields = () => {
    if (!selectedType) return null;
    
    // Get default field names so we can avoid duplicating them in the custom fields section
    const defaultFieldNames = selectedType.defaultFields ? Object.keys(selectedType.defaultFields) : [];
    
    // Filter custom fields to remove any that also appear in default fields
    const filteredCustomFields = selectedType.customFields.filter(
      field => !defaultFieldNames.includes(field.name)
    );
    
    const hasCustomFields = filteredCustomFields.length > 0;
    const hasDefaultFields = selectedType.defaultFields && Object.keys(selectedType.defaultFields).length > 0;
    
    if (!hasCustomFields && !hasDefaultFields) return null;

    // Filter out hardware fields that are already handled
    const directFieldNames = [
      ...FIELD_DEFINITIONS.physical,
      ...FIELD_DEFINITIONS.computing,
      ...FIELD_DEFINITIONS.networking
    ];
    
    return (
      <>
        {/* Default Fields Section */}
        {hasDefaultFields && selectedType.defaultFields && (
          <Paper 
            elevation={0} 
            sx={{ 
              p: 3, 
              mt: 3,
              bgcolor: '#f8f9fa', 
              border: '1px solid #e0e0e0',
              borderRadius: 2,
              mb: 3
            }}
            data-testid="default-fields-section"
          >
            <Typography 
              variant="subtitle1" 
              fontWeight="medium" 
              color="primary"
              sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}
            >
              <DescriptionIcon color="primary" fontSize="small" />
              {selectedType.name} Default Fields
            </Typography>
            
            <Grid container spacing={3}>
              {Object.entries(selectedType.defaultFields).map(([fieldName, fieldConfig]: [string, any]) => {
                // Skip if not a valid field config or is a direct field
                if (!fieldConfig || !fieldConfig.type || directFieldNames.includes(fieldName)) {
                  return null;
                }
                
                const fieldLabel = fieldConfig.label || fieldName.replace(/_/g, ' ')
                  .split(' ')
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ');
                
                switch (fieldConfig.type) {
                  case 'text':
                  case 'number':
                    return (
                      <Grid item xs={12} sm={6} md={4} key={`default-${fieldName}`}>
                        <TextField
                          fullWidth
                          label={fieldLabel}
                          type={fieldConfig.type === 'number' ? 'number' : 'text'}
                          value={formData.customFields[fieldName] || ''}
                          onChange={(e) => handleCustomFieldChange({name: fieldName, type: fieldConfig.type} as CustomField, e.target.value)}
                          required={fieldConfig.required}
                          helperText={fieldConfig.description}
                          select={fieldConfig.options ? true : false}
                        >
                          {fieldConfig.options?.map((option: any) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    );
                  case 'boolean':
                    return (
                      <Grid item xs={12} sm={6} md={4} key={`default-${fieldName}`}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={Boolean(formData.customFields[fieldName])}
                              onChange={(e) => handleCustomFieldChange({name: fieldName, type: fieldConfig.type} as CustomField, e.target.checked)}
                            />
                          }
                          label={fieldLabel}
                        />
                      </Grid>
                    );
                  case 'date':
                    return (
                      <Grid item xs={12} sm={6} md={4} key={`default-${fieldName}`}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label={fieldLabel}
                            value={formData.customFields[fieldName] ? new Date(formData.customFields[fieldName]) : null}
                            onChange={(date) => handleCustomFieldChange({name: fieldName, type: fieldConfig.type} as CustomField, date)}
                            slotProps={{ 
                              textField: { 
                                fullWidth: true, 
                                required: fieldConfig.required,
                                helperText: fieldConfig.description 
                              } 
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                    );
                  default:
                    return null;
                }
              })}
            </Grid>
          </Paper>
        )}
        
        {/* Custom Fields Section - Only show if there are actual custom fields not covered by default fields */}
        {hasCustomFields && (
          <Paper 
            elevation={0} 
            sx={{ 
              p: 3, 
              mt: 3,
              bgcolor: '#f5f9ff', 
              border: '1px solid #d0e1fd',
              borderRadius: 2,
              mb: 3
            }}
            data-testid="custom-fields-section"
          >
            <Typography 
              variant="subtitle1" 
              fontWeight="medium" 
              color="secondary"
              sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}
            >
              <DescriptionIcon color="secondary" fontSize="small" />
              {selectedType.name} Custom Fields
            </Typography>
            
            <Grid container spacing={3}>
              {filteredCustomFields.map((field: CustomField) => {
                // Skip fields that are already handled as direct fields
                if (directFieldNames.includes(field.name)) {
                  return null;
                }
                
                switch (field.type) {
                  case 'text':
                  case 'number':
                    return (
                      <Grid item xs={12} sm={6} md={4} key={`custom-${field.name}`}>
                        <TextField
                          fullWidth
                          label={field.label || field.name}
                          type={field.type === 'number' ? 'number' : 'text'}
                          value={formData.customFields[field.name] || ''}
                          onChange={(e) => handleCustomFieldChange(field, e.target.value)}
                          required={field.required}
                          helperText={field.validation?.options ? 'Choose from available options' : undefined}
                          select={field.validation?.options ? true : false}
                        >
                          {field.validation?.options?.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    );
                  case 'boolean':
                    return (
                      <Grid item xs={12} sm={6} md={4} key={`custom-${field.name}`}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={Boolean(formData.customFields[field.name])}
                              onChange={(e) => handleCustomFieldChange(field, e.target.checked)}
                            />
                          }
                          label={field.label || field.name}
                        />
                      </Grid>
                    );
                  case 'date':
                    return (
                      <Grid item xs={12} sm={6} md={4} key={`custom-${field.name}`}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label={field.label || field.name}
                            value={formData.customFields[field.name] ? new Date(formData.customFields[field.name]) : null}
                            onChange={(date) => handleCustomFieldChange(field, date)}
                            slotProps={{ textField: { fullWidth: true, required: field.required } }}
                          />
                        </LocalizationProvider>
                      </Grid>
                    );
                  default:
                    return null;
                }
              })}
            </Grid>
          </Paper>
        )}
      </>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: '90vh',
          height: '90vh',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        px: 3,
        py: 2,
        borderBottom: '1px solid',
        borderColor: 'divider'
      }}>
        <Typography variant="h6">
          {mode === 'create' ? 'Create Configuration Item' : 'Edit Configuration Item'}
        </Typography>
        <Chip 
          label={formData.type ? types.find(t => t._id === formData.type)?.name || 'Unknown Type' : 'Select Type'} 
          color="primary" 
          size="small" 
        />
      </DialogTitle>
      
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ px: 2 }}
        >
          <Tab label="Basic Information" />
          <Tab label="AI Insights" />
          {mode === 'edit' && formData._id && <Tab label="Relationships" />}
        </Tabs>
      </Box>
      
      <DialogContent sx={{ overflow: 'auto', p: 0, flexGrow: 1 }}>
        <TabPanel value={tabValue} index={0}>
          <Box sx={{ p: 3 }}>
            {error && (
              <Paper sx={{ p: 2, mb: 3, bgcolor: alpha(theme.palette.error.main, 0.1) }}>
                <Typography color="error">
                  {error}
                </Typography>
              </Paper>
            )}
            
            {/* Basic Information Section */}
            {renderBasicInfoSection()}
            
            
            {/* Custom Fields Section */}
            {renderCustomFields()}
          </Box>
        </TabPanel>
        
        <TabPanel value={tabValue} index={1}>
          <Box sx={{ p: 3 }}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <DescriptionIcon fontSize="small" color="primary" />
                  AI Insights
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <TextField
                  fullWidth
                  multiline
                  rows={20}
                  value={formData.aiInsights}
                  onChange={(e) => setFormData({ ...formData, aiInsights: e.target.value })}
                  placeholder="AI-generated insights about this configuration item"
                  variant="outlined"
                />
                <FormHelperText>
                  This field can be populated automatically by AI or edited manually
                </FormHelperText>
              </CardContent>
            </Card>
          </Box>
        </TabPanel>
        
        {mode === 'edit' && formData._id && (
          <TabPanel value={tabValue} index={2}>
            <Box sx={{ p: 3 }}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <RouterIcon fontSize="small" color="primary" />
                    CI Relationships
                  </Typography>
                  <Divider sx={{ mb: 2 }} />
                  <CIRelationships
                    ci={{ 
                      ...formData, 
                      _id: formData._id,
                      type: selectedType || {
                        _id: formData.type,
                        name: '',
                        description: '',
                        customFields: [],
                        baseType: 'CI',
                        parent: null,
                        isAbstract: false
                      }
                    }} 
                    onUpdate={() => {}}
                  />
                </CardContent>
              </Card>
            </Box>
          </TabPanel>
        )}
      </DialogContent>
      
      <DialogActions sx={{ p: 2, borderTop: '1px solid', borderColor: 'divider' }}>
        <Button onClick={handleClose} color="inherit">Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          {mode === 'edit' ? 'Save Changes' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CIForm;
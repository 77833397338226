import axios from 'axios';
import { API_URL } from '../config';

interface CreateWorkflowInstanceData {
  templateId: string;
  requestId: string;
  name: string;
  description?: string;
  priority?: 'low' | 'medium' | 'high' | 'critical';
  dueDate?: Date | null;
}

interface WorkflowInstance {
  _id: string;
  templateId: string;
  requests: string[];
  name: string;
  description?: string;
  status: 'active' | 'completed' | 'cancelled' | 'failed';
  priority: 'low' | 'medium' | 'high' | 'critical';
  startDate: Date;
  endDate?: Date;
  dueDate?: Date;
  progress: number;
  createdBy: string;
  updatedBy?: string;
  tasks: any[];
}

const getWorkflowInstances = async (): Promise<WorkflowInstance[]> => {
  const response = await axios.get<WorkflowInstance[]>(`${API_URL}/workflow-instances`);
  return response.data;
};

const getWorkflowInstance = async (id: string): Promise<WorkflowInstance> => {
  const response = await axios.get<WorkflowInstance>(`${API_URL}/workflow-instances/${id}`);
  return response.data;
};

const createInstance = async (data: CreateWorkflowInstanceData): Promise<WorkflowInstance> => {
  const response = await axios.post<WorkflowInstance>(`${API_URL}/workflow-instances`, data);
  return response.data;
};

const updateWorkflowInstance = async (id: string, data: Partial<WorkflowInstance>): Promise<WorkflowInstance> => {
  const response = await axios.put<WorkflowInstance>(`${API_URL}/workflow-instances/${id}`, data);
  return response.data;
};

const deleteWorkflowInstance = async (id: string): Promise<void> => {
  await axios.delete(`${API_URL}/workflow-instances/${id}`);
};

const updateWorkflowStatus = async (id: string, status: WorkflowInstance['status']): Promise<WorkflowInstance> => {
  const response = await axios.patch<WorkflowInstance>(`${API_URL}/workflow-instances/${id}/status`, {
    status
  });
  return response.data;
};

const getWorkflowProgress = async (id: string): Promise<{ progress: number }> => {
  const response = await axios.get<{ progress: number }>(`${API_URL}/workflow-instances/${id}/progress`);
  return response.data;
};

const addRequestToWorkflow = async (workflowId: string, requestId: string): Promise<WorkflowInstance> => {
  const response = await axios.post<WorkflowInstance>(
    `${API_URL}/workflow-instances/${workflowId}/requests`,
    { requestId }
  );
  return response.data;
};

const removeRequestFromWorkflow = async (workflowId: string, requestId: string): Promise<WorkflowInstance> => {
  const response = await axios.delete<WorkflowInstance>(
    `${API_URL}/workflow-instances/${workflowId}/requests/${requestId}`
  );
  return response.data;
};

const workflowInstanceService = {
  getWorkflowInstances,
  getWorkflowInstance,
  createInstance,
  updateWorkflowInstance,
  deleteWorkflowInstance,
  updateWorkflowStatus,
  getWorkflowProgress,
  addRequestToWorkflow,
  removeRequestFromWorkflow
};

export default workflowInstanceService;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Chip,
  Typography
} from '@mui/material';
import { Edit as EditIcon, Visibility as ViewIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import workflowInstanceService from '../../services/workflowInstanceService';
import { formatDate } from '../../utils/dateUtils';

const WorkflowInstanceList: React.FC = () => {
  const [instances, setInstances] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    loadInstances();
  }, []);

  const loadInstances = async () => {
    try {
      const data = await workflowInstanceService.getWorkflowInstances();
      setInstances(data);
    } catch (error) {
      console.error('Error loading workflow instances:', error);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'active': return 'primary';
      case 'completed': return 'success';
      case 'cancelled': return 'error';
      case 'failed': return 'error';
      default: return 'default';
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Priority</TableCell>
                <TableCell>Progress</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>Due Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {instances
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((instance) => (
                  <TableRow key={instance._id}>
                    <TableCell>
                      <Typography variant="body2">{instance.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={instance.status}
                        color={getStatusColor(instance.status)}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={instance.priority}
                        size="small"
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell>{instance.progress}%</TableCell>
                    <TableCell>{formatDate(instance.startDate)}</TableCell>
                    <TableCell>{formatDate(instance.dueDate)}</TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => navigate(`/workflow-instances/${instance._id}`)}
                      >
                        <ViewIcon />
                      </IconButton>
                      {instance.status !== 'completed' && (
                        <IconButton
                          size="small"
                          onClick={() => navigate(`/workflow-instances/${instance._id}/edit`)}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={instances.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default WorkflowInstanceList;

import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  FormControlLabel,
  Switch,
  CircularProgress,
  Box
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import slaService from '../../services/slaService';
import teamService, { Team } from '../../services/teamService';
import calendarService from '../../services/calendarService';
import { Calendar } from '../../types/calendar';

interface SLAFormProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  sla?: any; // Replace with proper type
}

const validationSchema = yup.object({
  type: yup.string().oneOf(['SLA', 'OLA']).required('Type is required'),
  category: yup.string().when('type', {
    is: (val: string) => val === 'SLA',
    then: (schema) => schema.oneOf(['rfi', 'rfc', 'bug', 'development']).required('Category is required for SLA'),
    otherwise: (schema) => schema
  }),
  team: yup.string().when('type', {
    is: (val: string) => val === 'OLA',
    then: (schema) => schema.required('Team is required for OLA'),
    otherwise: (schema) => schema
  }),
  priority: yup.string()
    .oneOf(['low', 'medium', 'high', 'critical'])
    .required('Priority is required'),
  calendar: yup.string().required('Calendar is required'),
  resolutionDuration: yup.number()
    .required('Resolution duration is required')
    .min(1, 'Duration must be at least 1 minute'),
  responseDuration: yup.number()
    .required('Response duration is required')
    .min(1, 'Duration must be at least 1 minute'),
});

const SLAForm: React.FC<SLAFormProps> = ({
  open,
  onClose,
  onSuccess,
  sla,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [teams, setTeams] = useState<Team[]>([]);
  const [calendars, setCalendars] = useState<Calendar[]>([]);
  const [loadingTeams, setLoadingTeams] = useState(false);
  const [loadingCalendars, setLoadingCalendars] = useState(false);
  const isEditMode = Boolean(sla);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        setLoadingTeams(true);
        const data = await teamService.getAll();
        setTeams(data);
      } catch (err) {
        console.error('Error fetching teams:', err);
        setError('Failed to load teams');
      } finally {
        setLoadingTeams(false);
      }
    };

    const fetchCalendars = async () => {
      try {
        setLoadingCalendars(true);
        const data = await calendarService.getAll();
        setCalendars(data);
      } catch (err) {
        console.error('Error fetching calendars:', err);
        setError('Failed to load calendars');
      } finally {
        setLoadingCalendars(false);
      }
    };

    fetchTeams();
    fetchCalendars();
  }, []);

  const formik = useFormik({
    initialValues: {
      type: sla?.type || 'SLA',
      category: sla?.category || '',
      team: sla?.team || '',
      priority: sla?.priority || 'medium',
      calendar: sla?.calendar || '',
      resolutionDuration: sla?.resolutionDuration || 60,
      responseDuration: sla?.responseDuration || 30,
      isActive: sla?.isActive ?? true,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const slaData = {
          ...values,
        };

        if (isEditMode && sla?._id) {
          await slaService.update(sla._id, slaData);
        } else {
          await slaService.create(slaData);
        }
        
        onSuccess();
        onClose();
        formik.resetForm();
      } catch (err: any) {
        console.error('Error saving SLA:', err);
        setError(`Failed to save SLA: ${err.message}`);
      }
    },
    enableReinitialize: true,
  });

  const handleClose = () => {
    formik.resetForm();
    setError(null);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {isEditMode ? 'Edit SLA Configuration' : 'Create SLA Configuration'}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Type</InputLabel>
                <Select
                  name="type"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  label="Type"
                >
                  <MenuItem value="SLA">Service Level Agreement (SLA)</MenuItem>
                  <MenuItem value="OLA">Operational Level Agreement (OLA)</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {formik.values.type === 'SLA' && (
              <>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Category</InputLabel>
                    <Select
                      name="category"
                      value={formik.values.category}
                      onChange={formik.handleChange}
                      label="Category"
                      error={formik.touched.category && Boolean(formik.errors.category)}
                    >
                      <MenuItem value="rfi">Request for Information (RFI)</MenuItem>
                      <MenuItem value="rfc">Request for Change (RFC)</MenuItem>
                      <MenuItem value="bug">Bug</MenuItem>
                      <MenuItem value="development">Development</MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.category && formik.errors.category && (
                    <Typography color="error" variant="caption">
                      {formik.errors.category as string}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Priority</InputLabel>
                    <Select
                      name="priority"
                      value={formik.values.priority}
                      onChange={formik.handleChange}
                      label="Priority"
                      error={formik.touched.priority && Boolean(formik.errors.priority)}
                    >
                      <MenuItem value="critical">
                        <Box display="flex" alignItems="center">
                          <Box component="span" display="inline-block" width={16} height={16} bgcolor="error.main" borderRadius={1} mr={1} />
                          Critical
                        </Box>
                      </MenuItem>
                      <MenuItem value="high">
                        <Box display="flex" alignItems="center">
                          <Box component="span" display="inline-block" width={16} height={16} bgcolor="warning.main" borderRadius={1} mr={1} />
                          High
                        </Box>
                      </MenuItem>
                      <MenuItem value="medium">
                        <Box display="flex" alignItems="center">
                          <Box component="span" display="inline-block" width={16} height={16} bgcolor="info.main" borderRadius={1} mr={1} />
                          Medium
                        </Box>
                      </MenuItem>
                      <MenuItem value="low">
                        <Box display="flex" alignItems="center">
                          <Box component="span" display="inline-block" width={16} height={16} bgcolor="success.main" borderRadius={1} mr={1} />
                          Low
                        </Box>
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.priority && formik.errors.priority && (
                    <Typography color="error" variant="caption">
                      {formik.errors.priority as string}
                    </Typography>
                  )}
                </Grid>
              </>
            )}

            {formik.values.type === 'OLA' && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Team</InputLabel>
                  {loadingTeams ? (
                    <CircularProgress size={24} sx={{ m: 2 }} />
                  ) : (
                    <Select
                      name="team"
                      value={formik.values.team}
                      onChange={formik.handleChange}
                      label="Team"
                      error={formik.touched.team && Boolean(formik.errors.team)}
                    >
                      {teams.map((team) => (
                        <MenuItem key={team._id} value={team._id}>
                          {team.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
                {formik.touched.team && formik.errors.team && (
                  <Typography color="error" variant="caption">
                    {formik.errors.team as string}
                  </Typography>
                )}
              </Grid>
            )}

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Calendar</InputLabel>
                {loadingCalendars ? (
                  <CircularProgress size={24} sx={{ m: 2 }} />
                ) : (
                  <Select
                    name="calendar"
                    value={formik.values.calendar}
                    onChange={formik.handleChange}
                    label="Calendar"
                    error={formik.touched.calendar && Boolean(formik.errors.calendar)}
                  >
                    {calendars.map((calendar) => (
                      <MenuItem key={calendar._id} value={calendar._id}>
                        {calendar.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
              {formik.touched.calendar && formik.errors.calendar && (
                <Typography color="error" variant="caption">
                  {formik.errors.calendar as string}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="responseDuration"
                label="Response Duration (minutes)"
                type="number"
                value={formik.values.responseDuration}
                onChange={formik.handleChange}
                error={formik.touched.responseDuration && Boolean(formik.errors.responseDuration)}
                helperText={formik.touched.responseDuration && (formik.errors.responseDuration as string)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="resolutionDuration"
                label="Resolution Duration (minutes)"
                type="number"
                value={formik.values.resolutionDuration}
                onChange={formik.handleChange}
                error={formik.touched.resolutionDuration && Boolean(formik.errors.resolutionDuration)}
                helperText={formik.touched.resolutionDuration && (formik.errors.resolutionDuration as string)}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.isActive}
                    onChange={(e) => formik.setFieldValue('isActive', e.target.checked)}
                    name="isActive"
                  />
                }
                label="Active"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button 
            type="submit" 
            variant="contained" 
            disabled={!formik.isValid || formik.isSubmitting}
          >
            {isEditMode ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SLAForm;

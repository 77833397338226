import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  TablePagination,
  CircularProgress,
  Dialog,
} from '@mui/material';
import { Add as AddIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { WorkflowTemplate } from '../../types/workflow';
import workflowTemplateService from '../../services/workflowTemplateService';
import WorkflowTemplateDetail from './WorkflowTemplateDetail';
import NewWorkflowTemplate from './NewWorkflowTemplate';

const WorkflowTemplateList: React.FC = () => {
  const [templates, setTemplates] = useState<WorkflowTemplate[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [isNewTemplateOpen, setIsNewTemplateOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchTemplates = useCallback(async () => {
    try {
      setLoading(true);
      const response = await workflowTemplateService.getWorkflowTemplates();
      setTemplates(response);
    } catch (error) {
      console.error('Error fetching templates:', error);
      enqueueSnackbar('Error fetching templates', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTemplateClick = (templateId: string) => {
    setSelectedTemplate(templateId);
    setIsDetailOpen(true);
  };

  const handleDetailClose = () => {
    setIsDetailOpen(false);
    setSelectedTemplate(null);
  };

  const handleNewTemplateClose = () => {
    setIsNewTemplateOpen(false);
  };

  const handleTemplateUpdated = () => {
    fetchTemplates();
    handleDetailClose();
  };

  const handleNewTemplateSuccess = () => {
    fetchTemplates();
    handleNewTemplateClose();
    enqueueSnackbar('Template created successfully', { variant: 'success' });
  };

  const formatDate = (date?: Date | string) => {
    if (!date) return '-';
    return new Date(date).toLocaleString();
  };

  if (loading) {
    return (
      <Box p={3}>
        <CircularProgress />
      </Box>
    );
  }

  const filteredTemplates = templates;

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h5">Workflow Templates</Typography>
        <Box>
          <IconButton
            onClick={fetchTemplates}
            sx={{ mr: 1 }}
            title="Refresh"
          >
            <RefreshIcon />
          </IconButton>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setIsNewTemplateOpen(true)}
          >
            New Template
          </Button>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Version</TableCell>
              <TableCell>Tasks</TableCell>
              <TableCell>Priority</TableCell>
              <TableCell>Updated At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTemplates.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center" sx={{ py: 3 }}>
                  No workflow templates found
                </TableCell>
              </TableRow>
            ) : (
              filteredTemplates
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((template) => (
                  <TableRow
                    key={template._id || template.id}
                    hover
                    onClick={() => (template._id || template.id) && handleTemplateClick(template._id || template.id!)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>{template.name}</TableCell>
                    <TableCell>{template.category}</TableCell>
                    <TableCell>{template.version}</TableCell>
                    <TableCell>{template.tasks.length}</TableCell>
                    <TableCell>
                      <Chip 
                        label={template.sla.priority} 
                        color={template.sla.priority === 'high' ? 'error' : template.sla.priority === 'medium' ? 'warning' : 'success'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>{formatDate(template.updatedAt)}</TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredTemplates.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Dialog
        open={isDetailOpen}
        onClose={handleDetailClose}
        maxWidth="md"
        fullWidth
      >
        {selectedTemplate && (
          <WorkflowTemplateDetail
            templateId={selectedTemplate}
            onClose={handleDetailClose}
            onTemplateUpdated={handleTemplateUpdated}
          />
        )}
      </Dialog>

      <Dialog
        open={isNewTemplateOpen}
        onClose={handleNewTemplateClose}
        maxWidth="md"
        fullWidth
      >
        <NewWorkflowTemplate
          open={isNewTemplateOpen}
          onClose={handleNewTemplateClose}
          onSuccess={handleNewTemplateSuccess}
        />
      </Dialog>
    </Box>
  );
};

export default WorkflowTemplateList;

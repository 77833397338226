// frontend/src/components/common/TableFilters.tsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Chip,
  Stack,
  Divider,
  IconButton,
  Paper
} from '@mui/material';
import {
  Clear as ClearIcon,
  FilterList as FilterIcon,
  Add as AddIcon
} from '@mui/icons-material';
import { TableField } from '../../types/table';

interface FilterValue {
  operator: string;
  value: any;
}

interface TableFiltersProps {
  fields: TableField[];
  onApplyFilters: (filters: Record<string, FilterValue>) => void;
  onClearFilters: () => void;
  initialFilters?: Record<string, FilterValue>;
}

const OPERATORS = {
  text: [
    { value: 'contains', label: 'Contains' },
    { value: 'equals', label: 'Equals' },
    { value: 'startsWith', label: 'Starts with' },
    { value: 'endsWith', label: 'Ends with' }
  ],
  number: [
    { value: 'equals', label: 'Equals' },
    { value: 'gt', label: 'Greater than' },
    { value: 'lt', label: 'Less than' },
    { value: 'gte', label: 'Greater than or equal' },
    { value: 'lte', label: 'Less than or equal' }
  ],
  date: [
    { value: 'equals', label: 'Equals' },
    { value: 'after', label: 'After' },
    { value: 'before', label: 'Before' }
  ],
  boolean: [
    { value: 'equals', label: 'Equals' }
  ],
  select: [
    { value: 'equals', label: 'Equals' },
    { value: 'in', label: 'Is any of' }
  ]
};

const TableFilters: React.FC<TableFiltersProps> = ({
  fields,
  onApplyFilters,
  onClearFilters,
  initialFilters = {}
}) => {
  const [filterValues, setFilterValues] = useState<Record<string, FilterValue>>(initialFilters);
  const [activeFilters, setActiveFilters] = useState<string[]>(Object.keys(initialFilters));
  const [availableFields, setAvailableFields] = useState<string[]>([]);

  // Only show fields that are filterable
  const filterableFields = fields.filter(field => field.filterable !== false);

  useEffect(() => {
    // Initialize available fields (filterable fields that aren't active yet)
    const activeSet = new Set(activeFilters);
    setAvailableFields(filterableFields.map(f => f.name).filter(name => !activeSet.has(name)));
    
    // Initialize filter values from props
    if (Object.keys(initialFilters).length > 0) {
      setFilterValues(initialFilters);
      setActiveFilters(Object.keys(initialFilters));
    }
  }, [fields, initialFilters]);

  const handleAddFilter = (fieldName: string) => {
    if (!fieldName) return;
    
    const field = fields.find(f => f.name === fieldName);
    if (!field) return;
    
    const newActiveFilters = [...activeFilters, fieldName];
    setActiveFilters(newActiveFilters);
    setAvailableFields(availableFields.filter(f => f !== fieldName));
    
    // Initialize with default operator and value
    setFilterValues({
      ...filterValues,
      [fieldName]: {
        operator: getDefaultOperator(field.type),
        value: getDefaultValue(field.type)
      }
    });
  };

  const handleRemoveFilter = (fieldName: string) => {
    setActiveFilters(activeFilters.filter(f => f !== fieldName));
    setAvailableFields([...availableFields, fieldName]);
    
    // Remove from filter values
    const newFilterValues = { ...filterValues };
    delete newFilterValues[fieldName];
    setFilterValues(newFilterValues);
  };

  const handleChangeOperator = (fieldName: string, operator: string) => {
    setFilterValues({
      ...filterValues,
      [fieldName]: {
        ...filterValues[fieldName],
        operator
      }
    });
  };

  const handleChangeValue = (fieldName: string, value: any) => {
    setFilterValues({
      ...filterValues,
      [fieldName]: {
        ...filterValues[fieldName],
        value
      }
    });
  };

  const handleApplyFilters = () => {
    // Only include active filters with valid values
    const filters: Record<string, FilterValue> = {};
    activeFilters.forEach(fieldName => {
      const filterValue = filterValues[fieldName];
      if (filterValue && (filterValue.value !== null && filterValue.value !== undefined && filterValue.value !== '')) {
        filters[fieldName] = filterValue;
      }
    });
    
    onApplyFilters(filters);
  };

  const handleClearAllFilters = () => {
    setActiveFilters([]);
    setFilterValues({});
    setAvailableFields(filterableFields.map(f => f.name));
    onClearFilters();
  };

  const getDefaultOperator = (fieldType: string): string => {
    switch (fieldType) {
      case 'text':
      case 'rich_text':
      case 'email':
      case 'url':
        return 'contains';
      case 'number':
        return 'equals';
      case 'date':
      case 'datetime':
        return 'equals';
      case 'boolean':
        return 'equals';
      case 'select':
      case 'multiselect':
        return 'equals';
      default:
        return 'equals';
    }
  };

  const getDefaultValue = (fieldType: string): any => {
    switch (fieldType) {
      case 'boolean':
        return true;
      case 'number':
        return '';
      case 'date':
      case 'datetime':
        return null;
      case 'select':
      case 'multiselect':
        return '';
      default:
        return '';
    }
  };

  const getOperatorsForFieldType = (fieldType: string) => {
    switch (fieldType) {
      case 'text':
      case 'rich_text':
      case 'email':
      case 'url':
        return OPERATORS.text;
      case 'number':
        return OPERATORS.number;
      case 'date':
      case 'datetime':
        return OPERATORS.date;
      case 'boolean':
        return OPERATORS.boolean;
      case 'select':
      case 'multiselect':
        return OPERATORS.select;
      default:
        return OPERATORS.text;
    }
  };

  const renderFilterControl = (field: TableField) => {
    const fieldName = field.name;
    const filter = filterValues[fieldName] || { 
      operator: getDefaultOperator(field.type), 
      value: getDefaultValue(field.type) 
    };
    
    return (
      <Paper key={fieldName} sx={{ p: 2, mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
          <Typography variant="subtitle2">{field.label}</Typography>
          <IconButton size="small" onClick={() => handleRemoveFilter(fieldName)}>
            <ClearIcon fontSize="small" />
          </IconButton>
        </Box>
        
        <Box sx={{ display: 'flex', gap: 1, flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'flex-start' }}>
          {/* Operator select */}
          <FormControl size="small" sx={{ minWidth: 150 }}>
            <InputLabel id={`operator-label-${fieldName}`}>Operator</InputLabel>
            <Select
              labelId={`operator-label-${fieldName}`}
              value={filter.operator}
              onChange={(e) => handleChangeOperator(fieldName, e.target.value)}
              label="Operator"
              size="small"
            >
              {getOperatorsForFieldType(field.type).map((op) => (
                <MenuItem key={op.value} value={op.value}>
                  {op.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          {/* Value input based on field type */}
          {renderValueInput(field, filter.value, (value) => handleChangeValue(fieldName, value))}
        </Box>
      </Paper>
    );
  };

  const renderValueInput = (field: TableField, value: any, onChange: (value: any) => void) => {
    switch (field.type) {
      case 'boolean':
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(value)}
                onChange={(e) => onChange(e.target.checked)}
              />
            }
            label="True"
            sx={{ mt: 1 }}
          />
        );
        
      case 'select':
        return (
          <FormControl fullWidth size="small">
            <InputLabel id={`value-label-${field.name}`}>Value</InputLabel>
            <Select
              labelId={`value-label-${field.name}`}
              value={value || ''}
              onChange={(e) => onChange(e.target.value)}
              label="Value"
              size="small"
            >
              {field.options?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
        
      case 'multiselect':
        return (
          <FormControl fullWidth size="small">
            <InputLabel id={`value-label-${field.name}`}>Value</InputLabel>
            <Select
              labelId={`value-label-${field.name}`}
              multiple
              value={Array.isArray(value) ? value : []}
              onChange={(e) => onChange(e.target.value)}
              label="Value"
              size="small"
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {(selected as string[]).map((value) => (
                    <Chip key={value} label={value} size="small" />
                  ))}
                </Box>
              )}
            >
              {field.options?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
        
      case 'number':
        return (
          <TextField
            type="number"
            value={value}
            onChange={(e) => onChange(e.target.value !== '' ? Number(e.target.value) : '')}
            label="Value"
            size="small"
            fullWidth
          />
        );
        
      case 'date':
        return (
          <TextField
            type="date"
            value={value || ''}
            onChange={(e) => onChange(e.target.value)}
            label="Value"
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        );
        
      case 'datetime':
        return (
          <TextField
            type="datetime-local"
            value={value || ''}
            onChange={(e) => onChange(e.target.value)}
            label="Value"
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        );
        
      // Default text input for text, rich_text, email, url, etc.
      default:
        return (
          <TextField
            value={value || ''}
            onChange={(e) => onChange(e.target.value)}
            label="Value"
            size="small"
            fullWidth
          />
        );
    }
  };

  return (
    <Box sx={{ minWidth: 300, maxWidth: 500, p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">
          <FilterIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
          Filters
        </Typography>
        
        <Button 
          size="small" 
          onClick={handleClearAllFilters}
          disabled={activeFilters.length === 0}
        >
          Clear All
        </Button>
      </Box>
      
      {/* Active filters */}
      {activeFilters.length > 0 ? (
        <Box sx={{ mb: 3 }}>
          {activeFilters.map(fieldName => {
            const field = fields.find(f => f.name === fieldName);
            if (!field) return null;
            return renderFilterControl(field);
          })}
        </Box>
      ) : (
        <Typography color="text.secondary" sx={{ mb: 2 }}>
          No filters applied. Add a filter to narrow down results.
        </Typography>
      )}
      
      {/* Add filter dropdown */}
      {availableFields.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="add-filter-label">Add Filter</InputLabel>
            <Select
              labelId="add-filter-label"
              value=""
              onChange={(e) => handleAddFilter(e.target.value)}
              label="Add Filter"
              displayEmpty
              renderValue={() => "Select field to filter"}
            >
              {availableFields.map(fieldName => {
                const field = fields.find(f => f.name === fieldName);
                return (
                  <MenuItem key={fieldName} value={fieldName}>
                    {field?.label || fieldName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      )}
      
      {/* Apply button */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button 
          variant="contained"
          onClick={handleApplyFilters}
          disabled={activeFilters.length === 0}
        >
          Apply Filters
        </Button>
      </Box>
    </Box>
  );
};

export default TableFilters;
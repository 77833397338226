import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography, List, ListItem, ListItemText, CircularProgress, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import dashboardService, { DashboardStats } from '../../services/dashboardService';
import reportService from '../../services/reportService';
import { format } from 'date-fns';
import ReportChart from '../Reports/ReportChart';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}));

interface ReportTemplate {
  _id: string;
  name: string;
  visualization: {
    type: string;
    options: any;
  };
}

interface ReportData {
  _id: string;
  name: string;
  data: Array<Record<string, any>>;
  visualization: {
    type: string;
    options: any;
  };
}

interface Activity {
  _id: string;
  title: string;
  status: string;
  updatedAt: string;
}

const Dashboard: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [dashboardData, setDashboardData] = useState<DashboardStats | null>(null);
  const [reports, setReports] = useState<ReportData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [dashData, reportTemplates] = await Promise.all([
          dashboardService.getStats(),
          reportService.getTemplates() // Fixed method name
        ]);

        // Get data for each report
        const reportData = await Promise.all(
          reportTemplates.slice(0, 4).map(async (template: ReportTemplate) => {
            const data = await reportService.executeReport(template._id);
            return {
              _id: template._id,
              name: template.name,
              data,
              visualization: template.visualization
            };
          })
        );

        setDashboardData(dashData);
        setReports(reportData);
        setError(null);
      } catch (err) {
        setError('Failed to load dashboard data');
        console.error('Dashboard data fetch error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
        Dashboard
      </Typography>
      
      <Grid container spacing={3}>
        {/* Metrics */}
        <Grid item xs={12} md={4}>
          <Item>
            <Typography variant="h3" color="primary" gutterBottom>
              {dashboardData?.stats.activeRequests || 0}
            </Typography>
            <Typography variant="h6">Active Requests</Typography>
          </Item>
        </Grid>
        
        <Grid item xs={12} md={4}>
          <Item>
            <Typography variant="h3" color="primary" gutterBottom>
              {dashboardData?.stats.pendingTasks || 0}
            </Typography>
            <Typography variant="h6">Pending Tasks</Typography>
          </Item>
        </Grid>
        
        <Grid item xs={12} md={4}>
          <Item>
            <Typography variant="h3" color="primary" gutterBottom>
              {dashboardData?.stats.activeWorkflows || 0}
            </Typography>
            <Typography variant="h6">Active Workflows</Typography>
          </Item>
        </Grid>

        {/* Report Charts */}
        {reports.map((report) => (
          <Grid item xs={12} md={6} key={report._id}>
            <Paper sx={{ p: 3, height: '400px' }}>
              <Typography variant="h6" gutterBottom>
                {report.name}
              </Typography>
              <Box sx={{ width: '100%', height: 'calc(100% - 40px)' }}>
                <ReportChart
                  type={report.visualization.type}
                  data={report.data}
                  options={report.visualization.options}
                />
              </Box>
            </Paper>
          </Grid>
        ))}

        {/* Recent Activity */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Recent Activity
            </Typography>
            <List>
              {dashboardData?.recentActivity.map((activity: Activity, index) => (
                <React.Fragment key={activity._id}>
                  <ListItem>
                    <ListItemText
                      primary={activity.title}
                      secondary={`Status: ${activity.status} • Updated: ${format(new Date(activity.updatedAt), 'MMM dd, yyyy HH:mm')}`}
                    />
                  </ListItem>
                  {index < (dashboardData?.recentActivity.length || 0) - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
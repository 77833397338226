import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Box,
  Typography,
  FormControlLabel,
  Switch,
  CircularProgress
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { TableMetadataBase, TableField, TableConfig } from '../../types/table';
import { useSnackbar } from 'notistack';

interface CreateTableDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (table: TableMetadataBase) => Promise<void>;
}

const CreateTableDialog: React.FC<CreateTableDialogProps> = ({
  open,
  onClose,
  onSubmit
}) => {
  const [name, setName] = useState('');
  const [label, setLabel] = useState('');
  const [description, setDescription] = useState('');
  const [fields, setFields] = useState<TableField[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleAddField = () => {
    setFields([
      ...fields,
      {
        name: '',
        label: '',
        type: 'text',
        required: false,
        unique: false,
        searchable: true,
        displayInList: true
      }
    ]);
  };

  const handleRemoveField = (index: number) => {
    setFields(fields.filter((_, i) => i !== index));
  };

  const handleFieldChange = (index: number, field: Partial<TableField>) => {
    const newFields = [...fields];
    newFields[index] = { ...newFields[index], ...field };
    setFields(newFields);
  };

  const handleSubmit = async () => {
    if (!name || !label || fields.length === 0) {
      enqueueSnackbar('Please fill in all required fields and add at least one field', { variant: 'error' });
      return;
    }

    // Validate field names
    const invalidFields = fields.filter(field => !field.name || !field.label || !field.type);
    if (invalidFields.length > 0) {
      enqueueSnackbar('Please fill in all field properties', { variant: 'error' });
      return;
    }

    setSubmitting(true);
    try {
      const tableConfig: TableConfig = {
        enableVersioning: false,
        enableAudit: true,
        enableSearch: true,
        enableApi: true,
        enableWorkflow: false,
        enableComments: true,
        enableAttachments: false
      };

      const tableData: TableMetadataBase = {
        name,
        label,
        description,
        type: 'custom',
        collectionName: '', // This will be set by the backend
        fields,
        displayField: fields[0]?.name || '',
        searchFields: fields.filter(f => f.searchable).map(f => f.name),
        isActive: true,
        config: tableConfig
      };

      await onSubmit(tableData);
      // Reset form
      setName('');
      setLabel('');
      setDescription('');
      setFields([]);
      onClose();
      enqueueSnackbar('Table created successfully', { variant: 'success' });
    } catch (error) {
      console.error('Failed to create table:', error);
      enqueueSnackbar('Failed to create table', { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Create New Table</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 1 }}>
          <TextField
            fullWidth
            label="Table Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Display Label"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            margin="normal"
            multiline
            rows={2}
          />

          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography variant="h6">Fields</Typography>
            <Button
              startIcon={<AddIcon />}
              onClick={handleAddField}
              variant="outlined"
              sx={{ mt: 1 }}
            >
              Add Field
            </Button>
          </Box>

          {fields.map((field, index) => (
            <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <TextField
                label="Field Name"
                value={field.name}
                onChange={(e) => handleFieldChange(index, { name: e.target.value })}
                required
                size="small"
              />
              <TextField
                label="Display Label"
                value={field.label}
                onChange={(e) => handleFieldChange(index, { label: e.target.value })}
                required
                size="small"
              />
              <TextField
                select
                label="Type"
                value={field.type}
                onChange={(e) => handleFieldChange(index, { type: e.target.value })}
                required
                size="small"
                SelectProps={{ native: true }}
              >
                <option value="text">Text</option>
                <option value="number">Number</option>
                <option value="date">Date</option>
                <option value="boolean">Boolean</option>
                <option value="select">Select</option>
                <option value="email">Email</option>
              </TextField>
              <FormControlLabel
                control={
                  <Switch
                    checked={field.required}
                    onChange={(e) => handleFieldChange(index, { required: e.target.checked })}
                    size="small"
                  />
                }
                label="Required"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={field.searchable}
                    onChange={(e) => handleFieldChange(index, { searchable: e.target.checked })}
                    size="small"
                  />
                }
                label="Searchable"
              />
              <IconButton
                onClick={() => handleRemoveField(index)}
                color="error"
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={submitting}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={submitting}
          startIcon={submitting ? <CircularProgress size={20} /> : null}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTableDialog;

import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Button,
  Paper,
  Divider,
  Snackbar,
  Alert
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { ConditionItem, OperatorType } from '../../types/rule';

interface ConditionBuilderProps {
  conditions: ConditionItem[];
  onChange: (conditions: ConditionItem[]) => void;
}

const ConditionBuilder: React.FC<ConditionBuilderProps> = ({ conditions, onChange }) => {
  const [manualCondition, setManualCondition] = useState('');
  const [parseError, setParseError] = useState('');

  // Add a new condition
  const addCondition = () => {
    onChange([
      ...conditions,
      { field: '', operator: '$eq', value: '' }
    ]);
  };

  // Remove a condition
  const removeCondition = (index: number) => {
    const newConditions = [...conditions];
    newConditions.splice(index, 1);
    onChange(newConditions);
  };

  // Update a condition
  const updateCondition = (index: number, field: keyof ConditionItem, value: any) => {
    const newConditions = [...conditions];
    newConditions[index] = {
      ...newConditions[index],
      [field]: value
    };
    onChange(newConditions);
  };

  // Get operator options with more comprehensive list
  const getOperatorOptions = () => {
    return [
      // Comparison Operators
      { value: '$eq', label: 'Equals' },
      { value: '$ne', label: 'Not Equals' },
      { value: '$gt', label: 'Greater Than' },
      { value: '$gte', label: 'Greater Than or Equal' },
      { value: '$lt', label: 'Less Than' },
      { value: '$lte', label: 'Less Than or Equal' },
      
      // Array Operators
      { value: '$in', label: 'In Array' },
      { value: '$nin', label: 'Not In Array' },
      { value: '$size', label: 'Array Size' },
      
      // Change Operators
      { value: '$changed', label: 'Has Changed' },
      { value: '$changedTo', label: 'Changed To' },
      { value: '$changedFrom', label: 'Changed From' },
      
      // String Operators
      { value: '$contains', label: 'Contains' },
      { value: '$startsWith', label: 'Starts With' },
      { value: '$endsWith', label: 'Ends With' },
      { value: '$regex', label: 'Matches Regex' },
      
      // Existence Operators
      { value: '$exists', label: 'Exists' }
    ];
  };

  // Determine if value input is needed based on operator
  const shouldShowValueInput = (operator: OperatorType) => {
    const operatorsWithoutValue = ['$changed', '$exists'];
    return !operatorsWithoutValue.includes(operator);
  };

  // Handle manual condition input
  const handleManualConditionChange = () => {
    try {
      // Try to parse the JSON
      const parsedCondition = JSON.parse(manualCondition);
      
      // Convert the parsed condition to ConditionItem array
      const convertedConditions = convertConditionToItems(parsedCondition);
      
      // Clear any previous errors
      setParseError('');
      
      // Update conditions
      onChange(convertedConditions);
    } catch (error) {
      // Set error message
      setParseError('Invalid JSON condition');
    }
  };

  // Convert condition object to ConditionItem array
  const convertConditionToItems = (condition: any): ConditionItem[] => {
    // Handle $and and $or logical operators
    if (condition.$and || condition.$or) {
      const logicalOperator = condition.$and ? '$and' : '$or';
      const conditions = condition[logicalOperator];

      if (Array.isArray(conditions)) {
        return conditions.flatMap(subCondition => 
          Object.entries(subCondition).map(([field, value]) => {
            if (typeof value === 'object' && value !== null) {
              const operatorKeys = Object.keys(value);
              if (operatorKeys.length > 0) {
                const operator = operatorKeys[0] as OperatorType;
                return {
                  field,
                  operator,
                  value: value[operator as keyof typeof value]
                };
              }
            }
            // Simple equality
            return {
              field,
              operator: '$eq' as OperatorType,
              value
            };
          })
        );
      }
    }

    // Handle simple conditions
    return Object.entries(condition).map(([field, value]) => {
      if (typeof value === 'object' && value !== null) {
        const operatorKeys = Object.keys(value);
        if (operatorKeys.length > 0) {
          const operator = operatorKeys[0] as OperatorType;
          return {
            field,
            operator,
            value: value[operator as keyof typeof value]
          };
        }
      }
      // Simple equality
      return {
        field,
        operator: '$eq' as OperatorType,
        value
      };
    });
  };

  // Helper function to convert from UI items to condition object
  function buildConditionObject(items: ConditionItem[]): any {
    if (items.length === 0) return null;
    
    if (items.length === 1) {
      const item = items[0];
      if (item.operator === '$eq') {
        return { [item.field]: item.value };
      } else {
        return { [item.field]: { [item.operator]: item.value } };
      }
    }
    
    // Multiple conditions are combined with $and by default
    return {
      $and: items.map(item => {
        if (item.operator === '$eq') {
          return { [item.field]: item.value };
        } else {
          return { [item.field]: { [item.operator]: item.value } };
        }
      })
    };
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="body2" color="text.secondary">
        Define conditions that must be met for this rule to execute.
      </Typography>
      
      {conditions.length === 0 ? (
        <Typography variant="body2" color="text.secondary" align="center" sx={{ py: 2 }}>
          No conditions defined. Rule will execute for all matching triggers.
        </Typography>
      ) : (
        <Paper variant="outlined" sx={{ p: 2 }}>
          {conditions.map((condition, index) => (
            <Box key={index} display="flex" gap={2} mb={2} alignItems="center">
              <TextField
                fullWidth
                label="Field"
                value={condition.field}
                onChange={(e) => updateCondition(index, 'field', e.target.value)}
                placeholder="e.g., status, rawData.results"
                size="small"
              />
              
              <FormControl fullWidth size="small">
                <InputLabel>Operator</InputLabel>
                <Select
                  value={condition.operator}
                  label="Operator"
                  onChange={(e) => {
                    updateCondition(index, 'operator', e.target.value as OperatorType);
                    // Reset value if operator doesn't require a value
                    if (!shouldShowValueInput(e.target.value as OperatorType)) {
                      updateCondition(index, 'value', '');
                    }
                  }}
                >
                  {getOperatorOptions().map((op) => (
                    <MenuItem key={op.value} value={op.value}>
                      {op.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              
              {shouldShowValueInput(condition.operator) && (
                <TextField
                  fullWidth
                  label="Value"
                  value={condition.value}
                  onChange={(e) => updateCondition(index, 'value', e.target.value)}
                  size="small"
                />
              )}
              
              <IconButton 
                color="error" 
                onClick={() => removeCondition(index)}
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Paper>
      )}
      
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Button
          startIcon={<AddIcon />}
          onClick={addCondition}
          variant="outlined"
          size="small"
        >
          Add Condition
        </Button>
      </Box>
      
      {conditions.length > 0 && (
        <Box mt={2}>
          <Typography variant="subtitle2" gutterBottom>
            Condition Preview and Manual Edit
          </Typography>
          <Paper 
            variant="outlined" 
            sx={{ 
              p: 2, 
              bgcolor: 'grey.50',
              fontFamily: 'monospace',
              fontSize: '0.875rem',
              overflow: 'auto'
            }}
          >
            <pre>{JSON.stringify(buildConditionObject(conditions), null, 2)}</pre>
          </Paper>
          
          <Box mt={2} display="flex" gap={2}>
            <TextField
              fullWidth
              label="Manual Condition JSON"
              multiline
              rows={4}
              value={manualCondition}
              onChange={(e) => setManualCondition(e.target.value)}
              placeholder='Enter condition as JSON, e.g. {"status": "pending"}'
              variant="outlined"
            />
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleManualConditionChange}
            >
              Apply JSON
            </Button>
          </Box>
        </Box>
      )}

      <Snackbar
        open={!!parseError}
        autoHideDuration={6000}
        onClose={() => setParseError('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setParseError('')} 
          severity="error" 
          sx={{ width: '100%' }}
        >
          {parseError}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ConditionBuilder;
import React, { useState, useEffect } from 'react'; // Import useEffect
import { Box, Grid, Paper, Typography } from '@mui/material';
import { useLayout } from '../../contexts/LayoutContext'; // Import useLayout
import TeamList from './TeamList';
import TeamDetail from './TeamDetail';
import NewTeam from './NewTeam';

const TeamsPage: React.FC = () => {
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null);
  const [showNewTeamForm, setShowNewTeamForm] = useState<boolean>(false);
  const [refreshListKey, setRefreshListKey] = useState<number>(0);
  const { isSidebarCollapsed, setIsSidebarCollapsed } = useLayout(); // Use layout context

  const handleTeamSelect = (teamId: string) => {
    setSelectedTeamId(teamId);
    setShowNewTeamForm(false);
    if (!isSidebarCollapsed) { // Collapse sidebar if not already collapsed
      setIsSidebarCollapsed(true);
    }
  };

  const handleAddNewTeam = () => {
    setSelectedTeamId(null);
    setShowNewTeamForm(true);
    if (!isSidebarCollapsed) { // Collapse sidebar if not already collapsed
      setIsSidebarCollapsed(true);
    }
  };

  const handleCloseDetail = () => {
    setSelectedTeamId(null);
    setShowNewTeamForm(false);
    // Optionally, you could re-expand the sidebar here if desired:
    // if (isSidebarCollapsed) {
    //   setIsSidebarCollapsed(false);
    // }
  };

  const handleTeamCreatedOrUpdated = () => {
    // Refresh the list and close the detail/new view
    setRefreshListKey(prev => prev + 1);
    handleCloseDetail();
  };

  const isDetailViewOpen = !!selectedTeamId || showNewTeamForm;

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)', // Adjust height based on your AppBar/layout
         overflow: 'hidden' }}>

      {/* Left Panel: Team List (conditionally sized) */}
      <Paper
        elevation={isDetailViewOpen ? 1 : 0} // Only show elevation/border in split view
        sx={{
          width: isDetailViewOpen ? '35%' : '100%', // Full width initially, shrinks when detail opens
          minWidth: isDetailViewOpen ? '300px' : 'auto',
          height: '100%',
          overflowY: 'auto',
          borderRight: isDetailViewOpen ? 1 : 0, // Only show border in split view
          borderColor: 'divider',
          display: 'flex',
          flexDirection: 'column',
          transition: 'width 0.3s ease-in-out' // Smooth transition
         }}>
         {/* Add padding inside the Paper */}
         <Box sx={{ p: 2, flexGrow: 1, overflowY: 'auto' }}>
            <TeamList
              key={refreshListKey}
              onTeamSelect={handleTeamSelect}
              onAddNewTeam={handleAddNewTeam}
              selectedTeamId={selectedTeamId}
              isDetailViewOpen={isDetailViewOpen} // Pass the state down
            />
         </Box>
      </Paper>

      {/* Right Panel: Detail/New Form (conditionally rendered) */}
      {isDetailViewOpen && (
        <Box sx={{
            flexGrow: 1,
            overflowY: 'auto',
            p: 3,
            borderLeft: 1, // Add border for visual separation
            borderColor: 'divider'
           }}>
          {selectedTeamId ? (
            <TeamDetail
              teamId={selectedTeamId}
              onTeamUpdated={handleTeamCreatedOrUpdated}
              onClose={handleCloseDetail}
            />
          ) : showNewTeamForm ? (
            <NewTeam
              onTeamCreated={handleTeamCreatedOrUpdated}
              onClose={handleCloseDetail}
            />
          ) : null /* Should not happen if isDetailViewOpen is true */}
        </Box>
      )}
    </Box>
  );
};

export default TeamsPage;
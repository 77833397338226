import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Button,
  Toolbar,
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  Stack,
  Tooltip,
  TablePagination,
} from '@mui/material';
import { 
  Edit as EditIcon, 
  Delete as DeleteIcon, 
  Add as AddIcon, 
  Search as SearchIcon,
  FilterList as FilterIcon,
  Visibility as VisibilityIcon,
  Link as LinkIcon,
} from '@mui/icons-material';
import { ciService } from '../../services/ciService';
import { CI } from '../../types/cmdb';
import CIForm from './CIForm';
import { CIDetails } from './CIDetails';
import { useSnackbar } from 'notistack';
import ConfirmDialog from '../common/ConfirmDialog';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

export const CIList = () => {
  const [cis, setCIs] = useState<CI[]>([]);
  const [filteredCIs, setFilteredCIs] = useState<CI[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [newCIDialogOpen, setNewCIDialogOpen] = useState(false);
  const [editCIDialogOpen, setEditCIDialogOpen] = useState(false);
  const [selectedCI, setSelectedCI] = useState<CI | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // Search and filter states
  const [searchTerm, setSearchTerm] = useState('');
  const [typeFilter, setTypeFilter] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('');

  // Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const loadCIs = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await ciService.getAllCIs();
      setCIs(data || []);
      setFilteredCIs(data || []);
    } catch (error) {
      console.error('Error loading CIs:', error);
      setError('Failed to load Configuration Items');
      enqueueSnackbar('Failed to load Configuration Items', { variant: 'error' });
      setCIs([]);
      setFilteredCIs([]);
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    loadCIs();
  }, [loadCIs]);

  // Filter CIs based on search term and filters
  useEffect(() => {
    let result = [...cis];

    // Apply search term
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      result = result.filter(ci => 
        ci.name.toLowerCase().includes(lowerSearchTerm) || 
        ci.description?.toLowerCase().includes(lowerSearchTerm) ||
        ci.type.name.toLowerCase().includes(lowerSearchTerm)
      );
    }

    // Apply type filter
    if (typeFilter) {
      result = result.filter(ci => ci.type._id === typeFilter);
    }

    // Apply status filter
    if (statusFilter) {
      result = result.filter(ci => ci.status === statusFilter);
    }

    setFilteredCIs(result);
    setPage(0); // Reset to first page when filters change
  }, [cis, searchTerm, typeFilter, statusFilter]);

  const handleEdit = (event: React.MouseEvent, ci: CI) => {
    event.stopPropagation();
    setSelectedCI(ci);
    setEditCIDialogOpen(true);
  };

  const handleDelete = async (id: string) => {
    try {
      await ciService.deleteCI(id);
      loadCIs();
      setDeleteDialogOpen(false);
      setSelectedCI(null);
      enqueueSnackbar('Configuration Item deleted successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error deleting CI:', error);
      enqueueSnackbar('Failed to delete Configuration Item', { variant: 'error' });
    }
  };

  const handleDeleteClick = (event: React.MouseEvent, ci: CI) => {
    event.stopPropagation();
    setSelectedCI(ci);
    setDeleteDialogOpen(true);
  };

  const handleRowClick = (ci: CI) => {
    setSelectedCI(ci);
    setDetailsModalOpen(true);
  };

  const handleViewRelationships = (event: React.MouseEvent, ci: CI) => {
    event.stopPropagation();
    navigate(`/cmdb/relationships/${ci._id}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Get unique CI types for filter dropdown
  const ciTypes = Array.from(new Map(cis.map(ci => [ci.type._id, ci.type] as [string, CI['type']])).values());

  if (loading) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography>Loading Configuration Items...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">{error}</Typography>
        <Button onClick={loadCIs} sx={{ mt: 2 }}>
          Retry
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, mb: 2 }}>
        <Typography variant="h6" component="div" sx={{ flex: '1 1 100%' }}>
          Configuration Items
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setNewCIDialogOpen(true)}
          sx={{ whiteSpace: 'nowrap' }}
        >
          Add CI
        </Button>
      </Toolbar>

      {/* Search and Filter Bar */}
      <Box sx={{ mb: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ minWidth: 200, flex: 1 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <FormControl size="small" sx={{ minWidth: 150 }}>
          <InputLabel id="type-filter-label">Type</InputLabel>
          <Select
            labelId="type-filter-label"
            value={typeFilter}
            label="Type"
            onChange={(e) => setTypeFilter(e.target.value)}
          >
            <MenuItem value="">All Types</MenuItem>
            {ciTypes.map(type => (
              <MenuItem key={type._id} value={type._id}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: 150 }}>
          <InputLabel id="status-filter-label">Status</InputLabel>
          <Select
            labelId="status-filter-label"
            value={statusFilter}
            label="Status"
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <MenuItem value="">All Statuses</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
            <MenuItem value="maintenance">Maintenance</MenuItem>
          </Select>
        </FormControl>

        {(searchTerm || typeFilter || statusFilter) && (
          <Button 
            variant="outlined" 
            size="small"
            onClick={() => {
              setSearchTerm('');
              setTypeFilter('');
              setStatusFilter('');
            }}
          >
            Clear Filters
          </Button>
        )}
      </Box>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>IP Address</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCIs
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((ci) => (
                <TableRow
                  key={ci._id}
                  hover
                  sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                  onClick={() => handleRowClick(ci)}
                >
                  <TableCell sx={{ py: 1 }}>{ci.name}</TableCell>
                  <TableCell sx={{ py: 1 }}>
                    <Chip 
                      label={ci.type.name} 
                      size="small" 
                      sx={{ 
                        fontSize: '0.75rem',
                        height: 24
                      }} 
                    />
                  </TableCell>
                  <TableCell sx={{ py: 1 }}>
                    <Chip 
                      label={ci.status} 
                      size="small"
                      color={
                        ci.status === 'active' ? 'success' : 
                        ci.status === 'maintenance' ? 'warning' : 'default'
                      }
                      sx={{ 
                        fontSize: '0.75rem',
                        height: 24
                      }} 
                    />
                  </TableCell>
                  <TableCell sx={{ py: 1 }}>{ci.location || '-'}</TableCell>
                  <TableCell sx={{ py: 1 }}>{ci.customFields?.ip_address || '-'}</TableCell>
                  <TableCell sx={{ py: 1 }}>
                    {ci.createdAt ? format(new Date(ci.createdAt), 'MM/dd/yyyy') : '-'}
                  </TableCell>
                  <TableCell sx={{ py: 1 }}>
                    {ci.updatedAt ? format(new Date(ci.updatedAt), 'MM/dd/yyyy') : '-'}
                  </TableCell>
                  <TableCell align="right" sx={{ py: 1 }}>
                    <Stack direction="row" spacing={0} justifyContent="flex-end">
                      <Tooltip title="View Details">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRowClick(ci);
                          }}
                        >
                          <VisibilityIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="View Relationships">
                        <IconButton
                          size="small"
                          onClick={(e) => handleViewRelationships(e, ci)}
                          color="primary"
                        >
                          <LinkIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <IconButton
                          size="small"
                          onClick={(e) => handleEdit(e, ci)}
                          color="primary"
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          size="small"
                          onClick={(e) => handleDeleteClick(e, ci)}
                          color="error"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            {filteredCIs.length === 0 && (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <Typography variant="body2" color="text.secondary" sx={{ py: 2 }}>
                    No configuration items found.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={filteredCIs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {/* Create CI Modal */}
      {newCIDialogOpen && (
        <CIForm
          open={newCIDialogOpen}
          onClose={() => setNewCIDialogOpen(false)}
          onSuccess={() => {
            setNewCIDialogOpen(false);
            loadCIs();
          }}
          mode="create"
        />
      )}

      {/* Edit CI Modal */}
      {editCIDialogOpen && selectedCI && (
        <CIForm
          open={editCIDialogOpen}
          onClose={() => {
            setEditCIDialogOpen(false);
            setSelectedCI(null);
          }}
          onSuccess={() => {
            setEditCIDialogOpen(false);
            setSelectedCI(null);
            loadCIs();
          }}
          mode="edit"
          initialData={selectedCI}
        />
      )}

      {/* Delete Confirmation Dialog */}
      <ConfirmDialog
        open={deleteDialogOpen}
        title="Delete Configuration Item"
        content={`Are you sure you want to delete ${selectedCI?.name}?`}
        onConfirm={() => selectedCI?._id && handleDelete(selectedCI._id)}
        onCancel={() => {
          setDeleteDialogOpen(false);
          setSelectedCI(null);
        }}
      />

      {/* CI Details Modal */}
      {selectedCI && detailsModalOpen && (
        <CIDetails 
          open={detailsModalOpen} 
          onClose={() => {
            setDetailsModalOpen(false);
            setSelectedCI(null);
          }} 
          ciId={selectedCI._id}
        />
      )}
    </Box>
  );
};
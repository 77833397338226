// agent/services/agent-service.js
import { NLPProcessor } from '../components/nlp-processor';
import { CapabilityManager } from '../capabilities/capability-manager';
import logger from '../utils/bot-logger'; // Import the correct bot-logger

// Mock context manager since we can't import the real one due to missing dependencies
class ContextManager {
  constructor() {
    this.contexts = new Map();
  }
  
  getContext(conversationId) {
    if (!this.contexts.has(conversationId)) {
      const newContext = { conversationId, entities: {}, lastAccessed: Date.now() };
      this.contexts.set(conversationId, newContext);
      return newContext;
    }
    return this.contexts.get(conversationId);
  }
  
  updateContext(conversationId, updates) {
    const context = this.getContext(conversationId);
    Object.assign(context, updates);
    context.lastAccessed = Date.now();
    this.contexts.set(conversationId, context);
    return context;
  }
  
  addMessage(conversationId, message) {
    const context = this.getContext(conversationId);
    if (!context.messages) {
      context.messages = [];
    }
    context.messages.push(message);
    this.contexts.set(conversationId, context);
    return context;
  }
}

class AgentService {
  constructor() {
    this.nlpProcessor = new NLPProcessor();
    this.capabilityManager = new CapabilityManager();
    this.contextManager = new ContextManager();
    
    // Register all available capabilities
    this.registerCapabilities();
  }
  
  registerCapabilities() {
    // Request management capabilities
    this.capabilityManager.register('createRequest', {
      patterns: ['create a request', 'new request', 'make a request', 'open a ticket'],
      handler: this.handleCreateRequest.bind(this),
      requiredParams: ['type', 'description'],
      optionalParams: ['priority', 'assignee', 'dueDate']
    });
    
    this.capabilityManager.register('updateRequest', {
      patterns: ['update request', 'modify request', 'change request', 'edit request'],
      handler: this.handleUpdateRequest.bind(this),
      requiredParams: ['requestId'],
      optionalParams: ['status', 'priority', 'assignee', 'description']
    });
    
    // Knowledge management capabilities
    this.capabilityManager.register('suggestKnowledge', {
      patterns: ['suggest article', 'find knowledge', 'related articles', 'documentation for'],
      handler: this.handleSuggestKnowledge.bind(this),
      requiredParams: ['query'],
      optionalParams: ['category']
    });
    
    this.capabilityManager.register('createKnowledge', {
      patterns: ['create article', 'new knowledge article', 'document this', 'add to knowledge base'],
      handler: this.handleCreateKnowledge.bind(this),
      requiredParams: ['title', 'content'],
      optionalParams: ['category', 'tags']
    });
    
    // Note capabilities
    this.capabilityManager.register('addNote', {
      patterns: ['add note', 'create note', 'make a note', 'append note'],
      handler: this.handleAddNote.bind(this),
      requiredParams: ['content'],
      optionalParams: ['requestId', 'visibility']
    });
    
    // Solution suggestion
    this.capabilityManager.register('suggestSolution', {
      patterns: ['suggest solution', 'how to fix', 'solve this', 'resolve issue'],
      handler: this.handleSuggestSolution.bind(this),
      requiredParams: ['problem'],
      optionalParams: ['context', 'attempted']
    });
    
    // Retrieval capabilities
    this.capabilityManager.register('retrieveRequests', {
      patterns: ['get requests', 'find tickets', 'show requests', 'list tickets'],
      handler: this.handleRetrieveRequests.bind(this),
      requiredParams: [],
      optionalParams: ['status', 'priority', 'assignee', 'timeframe', 'limit']
    });
    
    this.capabilityManager.register('retrieveTasks', {
      patterns: ['get tasks', 'my tasks', 'show tasks', 'list tasks'],
      handler: this.handleRetrieveTasks.bind(this),
      requiredParams: [],
      optionalParams: ['status', 'priority', 'assignee', 'timeframe', 'limit']
    });
    
    this.capabilityManager.register('retrieveApprovals', {
      patterns: ['get approvals', 'pending approvals', 'my approvals', 'approval requests'],
      handler: this.handleRetrieveApprovals.bind(this),
      requiredParams: [],
      optionalParams: ['status', 'type', 'timeframe']
    });
    
    this.capabilityManager.register('retrieveCIs', {
      patterns: ['get CI', 'show configuration items', 'find CI', 'list CIs'],
      handler: this.handleRetrieveCIs.bind(this),
      requiredParams: [],
      optionalParams: ['type', 'status', 'name', 'limit']
    });
  }
  
  /**
   * Process a message from a user
   * @param {string} message - The message to process
   * @param {string} userId - The ID of the user
   * @param {string} conversationId - The ID of the conversation
   * @returns {Promise<Object>} - The processing result with conversationId
   */
  async processMessage(message, userId, conversationId) {
    try {
      // Generate a new conversation ID if none is provided
      if (!conversationId) {
        conversationId = `conv_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
      }
      
      // Handle special commands
      if (message === '_get_history') {
        return {
          type: 'history',
          conversationId,
          result: {
            messages: await this.getChatHistory(userId)
          }
        };
      }
      
      // Make API call to backend for LLM processing
      try {
        console.log('Sending message to backend for LLM processing:', message);
        
        // Get the base API URL from the environment or use the default
        const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:8080/api';
        
        const response = await fetch(`${baseUrl}/agent/message`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({
            message,
            conversationId
          })
        });
        
        if (response.ok) {
          const result = await response.json();
          console.log('Received response from backend:', result);
          
          if (result.success && result.result) {
            // Add the response to the local context for UI consistency
            await this.contextManager.addMessage(conversationId, {
              content: result.result.text,
              sender: 'agent',
              timestamp: new Date()
            });
            
            // Log successful LLM processing
            logger.info('Successfully processed message with LLM', {
              messageLength: message.length,
              responseLength: result.result.text.length
            });
            
            return {
              type: 'response',
              content: result.result.text,
              conversationId: result.conversationId || conversationId,
              text: result.result.text
            };
          } else {
            console.error('Backend response missing result structure:', result);
            logger.error('Backend response missing result structure', { result });
          }
        } else {
          const errorText = await response.text();
          console.error('Backend API call failed with status:', response.status, errorText);
          logger.error('Backend API call failed', { 
            status: response.status, 
            statusText: response.statusText,
            error: errorText
          });
        }
        
        // If API call fails, fall back to local processing
        console.warn('Backend LLM processing failed, falling back to local NLP');
        logger.warn('Backend LLM processing failed, falling back to local NLP');
      } catch (error) {
        console.error('Error calling backend agent service:', error);
        logger.error('Error calling backend agent service', { error: error.message, stack: error.stack });
        console.warn('Falling back to local NLP processing');
      }
      
      // Fallback to local processing if backend call fails
      // Load conversation context
      const context = await this.contextManager.getContext(conversationId);
      
      // Add user info to context if not already present
      if (!context.userId && userId) {
        context.userId = userId;
        
        // Load basic user info if possible
        try {
          // Mock user data
          const user = { name: 'John Doe', email: 'john.doe@example.com', role: 'admin', permissions: ['read', 'write'] };
          context.userInfo = user;
        } catch (error) {
          console.error('Error loading user info:', error);
        }
      }
      
      // Save the current text for context
      context.currentText = message;
      
      // Add message to conversation history
      await this.contextManager.addMessage(conversationId, {
        content: message,
        sender: 'user',
        timestamp: new Date()
      });
      
      // Process the message with NLP
      const nlpResult = await this.nlpProcessor.process(message, context);
      
      // Identify the most likely capability based on intent
      const capability = this.matchCapability(nlpResult.intent, nlpResult.entities);
      
      if (!capability) {
        const responseMessage = {
          content: "I'm not sure what you're asking for. Could you provide more details about what you'd like me to do?",
          sender: 'agent',
          timestamp: new Date()
        };
        
        await this.contextManager.addMessage(conversationId, responseMessage);
        
        return {
          type: 'response',
          content: responseMessage.content,
          suggestedCapabilities: this.getSuggestions(nlpResult.intent),
          conversationId // Add conversationId to the response
        };
      }
      
      // Check if we have all required parameters
      const missingParams = this.checkRequiredParams(capability, nlpResult.entities);
      
      if (missingParams.length > 0) {
        // We need more information, ask the user
        const promptMessage = {
          content: `To ${capability.name.toLowerCase()}, I need some more information. Please provide: ${missingParams.join(', ')}`,
          sender: 'agent',
          timestamp: new Date()
        };
        
        await this.contextManager.addMessage(conversationId, promptMessage);
        
        return {
          type: 'parameter_request',
          content: promptMessage.content,
          capability: capability.name,
          missingParams,
          currentEntities: nlpResult.entities,
          conversationId // Add conversationId to the response
        };
      }
      
      // Execute the capability handler
      const result = await capability.handler(nlpResult.entities, context);
      
      // Add response to conversation history
      const responseMessage = {
        content: result.content || `I processed your ${capability.name} request.`,
        sender: 'agent',
        timestamp: new Date(),
        capability: capability.name,
        data: result
      };
      
      await this.contextManager.addMessage(conversationId, responseMessage);
      
      // Update context with this interaction
      await this.contextManager.updateContext(conversationId, {
        lastIntent: nlpResult.intent,
        lastEntities: nlpResult.entities,
        lastCapability: capability.name,
        lastResult: result
      });
      
      return {
        type: 'capability_result',
        content: responseMessage.content,
        capability: capability.name,
        result,
        conversationId // Add conversationId to the response
      };
    } catch (error) {
      console.error('Error processing message:', error);
      const botLogger = require('../utils/bot-logger');
      botLogger.error('Error processing message', error);
      
      // Determine the type of error for better user feedback
      let errorMessage = {
        content: 'Sorry, I encountered an error while processing your request.',
        sender: 'agent',
        timestamp: new Date(),
        error: true
      };
      
      // Provide more specific error messages based on error type
      if (error.message && error.message.includes('NLP')) {
        errorMessage.content = 'I had trouble understanding your request. Could you rephrase it?';
      } else if (error.message && error.message.includes('missing parameter')) {
        errorMessage.content = 'I need more information to process your request. Please provide more details.';
      } else if (error.message && error.message.includes('capability')) {
        errorMessage.content = 'I don\'t have the capability to handle this request yet. Is there something else I can help with?';
      }
      
      // Add error message to conversation history
      if (conversationId) {
        await this.contextManager.addMessage(conversationId, errorMessage);
      }
      
      return {
        type: 'error',
        content: errorMessage.content,
        error: error.message || 'An unexpected error occurred',
        conversationId // Add conversationId to the response
      };
    }
  }

  /**
   * Match capability based on intent
   * @param {string} intent - The detected intent
   * @param {Object} entities - The extracted entities
   * @returns {Object|null} The matched capability or null
   */
  matchCapability(intent, entities) {
    // This is a simple matching implementation
    // In a real application, you might have more complex matching logic
    return this.capabilityManager.getCapability(intent) || null;
  }

  /**
   * Get suggested capabilities for an intent
   * @param {string} intent - The intent to get suggestions for
   * @returns {Array} List of suggested capabilities
   */
  getSuggestions(intent) {
    // This is a placeholder implementation
    return this.capabilityManager.getAllCapabilities().slice(0, 3);
  }
  
  /**
   * Check required parameters for a capability
   * @param {Object} capability - The capability to check
   * @param {Object} entities - The extracted entities
   * @returns {Array} List of missing parameters
   */
  checkRequiredParams(capability, entities) {
    return capability.requiredParams.filter(param => !entities[param]);
  }
  
  /**
   * Get chat history for a user
   * @param {string} userId - The ID of the user
   * @returns {Promise<Array>} Array of chat messages
   */
  async getChatHistory(userId) {
    try {
      // Find all contexts for this user
      const userContexts = Array.from(this.contextManager.contexts.values())
        .filter(context => context.userId === userId);
      
      if (userContexts.length === 0) {
        return [];
      }
      
      // Sort by last accessed time to get the most recent conversation
      userContexts.sort((a, b) => b.lastAccessed - a.lastAccessed);
      const mostRecentContext = userContexts[0];
      
      return mostRecentContext.messages || [];
    } catch (error) {
      console.error('Error retrieving chat history:', error);
      return [];
    }
  }
  
  /**
   * Send a message as a user
   * @param {string} userId - The ID of the user
   * @param {string} message - The message to send
   * @param {string} conversationId - Optional conversation ID
   * @returns {Promise<Object>} The agent's response
   */
  async sendMessage(userId, message, conversationId) {
    const result = await this.processMessage(message, userId, conversationId);
    
    // Format the response for the client
    return {
      id: `msg_${Date.now()}`,
      text: result.content,
      conversationId: result.conversationId
    };
  }
  
  // Capability Handlers - implement these based on your existing models and business logic
  async handleCreateRequest(entities, context) {
    try {
      // Mock implementation
      const requestId = 'REQ' + Math.floor(Math.random() * 10000);
      
      return {
        success: true,
        content: `I've created a new ${entities.type || 'incident'} request with the description: "${entities.description}".`,
        requestId
      };
    } catch (error) {
      console.error('Error creating request:', error);
      return {
        success: false,
        content: 'I encountered an error while trying to create your request.',
        error: error.message
      };
    }
  }
  
  async handleUpdateRequest(entities, context) {
    try {
      // Mock implementation
      return {
        success: true,
        content: `I've updated request ${entities.requestId} with your changes.`,
        updatedFields: Object.keys(entities).filter(k => k !== 'requestId')
      };
    } catch (error) {
      console.error('Error updating request:', error);
      return {
        success: false,
        content: 'I encountered an error while trying to update the request.',
        error: error.message
      };
    }
  }
  
  async handleSuggestKnowledge(entities, context) {
    try {
      // Mock implementation
      return {
        success: true,
        content: `Here are some knowledge articles that might help with "${entities.query}":`,
        articles: [
          { id: 'KB001', title: 'Common issues and solutions', relevance: 0.95 },
          { id: 'KB002', title: 'Troubleshooting guide', relevance: 0.82 },
          { id: 'KB003', title: 'Quick start guide', relevance: 0.78 }
        ]
      };
    } catch (error) {
      console.error('Error suggesting knowledge:', error);
      return {
        success: false,
        content: 'I encountered an error while searching for knowledge articles.',
        error: error.message
      };
    }
  }
  
  async handleCreateKnowledge(entities, context) {
    try {
      // Mock implementation
      const articleId = 'KB' + Math.floor(Math.random() * 10000);
      
      return {
        success: true,
        content: `I've created a new knowledge article titled "${entities.title}".`,
        articleId
      };
    } catch (error) {
      console.error('Error creating knowledge article:', error);
      return {
        success: false,
        content: 'I encountered an error while creating the knowledge article.',
        error: error.message
      };
    }
  }
  
  async handleAddNote(entities, context) {
    try {
      // Mock implementation
      const noteId = 'NOTE' + Math.floor(Math.random() * 10000);
      
      return {
        success: true,
        content: 'I\'ve added your note successfully.',
        noteId
      };
    } catch (error) {
      console.error('Error adding note:', error);
      return {
        success: false,
        content: 'I encountered an error while adding your note.',
        error: error.message
      };
    }
  }
  
  async handleSuggestSolution(entities, context) {
    try {
      // Mock implementation
      return {
        success: true,
        content: `Based on your problem "${entities.problem}", here are some suggested solutions:`,
        solutions: [
          { description: 'Try restarting the service', confidence: 0.92 },
          { description: 'Check the configuration file for errors', confidence: 0.85 },
          { description: 'Verify network connectivity', confidence: 0.78 }
        ]
      };
    } catch (error) {
      console.error('Error suggesting solutions:', error);
      return {
        success: false,
        content: 'I encountered an error while finding solutions for your problem.',
        error: error.message
      };
    }
  }
  
  async handleRetrieveRequests(entities, context) {
    try {
      // Mock implementation
      return {
        success: true,
        content: 'Here are the requests matching your criteria:',
        requests: [
          { id: 'REQ001', status: 'Open', priority: 'High', description: 'Server down' },
          { id: 'REQ002', status: 'In Progress', priority: 'Medium', description: 'Application error' },
          { id: 'REQ003', status: 'Open', priority: 'Low', description: 'Feature request' }
        ]
      };
    } catch (error) {
      console.error('Error retrieving requests:', error);
      return {
        success: false,
        content: 'I encountered an error while retrieving requests.',
        error: error.message
      };
    }
  }
  
  async handleRetrieveTasks(entities, context) {
    try {
      // Mock implementation
      return {
        success: true,
        content: 'Here are your current tasks:',
        tasks: [
          { id: 'TASK001', status: 'To Do', priority: 'High', description: 'Update server' },
          { id: 'TASK002', status: 'In Progress', priority: 'Medium', description: 'Deploy application' }
        ]
      };
    } catch (error) {
      console.error('Error retrieving tasks:', error);
      return {
        success: false,
        content: 'I encountered an error while retrieving tasks.',
        error: error.message
      };
    }
  }
  
  async handleRetrieveApprovals(entities, context) {
    try {
      // Mock implementation
      return {
        success: true,
        content: 'Here are the pending approvals:',
        approvals: [
          { id: 'APR001', type: 'Change', requestor: 'John Doe', requestDate: '2023-01-15' },
          { id: 'APR002', type: 'Access', requestor: 'Jane Smith', requestDate: '2023-01-14' }
        ]
      };
    } catch (error) {
      console.error('Error retrieving approvals:', error);
      return {
        success: false,
        content: 'I encountered an error while retrieving approvals.',
        error: error.message
      };
    }
  }
  
  async handleRetrieveCIs(entities, context) {
    try {
      // Mock implementation
      return {
        success: true,
        content: 'Here are the configuration items matching your criteria:',
        cis: [
          { id: 'CI001', type: 'Server', name: 'prod-app-01', status: 'Active' },
          { id: 'CI002', type: 'Database', name: 'prod-db-01', status: 'Active' },
          { id: 'CI003', type: 'Network', name: 'core-switch-01', status: 'Active' }
        ]
      };
    } catch (error) {
      console.error('Error retrieving CIs:', error);
      return {
        success: false,
        content: 'I encountered an error while retrieving configuration items.',
        error: error.message
      };
    }
  }
}

// Create a singleton instance
const agentServiceInstance = new AgentService();

// Export the instance
export const agentService = agentServiceInstance;

// Export specific functions used by components
export const sendMessage = async (message, userId, conversationId) => {
  const result = await agentServiceInstance.processMessage(message, userId, conversationId);
  
  // Ensure the response matches the expected structure
  return {
    type: result.type || 'response',
    content: result.content || 'No content',
    capability: result.capability || null,
    error: result.error || null,
    suggestedCapabilities: result.suggestedCapabilities || [],
    conversationId: conversationId, // Ensure conversationId is included
    result: result.result || null, // Add result property
    id: result.id || `msg_${Date.now()}`, // Add id property
    text: result.text || result.content // Add text property
  };
};

export const getConversation = async (conversationId) => {
  try {
    const context = await agentServiceInstance.contextManager.getContext(conversationId);
    return {
      success: true,
      messages: context.messages || []
    };
  } catch (error) {
    console.error('Error retrieving conversation:', error);
    return {
      success: false,
      error: error.message
    };
  }
};

// For backward compatibility with require()
export default agentServiceInstance;
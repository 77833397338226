import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Chip,
  // Alert, // Unused import
  Tabs,
  Tab
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { ciTypeService } from '../../services/ciTypeService';
import { CIType, CustomField, BaseType } from '../../types/cmdb';
import { CITypeCollectionAssociations } from './CITypeCollectionAssociations';

const BASE_TYPES = ['CI', 'Hardware', 'Software', 'SoftwareInstance', 'BusinessService'];
const FIELD_TYPES = ['text', 'number', 'date', 'boolean', 'reference', 'array'];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`ci-type-tabpanel-${index}`}
      aria-labelledby={`ci-type-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export const CITypeSettings = () => {
  const [types, setTypes] = useState<CIType[]>([]);
  const [open, setOpen] = useState(false);
  const [editingType, setEditingType] = useState<CIType | null>(null);
  const [formData, setFormData] = useState<CIType>({
    name: '',
    description: '',
    baseType: 'CI',
    parent: null,
    isAbstract: false,
    customFields: [],
    defaultFields: new Map()
  });
  const [newField, setNewField] = useState<CustomField>({
    name: '',
    label: '',
    type: 'text',
    required: false,
    validation: {}
  });
  const [availableParents, setAvailableParents] = useState<CIType[]>([]);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    loadTypes();
  }, []);

  useEffect(() => {
    // Update available parents when baseType changes
    if (formData.baseType) {
      const filtered = types.filter(t => 
        t.baseType === formData.baseType || 
        (formData.baseType === 'SoftwareInstance' && t.baseType === 'Software')
      );
      setAvailableParents(filtered);
    }
  }, [formData.baseType, types]);

  const loadTypes = async () => {
    try {
      const data = await ciTypeService.getAllTypes();
      setTypes(data);
    } catch (error) {
      console.error('Error loading CI types:', error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingType(null);
    setFormData({
      name: '',
      description: '',
      baseType: 'CI',
      parent: null,
      isAbstract: false,
      customFields: [],
      defaultFields: new Map()
    });
    setNewField({
      name: '',
      label: '',
      type: 'text',
      required: false,
      validation: {}
    });
  };

  const handleEdit = (type: CIType) => {
    setEditingType(type);
    setFormData(type);
    setOpen(true);
  };

  const handleDelete = async (id: string) => {
    try {
      await ciTypeService.deleteType(id);
      loadTypes();
    } catch (error) {
      console.error('Error deleting CI type:', error);
    }
  };

  const handleAddField = () => {
    if (newField.name && newField.label) {
      setFormData(prev => ({
        ...prev,
        customFields: [...prev.customFields, { ...newField }]
      }));
      setNewField({
        name: '',
        label: '',
        type: 'text',
        required: false,
        validation: {}
      });
    }
  };

  const handleRemoveField = (index: number) => {
    setFormData(prev => ({
      ...prev,
      customFields: prev.customFields.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async () => {
    try {
      if (editingType?._id) {
        await ciTypeService.updateType(editingType._id, formData);
      } else {
        await ciTypeService.createType(formData);
      }
      loadTypes();
      handleClose();
    } catch (error) {
      console.error('Error saving CI type:', error);
    }
  };

  const handleFieldChange = (index: number, field: string, value: any) => {
    setFormData(prev => ({
      ...prev,
      customFields: prev.customFields.map((f, i) => 
        i === index ? { ...f, [field]: value } : f
      ),
    }));
  };

  const handleNewFieldChange = (field: string, value: any) => {
    setNewField(prev => ({ ...prev, [field]: value }));
  };

  const handleFieldValidationChange = (field: CustomField, validationField: string, value: any) => {
    setFormData(prev => ({
      ...prev,
      customFields: prev.customFields.map(f => 
        f.name === field.name ? { ...f, validation: { ...f.validation, [validationField]: value } } : f
      ),
    }));
  };

  const renderFieldValidation = (field: CustomField) => {
    switch (field.type) {
      case 'number':
        return (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              label="Min"
              type="number"
              value={field.validation?.min || ''}
              onChange={(e) => handleFieldValidationChange(field, 'min', e.target.value)}
              size="small"
            />
            <TextField
              label="Max"
              type="number"
              value={field.validation?.max || ''}
              onChange={(e) => handleFieldValidationChange(field, 'max', e.target.value)}
              size="small"
            />
          </Box>
        );
      case 'reference':
        return (
          <FormControl fullWidth size="small">
            <InputLabel>Reference Type</InputLabel>
            <Select
              value={field.validation?.referenceType || ''}
              onChange={(e) => handleFieldValidationChange(field, 'referenceType', e.target.value)}
            >
              {types.map(type => (
                <MenuItem key={type._id} value={type.name}>{type.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      default:
        return null;
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">CI Type Settings</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpen}
        >
          Add CI Type
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Base Type</TableCell>
              <TableCell>Parent</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Fields</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {types.map((type) => (
              <TableRow key={type._id}>
                <TableCell>
                  {type.name}
                  {type.isAbstract && (
                    <Chip size="small" label="Abstract" sx={{ ml: 1 }} />
                  )}
                  {type.systemType && (
                    <Chip size="small" color="primary" label="System" sx={{ ml: 1 }} />
                  )}
                </TableCell>
                <TableCell>{type.baseType}</TableCell>
                <TableCell>{type.parent ? types.find(t => t._id === type.parent)?.name : '-'}</TableCell>
                <TableCell>{type.description}</TableCell>
                <TableCell>
                  {type.customFields.length + (type.defaultFields ? Object.keys(type.defaultFields).length : 0)} fields
                </TableCell>
                <TableCell align="right">
                  {!type.systemType && (
                    <>
                      <IconButton onClick={() => handleEdit(type)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton 
                        onClick={() => type._id && handleDelete(type._id)}
                        disabled={types.some(t => t.parent === type._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          {editingType ? 'Edit CI Type' : 'New CI Type'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab label="General" />
              <Tab label="Fields" />
              <Tab label="Collections" />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <TextField
                fullWidth
                label="Name"
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
              />
              
              <TextField
                fullWidth
                label="Description"
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                multiline
                rows={2}
              />

              <FormControl fullWidth>
                <InputLabel>Base Type</InputLabel>
                <Select
                  value={formData.baseType}
                  onChange={(e) => setFormData(prev => ({ ...prev, baseType: e.target.value as BaseType }))}
                  disabled={editingType?.systemType}
                >
                  {BASE_TYPES.map(type => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel>Parent Type</InputLabel>
                <Select
                  value={formData.parent || ''}
                  onChange={(e) => setFormData(prev => ({ ...prev, parent: e.target.value }))}
                  disabled={editingType?.systemType}
                >
                  <MenuItem value="">None</MenuItem>
                  {availableParents.map(type => (
                    <MenuItem key={type._id} value={type._id}>{type.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.isAbstract}
                    onChange={(e) => setFormData(prev => ({ ...prev, isAbstract: e.target.checked }))}
                    disabled={editingType?.systemType}
                  />
                }
                label="Abstract Type (cannot be instantiated directly)"
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              {editingType?.defaultFields && Object.keys(editingType.defaultFields).length > 0 && (
                <>
                  <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>Default Fields</Typography>
                  {Object.entries(editingType.defaultFields).map(([fieldName, fieldConfig]) => (
                    <Card key={`default-${fieldName}`} variant="outlined" sx={{ mb: 2 }}>
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                          <Typography variant="subtitle1">{fieldName}</Typography>
                          <Chip 
                            size="small" 
                            color="primary" 
                            label="Default Field" 
                          />
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2, mb: 2, flexWrap: 'wrap' }}>
                          {fieldConfig.type && (
                            <Chip size="small" label={`Type: ${fieldConfig.type}`} />
                          )}
                          {fieldConfig.required && (
                            <Chip size="small" color="error" label="Required" />
                          )}
                          {fieldConfig.description && (
                            <Typography variant="body2" color="text.secondary">
                              {fieldConfig.description}
                            </Typography>
                          )}
                        </Box>
                        {fieldConfig.options && (
                          <Box sx={{ mt: 1 }}>
                            <Typography variant="caption">Options:</Typography>
                            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 0.5 }}>
                              {fieldConfig.options.map((option: any) => (
                                <Chip 
                                  key={option.value} 
                                  size="small" 
                                  label={option.label} 
                                  variant="outlined" 
                                />
                              ))}
                            </Box>
                          </Box>
                        )}
                      </CardContent>
                    </Card>
                  ))}
                </>
              )}
              
              <Typography variant="h6" sx={{ mt: 2 }}>Custom Fields</Typography>
              
              {formData.customFields.map((field, index) => (
                <Card key={index} variant="outlined" sx={{ mb: 2 }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                      <Typography variant="subtitle1">{field.label}</Typography>
                      <IconButton 
                        size="small" 
                        onClick={() => handleRemoveField(index)}
                        disabled={editingType?.systemType}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                      <TextField
                        label="Field Name"
                        value={field.name}
                        onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                        disabled={editingType?.systemType}
                        size="small"
                      />
                      <TextField
                        label="Field Label"
                        value={field.label}
                        onChange={(e) => handleFieldChange(index, 'label', e.target.value)}
                        disabled={editingType?.systemType}
                        size="small"
                      />
                      <FormControl sx={{ minWidth: 120 }} size="small">
                        <InputLabel>Type</InputLabel>
                        <Select
                          value={field.type}
                          onChange={(e) => handleFieldChange(index, 'type', e.target.value)}
                          disabled={editingType?.systemType}
                        >
                          {FIELD_TYPES.map(type => (
                            <MenuItem key={type} value={type}>{type}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.required}
                            onChange={(e) => handleFieldChange(index, 'required', e.target.checked)}
                            disabled={editingType?.systemType}
                            size="small"
                          />
                        }
                        label="Required"
                      />
                    </Box>
                    {renderFieldValidation(field)}
                  </CardContent>
                </Card>
              ))}
              {!editingType?.systemType && (
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>Add New Field</Typography>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                      <TextField
                        label="Field Name"
                        value={newField.name}
                        onChange={(e) => handleNewFieldChange('name', e.target.value)}
                        size="small"
                      />
                      <TextField
                        label="Field Label"
                        value={newField.label}
                        onChange={(e) => handleNewFieldChange('label', e.target.value)}
                        size="small"
                      />
                      <FormControl sx={{ minWidth: 120 }} size="small">
                        <InputLabel>Type</InputLabel>
                        <Select
                          value={newField.type}
                          onChange={(e) => handleNewFieldChange('type', e.target.value)}
                        >
                          {FIELD_TYPES.map(type => (
                            <MenuItem key={type} value={type}>{type}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={newField.required}
                            onChange={(e) => handleNewFieldChange('required', e.target.checked)}
                            size="small"
                          />
                        }
                        label="Required"
                      />
                      <Button
                        variant="contained"
                        onClick={handleAddField}
                        disabled={!newField.name || !newField.label}
                      >
                        Add Field
                      </Button>
                    </Box>
                    {renderFieldValidation(newField)}
                  </CardContent>
                </Card>
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              {editingType && editingType._id && (
                <CITypeCollectionAssociations ciTypeId={editingType._id} />
              )}
            </TabPanel>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {editingType ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CITypeSettings;

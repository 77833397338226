import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Button, 
  Typography, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TablePagination,
  Chip
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Event as EventIcon } from '@mui/icons-material';
import { format } from 'date-fns';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import holidayService, { Holiday, UpdateHolidayDto } from '../../../services/holidayService';
import CreateHolidayModal from './CreateHolidayModal';

const HolidayList: React.FC = () => {
  const navigate = useNavigate();
  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [deleteHolidayId, setDeleteHolidayId] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedHoliday, setSelectedHoliday] = useState<Holiday | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    loadHolidays();
  }, []);

  const loadHolidays = async () => {
    try {
      setIsLoading(true);
      const data = await holidayService.getAll();
      setHolidays(data);
    } catch (err) {
      setError('Failed to load holidays');
      console.error('Error loading holidays:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateHoliday = () => {
    setIsCreateModalOpen(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleEdit = (holidayId: string) => {
    navigate(`/settings/holidays/${holidayId}`);
  };

  const handleDelete = async () => {
    if (!deleteHolidayId) return;

    try {
      await holidayService.delete(deleteHolidayId);
      setHolidays(holidays.filter(h => h._id !== deleteHolidayId));
      setDeleteHolidayId(null);
    } catch (err) {
      setError('Failed to delete holiday');
      console.error('Error deleting holiday:', err);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (holiday: Holiday) => {
    setSelectedHoliday(holiday);
    setIsModalOpen(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedHoliday(null);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleHolidayCreated = () => {
    loadHolidays();
  };

  const transformHolidayToDto = (holiday: Holiday | null): { id: string, holidayData: Partial<Holiday> } | undefined => {
    if (!holiday) return undefined;
    
    return {
      id: holiday._id,
      holidayData: {
        name: holiday.name,
        description: holiday.description,
        date: holiday.date,
        type: holiday.type,
        calendar: { _id: holiday.calendar._id, name: holiday.calendar.name },
        isActive: holiday.isActive
      }
    };
  };

  if (isLoading) {
    return <Typography>Loading holidays...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h5">Holidays</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreateHoliday}
        >
          Create Holiday
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Calendar</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? holidays.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : holidays
            ).map((holiday) => (
              <TableRow 
                key={holiday._id} 
                hover 
                onClick={() => handleRowClick(holiday)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>{holiday.name}</TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <EventIcon fontSize="small" sx={{ mr: 1 }} />
                    <Chip
                      label={format(new Date(holiday.date), 'MMM dd, yyyy')}
                      size="small"
                      color="primary"
                    />
                  </Box>
                </TableCell>
                <TableCell>{holiday.calendar.name}</TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click
                      setSelectedHoliday(holiday);
                      setIsModalOpen(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click
                      setDeleteHolidayId(holiday._id);
                    }}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={holidays.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <CreateHolidayModal
        open={isModalOpen || isCreateModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setIsCreateModalOpen(false);
          setSelectedHoliday(null);
        }}
        onHolidayCreated={loadHolidays}
        holiday={transformHolidayToDto(selectedHoliday)?.holidayData}
      />

      <Dialog
        open={Boolean(deleteHolidayId)}
        onClose={() => setDeleteHolidayId(null)}
      >
        <DialogTitle>Delete Holiday</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this holiday? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteHolidayId(null)}>Cancel</Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default HolidayList;

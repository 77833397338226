import api from './api';
import { RawScanData, DiscoveryToken } from '../types/discovery';

class DiscoveryService {
  async getAllScanData(params: {
    page?: number;
    limit?: number;
    status?: string;
    ipAddress?: string;
    hostname?: string;
  } = {}) {
    const response = await api.get('/discovery/scan-data', { params });
    return response.data;
  }

  async getScanDataById(id: string, includeRawData: boolean = false) {
    const response = await api.get(`/discovery/scan-data/${id}`, {
      params: { includeRawData }
    });
    return response.data;
  }

  async updateScanDataStatus(id: string, status: string, processingErrors?: string[]) {
    // Validate status
    const validStatuses = ['pending', 'processing', 'processed', 'analyzed', 'completed', 'failed'];
    if (!validStatuses.includes(status)) {
      throw new Error(`Invalid status: ${status}`);
    }
    
    const response = await api.patch(`/discovery/scan-data/${id}/status`, {
      status,
      processingErrors
    });
    return response.data;
  }

  async updateScanData(id: string, data: {
    hostname?: string;
    status?: string;
    notes?: string;
  }) {
    const response = await api.put(`/discovery/scan-data/${id}`, data);
    return response.data;
  }

  async processScanData(id: string) {
    const response = await api.post(`/discovery/scan-data/${id}/process`, {});
    return response.data;
  }

  async deleteScanData(id: string) {
    const response = await api.delete(`/discovery/scan-data/${id}`);
    return response.data;
  }

  // Token Management Methods
  async getAllTokens() {
    const response = await api.get('/discovery/tokens');
    return response.data;
  }

  async createToken(tokenData: {
    name: string;
    description?: string;
  }) {
    const response = await api.post('/discovery/tokens', tokenData);
    return response.data;
  }

  async getTokenById(id: string) {
    const response = await api.get(`/discovery/tokens/${id}`);
    return response.data;
  }

  async updateToken(id: string, tokenData: {
    name?: string;
    enabled?: boolean;
    description?: string;
  }) {
    const response = await api.patch(`/discovery/tokens/${id}`, tokenData);
    return response.data;
  }

  async deleteToken(id: string) {
    const response = await api.delete(`/discovery/tokens/${id}`);
    return response.data;
  }

  async regenerateToken(id: string) {
    const response = await api.post(`/discovery/tokens/${id}/regenerate`);
    return response.data;
  }

  // Debug method for testing token creation without authentication
  async createTokenDebug(tokenData: {
    name: string;
    description?: string;
  }) {
    const response = await api.post('/discovery/debug/tokens', tokenData);
    return response.data;
  }

  // Debug method to check if the API is working
  async checkApiStatus() {
    const response = await api.get('/discovery/debug');
    return response.data;
  }
}

export const discoveryService = new DiscoveryService();
import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  TextField, 
  IconButton, 
  Avatar, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemAvatar,
  Fab,
  Zoom,
  Badge
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ChatIcon from '@mui/icons-material/Chat';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import PersonIcon from '@mui/icons-material/Person';
import { agentService, sendMessage } from '../services/agent-service';

// Styled components
const ChatWindow = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  bottom: 80,
  right: 20,
  width: 350,
  height: 500,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  zIndex: 1000,
  boxShadow: theme.shadows[10],
  borderRadius: '10px',
}));

const ChatHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const MessageList = styled(List)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
}));

const MessageInput = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
  display: 'flex',
}));

const ChatFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: 20,
  right: 20,
  zIndex: 1000,
}));

// Types
interface ChatMessage {
  id: string;
  text: string;
  sender: 'user' | 'agent';
  timestamp: Date;
}

interface ChatWidgetProps {
  userId: string;
  position?: 'bottom-right' | 'bottom-left';
}

const ChatWidget: React.FC<ChatWidgetProps> = ({ userId, position = 'bottom-right' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [unreadCount, setUnreadCount] = useState(0);
  const [conversationId, setConversationId] = useState<string | undefined>(undefined);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  
  // Scroll to bottom of messages
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  
  useEffect(() => {
    if (isOpen) {
      scrollToBottom();
      setUnreadCount(0);
    }
  }, [messages, isOpen]);
  
  // Load chat history when component mounts
  useEffect(() => {
    const loadChatHistory = async () => {
      try {
        // Use the special command to get history
        const result = await sendMessage('_get_history', userId);
        
        if (result && result.type === 'history' && result.result && result.result.messages) {
          const chatMessages = result.result.messages
            .filter((msg: any) => msg.content || msg.text) // Filter out any invalid messages
            .map((msg: any) => ({
              id: msg.id || `history_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
              text: msg.content || msg.text,
              sender: msg.sender,
              timestamp: new Date(msg.timestamp)
            }));
          
          if (chatMessages.length > 0) {
            setMessages(chatMessages);
          }
          
          if (result.conversationId) {
            setConversationId(result.conversationId);
          }
        }
      } catch (error) {
        console.error('Failed to load chat history:', error);
      }
    };
    
    loadChatHistory();
    
    // Set up listener for new messages from agent
    const handleNewAgentMessage = (message: ChatMessage) => {
      setMessages(prev => [...prev, message]);
      if (!isOpen) {
        setUnreadCount(prev => prev + 1);
      }
    };
    
    // Simulate receiving a welcome message after 1 second
    const timer = setTimeout(() => {
      // Only add welcome message if no history was loaded
      if (messages.length === 0) {
        const welcomeMessage: ChatMessage = {
          id: `welcome_${Date.now()}`,
          text: "Hello! I'm your AI assistant. How can I help you today?",
          sender: 'agent',
          timestamp: new Date()
        };
        handleNewAgentMessage(welcomeMessage);
      }
    }, 1000);
    
    return () => {
      clearTimeout(timer);
      // Clean up any event listeners here
    };
  }, [userId]);
  
  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;
    
    const userMessage: ChatMessage = {
      id: `user_${Date.now()}`,
      text: newMessage,
      sender: 'user',
      timestamp: new Date()
    };
    
    setMessages(prev => [...prev, userMessage]);
    setNewMessage('');
    
    try {
      // Send message to agent service
      const result = await sendMessage(newMessage, userId, conversationId);
      
      // Update conversation ID if this is a new conversation
      if (!conversationId && result.conversationId) {
        setConversationId(result.conversationId);
      }
      
      // Add agent response to messages
      const agentMessage: ChatMessage = {
        id: result.id || `agent_${Date.now()}`,
        text: result.content || result.text,
        sender: 'agent',
        timestamp: new Date()
      };
      
      setMessages(prev => [...prev, agentMessage]);
    } catch (error) {
      console.error('Failed to send message:', error);
      // Add error message
      const errorMessage: ChatMessage = {
        id: `error_${Date.now()}`,
        text: "Sorry, I'm having trouble responding right now. Please try again later.",
        sender: 'agent',
        timestamp: new Date()
      };
      
      setMessages(prev => [...prev, errorMessage]);
    }
  };
  
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };
  
  const toggleChat = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setUnreadCount(0);
    }
  };
  
  return (
    <>
      {isOpen && (
        <ChatWindow>
          <ChatHeader>
            <Box display="flex" alignItems="center">
              <Avatar sx={{ mr: 1, bgcolor: 'secondary.main' }}>
                <SmartToyIcon />
              </Avatar>
              <Typography variant="h6">AI Assistant</Typography>
            </Box>
            <IconButton color="inherit" onClick={toggleChat} size="small">
              <CloseIcon />
            </IconButton>
          </ChatHeader>
          
          <MessageList>
            {messages.map((message) => (
              <ListItem 
                key={message.id}
                sx={{ 
                  justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
                  mb: 1
                }}
              >
                <Paper
                  sx={{
                    p: 1.5,
                    backgroundColor: message.sender === 'user' 
                      ? 'primary.light' 
                      : 'background.paper',
                    borderRadius: 2,
                    maxWidth: '80%'
                  }}
                >
                  <ListItemAvatar sx={{ minWidth: 40 }}>
                    <Avatar 
                      sx={{ 
                        width: 24, 
                        height: 24,
                        bgcolor: message.sender === 'user' ? 'primary.main' : 'secondary.main'
                      }}
                    >
                      {message.sender === 'user' ? <PersonIcon fontSize="small" /> : <SmartToyIcon fontSize="small" />}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText 
                    primary={message.text}
                    secondary={message.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    primaryTypographyProps={{
                      variant: 'body2',
                      color: message.sender === 'user' ? 'primary.contrastText' : 'text.primary'
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                      color: message.sender === 'user' ? 'primary.contrastText' : 'text.secondary',
                      sx: { opacity: 0.7 }
                    }}
                  />
                </Paper>
              </ListItem>
            ))}
            <div ref={messagesEndRef} />
          </MessageList>
          
          <MessageInput>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type a message..."
              size="small"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              sx={{ mr: 1 }}
            />
            <IconButton 
              color="primary" 
              onClick={handleSendMessage}
              disabled={newMessage.trim() === ''}
            >
              <SendIcon />
            </IconButton>
          </MessageInput>
        </ChatWindow>
      )}
      
      <Zoom in={!isOpen}>
        <ChatFab 
          color="primary" 
          onClick={toggleChat}
          sx={{ right: position === 'bottom-right' ? 20 : 'auto', left: position === 'bottom-left' ? 20 : 'auto' }}
        >
          <Badge badgeContent={unreadCount} color="error">
            <ChatIcon />
          </Badge>
        </ChatFab>
      </Zoom>
    </>
  );
};

export default ChatWidget;
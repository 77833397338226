import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import TabbedRelationships from './TabbedRelationships';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Tooltip,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Slider as _Slider,
  CircularProgress,
  Switch,
  Chip,
  Alert,
  Snackbar,
  Tabs,
  Tab
} from '@mui/material';
import { 
  Delete as DeleteIcon, 
  HelpOutline as HelpIcon,
  Storage as StorageIcon,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Dns as _DnsIcon,
  Devices as DevicesIcon,
  Web as WebIcon,
  Security as SecurityIcon,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Monitor as _MonitorIcon,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Folder as _FolderIcon,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Code as _CodeIcon,
  Computer as ComputerIcon,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Laptop as _LaptopIcon,
  Router as RouterIcon,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Language as _LanguageIcon,
  Android as AndroidIcon,
  Apple as AppleIcon,
  Window as WindowIcon
} from '@mui/icons-material';
import ReactFlow, { 
  ReactFlowProvider, 
  Controls, 
  Background, 
  MiniMap, 
  Node,
  ConnectionLineType,
  MarkerType,
  EdgeProps,
  getBezierPath,
  OnNodesChange,
  applyNodeChanges,
  NodeChange,
  Position,
  CoordinateExtent,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Edge as _Edge
} from 'reactflow';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Edge } from 'reactflow';
import 'reactflow/dist/style.css';
import { ciService } from '../../services/ciService';
import { ciRelationshipService } from '../../services/ciRelationshipService';
import { CI, CIRelationship, CIRelationType } from '../../types/cmdb';

interface CIRelationshipsProps {
  ci: CI;
  onUpdate: () => void;
}

interface CreateRelationshipData {
  targetId: string;
  typeId: string;
}

// Extend CIRelationship type to include direction
interface RelationshipWithDirection extends CIRelationship {
  direction: 'incoming' | 'outgoing';
  depth?: number;
}



interface FlowNode {
  id: string;
  data: { 
    label: React.ReactNode;
    type?: string;
    depth?: number;
    direction?: 'incoming' | 'outgoing';
    process?: string;        // Add process information to nodes
    isProcessGroup?: boolean; // Flag to identify group containers
  };
  position: { x: number; y: number };
  type?: string;
  style?: React.CSSProperties;
  draggable?: boolean;
  sourcePosition?: Position;
  targetPosition?: Position;
  parentNode?: string;       // For grouped nodes, reference to parent
  extent?: 'parent' | CoordinateExtent;  // Match ReactFlow's Node type
}

interface FlowEdge {
  id: string;
  source: string;
  target: string;
  label?: string;
  type: string;
  animated?: boolean;
  style?: React.CSSProperties;
  markerEnd?: any;
  markerStart?: any; // Add support for bidirectional edges
  data?: {
    relationType?: string;
    category?: string;
    depth?: number;
    process?: string;
    isBidirectional?: boolean; // Add flag for bidirectional relationships
    sourceProcess?: string;    // Process name for the source node
    targetProcess?: string;    // Process name for the target node
    sourceNode?: string;       // ID of the source node
    targetNode?: string;       // ID of the target node
    connections?: any;         // Connection information array
  };
}

interface RelationshipFilters {
  showServerRelations: boolean;
  showAppRelations: boolean;
  showProcessView: boolean;
}

// Helper to extract process name from edge data
const getProcessName = (data: any): string => {
  if (!data) return '';
  
  // Check direct process fields
  if (data.process) return data.process;
  if (data.sourceProcess) return data.sourceProcess;
  
  // Check connections array
  if (data.connections && Array.isArray(data.connections)) {
    for (const conn of data.connections) {
      if (conn.process) return conn.process;
    }
  }
  
  return '';
};

export const CIRelationships: React.FC<CIRelationshipsProps> = ({ ci, onUpdate }) => {
  const [relationships, setRelationships] = useState<RelationshipWithDirection[]>([]);
  const [relationshipTypes, setRelationshipTypes] = useState<CIRelationType[]>([]);
  const [availableTargets, setAvailableTargets] = useState<CI[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [notification, setNotification] = useState<{message: string; type: 'info' | 'success' | 'warning' | 'error'} | null>(null);
  const [exploredCIs, setExploredCIs] = useState<Set<string>>(new Set());
  // Track which CI is currently loading
  const [loadingNodeId, setLoadingNodeId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingTypes, setLoadingTypes] = useState(false);
  const [nodes, setNodes] = useState<FlowNode[]>([]);
  const [edges, setEdges] = useState<FlowEdge[]>([]);
  const [relationshipsTabValue, setRelationshipsTabValue] = useState(0);
  const [formData, setFormData] = useState<CreateRelationshipData>({
    targetId: '',
    typeId: '',
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setRelationshipsTabValue(newValue);
  };

  // Helper function to create an outgoing edge - not used but keeping for potential future use
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _createOutgoingEdge = (rel: CIRelationship, ciId: string, depth: number): FlowEdge | null => {
    const edgeId = `edge-${getRelationshipKey(rel)}`;
    const isUnknownDevice = Boolean(
      (rel.targetType === 'UnknownDevice' || !rel.target?._id) && rel.targetIdentifier
    );
    return {
      id: edgeId,
      source: ciId,
      target: rel.target?._id || `unknown-${rel.targetIdentifier}`,
      type: 'custom',
      style: getEdgeStyle(rel.type?.name || '', depth + 1, isUnknownDevice),
      animated: false,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
        color: getEdgeStyle(rel.type?.name || '', depth + 1, isUnknownDevice).stroke
      },
      data: {
        relationType: rel.type?.name || 'Unknown',
        category: rel.attributes?.category || 'Other',
        depth: depth + 1,
        process: rel.process
      }
    };
  };

  // Helper function to create an incoming edge - not used but keeping for potential future use
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _createIncomingEdge = (rel: CIRelationship, ciId: string, depth: number): FlowEdge | null => {
    const edgeId = `edge-${getRelationshipKey(rel)}`;
    const isUnknownDevice = Boolean(
      (rel.sourceType === 'UnknownDevice' || !rel.source?._id) && rel.sourceIdentifier
    );
    return {
      id: edgeId,
      source: rel.source?._id || `unknown-${rel.sourceIdentifier}`,
      target: ciId,
      type: 'custom',
      style: getEdgeStyle(rel.type?.name || '', depth + 1, isUnknownDevice),
      animated: false,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
        color: getEdgeStyle(rel.type?.name || '', depth + 1, isUnknownDevice).stroke
      },
      data: {
        relationType: rel.type?.name || 'Unknown',
        category: rel.attributes?.category || 'Other',
        depth: depth + 1,
        process: rel.process
      }
    };
  };

  // Helper function to create a unique relationship key
  const getRelationshipKey = (rel: CIRelationship): string => {
    const sourceId = rel.source?._id || rel.sourceIdentifier || 'unknown';
    const targetId = rel.target?._id || rel.targetIdentifier || 'unknown';
    const typeId = rel.type?._id || 'unknown';
    
    // In process view, include process in the key to allow multiple edges
    if (rel.process) {
      return `${sourceId}-${targetId}-${typeId}-${rel.process}`;
    }
    
    // Sort IDs to ensure the same relationship is identified regardless of direction
    const [id1, id2] = [sourceId, targetId].sort();
    return `${id1}-${id2}-${typeId}`;
  };

  // Define the getEdgeStyle function that was missing
  const getEdgeStyle = (relationType: string, depth: number, isUnknownDevice = false): React.CSSProperties => {
    // Base styles
    const baseStyle: React.CSSProperties = {
      stroke: '#aaa',
      strokeWidth: Math.max(3 - (depth * 0.5), 1),
      opacity: isUnknownDevice ? 0.6 : 1,
    };

    // Style based on relationship type
    switch (relationType.toLowerCase()) {
      case 'connected to':
        return {
          ...baseStyle,
          stroke: '#1890ff',  // Blue for network connections
        };
      case 'runs on':
        return {
          ...baseStyle,
          stroke: '#52c41a',  // Green for application deployments
          strokeDasharray: '5,5',
        };
      case 'depends on':
        return {
          ...baseStyle,
          stroke: '#722ed1',  // Purple for dependencies
          strokeDasharray: '3,3',
        };
      case 'contains':
      case 'is contained by':
        return {
          ...baseStyle,
          stroke: '#fa8c16',  // Orange for containment 
          strokeWidth: Math.max(4 - (depth * 0.5), 1.5),
        };
      default:
        return baseStyle;
    }
  };

  // Define the getNodeStyle function that was referenced throughout the code
  const getNodeStyle = (ciType: string, depth: number, isUnknown = false): React.CSSProperties => {
    // Base styles
    const baseStyle: React.CSSProperties = {
      padding: 10,
      borderRadius: 8,
      borderWidth: 1,
      borderStyle: 'solid',
      boxShadow: isUnknown ? 'none' : '0 2px 4px rgba(0,0,0,0.1)',
      opacity: isUnknown ? 0.7 : 1,
      width: 130,
      height: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
    };

    // For the loading CI, add a pulsing effect
    if (loadingNodeId && ciType === loadingNodeId) {
      return {
        ...baseStyle,
        animation: 'pulse 1.5s infinite',
        backgroundColor: '#e6f7ff',
        borderColor: '#91d5ff',
      };
    }

    // Style based on CI type
    if (ciType.toLowerCase().includes('server') || 
        ciType.toLowerCase().includes('device') || 
        ciType.toLowerCase().includes('router') ||
        ciType.toLowerCase().includes('storage')) {
      return {
        ...baseStyle,
        backgroundColor: '#e6f7ff',  // Light blue for hardware
        borderColor: '#91d5ff',
      };
    } else if (ciType.toLowerCase().includes('software') || 
              ciType.toLowerCase().includes('application')) {
      return {
        ...baseStyle,
        backgroundColor: '#f6ffed',  // Light green for software
        borderColor: '#b7eb8f',
      };
    } else if (ciType.toLowerCase().includes('instance')) {
      return {
        ...baseStyle,
        backgroundColor: '#fff7e6',  // Light orange for instances
        borderColor: '#ffd591',
      };
    } else if (isUnknown || ciType.toLowerCase().includes('unknown')) {
      return {
        ...baseStyle,
        backgroundColor: '#f5f5f5',  // Gray for unknown devices
        borderColor: '#d9d9d9',
        borderStyle: 'dashed',
      };
    } else {
      return {
        ...baseStyle,
        backgroundColor: '#f0f5ff',  // Light indigo for others
        borderColor: '#adc6ff',
      };
    }
  };
  const [filters, setFilters] = useState<RelationshipFilters>({
    showServerRelations: true,
    showAppRelations: true,
    showProcessView: false
  });

  // Helper function to filter relationships based on the current filter settings
  const filterRelationships = useCallback((relationships: CIRelationship[]) => {
    if (!relationships) return [];
    
    const connectedToType = relationshipTypes.find(t => t.name === 'Connected To');
    const runsOnType = relationshipTypes.find(t => t.name === 'Runs On');
    
    if (!connectedToType || !runsOnType) return relationships;
    
    return relationships.filter((rel: CIRelationship) => {
      const isConnectedTo = rel.type._id === connectedToType._id;
      const isRunsOn = rel.type._id === runsOnType._id;
      
      if (isConnectedTo && !filters.showServerRelations) return false;
      if (isRunsOn && !filters.showAppRelations) return false;
      
      return true;
    });
  }, [relationshipTypes, filters]);

  // Handle node drag
  const onNodesChange: OnNodesChange = useCallback((changes: NodeChange[]) => {
    // Track which nodes have been manually positioned
    changes.forEach(change => {
      if (change.type === 'position' && change.dragging === true && change.id) {
        manuallyPositionedNodes.current.add(change.id);
      }
    });
    
    // Apply changes normally
    setNodes((nds) => applyNodeChanges(changes, nds) as FlowNode[]);
  }, []);

  // Consolidate bidirectional relationships into a single edge
  const consolidateBidirectionalEdges = useCallback((edges: FlowEdge[]): FlowEdge[] => {
    // Create a map to track connections between nodes
    const connectionMap = new Map<string, FlowEdge>();
    const resultEdges: FlowEdge[] = [];
    
    // Process each edge
    edges.forEach(edge => {
      // Create a unique key for the connection (sorted node IDs)
      const nodeIds = [edge.source, edge.target].sort();
      const connectionKey = `${nodeIds[0]}-${nodeIds[1]}`;
      
      // Check if we've already processed an edge between these nodes
      if (connectionMap.has(connectionKey)) {
        // Edge already exists, modify it to be bidirectional
        const existingEdge = connectionMap.get(connectionKey)!;
        
        // Only convert to bidirectional if the relationship type matches
        if (existingEdge.data?.relationType === edge.data?.relationType) {
          // Instead of directly modifying an edge from the loop, create a copy with all the bidirectional properties
          const bidirectionalEdge: FlowEdge = {
            ...existingEdge,
            markerStart: {
              type: MarkerType.ArrowClosed,
              width: 20,
              height: 20,
              color: (existingEdge.style as React.CSSProperties).stroke
            },
            data: {
              ...existingEdge.data,
              isBidirectional: true,
              // Store both process names for bidirectional edges
              sourceProcess: existingEdge.data?.process || '',
              targetProcess: edge.data?.process || '',
              // Ensure we know which nodes are source and target for each process
              sourceNode: existingEdge.source,
              targetNode: existingEdge.target
            }
          };
          
          // Replace the existing edge in both the map and result array
          connectionMap.set(connectionKey, bidirectionalEdge);
          
          // Find the existing edge in the result array and replace it
          const index = resultEdges.findIndex(e => e.id === existingEdge.id);
          if (index !== -1) {
            resultEdges[index] = bidirectionalEdge;
          }
        } else {
          // Different relationship types, don't consolidate
          resultEdges.push(edge);
        }
      } else {
        // New edge, add it to the map
        connectionMap.set(connectionKey, edge);
        resultEdges.push(edge);
      }
    });
    
    return resultEdges;
  }, []);

  // Get device icon based on CI type and name
  const getDeviceIcon = (ci: CI | undefined, isUnknown: boolean) => {
    if (!ci) return <DevicesIcon sx={{ color: isUnknown ? '#8c8c8c' : 'inherit' }} />;
    
    if (isUnknown) return <DevicesIcon sx={{ color: '#8c8c8c' }} />;
    
    const name = ci.name?.toLowerCase() || '';
    const type = ci.type?.name?.toLowerCase() || '';
    const customFields = ci.customFields || {};
    
    // Check for OS type in custom fields if available
    const os = (customFields.os_type || customFields.operating_system || '')?.toLowerCase();
    
    if (os?.includes('windows')) return <WindowIcon />;
    if (os?.includes('linux')) return <AndroidIcon />;
    if (os?.includes('mac') || os?.includes('apple')) return <AppleIcon />;
    
    // Check name and type for server indicators
    if (name.includes('server') || type.includes('server')) return <StorageIcon />;
    if (name.includes('router') || type.includes('router')) return <RouterIcon />;
    if (name.includes('switch') || type.includes('switch')) return <RouterIcon />;
    if (name.includes('firewall') || type.includes('firewall')) return <SecurityIcon />;
    if (name.includes('database') || type.includes('database')) return <StorageIcon />;
    if (name.includes('web') || type.includes('web')) return <WebIcon />;
    
    // Default
    return <ComputerIcon />;
  };

  // Prepare the custom edge components
  const CustomEdge = useMemo(() => {
    // Define the component
    return ({ id, source, target, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style, data, markerEnd, markerStart }: EdgeProps) => {
      const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
      });

      // Try to extract process info from multiple sources
      const getEdgeProcess = (data: any) => {
        if (data?.process) return data.process;
        
        // Look in connections array if it exists
        if (data?.connections && Array.isArray(data.connections) && data.connections.length > 0) {
          for (const conn of data.connections) {
            if (conn.process) return conn.process;
          }
        }
        
        return '';
      };

      // Get process info
      const processInfo = getEdgeProcess(data);
      const sourceProcessInfo = data?.sourceProcess || processInfo;
      const targetProcessInfo = data?.targetProcess || processInfo;

      // Determine what to display on the edge
      let labelContent;
      
      if (filters.showProcessView) {
        if (data?.isBidirectional && sourceProcessInfo && targetProcessInfo) {
          // For bidirectional connections with process information on both sides
          // Determine the correct direction for each process (which one goes up/down)
          // based on the source/target node IDs
          const isSourceToTarget = data.sourceNode === source && data.targetNode === target;
          const sourceProcessName = isSourceToTarget ? sourceProcessInfo : targetProcessInfo;
          const targetProcessName = isSourceToTarget ? targetProcessInfo : sourceProcessInfo;
          
          labelContent = (
            <div style={{ 
              textAlign: 'center', 
              fontSize: '12px', 
              backgroundColor: 'rgba(255,240,230,0.95)', 
              padding: '2px 6px',
              borderRadius: '4px',
              border: '1px solid #ff9800',
              color: '#d84315',
              fontWeight: 'bold',
              display: 'flex',
              flexDirection: 'column',
              gap: '2px'
            }}>
              <span>↑ {sourceProcessName || 'No Process'}</span>
              <span>↓ {targetProcessName || 'No Process'}</span>
            </div>
          );
        } else if (processInfo) {
          // Single process
          labelContent = (
            <div style={{ 
              textAlign: 'center', 
              fontSize: '12px', 
              backgroundColor: 'rgba(255,240,230,0.95)', 
              padding: '2px 6px',
              borderRadius: '4px',
              border: '1px solid #ff9800',
              color: '#d84315',
              fontWeight: 'bold'
            }}>
              {processInfo}
            </div>
          );
        } else {
          // No process information
          labelContent = (
            <div style={{ 
              textAlign: 'center', 
              fontSize: '12px', 
              backgroundColor: 'rgba(255,255,255,0.95)', 
              padding: '2px 6px',
              borderRadius: '4px',
              border: '1px solid #e0e0e0',
              color: '#999',
              fontWeight: 'normal'
            }}>
              No Process Info
            </div>
          );
        }
      } else {
        // Default view (relationship type)
        labelContent = (
          <div style={{ 
            textAlign: 'center', 
            fontSize: '12px', 
            backgroundColor: 'rgba(255,255,255,0.95)', 
            padding: '2px 6px',
            borderRadius: '4px',
            border: '1px solid #e0e0e0',
            color: '#333',
            fontWeight: 'bold'
          }}>
            {data?.relationType || 'Connected to'}
          </div>
        );
      }

      return (
        <>
          <path
            id={id}
            style={style}
            className="react-flow__edge-path"
            d={edgePath}
            markerEnd={markerEnd}
            markerStart={markerStart}
          />
          
          {/* Add text label for relationship type or process */}
          <foreignObject
            width={140}
            height={data?.isBidirectional && filters.showProcessView ? 50 : 30}
            x={labelX - 70}
            y={labelY - (data?.isBidirectional && filters.showProcessView ? 25 : 15)}
            requiredExtensions="http://www.w3.org/1999/xhtml"
          >
            {labelContent}
          </foreignObject>
        </>
      );
    };
  }, [filters.showProcessView]); // Only re-create when process view changes

  // Create the group node component
  const GroupNode = useMemo(() => {
    return ({ data }: { data: any }) => {
      return (
        <div style={{ 
          height: '100%', 
          width: '100%', 
          position: 'relative',
          overflow: 'visible'
        }}>
          <div style={{
            position: 'absolute',
            top: '-30px',
            left: 0,
            backgroundColor: 'rgba(33, 150, 243, 0.1)',
            padding: '4px 10px',
            borderRadius: '4px 4px 0 0',
            borderTop: '2px solid #2196f3',
            borderLeft: '2px solid #2196f3',
            borderRight: '2px solid #2196f3',
            fontSize: '12px',
            fontWeight: 'bold'
          }}>
            {data.label}
          </div>
        </div>
      );
    };
  }, []);

  // Define edge and node types
  const edgeTypes = useMemo(() => ({
    custom: CustomEdge,
  }), [CustomEdge]);
  
  const nodeTypes = useMemo(() => ({
    group: GroupNode,
  }), [GroupNode]);

  const loadRelationships = useCallback(async () => {
    if (!ci._id) return;
    
    // Prevent concurrent loads
    if (loadingInProgress.current) {
      console.log('Load already in progress, skipping duplicate request');
      return;
    }
    
    loadingInProgress.current = true;

    try {
      setIsLoading(true);
      
      // Initialize the set of explored CIs with the focus CI
      const newExploredCIs = new Set<string>();
      if (ci._id) newExploredCIs.add(ci._id);
      setExploredCIs(newExploredCIs);
      
      // Initialize nodes and edges
      const graphNodes: Map<string, FlowNode> = new Map();
      
      // Add the focus CI
      if (ci._id) graphNodes.set(ci._id, {
        id: ci._id,
        data: { 
          label: (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginBottom: '5px' }}>
                {getDeviceIcon(ci, false)}
              </div>
              <div>{ci.name}</div>
            </div>
          ),
          type: ci.type?.name, 
          depth: 0 
        },
        position: { x: 250, y: 250 },
        type: 'default',
        style: getNodeStyle(ci.type?.name || '', 0),
        draggable: true,
        sourcePosition: Position.Right,
        targetPosition: Position.Left
      });

      // Update state with initial node
      setNodes(Array.from(graphNodes.values()));
      setEdges([]);
      setRelationships([]);

      // Get immediate relationships for the focus CI
      // (simulate a click on the node)
      setLoadingNodeId(ci._id);
      
      // The API returns an object with a relationships property
      const [outgoing, incoming] = await Promise.all([
        ciRelationshipService.getRelationships({ sourceId: ci._id || '' }),
        ciRelationshipService.getRelationships({ targetId: ci._id || '' })
      ]);
      
      // Filter relationships based on type preferences
      const connectedToType = relationshipTypes.find(t => t.name === 'Connected To');
      const runsOnType = relationshipTypes.find(t => t.name === 'Runs On');
      
      let filteredOutgoing = outgoing.relationships || [];
      let filteredIncoming = incoming.relationships || [];
      
      if (connectedToType && runsOnType) {
        if (!filters.showServerRelations) {
          filteredOutgoing = filteredOutgoing.filter((rel: CIRelationship) => 
            rel.type._id !== connectedToType._id);
          filteredIncoming = filteredIncoming.filter((rel: CIRelationship) => 
            rel.type._id !== connectedToType._id);
        }
        
        if (!filters.showAppRelations) {
          filteredOutgoing = filteredOutgoing.filter((rel: CIRelationship) => 
            rel.type._id !== runsOnType._id);
          filteredIncoming = filteredIncoming.filter((rel: CIRelationship) => 
            rel.type._id !== runsOnType._id);
        }
      }
      

      
      // Process the relationships and update the graph
      if (ci._id) {
        // First add the relationships to the current relationships array
        const outgoingRels = filteredOutgoing.map((rel: CIRelationship) => ({
          ...rel,
          direction: 'outgoing' as const,
          depth: 1
        }));
        
        const incomingRels = filteredIncoming.map((rel: CIRelationship) => ({
          ...rel,
          direction: 'incoming' as const,
          depth: 1
        }));
        
        // Update relationships state with the combined relationships
        setRelationships([...outgoingRels, ...incomingRels]);
        
        // Create and add edges immediately for the initial load
        await updateGraphWithRelationships(ci._id, filteredOutgoing, filteredIncoming);
      }
      
    } catch (error) {
      console.error('Error loading relationships:', error);
    } finally {
      setIsLoading(false);
      setLoadingNodeId(null);
      loadingInProgress.current = false;
    }
  }, [ci._id, ci.name, relationshipTypes, filters]);

  // Add a ref to track which nodes have been manually positioned
  const manuallyPositionedNodes = React.useRef(new Set<string>());
  
  // Helper function to arrange nodes in a horizontal layout
  const arrangeNodesHorizontally = (nodes: Map<string, FlowNode>, centerNodeId: string) => {
    const nodeArray = Array.from(nodes.values());
    
    // Skip if no nodes
    if (nodeArray.length <= 1) return;
    
    // Find center node and keep its position
    const centerNode = nodes.get(centerNodeId);
    const centerX = centerNode ? centerNode.position.x : 250;
    const centerY = centerNode ? centerNode.position.y : 250;
    
    // Group nodes by their depth level and direction (incoming or outgoing)
    const incomingNodesByDepth: Map<number, FlowNode[]> = new Map();
    const outgoingNodesByDepth: Map<number, FlowNode[]> = new Map();
    
    nodeArray.forEach(node => {
      if (node.id === centerNodeId) return;
      
      // Get depth from node data
      const depth = node.data.depth || 1;
      const direction = node.data.direction;
      
      if (direction === 'incoming') {
        if (!incomingNodesByDepth.has(depth)) {
          incomingNodesByDepth.set(depth, []);
        }
        incomingNodesByDepth.get(depth)!.push(node);
      } else {
        if (!outgoingNodesByDepth.has(depth)) {
          outgoingNodesByDepth.set(depth, []);
        }
        outgoingNodesByDepth.get(depth)!.push(node);
      }
    });
    
    // Position incoming nodes on the left
    incomingNodesByDepth.forEach((depthNodes, depth) => {
      const xOffset = -depth * 300; // Negative offset for left side
      
      // Calculate total height needed for this depth level
      const totalHeight = depthNodes.length * 150; // 150px per node
      const startY = centerY - (totalHeight / 2);
      
      depthNodes.forEach((node, i) => {
        // Skip nodes that have been manually positioned
        if (manuallyPositionedNodes.current.has(node.id)) return;
        
        node.position = {
          x: centerX + xOffset,
          y: startY + (i * 150) // Vertical spacing between nodes at same depth
        };
      });
    });
    
    // Position outgoing nodes on the right
    outgoingNodesByDepth.forEach((depthNodes, depth) => {
      const xOffset = depth * 300; // Positive offset for right side
      
      // Calculate total height needed for this depth level
      const totalHeight = depthNodes.length * 150; // 150px per node
      const startY = centerY - (totalHeight / 2);
      
      depthNodes.forEach((node, i) => {
        // Skip nodes that have been manually positioned
        if (manuallyPositionedNodes.current.has(node.id)) return;
        
        node.position = {
          x: centerX + xOffset,
          y: startY + (i * 150) // Vertical spacing between nodes at same depth
        };
      });
    });
  };

  const loadRelationshipTypes = useCallback(async () => {
    try {
      setLoadingTypes(true);
      await ciRelationshipService.ensureDefaultRelationshipTypes();
      const data = await ciRelationshipService.getRelationshipTypes();
      setRelationshipTypes(data);
    } catch (error) {
      console.error('Error loading relationship types:', error);
    } finally {
      setLoadingTypes(false);
    }
  }, []);

  const loadAvailableTargets = useCallback(async () => {
    try {
      const data = await ciService.getAllCIs();
      // Filter out the current CI and existing relationships
      const filteredTargets = data.filter((target: CI) => 
        target._id !== ci._id && 
        !relationships.some(rel => 
          (rel.direction === 'outgoing' && rel.target?._id === target._id) ||
          (rel.direction === 'incoming' && rel.source?._id === target._id)
        )
      );
      setAvailableTargets(filteredTargets);
    } catch (error) {
      console.error('Error loading available targets:', error);
    }
  }, [ci._id, relationships]);

  // Console log for debugging
  useEffect(() => {
    if (filters.showProcessView) {
      console.log('Process View Enabled');
      console.log('Edges with processes:', edges.filter(edge => edge.data?.process || edge.data?.sourceProcess || edge.data?.targetProcess).length);
      console.log('Total edges:', edges.length);
      
      // Log a sample edge with process info if available
      const sampleEdge = edges.find(edge => edge.data?.process || edge.data?.sourceProcess);
      if (sampleEdge) {
        console.log('Sample edge with process:', sampleEdge);
      }
    }
  }, [filters.showProcessView, edges]);
  
  // Watch for changes in process view and update edges
  useEffect(() => {
    console.log('Process view changed to:', filters.showProcessView);
    // Make sure we don't call this immediately during render
    if (edges.length > 0) {
      try {
        // Just update edges without causing component remount
        const processed = [...edges]; // Create a new array reference
        setEdges(processed);
      } catch (err) {
        console.error('Error updating edges on process view change:', err);
      }
    }
  }, [filters.showProcessView]);

  // Effect to load relationship types on component mount
  useEffect(() => {
    loadRelationshipTypes();
  }, [loadRelationshipTypes]);
  useEffect(() => {
    if (relationshipTypes.length > 0 && ci._id) {
      loadRelationships();
    }
  }, [loadRelationships, relationshipTypes, ci._id]);

  useEffect(() => {
    if (dialogOpen) {
      loadAvailableTargets();
    }
  }, [dialogOpen, loadAvailableTargets]);

  const handleCreateRelationship = async () => {
    if (!ci._id) {
      console.error('Cannot create relationship: Source CI ID is missing');
      return;
    }

    try {
      await ciRelationshipService.createRelationship({
        source: ci._id,
        target: formData.targetId,
        type: formData.typeId
      });
      
      setDialogOpen(false);
      setFormData({ targetId: '', typeId: '' });
      loadRelationships(); // Reload relationships
      onUpdate();
    } catch (error) {
      console.error('Error creating relationship:', error);
    }
  };

  const handleDeleteRelationship = async (relationshipId: string | undefined) => {
    if (!relationshipId) return;
    
    try {
      await ciRelationshipService.deleteRelationship(relationshipId);
      loadRelationships();
      onUpdate();
    } catch (error) {
      console.error('Error deleting relationship:', error);
    }
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault(); // Prevent default form submission behavior
    const { name, checked } = event.target;
    setFilters(prev => ({ ...prev, [name]: checked }));
  };

  // Add refs to track loading state and initial load
  const loadingInProgress = React.useRef(false);
  const initialLoadDone = React.useRef(false);

  // Update the graph with relationships for a specific CI
  const updateGraphWithRelationships = useCallback(async (ciId: string, outgoing: CIRelationship[], incoming: CIRelationship[]) => {
    // Get existing nodes and edges
    const graphNodes = new Map(nodes.map(node => [node.id, node]));
    const graphEdges = new Map(edges.map(edge => [edge.id, edge]));
    const unknownDevices = new Map();
    const newRelationships: RelationshipWithDirection[] = [];
    
    // Process outgoing relationships
    outgoing.forEach((rel: CIRelationship) => {
      const relationshipKey = getRelationshipKey(rel);
      const edgeId = `edge-${relationshipKey}`;
      
      // Skip if we already have this edge
      if (graphEdges.has(edgeId) && !rel.process) return;
      
      // Handle unknown devices with targetIdentifier
      const targetIdentifier = rel.targetIdentifier || rel.attributes?.targetIdentifier;
      const isUnknownDevice = (rel.targetType === 'UnknownDevice' || !rel.target?._id) && targetIdentifier;
      let targetId = rel.target?._id;
      
      if (isUnknownDevice && targetIdentifier) {
        // Create a unique ID for this unknown device if we haven't seen it before
        if (!unknownDevices.has(targetIdentifier)) {
          const unknownId = `unknown-${targetIdentifier}`;
          unknownDevices.set(targetIdentifier, unknownId);
          
          // Add unknown device node if it doesn't exist
          if (!graphNodes.has(unknownId)) {
            graphNodes.set(unknownId, {
              id: unknownId,
              data: { 
                label: (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ marginBottom: '5px' }}>
                      {getDeviceIcon(undefined, true)}
                    </div>
                    <div style={{ color: '#8c8c8c' }}>U-{targetIdentifier}</div>
                  </div>
                ),
                type: 'Unknown Device', 
                depth: 1,
                direction: 'outgoing'
              },
              position: { x: 0, y: 0 }, // Will be positioned by layout
              type: 'default',
              style: getNodeStyle('', 1, true),
              draggable: true,
              sourcePosition: Position.Right,
              targetPosition: Position.Left
            });
          }
        }
        
        targetId = unknownDevices.get(targetIdentifier);
      }
      
      if (!targetId) return;
      
      // Add target to nodes if not already there and it's a known CI
      if (!isUnknownDevice && rel.target?._id && !graphNodes.has(rel.target._id)) {
        graphNodes.set(rel.target._id, {
          id: rel.target._id,
          data: { 
            label: (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ marginBottom: '5px' }}>
                  {getDeviceIcon(rel.target, false)}
                </div>
                <div>{rel.target?.name}</div>
              </div>
            ),
            type: rel.target?.type?.name || 'Unknown', 
            depth: 1,
            direction: 'outgoing'
          },
          position: { x: 0, y: 0 }, // Will be positioned by layout
          type: 'default',
          style: getNodeStyle(rel.target?.type?.name || '', 1),
          draggable: true,
          sourcePosition: Position.Right,
          targetPosition: Position.Left
        });
      }
      
      // Add edge for this relationship
      graphEdges.set(edgeId, {
        id: edgeId,
        source: ciId,
        target: targetId,
        type: 'custom',
        style: getEdgeStyle(rel.type?.name || '', 1, isUnknownDevice),
        animated: false,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 20,
          height: 20,
          color: getEdgeStyle(rel.type?.name || '', 1, isUnknownDevice).stroke
        },
        data: {
          relationType: rel.type?.name || 'Unknown',
          category: rel.attributes?.category || 'Other',
          depth: 1,
          // Extract process from multiple possible locations
          process: rel.process || (rel.attributes?.process || ''),
          sourceProcess: rel.process || (rel.attributes?.process || ''),
          sourceNode: ciId,
          targetNode: targetId,
          // Also check connections array
          connections: rel.attributes?.connections || []
        }
      });
      
      // Add to relationships list
      newRelationships.push({
        ...rel,
        direction: 'outgoing' as const,
        depth: 1
      });
    });
    
    // Process incoming relationships
    incoming.forEach((rel: CIRelationship) => {
      const relationshipKey = getRelationshipKey(rel);
      const edgeId = `edge-${relationshipKey}`;
      
      // Skip if we already have this edge
      if (graphEdges.has(edgeId) && !rel.process) return;
      
      // Handle unknown devices with sourceIdentifier
      const sourceIdentifier = rel.sourceIdentifier || rel.attributes?.sourceIdentifier;
      const isUnknownDevice = (rel.sourceType === 'UnknownDevice' || !rel.source?._id) && sourceIdentifier;
      let sourceId = rel.source?._id;
      
      if (isUnknownDevice && sourceIdentifier) {
        // Create a unique ID for this unknown device if we haven't seen it before
        if (!unknownDevices.has(sourceIdentifier)) {
          const unknownId = `unknown-${sourceIdentifier}`;
          unknownDevices.set(sourceIdentifier, unknownId);
          
          // Add unknown device node if it doesn't exist
          if (!graphNodes.has(unknownId)) {
            graphNodes.set(unknownId, {
              id: unknownId,
              data: { 
                label: (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ marginBottom: '5px' }}>
                      {getDeviceIcon(undefined, true)}
                    </div>
                    <div style={{ color: '#8c8c8c' }}>U-{sourceIdentifier}</div>
                  </div>
                ),
                type: 'Unknown Device', 
                depth: 1,
                direction: 'incoming'
              },
              position: { x: 0, y: 0 }, // Will be positioned by layout
              type: 'default',
              style: getNodeStyle('', 1, true),
              draggable: true,
              sourcePosition: Position.Right,
              targetPosition: Position.Left
            });
          }
        }
        
        sourceId = unknownDevices.get(sourceIdentifier);
      }
      
      if (!sourceId) return;
      
      // Add source to nodes if not already there and it's a known CI
      if (!isUnknownDevice && rel.source?._id && !graphNodes.has(rel.source._id)) {
        graphNodes.set(rel.source._id, {
          id: rel.source._id,
          data: { 
            label: (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ marginBottom: '5px' }}>
                  {getDeviceIcon(rel.source, false)}
                </div>
                <div>{rel.source?.name}</div>
              </div>
            ),
            type: rel.source?.type?.name || 'Unknown', 
            depth: 1,
            direction: 'incoming'
          },
          position: { x: 0, y: 0 }, // Will be positioned by layout
          type: 'default',
          style: getNodeStyle(rel.source?.type?.name || '', 1),
          draggable: true,
          sourcePosition: Position.Right,
          targetPosition: Position.Left
        });
      }
      
      // Add edge for this relationship
      graphEdges.set(edgeId, {
        id: edgeId,
        source: sourceId,
        target: ciId,
        type: 'custom',
        style: getEdgeStyle(rel.type?.name || '', 1, isUnknownDevice),
        animated: false,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 20,
          height: 20,
          color: getEdgeStyle(rel.type?.name || '', 1, isUnknownDevice).stroke
        },
        data: {
          relationType: rel.type?.name || 'Unknown',
          category: rel.attributes?.category || 'Other',
          depth: 1,
          // Extract process from multiple possible locations
          process: rel.process || (rel.attributes?.process || ''),
          sourceProcess: rel.process || (rel.attributes?.process || ''),
          sourceNode: sourceId,
          targetNode: ciId,
          // Also check connections array
          connections: rel.attributes?.connections || []
        }
      });
      
      // Add to relationships list
      newRelationships.push({
        ...rel,
        direction: 'incoming' as const,
        depth: 1
      });
    });
    
    // Update node positions for better visualization
    arrangeNodesHorizontally(graphNodes, ciId);
    
    // Process the edges arrays
    const combinedEdges = Array.from(graphEdges.values());
    const consolidatedEdges = consolidateBidirectionalEdges(combinedEdges);
    
    // Update states - handle appending vs. replacing based on if we're doing initial load
    if (edges.length === 0) {
      // Initial load - replace relationships completely
      setNodes(Array.from(graphNodes.values()));
      setEdges(consolidatedEdges);
    } else {
      // Add to existing relationships
      setRelationships(prev => [...prev, ...newRelationships]);
      setNodes(Array.from(graphNodes.values()));
      setEdges(consolidatedEdges);
    }
  }, [nodes, edges, getDeviceIcon, getNodeStyle, getEdgeStyle, getRelationshipKey, arrangeNodesHorizontally]);

  // Extract loadInitialRelationships from useEffect
  const loadInitialRelationships = useCallback(async () => {
    if (!ci._id) return;
    
    // Prevent concurrent loads
    if (loadingInProgress.current) {
      console.log('Load already in progress, skipping duplicate request');
      return;
    }
    
    loadingInProgress.current = true;

    try {
      setIsLoading(true);
      
      // Initialize the set of explored CIs with the focus CI
      const newExploredCIs = new Set<string>();
      if (ci._id) newExploredCIs.add(ci._id);
      setExploredCIs(newExploredCIs);
      
      // Initialize nodes and edges
      const graphNodes: Map<string, FlowNode> = new Map();
      const graphEdges: Map<string, FlowEdge> = new Map();
      
      // Add the focus CI
      if (ci._id) graphNodes.set(ci._id, {
        id: ci._id,
        data: { 
          label: (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginBottom: '5px' }}>
                {getDeviceIcon(ci, false)}
              </div>
              <div>{ci.name}</div>
            </div>
          ),
          type: ci.type?.name, 
          depth: 0 
        },
        position: { x: 250, y: 250 },
        type: 'default',
        style: getNodeStyle(ci.type?.name || '', 0),
        draggable: true,
        sourcePosition: Position.Right,
        targetPosition: Position.Left
      });

      // Empty the relationships first
      setRelationships([]);

      // Get immediate relationships for the focus CI
      setLoadingNodeId(ci._id);
      
      // The API returns an object with a relationships property
      const [outgoing, incoming] = await Promise.all([
        ciRelationshipService.getRelationships({ sourceId: ci._id || '' }),
        ciRelationshipService.getRelationships({ targetId: ci._id || '' })
      ]);
      
      console.log('Initial load - outgoing relationships:', outgoing.relationships?.length || 0);
      console.log('Initial load - incoming relationships:', incoming.relationships?.length || 0);
      
      // Filter relationships based on type preferences
      const connectedToType = relationshipTypes.find(t => t.name === 'Connected To');
      const runsOnType = relationshipTypes.find(t => t.name === 'Runs On');
      
      let filteredOutgoing = outgoing.relationships || [];
      let filteredIncoming = incoming.relationships || [];
      
      if (connectedToType && runsOnType) {
        if (!filters.showServerRelations) {
          filteredOutgoing = filteredOutgoing.filter((rel: CIRelationship) => 
            rel.type._id !== connectedToType._id);
          filteredIncoming = filteredIncoming.filter((rel: CIRelationship) => 
            rel.type._id !== connectedToType._id);
        }
        
        if (!filters.showAppRelations) {
          filteredOutgoing = filteredOutgoing.filter((rel: CIRelationship) => 
            rel.type._id !== runsOnType._id);
          filteredIncoming = filteredIncoming.filter((rel: CIRelationship) => 
            rel.type._id !== runsOnType._id);
        }
      }
      
      // Process outgoing relationships first
      const initialRelationships: RelationshipWithDirection[] = [];
      
      // Process outgoing relationships
      filteredOutgoing.forEach((rel: CIRelationship) => {
        const targetId = rel.target?._id;
        if (!targetId) return;
        
        // Skip unknown devices for simplicity in initial load
        if (rel.targetType === 'UnknownDevice') return;
        
        // Add target to nodes if not already there
        if (rel.target && !graphNodes.has(targetId)) {
          graphNodes.set(targetId, {
            id: targetId,
            data: { 
              label: (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div style={{ marginBottom: '5px' }}>
                    {getDeviceIcon(rel.target, false)}
                  </div>
                  <div>{rel.target.name}</div>
                </div>
              ),
              type: rel.target.type?.name || 'Unknown', 
              depth: 1,
              direction: 'outgoing'
            },
            position: { x: 550, y: 250 + (graphNodes.size * 50) % 400 - 200 }, // Position to the right with vertical offset
            type: 'default',
            style: getNodeStyle(rel.target.type?.name || '', 1),
            draggable: true,
            sourcePosition: Position.Right,
            targetPosition: Position.Left
          });
        }
        
        // Create edge for this relationship
        const relationshipKey = getRelationshipKey(rel);
        const edgeId = `edge-${relationshipKey}`;
        
        graphEdges.set(edgeId, {
          id: edgeId,
          source: ci._id!,
          target: targetId,
          type: 'custom',
          style: getEdgeStyle(rel.type?.name || '', 1, false),
          animated: false,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: getEdgeStyle(rel.type?.name || '', 1, false).stroke
          },
          data: {
            relationType: rel.type?.name || 'Unknown',
            category: rel.attributes?.category || 'Other',
            depth: 1,
            process: rel.process
          }
        });
        
        // Add to relationships list
        initialRelationships.push({
          ...rel,
          direction: 'outgoing' as const,
          depth: 1
        });
      });
      
      // Process incoming relationships
      filteredIncoming.forEach((rel: CIRelationship) => {
        const sourceId = rel.source?._id;
        if (!sourceId) return;
        
        // Skip unknown devices for simplicity in initial load
        if (rel.sourceType === 'UnknownDevice') return;
        
        // Add source to nodes if not already there
        if (rel.source && !graphNodes.has(sourceId)) {
          graphNodes.set(sourceId, {
            id: sourceId,
            data: { 
              label: (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div style={{ marginBottom: '5px' }}>
                    {getDeviceIcon(rel.source, false)}
                  </div>
                  <div>{rel.source.name}</div>
                </div>
              ),
              type: rel.source.type?.name || 'Unknown', 
              depth: 1,
              direction: 'incoming'
            },
            position: { x: -50, y: 250 + (graphNodes.size * 50) % 400 - 200 }, // Position to the left with vertical offset
            type: 'default',
            style: getNodeStyle(rel.source.type?.name || '', 1),
            draggable: true,
            sourcePosition: Position.Right,
            targetPosition: Position.Left
          });
        }
        
        // Create edge for this relationship
        const relationshipKey = getRelationshipKey(rel);
        const edgeId = `edge-${relationshipKey}`;
        
        graphEdges.set(edgeId, {
          id: edgeId,
          source: sourceId,
          target: ci._id!,
          type: 'custom',
          style: getEdgeStyle(rel.type?.name || '', 1, false),
          animated: false,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: getEdgeStyle(rel.type?.name || '', 1, false).stroke
          },
          data: {
            relationType: rel.type?.name || 'Unknown',
            category: rel.attributes?.category || 'Other',
            depth: 1,
            process: rel.process
          }
        });
        
        // Add to relationships list
        initialRelationships.push({
          ...rel,
          direction: 'incoming' as const,
          depth: 1
        });
      });
      
      // Update the relationships state
      setRelationships(initialRelationships);
      
      // Apply automatic layout to position nodes
      arrangeNodesHorizontally(graphNodes, ci._id);
      
      // Convert edges to array and consolidate bidirectional edges
      const initialEdges = Array.from(graphEdges.values());
      const consolidatedEdges = consolidateBidirectionalEdges(initialEdges);
      
      // Update the nodes and edges state
      console.log(`Initial load - created ${graphNodes.size} nodes and ${consolidatedEdges.length} edges (consolidated from ${initialEdges.length})`);
      setNodes(Array.from(graphNodes.values()));
      setEdges(consolidatedEdges);
      
      initialLoadDone.current = true;
      
    } catch (error) {
      console.error('Error loading relationships:', error);
    } finally {
      setIsLoading(false);
      setLoadingNodeId(null);
      loadingInProgress.current = false;
    }
  }, [ci._id, ci.name, ci.type?.name, relationshipTypes, filters, getDeviceIcon, getNodeStyle, getEdgeStyle, getRelationshipKey, arrangeNodesHorizontally]);

  // Add a function to group nodes by process
  const groupNodesByProcess = useCallback((currentNodes: FlowNode[], currentEdges: FlowEdge[]) => {
    if (!filters.showProcessView) return currentNodes;
    
    console.log('Grouping nodes by process...');
    console.log('Total edges:', currentEdges.length);
    
    // Extract process information from edges
    const nodeProcessMap = new Map<string, string[]>();
    
    // First, collect all processes for each node
    currentEdges.forEach(edge => {
      const processName = getProcessName(edge.data);
      
      // Skip if no process found
      if (!processName) return;
      
      console.log(`Found edge with process: ${processName} (${edge.source} -> ${edge.target})`);
      
      // Add process to source node
      if (!nodeProcessMap.has(edge.source)) {
        nodeProcessMap.set(edge.source, []);
      }
      if (!nodeProcessMap.get(edge.source)!.includes(processName)) {
        nodeProcessMap.get(edge.source)!.push(processName);
      }
      
      // Add process to target node
      if (!nodeProcessMap.has(edge.target)) {
        nodeProcessMap.set(edge.target, []);
      }
      if (!nodeProcessMap.get(edge.target)!.includes(processName)) {
        nodeProcessMap.get(edge.target)!.push(processName);
      }
    });
    
    console.log('Nodes with process info:', nodeProcessMap.size);
    
    // Now build the process to nodes mapping
    const processList = new Map<string, FlowNode[]>();
    
    // Map of original node positions to maintain spatial relationships
    const nodePositions = new Map<string, {x: number, y: number}>();
    
    // First pass: identify all processes and collect their nodes
    currentNodes.forEach(node => {
      // Store original positions
      nodePositions.set(node.id, {...node.position});
      
      // Skip if it's already a group
      if (node.data.isProcessGroup) return;
      
      // Get processes associated with this node
      const nodeProcesses = nodeProcessMap.get(node.id) || [];
      
      // Skip if no processes
      if (nodeProcesses.length === 0) {
        console.log(`Node ${node.id} has no processes`);
        return;
      }
      
      // For now, just use the first process (we could handle multiple processes differently)
      const mainProcess = nodeProcesses[0];
      
      // Set the process info on the node for future reference
      node.data.process = mainProcess;
      
      console.log(`Adding node ${node.id} to process ${mainProcess}`);
      
      // Add to the process map
      if (!processList.has(mainProcess)) {
        processList.set(mainProcess, []);
      }
      processList.get(mainProcess)!.push(node);
    });
    
    // Log processes and their node counts
    processList.forEach((nodes, process) => {
      console.log(`Process '${process}' has ${nodes.length} nodes`);
    });
    
    // Filter for processes with multiple nodes
    const processGroups = Array.from(processList.entries())
      .filter(([_, nodes]) => nodes.length > 1);
      
    console.log(`Found ${processGroups.length} processes with multiple nodes`);
    
    // If no multi-node processes, return the original nodes
    if (processGroups.length === 0) return currentNodes;
    
    // Create new array for the transformed nodes
    const newNodes: FlowNode[] = [];
    
    // Add single nodes that don't belong to any process group first
    currentNodes.forEach(node => {
      if (!node.data.process || 
          !processList.has(node.data.process) || 
          processList.get(node.data.process)!.length <= 1) {
        newNodes.push(node);
      }
    });
    
    // Create group containers and add nodes to them
    processGroups.forEach(([process, groupNodes]) => {
      // Calculate group boundaries and average position
      let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
      let avgX = 0, avgY = 0;
      groupNodes.forEach(node => {
        const pos = nodePositions.get(node.id) || node.position;
        minX = Math.min(minX, pos.x);
        minY = Math.min(minY, pos.y);
        maxX = Math.max(maxX, pos.x + 150); // Assuming node width
        maxY = Math.max(maxY, pos.y + 100); // Assuming node height
        avgX += pos.x;
        avgY += pos.y;
      });
      
      avgX = avgX / groupNodes.length;
      avgY = avgY / groupNodes.length;
      
      // Determine if nodes are spread widely or concentrated
      const isSpread = (maxX - minX > 300) || (maxY - minY > 300);
      
      // For widely spread nodes, arrange them in a grid layout
      if (isSpread) {
        const gridSize = Math.ceil(Math.sqrt(groupNodes.length));
        const nodeWidth = 150;
        const nodeHeight = 100;
        const spacing = 50;
        
        // Calculate new group dimensions based on grid layout
        const gridWidth = gridSize * (nodeWidth + spacing);
        const gridHeight = Math.ceil(groupNodes.length / gridSize) * (nodeHeight + spacing);
        
        minX = avgX - (gridWidth / 2);
        minY = avgY - (gridHeight / 2);
        maxX = minX + gridWidth;
        maxY = minY + gridHeight;
      }
      
      // Add padding
      minX -= 40;
      minY -= 40;
      maxX += 40;
      maxY += 40;
      
      const width = maxX - minX;
      const height = maxY - minY + 30; // Extra height for the label
      
      // Create group container node
      const groupId = `process-group-${process.replace(/[^a-zA-Z0-9]/g, '-')}`;
      const groupNode: FlowNode = {
        id: groupId,
        type: 'group',
        data: {
          label: (
            <div style={{ padding: '8px' }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                Process: {process}
              </Typography>
            </div>
          ),
          isProcessGroup: true,
        },
        position: { x: minX, y: minY },
        style: {
          width: width,
          height: height,
          backgroundColor: 'rgba(240, 250, 255, 0.6)',
          border: '2px dashed #2196f3',
          borderRadius: '8px',
          padding: '10px',
        },
        draggable: true,
      };
      
      console.log(`Creating group node for process '${process}' with ${groupNodes.length} children`);
      newNodes.push(groupNode);
      
      // Add child nodes with adjusted positions relative to the group
      if (isSpread) {
        // Create a grid layout for the child nodes
        const gridSize = Math.ceil(Math.sqrt(groupNodes.length));
        const nodeWidth = 150;
        const nodeHeight = 100;
        const spacing = 50;
        
        groupNodes.forEach((node, index) => {
          const childNode = {...node};
          childNode.parentNode = groupId;
          childNode.extent = 'parent';
          
          // Calculate grid position
          const col = index % gridSize;
          const row = Math.floor(index / gridSize);
          
          // Position within grid
          childNode.position = {
            x: 40 + col * (nodeWidth + spacing),
            y: 40 + row * (nodeHeight + spacing)
          };
          
          newNodes.push(childNode);
        });
      } else {
        // Use original relative positions for compact layouts
        groupNodes.forEach(node => {
          const childNode = {...node};
          childNode.parentNode = groupId;
          childNode.extent = 'parent';
          
          // Adjust position to be relative to parent
          childNode.position = {
            x: nodePositions.get(node.id)!.x - minX,
            y: nodePositions.get(node.id)!.y - minY,
          };
          
          newNodes.push(childNode);
        });
      }
    });
    
    console.log(`Returning ${newNodes.length} nodes (${processGroups.length} groups)`);
    return newNodes;
  }, [filters.showProcessView]);

  // Update useEffect to use memoized functions
  useEffect(() => {
    if (!ci._id) return;
    if (initialLoadDone.current) return;
    loadInitialRelationships();
  }, [ci._id, loadInitialRelationships]);
  
  // Track whether process view is enabled for preventing re-renders
  const processNodesRef = useRef(filters.showProcessView);

  // Add effect for process view toggling to regroup nodes
  useEffect(() => {
    if (nodes.length === 0) return;
    
    // Only run when process view changes, not on every render
    const shouldGroup = filters.showProcessView;
    
    // Only process if the showProcessView flag has changed
    if (processNodesRef.current !== shouldGroup) {
      processNodesRef.current = shouldGroup;
      
      if (shouldGroup) {
        const groupedNodes = groupNodesByProcess(nodes, edges);
        setNodes(groupedNodes);
      } else {
        // When disabling process view, remove grouping
        const ungroupedNodes: FlowNode[] = [];
        
        // Loop through all nodes
        for (const node of nodes) {
          // Skip group container nodes
          if (node.data.isProcessGroup) continue;
          
          // Handle child nodes - remove parent references and restore absolute positions
          if (node.parentNode) {
            const parentNode = nodes.find(n => n.id === node.parentNode);
            if (parentNode) {
              const unGroupedNode: FlowNode = {
                ...node,
                parentNode: undefined,
                extent: undefined,
                position: {
                  x: parentNode.position.x + node.position.x,
                  y: parentNode.position.y + node.position.y
                }
              };
              ungroupedNodes.push(unGroupedNode);
            } else {
              // If parent not found, just add the node as is
              ungroupedNodes.push(node);
            }
          } else {
            // Not a child node, just add it
            ungroupedNodes.push(node);
          }
        }
        
        setNodes(ungroupedNodes);
      }
    }
  }, [filters.showProcessView, nodes, edges, groupNodesByProcess]);

  // Handle resetting the graph to the initial state
  const handleResetGraph = useCallback(() => {
    setNotification({
      message: 'Resetting graph to show only initial CI',
      type: 'info'
    });
    setTimeout(() => setNotification(null), 3000);
    
    // Reset exploration and reload just the initial CI
    initialLoadDone.current = false; // Reset the flag to allow reloading
    manuallyPositionedNodes.current.clear(); // Clear the manually positioned nodes
    loadRelationships();
  }, [loadRelationships]);

  // Handle node click to load and display relationships
  const handleNodeClick = useCallback(async (event: any, node: Node) => {
    const nodeId = node.id;
    // Skip if we've already loaded relationships for this node
    if (exploredCIs.has(nodeId)) return;
    
    // Prevent concurrent loads
    if (loadingInProgress.current) {
      console.log('Load already in progress, skipping duplicate request');
      return;
    }
    
    loadingInProgress.current = true;
    
    try {
      setLoadingNodeId(nodeId);
      
      // Get relationships for this node
      const [outgoing, incoming] = await Promise.all([
        ciRelationshipService.getRelationships({ sourceId: nodeId }),
        ciRelationshipService.getRelationships({ targetId: nodeId })
      ]);
      
      // Filter relationships based on current filters
      const filteredOutgoing = filterRelationships(outgoing.relationships || []);
      const filteredIncoming = filterRelationships(incoming.relationships || []);
      
      // Update graph with new relationships
      await updateGraphWithRelationships(nodeId, filteredOutgoing, filteredIncoming);
      
      // Add to explored CIs
      setExploredCIs(prev => {
        const newSet = new Set(prev);
        newSet.add(nodeId);
        return newSet;
      });
      
    } catch (error) {
      console.error('Error loading relationships for clicked CI:', error);
      setNotification({
        message: 'Error loading relationships',
        type: 'error'
      });
    } finally {
      setLoadingNodeId(null);
      loadingInProgress.current = false;
    }
  }, [exploredCIs, updateGraphWithRelationships, filterRelationships]);

  if (isLoading && relationships.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      {/* Notification snackbar */}
        <Snackbar 
          open={notification !== null} 
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000} 
          onClose={() => setNotification(null)}
        >
          <Alert 
            severity={notification?.type || 'info'} 
            onClose={() => setNotification(null)}
            sx={{ width: '100%' }}
          >
            {notification?.message}
          </Alert>
        </Snackbar>
        
        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
          <FormGroup row sx={{ ml: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.showServerRelations}
                  onChange={handleFilterChange}
                  name="showServerRelations"
                  size="small"
                />
              }
              label="Server Connections"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.showAppRelations}
                  onChange={handleFilterChange}
                  name="showAppRelations"
                  size="small"
                />
              }
              label="Application Deployments"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={filters.showProcessView}
                  onChange={(e) => {
                    e.stopPropagation();
                    // Use a function that doesn't trigger a form submission
                    const newValue = e.target.checked;
                    setTimeout(() => {
                      setFilters(prev => ({
                        ...prev,
                        showProcessView: newValue
                      }));
                    }, 0);
                    return false;
                  }}
                  name="showProcessView"
                  size="small"
                  onClick={(e) => e.stopPropagation()}
                />
              }
              label="Process View"
            />
            <Tooltip title="Process view shows application relationships with process names on connection lines and groups devices that share the same process">
              <IconButton size="small">
                <HelpIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </FormGroup>
          
          <Button
            variant="contained"
            onClick={() => setDialogOpen(true)}
            sx={{ mr: 1 }}
          >
            Add Relationship
          </Button>
          
          <Button
            variant="outlined"
            onClick={handleResetGraph}
            disabled={exploredCIs.size <= 1}
          >
            Reset Graph
          </Button>
        </Box>

        {/* Relationship graph */}
        <Box sx={{ height: 500, border: '1px solid #ccc', borderRadius: 1, position: 'relative' }}>
          <Box sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            zIndex: 5,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            padding: '5px 10px',
            borderRadius: 1,
            border: '1px solid #e0e0e0',
            fontSize: '0.75rem',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}>
            <Typography variant="caption">
              Showing relationships for <strong>{exploredCIs.size}</strong> CI{exploredCIs.size !== 1 ? 's' : ''}
            </Typography>
            {/* Add a hint that clicking on nodes will expand the graph */}
            <Tooltip title="Click on a node to explore its relationships">
              <IconButton size="small">
                <HelpIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <style>
            {`
              @keyframes pulse {
                0% { box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.7); }
                70% { box-shadow: 0 0 0 10px rgba(25, 118, 210, 0); }
                100% { box-shadow: 0 0 0 0 rgba(25, 118, 210, 0); }
              }
            `}
          </style>
          <ReactFlowProvider>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onNodeClick={handleNodeClick}
                edgeTypes={edgeTypes}
                nodeTypes={nodeTypes}
                fitView
                connectionLineType={ConnectionLineType.Bezier}
              >
              <Controls />
              <Background color="#f5f5f5" gap={16} />
              <MiniMap />
            </ReactFlow>
          </ReactFlowProvider>
        </Box>

        {/* Legend */}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
          <Typography variant="subtitle2" sx={{ mr: 2 }}>Legend:</Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 16, height: 16, bgcolor: '#e6f7ff', border: '1px solid #91d5ff', mr: 1 }} />
            <Typography variant="caption">Server</Typography>
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 16, height: 16, bgcolor: '#f6ffed', border: '1px solid #b7eb8f', mr: 1 }} />
            <Typography variant="caption">Software</Typography>
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 16, height: 16, bgcolor: '#fff7e6', border: '1px solid #ffd591', mr: 1 }} />
            <Typography variant="caption">Software Instance</Typography>
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
            <div style={{ width: 30, height: 3, backgroundColor: '#1890ff', marginRight: 4 }}></div>
            <Typography variant="caption">Connected To</Typography>
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 30, height: 0, borderTop: '3px dashed #52c41a', marginRight: 4 }}></div>
            <Typography variant="caption">Runs On</Typography>
          </Box>
          
          {filters.showProcessView && (
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <div style={{ 
                width: 30, 
                height: 20, 
                backgroundColor: 'rgba(240, 250, 255, 0.6)', 
                border: '2px dashed #2196f3',
                borderRadius: '4px',
                marginRight: 4 
              }}></div>
              <Typography variant="caption">Process Group</Typography>
            </Box>
          )}
        </Box>

          <TabbedRelationships 
            relationships={relationships} 
             onDelete={handleDeleteRelationship}
          />
            </Box>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Add Relationship</DialogTitle>
        <DialogContent>
          <Box sx={{ width: '400px', mt: 2 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Relationship Type</InputLabel>
              <Select
                value={formData.typeId}
                onChange={(e) => setFormData({ ...formData, typeId: e.target.value })}
                label="Relationship Type"
                disabled={loadingTypes}
              >
                {loadingTypes ? (
                  <MenuItem disabled>Loading relationship types...</MenuItem>
                ) : relationshipTypes.length === 0 ? (
                  <MenuItem disabled>No relationship types available</MenuItem>
                ) : (
                  relationshipTypes.map((type) => (
                    <MenuItem key={type._id} value={type._id}>
                      {type.name} - {type.sourceLabel || 'Source'} → {type.targetLabel || 'Target'}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Target CI</InputLabel>
              <Select
                value={formData.targetId}
                onChange={(e) => setFormData({ ...formData, targetId: e.target.value })}
                label="Target CI"
                disabled={isLoading}
              >
                {isLoading ? (
                  <MenuItem disabled>Loading target CIs...</MenuItem>
                ) : availableTargets.length === 0 ? (
                  <MenuItem disabled>No available target CIs</MenuItem>
                ) : (
                  availableTargets.map((target) => (
                    <MenuItem key={target._id} value={target._id}>
                      {target.name} ({target.type?.name})
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleCreateRelationship}
            variant="contained"
            disabled={!formData.targetId || !formData.typeId}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
import React from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
  Tooltip,
  Tabs,
  Tab
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { CI, CIRelationship } from '../../types/cmdb';

// Extend CIRelationship type to include direction
interface RelationshipWithDirection extends CIRelationship {
  direction: 'incoming' | 'outgoing';
  depth?: number;
}

interface TabbedRelationshipsProps {
  relationships: RelationshipWithDirection[];
  onDelete: (id: string | undefined) => void;
}

const TabbedRelationships: React.FC<TabbedRelationshipsProps> = ({ relationships, onDelete }) => {
  const [tabValue, setTabValue] = React.useState(0);
  
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  
  const outboundRelationships = relationships.filter(r => r.direction === 'outgoing');
  const inboundRelationships = relationships.filter(r => r.direction === 'incoming');
  
  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          aria-label="relationship tabs"
          variant="fullWidth"
        >
          <Tab label={`Outbound Relationships (${outboundRelationships.length})`} />
          <Tab label={`Inbound Relationships (${inboundRelationships.length})`} />
        </Tabs>
      </Box>

      {/* Outbound Relationships Table */}
      <div role="tabpanel" hidden={tabValue !== 0}>
        {tabValue === 0 && (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Target CI</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Relationship</TableCell>
                  <TableCell>Process</TableCell>
                  <TableCell>Application</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {outboundRelationships.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography variant="body2" color="text.secondary">
                        No outbound relationships found. Use the "Add Relationship" button to create one.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  outboundRelationships.map((relationship, index) => (
                    <TableRow key={relationship._id ? `${relationship._id}-${index}` : `relationship-${index}`}>
                      <TableCell>
                        {relationship.target?.name || 
                         (relationship.targetIdentifier 
                           ? `U-${relationship.targetIdentifier}` 
                           : (relationship.attributes?.targetIdentifier 
                              ? `U-${relationship.attributes.targetIdentifier}` 
                              : (relationship.attributes?.ip 
                                 ? `U-${relationship.attributes.ip}` 
                                 : (relationship.attributes?.connections && relationship.attributes.connections.length > 0 && relationship.attributes.connections[0].remoteAddress 
                                    ? `U-${relationship.attributes.connections[0].remoteAddress}` 
                                    : 'Unknown'))))}
                      </TableCell>
                      <TableCell>
                        {relationship.target?.type?.name || 'Unknown'}
                      </TableCell>
                      <TableCell>
                        {relationship.type?.sourceLabel || 'Unknown Relationship'}
                      </TableCell>
                      <TableCell>
                        {relationship.process ? (
                          <Chip 
                            label={relationship.process} 
                            size="small" 
                            variant="outlined" 
                            color="primary"
                          />
                        ) : (
                          <Typography variant="caption" color="text.secondary">
                            N/A
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {relationship.application ? (
                          <Tooltip title={
                            <>
                              <div><strong>Vendor:</strong> {relationship.application.vendor || 'Unknown'}</div>
                              <div><strong>Version:</strong> {relationship.application.version || 'Unknown'}</div>
                              {relationship.application.installLocation && 
                                <div><strong>Location:</strong> {relationship.application.installLocation}</div>
                              }
                            </>
                          }>
                            <Chip 
                              label={relationship.application.name || 'Unknown App'} 
                              size="small" 
                              variant="outlined" 
                              color="success"
                            />
                          </Tooltip>
                        ) : (
                          <Typography variant="caption" color="text.secondary">
                            N/A
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          size="small"
                          onClick={() => onDelete(relationship._id)}
                          color="error"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>

      {/* Inbound Relationships Table */}
      <div role="tabpanel" hidden={tabValue !== 1}>
        {tabValue === 1 && (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Source CI</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Relationship</TableCell>
                  <TableCell>Process</TableCell>
                  <TableCell>Application</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inboundRelationships.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography variant="body2" color="text.secondary">
                        No inbound relationships found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  inboundRelationships.map((relationship, index) => (
                    <TableRow key={relationship._id ? `${relationship._id}-${index}` : `relationship-${index}`}>
                      <TableCell>
                        {relationship.source?.name || 
                         (relationship.sourceIdentifier 
                           ? `U-${relationship.sourceIdentifier}` 
                           : (relationship.attributes?.sourceIdentifier 
                              ? `U-${relationship.attributes.sourceIdentifier}` 
                              : (relationship.attributes?.ip 
                                 ? `U-${relationship.attributes.ip}` 
                                 : (relationship.attributes?.connections && relationship.attributes.connections.length > 0 && relationship.attributes.connections[0].remoteAddress 
                                    ? `U-${relationship.attributes.connections[0].remoteAddress}` 
                                    : 'Unknown'))))}
                      </TableCell>
                      <TableCell>
                        {relationship.source?.type?.name || 'Unknown'}
                      </TableCell>
                      <TableCell>
                        {relationship.type?.targetLabel || 'Unknown Relationship'}
                      </TableCell>
                      <TableCell>
                        {relationship.process ? (
                          <Chip 
                            label={relationship.process} 
                            size="small" 
                            variant="outlined" 
                            color="primary"
                          />
                        ) : (
                          <Typography variant="caption" color="text.secondary">
                            N/A
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {relationship.application ? (
                          <Tooltip title={
                            <>
                              <div><strong>Vendor:</strong> {relationship.application.vendor || 'Unknown'}</div>
                              <div><strong>Version:</strong> {relationship.application.version || 'Unknown'}</div>
                              {relationship.application.installLocation && 
                                <div><strong>Location:</strong> {relationship.application.installLocation}</div>
                              }
                            </>
                          }>
                            <Chip 
                              label={relationship.application.name || 'Unknown App'} 
                              size="small" 
                              variant="outlined" 
                              color="success"
                            />
                          </Tooltip>
                        ) : (
                          <Typography variant="caption" color="text.secondary">
                            N/A
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          size="small"
                          onClick={() => onDelete(relationship._id)}
                          color="error"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </Box>
  );
};

export default TabbedRelationships;
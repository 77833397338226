import api from '../api/axios';

export interface Holiday {
  _id: string;
  name: string;
  description?: string;
  date: string;
  type: 'fixed' | 'floating';
  calendar: {
    _id: string;
    name: string;
  };
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  createdBy?: {
    _id: string;
    name: string;
    email: string;
  };
  updatedBy?: {
    _id: string;
    name: string;
    email: string;
  };
}

export interface CreateHolidayDto {
  name: string;
  description?: string;
  date: string;
  type?: 'fixed' | 'floating';
  calendar: { _id: string; name: string } | string;
  isActive?: boolean;
}

export interface UpdateHolidayDto extends CreateHolidayDto {
  _id: string;
}

const holidayService = {
  getAll: async (): Promise<Holiday[]> => {
    const response = await api.get('/holidays');
    return response.data;
  },

  getById: async (id: string): Promise<Holiday> => {
    const response = await api.get(`/holidays/${id}`);
    return response.data;
  },

  getByCalendar: async (calendarId: string): Promise<Holiday[]> => {
    const response = await api.get(`/holidays/calendar/${calendarId}`);
    return response.data;
  },

  create: async (holiday: CreateHolidayDto): Promise<Holiday> => {
    const response = await api.post('/holidays', holiday);
    return response.data;
  },

  update: async (id: string, holiday: Partial<UpdateHolidayDto>): Promise<Holiday> => {
    const response = await api.patch(`/holidays/${id}`, holiday);
    return response.data;
  },

  delete: async (id: string): Promise<void> => {
    await api.delete(`/holidays/${id}`);
  }
};

export default holidayService;

import axios from 'axios';
import { API_URL } from '../config';

// Add auth token to all requests
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const getKnowledgeBases = (search = '') => {
  return axios.get(`${API_URL}/knowledge-base${search ? `?search=${search}` : ''}`);
};

export const getKnowledgeBase = (id: string) => {
  return axios.get(`${API_URL}/knowledge-base/${id}`);
};

export const createKnowledgeBase = (data: any) => {
  return axios.post(`${API_URL}/knowledge-base`, data);
};

export const updateKnowledgeBase = (id: string, data: any) => {
  return axios.patch(`${API_URL}/knowledge-base/${id}`, data);
};

export const deleteKnowledgeBase = (id: string) => {
  return axios.delete(`${API_URL}/knowledge-base/${id}`);
};

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Editor from '@monaco-editor/react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Switch,
  CircularProgress,
  FormHelperText,
  Autocomplete,
  Typography,
  IconButton,
  Tabs,
  Tab,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Paper,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import api from '../../api/axios';
import { ConditionItem, OperatorType, Rule, RuleAction as RuleActionType } from '../../types/rule';
import ConditionBuilder from './ConditionBuilder';

// Define TabPanel component locally
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface FormValues {
  name: string;
  description: string;
  isActive: boolean;
  version: number;
  trigger: {
    objectType: string;
    event: string;
  };
  execution: {
    type: string;
    async: boolean;
  };
  action: {
    type: string;
    targetField?: string;
    sourceFields?: string[];
    aiConfig?: {
      prompt: {
        systemMessage?: string;
        template?: string;
      };
      outputField?: string;
    };
    manualConfig?: {
      transformation?: string;
    };
    scriptConfig?: {
      code?: string;
      inputFields?: string[];
      outputField?: string;
    };
  };
  actions: Array<{
    id: string;
    name: string;
    type: string;
    nextAction?: string | null;
    trueActionId?: string;
    falseActionId?: string;
    scriptConfig?: {
      code: string;
      inputFields?: string[];
    };
    aiConfig?: {
      prompt: {
        systemMessage?: string;
        template: string;
      };
    };
    condition?: {
      field: string;
      operator: string;
      value: string;
      trueAction?: string;
      falseAction?: string;
    };
  }>;
}

interface NewRuleProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  rule?: Rule | null;
}

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  trigger: yup.object().shape({
    objectType: yup.string().required('Object type is required'),
    event: yup.string().required('Event is required'),
  }),
  execution: yup.object().shape({
    type: yup.string().required('Execution type is required'),
    async: yup.boolean().required('Async is required'),
  }),
  action: yup.object().when(
    'version',
    ([version]) => {
      return version === 1
        ? yup.object().shape({
            type: yup.string().required('Action type is required'),
            targetField: yup.string().when(
              'type',
              ([type]) => {
                return type === 'update'
                  ? yup.string().required('Target field is required')
                  : yup.string();
              }
            ),
            sourceFields: yup.array().of(yup.string()).when(
              'type',
              ([type]) => {
                return type === 'update'
                  ? yup.array().min(1, 'At least one source field is required')
                  : yup.array();
              }
            ),
            aiConfig: yup.object().shape({
              prompt: yup.object().shape({
                template: yup.string().when(
                  ['action', 'type'], 
                  ([_, type]) => {
                    return type === 'ai'
                      ? yup.string().required('Template is required')
                      : yup.string();
                  }
                ),
                systemMessage: yup.string()
              }),
              outputField: yup.string().when(
                ['execution', 'type'], 
                ([_, type]) => {
                  return type === 'ai'
                    ? yup.string().required('Output field is required')
                    : yup.string();
                }
              ),
            }),
            manualConfig: yup.object().shape({
              transformation: yup.string().when(
                ['execution', 'type'], 
                ([_, type]) => {
                  return type === 'manual'
                    ? yup.string().required('Transformation is required')
                    : yup.string();
                }
              ),
            }),
            scriptConfig: yup.object().shape({
              code: yup.string().when(
                ['execution', 'type'], 
                ([_, type]) => {
                  return type === 'script'
                    ? yup.string().required('Code is required')
                    : yup.string();
                }
              ),
              inputFields: yup.array().of(yup.string()),
              outputField: yup.string().when(
                ['execution', 'type'], 
                ([_, type]) => {
                  return type === 'script'
                    ? yup.string().required('Output field is required')
                    : yup.string();
                }
              ),
            }),
          })
        : yup.object().optional();
    }
  ),
  actions: yup.array().when(
    'version',
    ([version]) => {
      return version === 2
        ? yup.array().of(
            yup.object().shape({
              id: yup.string().required('Action ID is required'),
              name: yup.string().required('Action name is required'),
              type: yup.string().required('Action type is required'),
              scriptConfig: yup.object().when(
                'type',
                ([type]) => {
                  return type === 'script'
                    ? yup.object().shape({
                        code: yup.string().required('Script code is required'),
                        inputFields: yup.array().of(yup.string()),
                      })
                    : yup.object().optional();
                }
              ),
              aiConfig: yup.object().when(
                'type',
                ([type]) => {
                  return type === 'ai'
                    ? yup.object().shape({
                        prompt: yup.object().shape({
                          template: yup.string().required('Template is required'),
                          systemMessage: yup.string(),
                        }),
                      })
                    : yup.object().optional();
                }
              ),
              condition: yup.object().when(
                'type',
                ([type]) => {
                  return type === 'condition'
                    ? yup.object().shape({
                        field: yup.string().required('Field is required'),
                        operator: yup.string().required('Operator is required'),
                        value: yup.string().required('Value is required'),
                      })
                    : yup.object().optional();
                }
              ),
            })
          )
        : yup.array().optional();
    }
  ),
});

const NewRule: React.FC<NewRuleProps> = ({ open, onClose, onSuccess, rule }) => {
  const [error, setError] = useState('');
  const [saving, setSaving] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [conditions, setConditions] = useState<ConditionItem[]>([]);
  const [loading, setLoading] = useState(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  // Helper function to convert from condition object to UI items
  function parseConditionObject(condition: any): ConditionItem[] {
    if (!condition) return [];
    
    const items: ConditionItem[] = [];
    
    // Check if it's a logical operator
    if (condition.$and || condition.$or) {
      const operator = condition.$and ? '$and' : '$or';
      const conditionArray = condition[operator];
      
      if (Array.isArray(conditionArray)) {
        conditionArray.forEach((cond: any) => {
          Object.entries(cond).forEach(([field, value]) => {
            if (field !== '$and' && field !== '$or' && field !== '$not') {
              if (typeof value === 'object' && value !== null) {
                const operatorKeys = Object.keys(value);
                if (operatorKeys.length > 0) {
                  const operator = operatorKeys[0] as OperatorType;
                  items.push({
                    field,
                    operator,
                    value: value[operator as keyof typeof value]
                  });
                }
              } else {
                // Simple equality
                items.push({
                  field,
                  operator: '$eq' as OperatorType,
                  value
                });
              }
            }
          });
        });
      }
    } else {
      // Handle direct field conditions
      Object.entries(condition).forEach(([field, value]) => {
        if (field !== '$and' && field !== '$or' && field !== '$not') {
          if (typeof value === 'object' && value !== null) {
            const operatorKeys = Object.keys(value);
            if (operatorKeys.length > 0) {
              const operator = operatorKeys[0] as OperatorType;
              items.push({
                field,
                operator,
                value: value[operator as keyof typeof value]
              });
            }
          } else {
            // Simple equality
            items.push({
              field,
              operator: '$eq' as OperatorType,
              value
            });
          }
        }
      });
    }
    
    return items;
  }

  // Helper function to convert from UI items to condition object
  function buildConditionObject(items: ConditionItem[]): any {
    if (items.length === 0) return null;
    
    if (items.length === 1) {
      const item = items[0];
      if (item.operator === '$eq') {
        return { [item.field]: item.value };
      } else {
        return { [item.field]: { [item.operator]: item.value } };
      }
    }
    
    // Multiple conditions are combined with $and by default
    return {
      $and: items.map(item => {
        if (item.operator === '$eq') {
          return { [item.field]: item.value };
        } else {
          return { [item.field]: { [item.operator]: item.value } };
        }
      })
    };
  }

  // Load rule data when editing
  useEffect(() => {
    if (rule) {
      // Parse condition object to UI items
      if (rule.condition) {
        setConditions(parseConditionObject(rule.condition));
      } else {
        setConditions([]);
      }
    } else {
      // Reset conditions when creating a new rule
      setConditions([]);
    }
  }, [rule]);

  const formik = useFormik<FormValues>({
    initialValues: rule ? {
      name: rule.name || '',
      description: rule.description || '',
      isActive: rule.isActive !== undefined ? rule.isActive : true,
      version: rule.version || 1,
      trigger: {
        objectType: rule.trigger?.objectType || 'request',
        event: rule.trigger?.event || 'insert',
      },
      execution: {
        type: rule.execution?.type || 'ai',
        async: rule.execution?.async || false,
      },
      action: {
        type: rule.action?.type || 'update',
        targetField: rule.action?.targetField || '',
        sourceFields: rule.action?.sourceFields || [],
        aiConfig: {
          prompt: {
            systemMessage: rule.action?.aiConfig?.prompt?.systemMessage || '',
            template: rule.action?.aiConfig?.prompt?.template || '',
          },
          outputField: (rule.action?.aiConfig as any)?.outputField || '',
        },
        manualConfig: {
          transformation: rule.action?.manualConfig?.transformation || '',
        },
        scriptConfig: {
          code: rule.action?.scriptConfig?.code || '',
          inputFields: rule.action?.scriptConfig?.inputFields || [],
          outputField: rule.action?.scriptConfig?.outputField || '',
        },
      },
      actions: rule.actions || [],
    } : {
      name: '',
      description: '',
      isActive: true,
      version: 1,
      trigger: {
        objectType: 'request',
        event: 'insert',
      },
      execution: {
        type: 'ai',
        async: false,
      },
      action: {
        type: 'update',
        targetField: '',
        sourceFields: [],
        aiConfig: {
          prompt: {
            template: '',
            systemMessage: '',
          },
          outputField: '',
        },
        manualConfig: {
          transformation: '',
        },
        scriptConfig: {
          code: '',
          inputFields: [],
          outputField: '',
        },
      },
      actions: [],
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      setSaving(true);
      try {
        const payload: any = {
          name: values.name,
          description: values.description,
          isActive: values.isActive,
          version: values.version,
          trigger: {
            objectType: values.trigger.objectType,
            event: values.trigger.event,
          },
          execution: {
            type: values.execution.type,
            async: typeof values.execution.async === 'string' 
              ? values.execution.async === 'true' 
              : values.execution.async
          }
        };

        if (values.version === 1) {
          payload.action = values.action;
          payload.condition = buildConditionObject(conditions);
        } else if (values.version === 2) {
          // Process actions to ensure proper structure
          const processedActions = values.actions.map(action => {
            const processedAction = { ...action };
            
            // For AI actions, update the template to use the correct action references
            if (action.type === 'ai' && action.aiConfig?.prompt?.template) {
              // Replace simplified action references like {{context.actions.action_1.output}}
              // with the full action ID references like {{context.actions.action_1742161016072.output}}
              const actionRefRegex = /\{\{context\.actions\.action_(\d+)\.output/g;
              const actionIds = values.actions.map(a => a.id);
              
              let template = action.aiConfig.prompt.template;
              let match;
              
              while ((match = actionRefRegex.exec(template)) !== null) {
                const actionIndex = parseInt(match[1]) - 1;
                if (actionIndex >= 0 && actionIndex < actionIds.length) {
                  const fullActionId = actionIds[actionIndex];
                  template = template.replace(
                    `{{context.actions.action_${match[1]}.output`,
                    `{{context.actions.${fullActionId}.output`
                  );
                }
              }
              
              // Safely update the template
              if (processedAction.aiConfig) {
                processedAction.aiConfig.prompt.template = template;
              }
            }
            
            // For condition actions, ensure the structure is correct
            if (action.type === 'condition' && action.condition) {
              processedAction.condition = {
                field: action.condition.field,
                operator: action.condition.operator,
                value: action.condition.value,
                trueAction: action.condition.trueAction || undefined,
                falseAction: action.condition.falseAction || undefined
              };
            }
            
            return processedAction;
          });
          
          // Add trigger.type which is required by the backend
          // Map event to the correct enum value expected by the backend
          const triggerTypeMap: Record<string, string> = {
            'create': 'insert',
            'update': 'update',
            'delete': 'delete',
            'both': 'both',
            // Default to 'both' if no mapping found
          };
          
          payload.trigger.type = triggerTypeMap[values.trigger.event] || 'both';
          payload.actions = processedActions;
          
          // For v2 rules, we don't need the execution field
          if (payload.execution) {
            delete payload.execution;
          }
        }
        
        console.log('Submitting rule:', payload);

        if (rule?._id) {
          // Update existing rule
          await api.patch(`/rules/${rule._id}`, payload);
          console.log('Rule updated');
        } else {
          // Create new rule
          await api.post('/rules', payload);
          console.log('New rule created');
        }

        onSuccess();
        onClose();
        resetForm();
        setConditions([]);
      } catch (err: any) {
        console.error('Error saving rule:', err.response?.data || err);
        setError(err.response?.data?.message || 'Failed to save rule');
      } finally {
        setSaving(false);
      }
    },
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">{rule ? 'Edit Rule' : 'Create New Rule'}</Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" gap={3}>
            {/* Basic Information */}
            <Box>
              <TextField
                fullWidth
                name="name"
                label="Rule Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />

              <Box mt={2}>
                <TextField
                  fullWidth
                  name="description"
                  label="Description"
                  multiline
                  rows={3}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Box>
            </Box>

            {/* Configuration Tabs */}
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs 
                  value={activeTab} 
                  onChange={handleTabChange} 
                  aria-label="rule configuration tabs"
                  variant="fullWidth"
                >
                  <Tab label="Trigger" {...a11yProps(0)} />
                  <Tab label="Execution" {...a11yProps(1)} />
                  <Tab label="Action" {...a11yProps(2)} />
                  <Tab label="Workflow" {...a11yProps(3)} />
                </Tabs>
              </Box>

              {/* Trigger Tab */}
              <TabPanel value={activeTab} index={0}>
                <Box display="flex" gap={2}>
                  <FormControl fullWidth>
                    <InputLabel>Object Type</InputLabel>
                    <Select
                      name="trigger.objectType"
                      value={formik.values.trigger.objectType}
                      onChange={formik.handleChange}
                      label="Object Type"
                      error={formik.touched.trigger?.objectType && Boolean(formik.errors.trigger?.objectType)}
                    >
                      <MenuItem value="request">Request</MenuItem>
                      <MenuItem value="task">Task</MenuItem>
                      <MenuItem value="workflow">Workflow</MenuItem>
                      <MenuItem value="rawScanData">Raw Scan Data</MenuItem>
                      <MenuItem value="ci">Configuration Item</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel>Event</InputLabel>
                    <Select
                      name="trigger.event"
                      value={formik.values.trigger.event}
                      onChange={formik.handleChange}
                      label="Event"
                      error={formik.touched.trigger?.event && Boolean(formik.errors.trigger?.event)}
                    >
                      <MenuItem value="insert">Insert</MenuItem>
                      <MenuItem value="update">Update</MenuItem>
                      <MenuItem value="both">Both</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                {/* Condition Builder */}
                <Box mt={3} p={2} border={1} borderColor="divider" borderRadius={1} bgcolor="background.paper">
                  <Typography variant="subtitle1" fontWeight="bold" mb={2}>
                    Condition Builder
                  </Typography>
                  <ConditionBuilder 
                    conditions={conditions} 
                    onChange={setConditions} 
                  />
                </Box>
              </TabPanel>

              {/* Execution Tab */}
              <TabPanel value={activeTab} index={1}>
                <Box display="flex" gap={2}>
                  <FormControl fullWidth>
                    <InputLabel>Execution Type</InputLabel>
                    <Select
                      name="execution.type"
                      value={formik.values.execution.type}
                      onChange={formik.handleChange}
                      label="Execution Type"
                      error={formik.touched.execution?.type && Boolean(formik.errors.execution?.type)}
                    >
                      <MenuItem value="ai">AI</MenuItem>
                      <MenuItem value="manual">Manual</MenuItem>
                      <MenuItem value="script">Script</MenuItem>
                      <MenuItem value="aiAndScript">AI + Script</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel>Async</InputLabel>
                    <Select
                      name="execution.async"
                      value={typeof formik.values.execution.async === 'boolean' ? formik.values.execution.async.toString() : formik.values.execution.async}
                      onChange={(e) => {
                        formik.setFieldValue('execution.async', e.target.value === 'true');
                      }}
                      label="Async"
                      error={formik.touched.execution?.async && Boolean(formik.errors.execution?.async)}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </TabPanel>

              {/* Action Tab */}
              <TabPanel value={activeTab} index={2}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <FormControl fullWidth>
                    <InputLabel>Action Type</InputLabel>
                    <Select
                      name="action.type"
                      value={formik.values.action.type}
                      onChange={formik.handleChange}
                      label="Action Type"
                      error={formik.touched.action?.type && Boolean(formik.errors.action?.type)}
                      disabled={formik.values.version === 2}
                    >
                      <MenuItem value="update">Update Field</MenuItem>
                      <MenuItem value="ai">AI</MenuItem>
                      <MenuItem value="script">Script</MenuItem>
                      <MenuItem value="aiAndScript">AI + Script</MenuItem>
                    </Select>
                    {formik.values.version === 2 && (
                      <FormHelperText>
                        Action configuration is disabled when using workflow-based rules. Please use the Workflow tab instead.
                      </FormHelperText>
                    )}
                  </FormControl>

                  {formik.values.version !== 2 && (
                    <>
                      {/* Target Field (for update action type) */}
                      {formik.values.action.type === 'update' && (
                        <TextField
                          fullWidth
                          name="action.targetField"
                          label="Target Field"
                          value={formik.values.action.targetField}
                          onChange={formik.handleChange}
                          error={formik.touched.action?.targetField && Boolean(formik.errors.action?.targetField)}
                          helperText={
                            (formik.touched.action?.targetField && formik.errors.action?.targetField) || 
                            "Field to update with the result"
                          }
                        />
                      )}

                      {/* Source Fields (for update action type) */}
                      {formik.values.action.type === 'update' && (
                        <Autocomplete
                          multiple
                          freeSolo
                          options={[]}
                          value={formik.values.action.sourceFields}
                          onChange={(_, newValue) => {
                            formik.setFieldValue('action.sourceFields', newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Source Fields"
                              error={formik.touched.action?.sourceFields && Boolean(formik.errors.action?.sourceFields)}
                              helperText={
                                (formik.touched.action?.sourceFields && formik.errors.action?.sourceFields) || 
                                "Fields to use as input for the transformation"
                              }
                            />
                          )}
                        />
                      )}

                      {/* AI Configuration */}
                      {['ai', 'aiAndScript'].includes(formik.values.action.type) && (
                        <>
                          <Typography variant="subtitle1">AI Configuration</Typography>
                          
                          <TextField
                            fullWidth
                            name="action.aiConfig.prompt.systemMessage"
                            label="System Message"
                            multiline
                            rows={3}
                            value={formik.values.action.aiConfig?.prompt.systemMessage}
                            onChange={formik.handleChange}
                          />
                          
                          <TextField
                            fullWidth
                            name="action.aiConfig.prompt.template"
                            label="Prompt Template"
                            multiline
                            rows={5}
                            value={formik.values.action.aiConfig?.prompt.template}
                            onChange={formik.handleChange}
                            error={
                              Boolean(formik.touched.action) && 
                              Boolean((formik.touched.action as any)?.aiConfig?.prompt?.template) && 
                              Boolean(formik.errors.action) &&
                              Boolean((formik.errors.action as any)?.aiConfig?.prompt?.template)
                            }
                            helperText={
                              (Boolean(formik.touched.action) && 
                              Boolean((formik.touched.action as any)?.aiConfig?.prompt?.template) && 
                              (formik.errors.action as any)?.aiConfig?.prompt?.template) || 
                              "Use {{fieldName}} to reference fields from the object"
                            }
                          />
                          
                          <TextField
                            fullWidth
                            name="action.aiConfig.outputField"
                            label="Output Field"
                            value={formik.values.action.aiConfig?.outputField}
                            onChange={formik.handleChange}
                            error={
                              Boolean(formik.touched.action) && 
                              Boolean((formik.touched.action as any)?.aiConfig?.outputField) && 
                              Boolean(formik.errors.action) &&
                              Boolean((formik.errors.action as any)?.aiConfig?.outputField)
                            }
                            helperText={
                              (Boolean(formik.touched.action) && 
                              Boolean((formik.touched.action as any)?.aiConfig?.outputField) && 
                              (formik.errors.action as any)?.aiConfig?.outputField) || 
                              "Field to store the AI output"
                            }
                          />
                        </>
                      )}

                      {/* Script Configuration */}
                      {['script', 'aiAndScript'].includes(formik.values.action.type) && (
                        <>
                          <Typography variant="subtitle1">Script Configuration</Typography>
                          
                          <TextField
                            fullWidth
                            name="action.scriptConfig.inputFields"
                            label="Input Fields"
                            value={formik.values.action.scriptConfig?.inputFields?.join(', ')}
                            onChange={(e) => {
                              const fields = e.target.value.split(',').map(f => f.trim()).filter(Boolean);
                              formik.setFieldValue('action.scriptConfig.inputFields', fields);
                            }}
                            helperText="Enter field names separated by commas (optional)"
                          />
                          
                          <Box sx={{ mb: 2 }}>
                            <Typography variant="caption" gutterBottom>
                              Script Code
                            </Typography>
                            <Box 
                              sx={{ 
                                border: 1,
                                borderColor: 'divider',
                                borderRadius: 1,
                                overflow: 'hidden'
                              }}
                            >
                              <Editor
                                height="200px"
                                defaultLanguage="javascript"
                                value={formik.values.action.scriptConfig?.code}
                                onChange={(value) => {
                                  formik.setFieldValue('action.scriptConfig.code', value);
                                }}
                                options={{
                                  minimap: { enabled: false },
                                  scrollBeyondLastLine: false,
                                  fontSize: 14,
                                  automaticLayout: true,
                                  wordWrap: 'on',
                                  lineNumbers: 'on',
                                  folding: true,
                                  tabSize: 2,
                                }}
                              />
                            </Box>
                          </Box>
                          
                          <TextField
                            fullWidth
                            name="action.scriptConfig.outputField"
                            label="Output Field"
                            value={formik.values.action.scriptConfig?.outputField}
                            onChange={formik.handleChange}
                            error={
                              Boolean(formik.touched.action) && 
                              Boolean((formik.touched.action as any)?.scriptConfig?.outputField) && 
                              Boolean(formik.errors.action) &&
                              Boolean((formik.errors.action as any)?.scriptConfig?.outputField)
                            }
                            helperText={
                              (Boolean(formik.touched.action) && 
                              Boolean((formik.touched.action as any)?.scriptConfig?.outputField) && 
                              (formik.errors.action as any)?.scriptConfig?.outputField) || 
                              "Field to store the script output"
                            }
                          />
                        </>
                      )}

                      {/* Manual Configuration */}
                      {formik.values.action.type === 'manual' && (
                        <>
                          <Typography variant="subtitle1">Manual Transformation</Typography>
                          
                          <TextField
                            fullWidth
                            name="action.manualConfig.transformation"
                            label="Transformation"
                            multiline
                            rows={3}
                            value={formik.values.action.manualConfig?.transformation}
                            onChange={formik.handleChange}
                            error={
                              Boolean(formik.touched.action) && 
                              Boolean((formik.touched.action as any)?.manualConfig?.transformation) && 
                              Boolean(formik.errors.action) &&
                              Boolean((formik.errors.action as any)?.manualConfig?.transformation)
                            }
                            helperText={
                              (Boolean(formik.touched.action) && 
                              Boolean((formik.touched.action as any)?.manualConfig?.transformation) && 
                              (formik.errors.action as any)?.manualConfig?.transformation) || 
                              "Enter a JavaScript expression. Use 'value' to reference the source field value (e.g., 'value.toUpperCase()')"
                            }
                          />
                        </>
                      )}
                    </>
                  )}
                </Box>
              </TabPanel>

              {/* Workflow Tab */}
              <TabPanel value={activeTab} index={3}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1" fontWeight="bold">
                      Workflow Actions (v2)
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.version === 2}
                          onChange={(e) => {
                            formik.setFieldValue('version', e.target.checked ? 2 : 1);
                          }}
                        />
                      }
                      label="Enable Workflow-Based Rules"
                    />
                  </Box>
                  
                  {formik.values.version === 2 ? (
                    <>
                      <Typography variant="body2" color="text.secondary">
                        Configure multiple actions that will be executed in sequence. Each action can access outputs from previous actions.
                      </Typography>
                      
                      {formik.values.actions.length === 0 ? (
                        <Box 
                          sx={{ 
                            p: 3, 
                            border: 1, 
                            borderColor: 'divider', 
                            borderRadius: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2
                          }}
                        >
                          <Typography variant="body1" color="text.secondary" align="center">
                            No actions added yet. Add your first action to start building the workflow.
                          </Typography>
                          <Button 
                            variant="contained" 
                            onClick={() => {
                              formik.setFieldValue('actions', [
                                ...formik.values.actions,
                                {
                                  id: `action_${Date.now()}`,
                                  name: 'New Action',
                                  type: 'script',
                                  scriptConfig: {
                                    code: '',
                                    inputFields: []
                                  }
                                }
                              ]);
                            }}
                          >
                            Add First Action
                          </Button>
                        </Box>
                      ) : (
                        <>
                          <Box sx={{ mb: 2 }}>
                            <Button 
                              variant="outlined" 
                              onClick={() => {
                                formik.setFieldValue('actions', [
                                  ...formik.values.actions,
                                  {
                                    id: `action_${Date.now()}`,
                                    name: 'New Action',
                                    type: 'script',
                                    scriptConfig: {
                                      code: '',
                                      inputFields: []
                                    }
                                  }
                                ]);
                              }}
                            >
                              Add Action
                            </Button>
                          </Box>
                          
                          {formik.values.actions.map((action, index) => (
                            <Box 
                              key={action.id}
                              sx={{ 
                                p: 2, 
                                border: 1, 
                                borderColor: 'divider', 
                                borderRadius: 1,
                                mb: 2
                              }}
                            >
                              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                <TextField
                                  label="Action Name"
                                  value={action.name}
                                  onChange={(e) => {
                                    const updatedActions = [...formik.values.actions];
                                    updatedActions[index].name = e.target.value;
                                    formik.setFieldValue('actions', updatedActions);
                                  }}
                                  size="small"
                                  sx={{ width: '60%' }}
                                />
                                <Box>
                                  <Button 
                                    color="error" 
                                    size="small"
                                    onClick={() => {
                                      const updatedActions = formik.values.actions.filter((_, i) => i !== index);
                                      formik.setFieldValue('actions', updatedActions);
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </Box>
                              </Box>
                              
                              <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel>Action Type</InputLabel>
                                <Select
                                  value={action.type}
                                  onChange={(e) => {
                                    const updatedActions = [...formik.values.actions];
                                    updatedActions[index].type = e.target.value;
                                    
                                    // Initialize config based on type
                                    if (e.target.value === 'script') {
                                      updatedActions[index].scriptConfig = {
                                        code: '',
                                        inputFields: []
                                      };
                                      delete updatedActions[index].aiConfig;
                                      delete updatedActions[index].condition;
                                      delete updatedActions[index].trueActionId;
                                      delete updatedActions[index].falseActionId;
                                    } else if (e.target.value === 'ai') {
                                      updatedActions[index].aiConfig = {
                                        prompt: {
                                          template: '',
                                          systemMessage: ''
                                        }
                                      };
                                      delete updatedActions[index].scriptConfig;
                                      delete updatedActions[index].condition;
                                      delete updatedActions[index].trueActionId;
                                      delete updatedActions[index].falseActionId;
                                    } else if (e.target.value === 'condition') {
                                      updatedActions[index].condition = {
                                        field: '',
                                        operator: '',
                                        value: ''
                                      };
                                      updatedActions[index].trueActionId = '';
                                      updatedActions[index].falseActionId = '';
                                      delete updatedActions[index].scriptConfig;
                                      delete updatedActions[index].aiConfig;
                                    }
                                    
                                    formik.setFieldValue('actions', updatedActions);
                                  }}
                                  label="Action Type"
                                >
                                  <MenuItem value="script">Script</MenuItem>
                                  <MenuItem value="ai">AI</MenuItem>
                                  <MenuItem value="condition">Condition</MenuItem>
                                </Select>
                              </FormControl>
                              
                              {/* Action-specific configuration */}
                              {action.type === 'script' && (
                                <Box sx={{ mb: 2 }}>
                                  <Typography variant="subtitle2" gutterBottom>
                                    Script Configuration
                                  </Typography>
                                  
                                  <Box mb={2}>
                                    <Typography variant="caption" gutterBottom>
                                      Input Fields
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      label="Input Fields"
                                      value={action.scriptConfig?.inputFields?.join(', ') || ''}
                                      onChange={(e) => {
                                        const fields = e.target.value
                                          .split(',')
                                          .map((f) => f.trim())
                                          .filter(Boolean);
                                        
                                        const updatedActions = [...formik.values.actions];
                                        if (!updatedActions[index].scriptConfig) {
                                          updatedActions[index].scriptConfig = { code: '', inputFields: [] };
                                        }
                                        updatedActions[index].scriptConfig!.inputFields = fields;
                                        formik.setFieldValue('actions', updatedActions);
                                      }}
                                      helperText="Enter field names separated by commas (optional)"
                                      size="small"
                                      sx={{ mb: 2 }}
                                    />
                                  </Box>
                                  
                                  <Box mb={2}>
                                    <Typography variant="caption" gutterBottom>
                                      Script Code
                                    </Typography>
                                    <Box 
                                      sx={{ 
                                        border: 1,
                                        borderColor: 'divider',
                                        borderRadius: 1,
                                        overflow: 'hidden'
                                      }}
                                    >
                                      <Editor
                                        height="200px"
                                        defaultLanguage="javascript"
                                        value={action.scriptConfig?.code || ''}
                                        onChange={(value) => {
                                          const updatedActions = [...formik.values.actions];
                                          if (!updatedActions[index].scriptConfig) {
                                            updatedActions[index].scriptConfig = { code: '', inputFields: [] };
                                          }
                                          updatedActions[index].scriptConfig!.code = value || '';
                                          formik.setFieldValue('actions', updatedActions);
                                        }}
                                        options={{
                                          minimap: { enabled: false },
                                          scrollBeyondLastLine: false,
                                          fontSize: 14,
                                          automaticLayout: true,
                                          wordWrap: 'on',
                                          lineNumbers: 'on',
                                          folding: true,
                                          tabSize: 2,
                                        }}
                                      />
                                    </Box>
                                    <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                                      Available variables: context.object, context.actions (outputs from previous actions)
                                    </Typography>
                                  </Box>
                                </Box>
                              )}
                              
                              {action.type === 'ai' && (
                                <Box sx={{ mb: 2 }}>
                                  <Typography variant="subtitle2" gutterBottom>
                                    AI Configuration
                                  </Typography>
                                  
                                  <TextField
                                    fullWidth
                                    label="System Message"
                                    multiline
                                    rows={3}
                                    value={action.aiConfig?.prompt.systemMessage || ''}
                                    onChange={(e) => {
                                      const updatedActions = [...formik.values.actions];
                                      if (!updatedActions[index].aiConfig) {
                                        updatedActions[index].aiConfig = {
                                          prompt: {
                                            template: '',
                                            systemMessage: ''
                                          }
                                        };
                                      }
                                      updatedActions[index].aiConfig!.prompt.systemMessage = e.target.value;
                                      formik.setFieldValue('actions', updatedActions);
                                    }}
                                    sx={{ mb: 2 }}
                                  />
                                  
                                  <TextField
                                    fullWidth
                                    label="Prompt Template"
                                    multiline
                                    rows={4}
                                    value={action.aiConfig?.prompt.template || ''}
                                    onChange={(e) => {
                                      const updatedActions = [...formik.values.actions];
                                      if (!updatedActions[index].aiConfig) {
                                        updatedActions[index].aiConfig = {
                                          prompt: {
                                            template: '',
                                            systemMessage: ''
                                          }
                                        };
                                      }
                                      updatedActions[index].aiConfig!.prompt.template = e.target.value;
                                      formik.setFieldValue('actions', updatedActions);
                                    }}
                                    sx={{ mb: 2 }}
                                  />
                                  
                                  <Typography variant="caption" color="text.secondary">
                                    You can use {`{{variable}}`} syntax to reference context variables
                                  </Typography>
                                </Box>
                              )}
                              
                              {action.type === 'condition' && (
                                <Box sx={{ mb: 2 }}>
                                  <Typography variant="subtitle2" gutterBottom>
                                    Condition Configuration
                                  </Typography>
                                  
                                  <Box mb={2}>
                                    <ConditionBuilder 
                                      conditions={parseConditionObject(action.condition || {})} 
                                      onChange={(newConditions) => {
                                        const updatedActions = [...formik.values.actions];
                                        updatedActions[index].condition = buildConditionObject(newConditions);
                                        formik.setFieldValue('actions', updatedActions);
                                      }}
                                    />
                                  </Box>
                                  
                                  <Box display="flex" gap={2}>
                                    <FormControl fullWidth>
                                      <InputLabel>If True, Go To</InputLabel>
                                      <Select
                                        value={action.trueActionId || ''}
                                        onChange={(e) => {
                                          const updatedActions = [...formik.values.actions];
                                          updatedActions[index].trueActionId = e.target.value;
                                          formik.setFieldValue('actions', updatedActions);
                                        }}
                                        label="If True, Go To"
                                      >
                                        <MenuItem value="">End Workflow</MenuItem>
                                        {formik.values.actions.map((a) => (
                                          a.id !== action.id && (
                                            <MenuItem key={`true-${a.id}`} value={a.id}>
                                              {a.name}
                                            </MenuItem>
                                          )
                                        ))}
                                      </Select>
                                    </FormControl>
                                    
                                    <FormControl fullWidth>
                                      <InputLabel>If False, Go To</InputLabel>
                                      <Select
                                        value={action.falseActionId || ''}
                                        onChange={(e) => {
                                          const updatedActions = [...formik.values.actions];
                                          updatedActions[index].falseActionId = e.target.value;
                                          formik.setFieldValue('actions', updatedActions);
                                        }}
                                        label="If False, Go To"
                                      >
                                        <MenuItem value="">End Workflow</MenuItem>
                                        {formik.values.actions.map((a) => (
                                          a.id !== action.id && (
                                            <MenuItem key={`false-${a.id}`} value={a.id}>
                                              {a.name}
                                            </MenuItem>
                                          )
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </Box>
                              )}
                              
                              {/* Next Action Selection (for non-condition actions) */}
                              {action.type !== 'condition' && (
                                <FormControl fullWidth>
                                  <InputLabel id={`next-action-label-${index}`}>Next Action</InputLabel>
                                  <Select
                                    labelId={`next-action-label-${index}`}
                                    value={action.nextAction || ''}
                                    onChange={(e) => {
                                      const updatedActions = [...formik.values.actions];
                                      updatedActions[index].nextAction = e.target.value === '' ? null : e.target.value;
                                      formik.setFieldValue('actions', updatedActions);
                                    }}
                                    label="Next Action"
                                    displayEmpty
                                  >
                                    <MenuItem value="" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                                      End Workflow
                                    </MenuItem>
                                    {formik.values.actions.map((a) => (
                                      a.id !== action.id && (
                                        <MenuItem key={`next-${a.id}`} value={a.id}>
                                          {a.name}
                                        </MenuItem>
                                      )
                                    ))}
                                  </Select>
                                </FormControl>
                              )}
                            </Box>
                          ))}
                        </>
                      )}
                    </>
                  ) : (
                    <Box 
                      sx={{ 
                        p: 3, 
                        border: 1, 
                        borderColor: 'divider', 
                        borderRadius: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2
                      }}
                    >
                      <Typography variant="body1" color="text.secondary" align="center">
                        Enable workflow-based rules to configure multiple sequential actions.
                      </Typography>
                      <Typography variant="body2" color="text.secondary" align="center">
                        This allows you to create complex rules with multiple steps and branching logic.
                      </Typography>
                    </Box>
                  )}
                </Box>
              </TabPanel>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" disabled={saving}>
            {saving ? <CircularProgress size={20} /> : 'Save'}
          </Button>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewRule;
import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import CodeEditor from '../../../components/common/CodeEditor';

// Define TypeScript interfaces
export interface Example {
  name: string;
  params: any[];
}

export interface Helper {
  id: string;
  name: string;
  category: string;
  description: string;
  code: string;
  examples: Example[];
  isBuiltIn: boolean;
}

interface HelperDetailProps {
  open: boolean;
  onClose: () => void;
  helper: Helper | null;
  onEdit: (helper: Helper) => void;
  onTest: (helper: Helper) => void;
}

const HelperDetail: React.FC<HelperDetailProps> = ({ 
  open, 
  onClose, 
  helper, 
  onEdit, 
  onTest 
}) => {
  if (!helper) return null;
  
  console.log("Helper code:", helper.code);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{helper.name}</Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box mb={2}>
          <Typography variant="subtitle1" gutterBottom>Category</Typography>
          <Chip label={helper.category} color="primary" />
        </Box>
        
        <Box mb={2}>
          <Typography variant="subtitle1" gutterBottom>Description</Typography>
          <Typography variant="body1">{helper.description}</Typography>
        </Box>
        
        <Box mb={2}>
          <Typography variant="subtitle1" gutterBottom>Code</Typography>
          <Paper variant="outlined" sx={{ p: 1 }}>
            <CodeEditor
              value={helper.code}
              language="javascript"
              height="200px"
              readOnly={true}
            />
          </Paper>
        </Box>
        
        <Box>
          <Typography variant="subtitle1" gutterBottom>Examples</Typography>
          {helper.examples.length > 0 ? (
            helper.examples.map((example, index) => (
              <Box key={index} mb={1}>
                <Typography variant="subtitle2">{example.name}</Typography>
                <Paper variant="outlined" sx={{ p: 1, bgcolor: '#f5f5f5' }}>
                  <code>
                    {helper.name}({example.params.map(p => JSON.stringify(p)).join(', ')})
                  </code>
                </Paper>
              </Box>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">No examples available</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onTest(helper)} color="primary">
          Test Helper
        </Button>
        {!helper.isBuiltIn && (
          <Button onClick={() => onEdit(helper)} color="primary">
            Edit Helper
          </Button>
        )}
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HelperDetail;
import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  Chip,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Event as EventIcon,
} from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import holidayService, { Holiday } from '../../../services/holidayService';
import CreateHolidayModal from './CreateHolidayModal';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface UpdateHolidayDto {
  _id: string;
  name: string;
  description?: string;
  date: string;
  calendar: string;
  type?: string;
  isActive?: boolean;
}

const HolidayDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [holiday, setHoliday] = useState<Holiday | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const loadHoliday = useCallback(async () => {
    if (!id) return;

    try {
      setLoading(true);
      const data = await holidayService.getById(id);
      setHoliday(data);
    } catch (err) {
      setError('Failed to load holiday');
      console.error('Error loading holiday:', err);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    loadHoliday();
  }, [id, loadHoliday]);

  const fetchHoliday = useCallback(async () => {
    await loadHoliday();
  }, [loadHoliday]);

  if (loading) {
    return <Typography>Loading holiday...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!holiday) {
    return <Typography>Holiday not found</Typography>;
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Box display="flex" alignItems="center" mb={3}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/settings/holidays')}
          sx={{ mr: 2 }}
        >
          Back to Holidays
        </Button>
        <Button
          onClick={() => setOpenModal(true)}
          sx={{ mr: 2 }}
        >
          Edit
        </Button>
        <Typography variant="h5">{holiday.name}</Typography>
      </Box>

      <Paper sx={{ padding: 3, borderRadius: 2, boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
        <Box display="flex" alignItems="center" mb={2}>
          <EventIcon sx={{ mr: 1 }} />
          <Chip
            label={format(new Date(holiday.date), 'MMMM dd, yyyy')}
            color="primary"
          />
        </Box>

        <Box mb={2}>
          <Typography variant="subtitle2" color="textSecondary">
            Calendar
          </Typography>
          <Typography>{holiday.calendar.name}</Typography>
        </Box>

        {holiday.description && (
          <Box mb={2}>
            <Typography variant="subtitle2" color="textSecondary">
              Description
            </Typography>
            <Typography>{holiday.description}</Typography>
          </Box>
        )}
      </Paper>
      <CreateHolidayModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        holiday={{
          name: holiday.name,
          description: holiday.description,
          date: holiday.date,
          type: holiday.type,
          calendar: { _id: holiday.calendar._id, name: holiday.calendar.name },
          isActive: holiday.isActive
        }}
        onHolidayCreated={() => {
          setOpenModal(false);
          fetchHoliday();
        }}
      />
    </Box>
  );
};

export default HolidayDetail;

// frontend/src/components/common/CodeEditor.tsx
import React, { useState } from 'react';
import Editor from '@monaco-editor/react';
import { Box, CircularProgress, Typography } from '@mui/material';

interface CodeEditorProps {
  value: string;
  onChange?: (value: string) => void;
  language?: string;
  height?: string;
  width?: string;
  readOnly?: boolean;
}

const CodeEditor: React.FC<CodeEditorProps> = ({
  value,
  onChange = () => {},
  language = 'javascript',
  height = '200px',
  width = '100%',
  readOnly = false
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState<string | null>(null);

  const handleEditorChange = (value: string | undefined) => {
    if (!readOnly) {
      onChange(value || '');
    }
  };

  const handleEditorDidMount = () => {
    setIsLoading(false);
  };

  const handleEditorWillMount = (monaco: any) => {
    // Optional: Configure Monaco editor here if needed
  };

  const handleEditorValidation = (markers: any[]) => {
    // Optional: Handle validation markers
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', height }}>
      {isLoading && (
        <Box 
          sx={{ 
            position: 'absolute', 
            top: 0, 
            left: 0, 
            right: 0, 
            bottom: 0, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            bgcolor: '#f5f5f5'
          }}
        >
          <CircularProgress size={24} />
        </Box>
      )}
      
      <Editor
        height={height}
        width={width}
        language={language}
        value={value}
        onChange={handleEditorChange}
        onMount={handleEditorDidMount}
        beforeMount={handleEditorWillMount}
        onValidate={handleEditorValidation}
        options={{
          minimap: { enabled: false },
          scrollBeyondLastLine: false,
          fontSize: 14,
          automaticLayout: true,
          readOnly: readOnly, // Fixed: Now using the prop value
          lineNumbers: 'on',
          renderLineHighlight: 'all',
          tabSize: 2
        }}
        loading={<CircularProgress size={24} />}
      />
      
      {loadError && (
        <Typography color="error" variant="body2" sx={{ mt: 1 }}>
          Error loading editor: {loadError}
        </Typography>
      )}
    </Box>
  );
};

export default CodeEditor;
import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Chip,
  IconButton,
  TablePagination,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { Add as AddIcon, Refresh as RefreshIcon, Settings as SettingsIcon } from '@mui/icons-material';
import api from '../../api/axios';
import NewRequest from './NewRequest';
import RequestDetail from './RequestDetail';

interface Request {
  _id: string;
  requestNumber: number;
  description: string;
  status: string;
  priority: string;
  subject?: string;
  type?: string;
  category?: string;
  requestor: {
    name: string;
    email: string;
  };
  team?: {
    _id: string;
    name: string;
    description: string;
  };
  createdAt: string;
  updatedAt: string;
}

interface ColumnConfig {
  id: string;
  label: string;
  visible: boolean;
}

const defaultColumns: ColumnConfig[] = [
  { id: 'requestNumber', label: 'Request Number', visible: true },
  { id: 'description', label: 'Description', visible: true },
  { id: 'status', label: 'Status', visible: true },
  { id: 'priority', label: 'Priority', visible: true },
  { id: 'subject', label: 'Subject', visible: true },
  { id: 'type', label: 'Type', visible: true },
  { id: 'category', label: 'Category', visible: true },
  { id: 'requestor', label: 'Requestor', visible: true },
  { id: 'team', label: 'Team', visible: true },
  { id: 'createdAt', label: 'Created At', visible: true },
];

const RequestList: React.FC = () => {
  const [requests, setRequests] = useState<Request[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isNewRequestOpen, setIsNewRequestOpen] = useState(false);
  const [selectedRequestId, setSelectedRequestId] = useState<string | null>(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [columns, setColumns] = useState<ColumnConfig[]>(defaultColumns);
  const [isColumnConfigOpen, setIsColumnConfigOpen] = useState(false);

  const fetchRequests = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get('/requests', {
        params: {
          page: page + 1,
          limit: rowsPerPage
        }
      });
      console.log('Fetched requests:', response.data); // Debug log
      if (response.data && response.data.requests) {
        setRequests(response.data.requests);
      } else if (Array.isArray(response.data)) {
        setRequests(response.data);
      } else {
        console.error('Unexpected response format:', response.data);
        setRequests([]);
      }
    } catch (error) {
      console.error('Error fetching requests:', error);
      setRequests([]);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    void fetchRequests();
  }, [fetchRequests]);

  useEffect(() => {
    const loadUserPreferences = async () => {
      try {
        const response = await api.get('/user-preferences/requestListColumns');
        if (response.data.preferences) {
          setColumns(response.data.preferences);
        }
      } catch (error) {
        console.error('Error loading user preferences:', error);
      }
    };
    loadUserPreferences();
  }, []);

  const handleColumnConfigSave = async () => {
    try {
      await api.post('/user-preferences/requestListColumns', {
        preferences: columns
      });
      setIsColumnConfigOpen(false);
    } catch (error) {
      console.error('Error saving column preferences:', error);
    }
  };

  const handleColumnToggle = (columnId: string) => {
    setColumns(prev => 
      prev.map(col => 
        col.id === columnId ? { ...col, visible: !col.visible } : col
      )
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getPriorityColor = (priority: string) => {
    switch (priority.toLowerCase()) {
      case 'low':
        return 'info';
      case 'medium':
        return 'warning';
      case 'high':
        return 'error';
      case 'critical':
        return 'error';
      default:
        return 'default';
    }
  };

  const handleRequestClick = (requestId: string) => {
    setSelectedRequestId(requestId);
    setIsDetailModalOpen(true);
  };

  const renderCellContent = (request: Request, columnId: string) => {
    switch (columnId) {
      case 'requestNumber':
        return request.requestNumber;
      case 'description':
        return request.description;
      case 'status':
        return <Chip label={request.status} color="primary" size="small" />;
      case 'priority':
        return <Chip label={request.priority} color={getPriorityColor(request.priority)} size="small" />;
      case 'subject':
        return request.subject;
      case 'type':
        return request.type;
      case 'category':
        return request.category;
      case 'requestor':
        return request.requestor.name;
      case 'team':
        return request.team?.name;
      case 'createdAt':
        return new Date(request.createdAt).toLocaleString();
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h5">Requests</Typography>
        <Box>
          <IconButton onClick={() => setIsColumnConfigOpen(true)} sx={{ mr: 1 }}>
            <SettingsIcon />
          </IconButton>
          <IconButton onClick={fetchRequests} sx={{ mr: 1 }}>
            <RefreshIcon />
          </IconButton>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setIsNewRequestOpen(true)}
          >
            New Request
          </Button>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns
                .filter(col => col.visible)
                .map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.filter(col => col.visible).length} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              requests.map((request) => (
                <TableRow
                  key={request._id}
                  onClick={() => handleRequestClick(request._id)}
                  sx={{ cursor: 'pointer' }}
                >
                  {columns
                    .filter(col => col.visible)
                    .map((column) => (
                      <TableCell key={column.id}>
                        {renderCellContent(request, column.id)}
                      </TableCell>
                    ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={-1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <NewRequest
        open={isNewRequestOpen}
        onClose={() => setIsNewRequestOpen(false)}
        onRequestCreated={fetchRequests}
      />

      <RequestDetail
        open={isDetailModalOpen}
        requestId={selectedRequestId}
        onClose={() => {
          setIsDetailModalOpen(false);
          setSelectedRequestId(null);
        }}
        onRequestUpdated={fetchRequests}
      />

      <Dialog open={isColumnConfigOpen} onClose={() => setIsColumnConfigOpen(false)}>
        <DialogTitle>Configure Columns</DialogTitle>
        <DialogContent>
          {columns.map((column) => (
            <MenuItem key={column.id} onClick={() => handleColumnToggle(column.id)}>
              <Checkbox checked={column.visible} />
              <ListItemText primary={column.label} />
            </MenuItem>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsColumnConfigOpen(false)}>Cancel</Button>
          <Button onClick={handleColumnConfigSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RequestList;

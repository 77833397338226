import api from '../api/axios';

const slaService = {
  getAll: async () => {
    const response = await api.get('/sla');
    return response.data;
  },

  getById: async (id: string) => {
    const response = await api.get(`/sla/${id}`);
    return response.data;
  },

  create: async (data: any) => {
    const response = await api.post('/sla', data);
    return response.data;
  },

  update: async (id: string, data: any) => {
    const response = await api.patch(`/sla/${id}`, data);
    return response.data;
  },

  delete: async (id: string) => {
    const response = await api.delete(`/sla/${id}`);
    return response.data;
  }
};

export default slaService;

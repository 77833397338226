import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  TablePagination,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@mui/material';
import { 
  Edit as EditIcon, 
  Visibility as ViewIcon,
  Delete as DeleteIcon 
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { TableMetadata } from '../../types/table';
import tableService from '../../services/tableService';
import TableDetailDialog from './TableDetailDialog';
import { useSnackbar } from 'notistack';

const AllTables: React.FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [tables, setTables] = useState<TableMetadata[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedTable, setSelectedTable] = useState<TableMetadata | null>(null);
  const [detailOpen, setDetailOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [tableToDelete, setTableToDelete] = useState<TableMetadata | null>(null);

  useEffect(() => {
    loadTables();
  }, []);

  const loadTables = async () => {
    setLoading(true);
    try {
      const response = await tableService.getTables();
      const tablesData = Array.isArray(response.data) ? response.data : response.data?.tables || [];
      setTables(tablesData);
    } catch (error) {
      console.error('Failed to load tables:', error);
      setTables([]);
    } finally {
      setLoading(false);
    }
  };

  const handleTableClick = (table: TableMetadata) => {
    setSelectedTable(table);
    setDetailOpen(true);
  };

  const handleEditClick = (event: React.MouseEvent, table: TableMetadata) => {
    event.stopPropagation();
    if (table.type === 'system') return;

    if (table.type === 'custom') {
      navigate(`/tables/custom/${table.name}`);
    } else {
      navigate(`/tables/${table.id || table._id}`);
    }
  };

  const handleDeleteClick = (event: React.MouseEvent, table: TableMetadata) => {
    event.stopPropagation();
    setTableToDelete(table);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!tableToDelete || !tableToDelete._id) {
      enqueueSnackbar('Invalid table selected', { variant: 'error' });
      return;
    }

    try {
      await tableService.deleteTable(tableToDelete._id);
      enqueueSnackbar('Table deleted successfully', { variant: 'success' });
      loadTables(); // Reload the tables list
      setDeleteDialogOpen(false);
      setTableToDelete(null);
    } catch (error) {
      console.error('Failed to delete table:', error);
      enqueueSnackbar('Failed to delete table', { variant: 'error' });
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDetailClose = () => {
    setDetailOpen(false);
    setSelectedTable(null);
  };

  const handleEdit = () => {
    if (!selectedTable) return;
    
    if (selectedTable.type === 'custom') {
      navigate(`/tables/custom/${selectedTable.name}`);
    } else {
      navigate(`/tables/${selectedTable.id || selectedTable._id}`);
    }
    setDetailOpen(false);
  };

  if (loading) {
    return <Box p={3}>Loading...</Box>;
  }

  const displayedTables = (tables || []).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Label</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Fields</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedTables.map((table) => (
              <TableRow
                key={table._id || table.id}
                hover
                onClick={() => handleTableClick(table)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>{table.name}</TableCell>
                <TableCell>{table.label}</TableCell>
                <TableCell>
                  <Chip
                    label={table.type}
                    color={
                      table.type === 'system' ? 'primary' :
                      table.type === 'cmdb_collection' ? 'info' :
                      'secondary'
                    }
                    size="small"
                  />
                </TableCell>
                <TableCell>{table.fields.length}</TableCell>
                <TableCell align="right">
                  <Tooltip title="View Details">
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTableClick(table);
                      }}
                    >
                      <ViewIcon />
                    </IconButton>
                  </Tooltip>
                  {table.type !== 'system' && (
                    <>
                      <Tooltip title="Edit Table">
                        <IconButton
                          size="small"
                          onClick={(e) => handleEditClick(e, table)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Table">
                        <IconButton
                          size="small"
                          onClick={(e) => handleDeleteClick(e, table)}
                          sx={{ color: 'error.main' }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={tables.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {selectedTable && (
        <TableDetailDialog
          open={detailOpen}
          onClose={handleDetailClose}
          table={selectedTable}
          onEdit={handleEdit}
        />
      )}

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Table</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the table "{tableToDelete?.label}"? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleDeleteConfirm} 
            color="error" 
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AllTables;

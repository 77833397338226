import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  IconButton,
  CircularProgress,
  Paper,
  Grid,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider, // Used in Box sx={{ borderBottom: 1, borderColor: 'divider' }}
} from '@mui/material';
import { Edit as EditIcon, Close as CloseIcon } from '@mui/icons-material';
import { NavigateFunction, Params, useNavigate, useParams } from 'react-router-dom';
import { ciService } from '../../services/ciService';
import { CIRelationships } from './CIRelationships';
import { CI } from '../../types/cmdb';
import { CIDetailCollections } from './CIDetailCollections';

interface CIDetailsProps {
  open: boolean;
  onClose: () => void;
  ciId?: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`ci-tabpanel-${index}`}
      aria-labelledby={`ci-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export const CIDetails: React.FC<CIDetailsProps> = ({ open, onClose, ciId }) => {
  const [ci, setCI] = useState<CI | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate: NavigateFunction = useNavigate();
  const params: Params = useParams();

  const loadCI = async (id: string) => {
    try {
      setLoading(true);
      const fetchedCI = await ciService.getCI(id);
      setCI(fetchedCI);
    } catch (error) {
      console.error('Error loading CI:', error);
      setCI(null); // Reset CI if loading fails
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const id = ciId || params.id;
    if (id && open) {
      loadCI(id);
    } else {
      setLoading(false); // Stop loading if no ID is available
    }
  }, [ciId, params.id, open]);

  const handleUpdate = () => {
    if (params.id) {
      loadCI(params.id);
    }
  };

  const handleEdit = () => {
    if (!ci || !ci._id) {
      console.error('No CI ID available for edit');
      return;
    }
    const editPath = `/cmdb/edit/${ci._id}`;
    navigate(editPath);
    onClose();
  };

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  // Helper function to get status color
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'active':
        return 'success';
      case 'maintenance':
        return 'warning';
      case 'inactive':
        return 'error';
      default:
        return 'default';
    }
  };

  // Group custom fields by category for better organization
  const groupCustomFields = () => {
    if (!ci || !ci.customFields) return {};
    
    const groups: Record<string, Record<string, any>> = {
      system: {},
      network: {},
      hardware: {},
      software: {},  // Add software category
      other: {}
    };
    
    // Check for hardware fields from both direct properties and customFields
    // Prefer direct properties over customFields
    
    // Serial number (try direct property first, then customFields)
    if (ci.serialNumber) {
      groups.system['serial_number'] = ci.serialNumber;
    } else if (ci.customFields.serialNumber) {
      groups.system['serial_number'] = ci.customFields.serialNumber;
    }
    
    // Asset Tag
    if (ci.assetTag) {
      groups.system['asset_tag'] = ci.assetTag;
    } else if (ci.customFields.assetTag) {
      groups.system['asset_tag'] = ci.customFields.assetTag;
    }
    
    // Operating System
    if (ci.operatingSystem) {
      groups.system['operating_system'] = ci.operatingSystem;
    } else if (ci.customFields.operatingSystem) {
      groups.system['operating_system'] = ci.customFields.operatingSystem;
    }
    
    // Domain
    if (ci.domain) {
      groups.system['domain'] = ci.domain;
    } else if (ci.customFields.domain) {
      groups.system['domain'] = ci.customFields.domain;
    }
    
    // IP Address
    if (ci.ipAddress) {
      groups.network['ip_address'] = ci.ipAddress;
    } else if (ci.customFields.ipAddress) {
      groups.network['ip_address'] = ci.customFields.ipAddress;
    }
    
    // Manufacturer
    if (ci.manufacturer) {
      groups.hardware['manufacturer'] = ci.manufacturer;
    } else if (ci.customFields.manufacturer) {
      groups.hardware['manufacturer'] = ci.customFields.manufacturer;
    }
    
    // Model
    if (ci.model) {
      groups.hardware['model'] = ci.model;
    } else if (ci.customFields.model) {
      groups.hardware['model'] = ci.customFields.model;
    }
    
    // Purchase Date
    if (ci.purchaseDate) {
      groups.hardware['purchase_date'] = new Date(ci.purchaseDate).toLocaleDateString();
    } else if (ci.customFields.purchaseDate) {
      groups.hardware['purchase_date'] = new Date(ci.customFields.purchaseDate).toLocaleDateString();
    }
    
    // Warranty Expiry Date
    if (ci.warrantyExpiryDate) {
      groups.hardware['warranty_expiry_date'] = new Date(ci.warrantyExpiryDate).toLocaleDateString();
    } else if (ci.customFields.warrantyExpiryDate) {
      groups.hardware['warranty_expiry_date'] = new Date(ci.customFields.warrantyExpiryDate).toLocaleDateString();
    }
    
    // CPU Cores
    if (ci.cpuCores !== undefined) {
      groups.hardware['cpu_cores'] = ci.cpuCores;
    } else if (ci.customFields.cpuCores !== undefined) {
      groups.hardware['cpu_cores'] = ci.customFields.cpuCores;
    }
    
    // RAM
    if (ci.ramGB !== undefined) {
      groups.hardware['ram_gb'] = ci.ramGB;
    } else if (ci.customFields.ramGB !== undefined) {
      groups.hardware['ram_gb'] = ci.customFields.ramGB;
    }
    
    // Disk
    if (ci.diskGB !== undefined) {
      groups.hardware['disk_gb'] = ci.diskGB;
    } else if (ci.customFields.diskGB !== undefined) {
      groups.hardware['disk_gb'] = ci.customFields.diskGB;
    }
    
    // Management IP
    if (ci.managementIP) {
      groups.network['management_ip'] = ci.managementIP;
    } else if (ci.customFields.managementIP) {
      groups.network['management_ip'] = ci.customFields.managementIP;
    }
    
    // Device Type
    if (ci.deviceType) {
      groups.network['device_type'] = ci.deviceType;
    } else if (ci.customFields.deviceType) {
      groups.network['device_type'] = ci.customFields.deviceType;
    }
    
    // Ports
    if (ci.ports !== undefined) {
      groups.network['ports'] = ci.ports;
    } else if (ci.customFields.ports !== undefined) {
      groups.network['ports'] = ci.customFields.ports;
    }
    
    // Management Protocols
    if (ci.managementProtocols && ci.managementProtocols.length > 0) {
      groups.network['management_protocols'] = ci.managementProtocols.join(', ');
    } else if (ci.customFields.managementProtocols) {
      groups.network['management_protocols'] = Array.isArray(ci.customFields.managementProtocols) 
        ? ci.customFields.managementProtocols.join(', ')
        : ci.customFields.managementProtocols;
    }
    
    // Software specific fields
    const softwareFields = [
      'vendor', 'website', 'lifecycle_status', 'category', 'owner',
      'supportContact', 'internalSupportTeam', 'strategicStatus', 'procurementCategory',
      'version', 'licenseType', 'endOfLife', 'installationDate', 'host'
    ];
    
    // Process other custom fields
    Object.entries(ci.customFields).forEach(([key, value]) => {
      // Skip fields already processed
      if (['serialNumber', 'assetTag', 'operatingSystem', 'domain', 'ipAddress', 
           'manufacturer', 'model', 'cpuCores', 'ramGB', 'diskGB', 
           'purchaseDate', 'warrantyExpiryDate',
           'managementIP', 'deviceType', 'ports', 'managementProtocols'].includes(key)) {
        return;
      }
      
      // Check if it's a software field
      if (softwareFields.includes(key)) {
        // Format date fields
        if (['endOfLife', 'installationDate'].includes(key) && value) {
          try {
            groups.software[key] = new Date(value).toLocaleDateString();
          } catch (e) {
            groups.software[key] = value;
          }
        } else {
          groups.software[key] = value;
        }
        return;
      }
      
      if (key.startsWith('os_')) {
        groups.system[key] = value;
      } else if (key.includes('address') || key.includes('ip') || key.includes('domain')) {
        groups.network[key] = value;
      } else if (key.includes('cpu') || key.includes('ram') || key.includes('disk')) {
        groups.hardware[key] = value;
      } else {
        groups.other[key] = value;
      }
    });
    
    return groups;
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      sx={{ '& .MuiDialog-paper': { height: '90vh' } }}
    >
      <DialogTitle sx={{ p: 0 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          p: 2, 
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}>
          <Typography variant="h6" component="h2">
            CI Details
          </Typography>
          <Box>
            <IconButton onClick={handleEdit} size="small" sx={{ mr: 1 }}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ p: 0, display: 'flex', flexDirection: 'column' }}>
        {/* Tabs */}
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTab-root': {
              textTransform: 'none',
              fontWeight: 'medium',
              fontSize: '0.875rem',
              px: 3,
            },
          }}
        >
          <Tab label="Details" />
          <Tab label="Relationships" />
          <Tab label="Collections" />
          <Tab label="AI Insights" />
        </Tabs>

        {/* Content */}
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 4,
              height: 300,
            }}
          >
            <CircularProgress size={40} />
          </Box>
        ) : (
          <Box sx={{ flex: 1, overflow: 'auto', p: 3 }}>
            <TabPanel value={activeTab} index={0}>
              {ci && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  {/* Debug Card (remove in production) */}
                  {process.env.NODE_ENV === 'development' && (
                    <Paper
                      elevation={0}
                      sx={{ 
                        p: 3, 
                        bgcolor: '#f9fafb', 
                        borderRadius: 2, 
                        border: '1px dashed grey',
                        mb: 3 
                      }}
                    >
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Debug Information (Development Only)
                      </Typography>
                      <Typography variant="body2" component="pre" sx={{ overflow: 'auto', maxHeight: '200px' }}>
                        {JSON.stringify({
                          id: ci._id,
                          name: ci.name,
                          type: ci.type?.name,
                          // Direct hardware fields
                          serialNumber: ci.serialNumber,
                          assetTag: ci.assetTag,
                          manufacturer: ci.manufacturer,
                          model: ci.model,
                          purchaseDate: ci.purchaseDate,
                          warrantyExpiryDate: ci.warrantyExpiryDate,
                          // All custom fields
                          customFields: ci.customFields,
                          // Grouped fields for debugging
                          groupedFields: groupCustomFields()
                        }, null, 2)}
                      </Typography>
                    </Paper>
                  )}

                  {/* Basic Information Card */}
                  <Paper
                    elevation={0}
                    sx={{ p: 2, bgcolor: '#f9fafb', borderRadius: 2 }}
                  >
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Basic Information
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="caption" color="text.secondary">
                          Name
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                          {ci.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="caption" color="text.secondary">
                          Type
                        </Typography>
                        <Typography variant="body1">
                          {ci.type?.name || 'Unknown'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="caption" color="text.secondary">
                          Status
                        </Typography>
                        <Box>
                          <Chip 
                            size="small"
                            label={ci.status}
                            color={getStatusColor(ci.status) as "success" | "warning" | "error" | "default"}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="caption" color="text.secondary">
                          Location
                        </Typography>
                        <Typography variant="body1">
                          {ci.location || 'N/A'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>

                  {/* Description Card */}
                  <Paper
                    elevation={0}
                    sx={{ p: 2, bgcolor: '#f9fafb', borderRadius: 2 }}
                  >
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Description
                    </Typography>
                    <Typography variant="body1">
                      {ci.description || 'No description provided'}
                    </Typography>
                  </Paper>

                  {/* System Information Card */}
                  {(groupCustomFields().system && Object.keys(groupCustomFields().system).length > 0) ? (
                    <Paper
                      elevation={0}
                      sx={{ p: 3, bgcolor: '#f9fafb', borderRadius: 2 }}
                    >
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        System Information
                      </Typography>
                      <Grid container spacing={2}>
                        {/* Display custom fields */}
                        {Object.entries(groupCustomFields().system).map(([key, value]) => (
                          <Grid item xs={12} sm={6} md={4} key={key}>
                            <Typography variant="caption" color="text.secondary">
                              {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                            </Typography>
                            <Typography variant="body1">
                              {value?.toString() || 'N/A'}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Paper>
                  ) : null}

                  {/* Network Information Card */}
                  {(groupCustomFields().network && Object.keys(groupCustomFields().network).length > 0) ? (
                    <Paper
                      elevation={0}
                      sx={{ p: 3, bgcolor: '#f9fafb', borderRadius: 2 }}
                    >
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Network Information
                      </Typography>
                      <Grid container spacing={3}>
                        {/* Display custom fields */}
                        {Object.entries(groupCustomFields().network).map(([key, value]) => (
                          <Grid item xs={12} sm={6} md={4} key={key}>
                            <Typography variant="caption" color="text.secondary">
                              {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                            </Typography>
                            <Typography variant="body1">
                              {value?.toString() || 'N/A'}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Paper>
                  ) : null}

                  {/* Hardware Information Card */}
                  {(groupCustomFields().hardware && Object.keys(groupCustomFields().hardware).length > 0) ? (
                    <Paper
                      elevation={0}
                      sx={{ p: 3, bgcolor: '#f9fafb', borderRadius: 2 }}
                    >
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Hardware Specifications
                      </Typography>
                      <Grid container spacing={3}>
                        {/* Display custom fields */}
                        {Object.entries(groupCustomFields().hardware).map(([key, value]) => (
                          <Grid item xs={12} sm={6} md={4} key={key}>
                            <Typography variant="caption" color="text.secondary">
                              {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                            </Typography>
                            <Typography variant="body1">
                              {value?.toString() || 'N/A'}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Paper>
                  ) : null}

                  {/* Software Information Card */}
                  {(groupCustomFields().software && Object.keys(groupCustomFields().software).length > 0) ? (
                    <Paper
                      elevation={0}
                      sx={{ p: 3, bgcolor: '#f9fafb', borderRadius: 2 }}
                    >
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Software Information
                      </Typography>
                      <Grid container spacing={3}>
                        {/* Display software fields */}
                        {Object.entries(groupCustomFields().software).map(([key, value]) => (
                          <Grid item xs={12} sm={6} md={4} key={key}>
                            <Typography variant="caption" color="text.secondary">
                              {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                            </Typography>
                            <Typography variant="body1">
                              {value?.toString() || 'N/A'}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Paper>
                  ) : null}

                  {/* Other Custom Fields Card */}
                  {groupCustomFields().other && Object.keys(groupCustomFields().other).length > 0 && (
                    <Paper
                      elevation={0}
                      sx={{ p: 3, bgcolor: '#f9fafb', borderRadius: 2 }}
                    >
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Additional Information
                      </Typography>
                      <Grid container spacing={3}>
                        {Object.entries(groupCustomFields().other).map(([key, value]) => (
                          <Grid item xs={12} sm={6} md={4} key={key}>
                            <Typography variant="caption" color="text.secondary">
                              {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                            </Typography>
                            <Typography variant="body1">
                              {value?.toString() || 'N/A'}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Paper>
                  )}
                </Box>
              )}
            </TabPanel>

            <TabPanel value={activeTab} index={1}>
              {ci && <CIRelationships ci={ci} onUpdate={handleUpdate} />}
            </TabPanel>

            <TabPanel value={activeTab} index={2}>
              {ci && <CIDetailCollections ci={ci} onUpdate={handleUpdate} />}
            </TabPanel>

            <TabPanel value={activeTab} index={3}>
              {ci && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    AI-Generated Insights
                  </Typography>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 3,
                      bgcolor: '#f9fafb',
                      borderRadius: 2,
                      maxHeight: '500px',
                      overflow: 'auto',
                    }}
                  >
                    {ci.aiInsights ? (
                      <Typography
                        variant="body1"
                        component="div"
                        sx={{
                          whiteSpace: 'pre-wrap',
                          '& p': { mb: 1.5 },
                          '& ul, & ol': { pl: 2, mb: 1.5 },
                          '& li': { mb: 0.5 },
                          '& h1, & h2, & h3, & h4, & h5, & h6': { mt: 2, mb: 1 },
                        }}
                      >
                        {ci.aiInsights.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                        No AI insights available for this CI yet.
                      </Typography>
                    )}
                  </Paper>
                </Box>
              )}
            </TabPanel>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
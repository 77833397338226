import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Chip,
  IconButton,
  TextField,
  InputAdornment,
  Button,
  Tooltip,
  CircularProgress
} from '@mui/material';
import {
  Refresh as RefreshIcon,
  Search as SearchIcon,
  Visibility as ViewIcon,
  Delete as DeleteIcon,
  PlayArrow as ProcessIcon
} from '@mui/icons-material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { discoveryService } from '../../services/discoveryService';
import { RawScanData, PaginatedResponse } from '../../types/discovery';

const ScanDataList: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [scanData, setScanData] = useState<RawScanData[]>([]);
  const [pagination, setPagination] = useState({
    total: 0,
    page: 0,
    limit: 10,
    pages: 0
  });
  const [searchParams, setSearchParams] = useState({
    ipAddress: '',
    hostname: '',
    status: ''
  });

  const loadScanData = async () => {
    setLoading(true);
    try {
      const response = await discoveryService.getAllScanData({
        page: pagination.page + 1,
        limit: pagination.limit,
        ...searchParams
      });
      
      const data = response as PaginatedResponse<RawScanData>;
      setScanData(data.data);
      setPagination({
        total: data.pagination.total,
        page: data.pagination.page - 1,
        limit: data.pagination.limit,
        pages: data.pagination.pages
      });
    } catch (error) {
      console.error('Failed to load scan data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadScanData();
  }, [pagination.page, pagination.limit]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPagination({ ...pagination, page: newPage });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({
      ...pagination,
      limit: parseInt(event.target.value, 10),
      page: 0
    });
  };

  const handleSearch = (event: React.FormEvent) => {
    event.preventDefault();
    setPagination({ ...pagination, page: 0 });
    loadScanData();
  };

  const handleViewDetails = (id: string) => {
    navigate(`/cmdb/discovery/scan-data/${id}`);
  };

  const handleProcessScan = async (id: string) => {
    try {
      await discoveryService.updateScanDataStatus(id, 'processing');
      // In a real implementation, you would trigger the processing job here
      // For now, we'll just update the status
      loadScanData();
    } catch (error) {
      console.error('Failed to process scan data:', error);
    }
  };

  const handleDeleteScan = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this scan data?')) {
      try {
        await discoveryService.deleteScanData(id);
        loadScanData();
      } catch (error) {
        console.error('Failed to delete scan data:', error);
      }
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'processing':
        return 'info';
      case 'processed':
        return 'success';
      case 'failed':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h5" component="h1" gutterBottom>
          Network Discovery Scan Data
        </Typography>
        <Button
          variant="contained"
          startIcon={<RefreshIcon />}
          onClick={() => loadScanData()}
        >
          Refresh
        </Button>
      </Box>

      <Paper component="form" onSubmit={handleSearch} sx={{ p: 2, mb: 3 }}>
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <TextField
            label="IP Address"
            variant="outlined"
            size="small"
            value={searchParams.ipAddress}
            onChange={(e) => setSearchParams({ ...searchParams, ipAddress: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Hostname"
            variant="outlined"
            size="small"
            value={searchParams.hostname}
            onChange={(e) => setSearchParams({ ...searchParams, hostname: e.target.value })}
          />
          <TextField
            select
            label="Status"
            variant="outlined"
            size="small"
            value={searchParams.status}
            onChange={(e) => setSearchParams({ ...searchParams, status: e.target.value })}
            SelectProps={{
              native: true,
            }}
          >
            <option value="">All</option>
            <option value="pending">Pending</option>
            <option value="processing">Processing</option>
            <option value="processed">Processed</option>
            <option value="failed">Failed</option>
          </TextField>
          <Button type="submit" variant="contained">
            Search
          </Button>
        </Box>
      </Paper>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>IP Address</TableCell>
              <TableCell>Hostname</TableCell>
              <TableCell>Scan ID</TableCell>
              <TableCell>Scan Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress size={24} sx={{ my: 2 }} />
                </TableCell>
              </TableRow>
            ) : scanData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No scan data found
                </TableCell>
              </TableRow>
            ) : (
              scanData.map((item) => (
                <TableRow key={item._id}>
                  <TableCell>{item.ipAddress}</TableCell>
                  <TableCell>{item.hostname || 'N/A'}</TableCell>
                  <TableCell>{item.scanId}</TableCell>
                  <TableCell>
                    {format(new Date(item.scanTimestamp), 'yyyy-MM-dd HH:mm:ss')}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={item.status}
                      color={getStatusColor(item.status) as any}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Tooltip title="View Details">
                        <IconButton
                          size="small"
                          onClick={() => handleViewDetails(item._id)}
                        >
                          <ViewIcon />
                        </IconButton>
                      </Tooltip>
                      {item.status === 'pending' && (
                        <Tooltip title="Process">
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => handleProcessScan(item._id)}
                          >
                            <ProcessIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Delete">
                        <IconButton
                          size="small"
                          color="error"
                          onClick={() => handleDeleteScan(item._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={pagination.total}
          rowsPerPage={pagination.limit}
          page={pagination.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

export default ScanDataList;
import axios from 'axios';

// Determine the API base URL
let apiBaseUrl = '/api';

// First try to get it from the runtime config
if (typeof window !== 'undefined' && (window as any).RUNTIME_CONFIG?.API_BASE_URL) {
  apiBaseUrl = (window as any).RUNTIME_CONFIG.API_BASE_URL;
  console.log('Using API base URL from runtime config:', apiBaseUrl);
} 
// Then try environment variables
else if (process.env.REACT_APP_API_BASE_URL) {
  apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  console.log('Using API base URL from environment variable:', apiBaseUrl);
} else {
  console.log('Using default API base URL:', apiBaseUrl);
}

// In development, use localhost if we're running locally
if (process.env.NODE_ENV === 'development' && window.location.hostname === 'localhost') {
  // Check if we're already using localhost
  if (!apiBaseUrl.includes('localhost')) {
    apiBaseUrl = 'http://localhost:8080/api';
    console.log('Development environment detected, using localhost API:', apiBaseUrl);
  }
}

const api = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add request interceptor for debugging
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    console.log(`API Request: ${config.method?.toUpperCase()} ${config.baseURL}${config.url}`, config);
    return config;
  },
  (error) => {
    console.error('API Request Error:', error);
    return Promise.reject(error);
  }
);

// Add response interceptor for debugging
api.interceptors.response.use(
  (response) => {
    console.log('API Response:', response.status, response.config.url);
    return response;
  },
  (error) => {
    console.error('API Response Error:', {
      status: error.response?.status,
      url: error.config?.url,
      message: error.message,
      data: error.response?.data,
    });
    return Promise.reject(error);
  }
);

export default api;

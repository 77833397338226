import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
  Typography,
  IconButton,
  Toolbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { TableMetadata, TableRecord } from '../../types/table';
import tableService from '../../services/tableService';
import { useSnackbar } from 'notistack';
import CreateRecordDialog from './CreateRecordDialog';

interface RecordDetailDialogProps {
  open: boolean;
  onClose: () => void;
  record: TableRecord;
  table: TableMetadata;
  onEdit?: () => void;
}

const RecordDetailDialog: React.FC<RecordDetailDialogProps> = ({
  open,
  onClose,
  record,
  table,
  onEdit
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Record Details</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ p: 2 }}>
          {table.fields.map((field) => (
            <Box key={field.name} sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary">
                {field.label || field.name}
              </Typography>
              <Typography variant="body1">
                {record[field.name]?.toString() || '-'}
              </Typography>
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        {onEdit && (
          <Button onClick={onEdit} color="primary">
            Edit
          </Button>
        )}
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CustomTableRecords: React.FC = () => {
  const { tableName } = useParams<{ tableName: string }>();
  const [table, setTable] = useState<TableMetadata | null>(null);
  const [records, setRecords] = useState<TableRecord[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<TableRecord | null>(null);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const loadTableAndRecords = useCallback(async () => {
    if (!tableName) {
      setError('No table name provided');
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const tableResponse = await tableService.getTableByName(tableName);
      if (!tableResponse.data) {
        throw new Error('Table not found');
      }
      setTable(tableResponse.data);

      const recordsResponse = await tableService.getCustomTableRecords(tableName);
      setRecords(recordsResponse.data || []);
    } catch (error) {
      console.error('Error loading table and records:', error);
      setError('Error loading table data. Please try again later.');
      enqueueSnackbar('Error loading table data', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [tableName, enqueueSnackbar]);

  useEffect(() => {
    loadTableAndRecords();
  }, [loadTableAndRecords]);

  const handleCreateRecord = async (record: any) => {
    if (!tableName) return;
    try {
      await tableService.createCustomTableRecord(tableName, record);
      enqueueSnackbar('Record created successfully', { variant: 'success' });
      setCreateDialogOpen(false);
      loadTableAndRecords();
    } catch (error) {
      console.error('Error creating record:', error);
      enqueueSnackbar('Error creating record', { variant: 'error' });
    }
  };

  const handleEditRecord = async (record: any) => {
    if (!tableName || !selectedRecord?._id) return;
    try {
      await tableService.updateCustomTableRecord(tableName, selectedRecord._id, record);
      enqueueSnackbar('Record updated successfully', { variant: 'success' });
      setEditDialogOpen(false);
      setDetailDialogOpen(false);
      loadTableAndRecords();
    } catch (error) {
      console.error('Error updating record:', error);
      enqueueSnackbar('Error updating record', { variant: 'error' });
    }
  };

  const handleDeleteRecord = async (recordId: string | undefined) => {
    if (!tableName || !recordId) return;
    try {
      await tableService.deleteCustomTableRecord(tableName, recordId);
      enqueueSnackbar('Record deleted successfully', { variant: 'success' });
      loadTableAndRecords();
    } catch (error) {
      console.error('Error deleting record:', error);
      enqueueSnackbar('Error deleting record', { variant: 'error' });
    }
  };

  const handleRowClick = (record: TableRecord) => {
    setSelectedRecord(record);
    setDetailDialogOpen(true);
  };

  const handleEditClick = (event: React.MouseEvent, record: TableRecord) => {
    event.stopPropagation();
    setSelectedRecord(record);
    setEditDialogOpen(true);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error || !table) {
    return (
      <Box p={3}>
        <Alert severity="error">
          {error || 'Table not found'}
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
        <Typography variant="h6" component="div" sx={{ flex: '1 1 100%' }}>
          {table.label || table.name}
        </Typography>
        <Button
          startIcon={<AddIcon />}
          onClick={() => setCreateDialogOpen(true)}
          variant="contained"
          color="primary"
        >
          Add Record
        </Button>
      </Toolbar>

      {records.length === 0 ? (
        <Box p={3} textAlign="center">
          <Typography color="text.secondary">
            No records found. Click "Add Record" to create one.
          </Typography>
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {table.fields.map((field) => (
                  <TableCell key={field.name}>{field.label || field.name}</TableCell>
                ))}
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.map((record) => (
                <TableRow
                  key={record._id}
                  hover
                  onClick={() => handleRowClick(record)}
                  sx={{ cursor: 'pointer' }}
                >
                  {table.fields.map((field) => (
                    <TableCell key={field.name}>
                      {record[field.name]?.toString() || '-'}
                    </TableCell>
                  ))}
                  <TableCell align="right">
                    <IconButton
                      size="small"
                      onClick={(e) => handleEditClick(e, record)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteRecord(record._id);
                      }}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Create Record Dialog */}
      <CreateRecordDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onSubmit={handleCreateRecord}
        fields={table.fields}
      />

      {/* Edit Record Dialog */}
      {selectedRecord && (
        <CreateRecordDialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          onSubmit={handleEditRecord}
          fields={table.fields}
          initialData={selectedRecord}
          isEdit
        />
      )}

      {/* Record Detail Dialog */}
      {selectedRecord && table && (
        <RecordDetailDialog
          open={detailDialogOpen}
          onClose={() => setDetailDialogOpen(false)}
          record={selectedRecord}
          table={table}
          onEdit={() => setEditDialogOpen(true)}
        />
      )}
    </Box>
  );
};

export default CustomTableRecords;

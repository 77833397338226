import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  CircularProgress,
  Grid,
  Chip,
  Divider,
  IconButton,
  Tooltip
} from '@mui/material';
import { Close as CloseIcon, Code as CodeIcon } from '@mui/icons-material';
import api from '../../../services/api';
import { format } from 'date-fns';

// Interface matching the data structure from the backend CpeData model
interface CPEData {
  _id: string;
  cpe_name: string;
  part: string;
  vendor: string;
  product: string;
  version: string;
  title: string;
  update: string;
  edition: string;
  language: string;
  sw_edition: string;
  target_sw: string;
  target_hw: string;
  other: string;
  deprecated: boolean;
  cpe_last_modified_date: string;
  createdAt?: string;
  updatedAt?: string;
}

interface SWCatalogDetailProps {
  open: boolean;
  onClose: () => void;
  cpeName: string | null; // Pass the full CPE name (URI encoded)
}

const SWCatalogDetail: React.FC<SWCatalogDetailProps> = ({ open, onClose, cpeName }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [cpeDetail, setCpeDetail] = useState<CPEData | null>(null);

  useEffect(() => {
    const fetchCpeDetail = async () => {
      if (!cpeName || !open) {
        setCpeDetail(null); // Clear details if no cpeName or dialog closed
        return;
      }
      setLoading(true);
      setError(null);
      try {
        // Ensure cpeName is properly encoded if it wasn't already
        const encodedCpeName = encodeURIComponent(cpeName);
        const response = await api.get(`/cpe/item/${encodedCpeName}`);
        setCpeDetail(response.data);
      } catch (err) {
        console.error('Error fetching CPE detail:', err);
        setError('Failed to load software details.');
        setCpeDetail(null);
      } finally {
        setLoading(false);
      }
    };

    fetchCpeDetail();
  }, [cpeName, open]); // Refetch when cpeName or open status changes

  const renderDetailItem = (label: string, value: string | boolean | undefined | null) => {
    let displayValue: React.ReactNode = '-';
    if (typeof value === 'boolean') {
      displayValue = value ? 'Yes' : 'No';
    } else if (value) {
      displayValue = value;
    }

    return (
      <Grid item xs={12} sm={6} md={4} key={label}>
        <Typography variant="caption" color="textSecondary" display="block">
          {label}
        </Typography>
        <Typography variant="body2">{displayValue}</Typography>
      </Grid>
    );
  };

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return '-';
    try {
      return format(new Date(dateString), 'PPpp'); // Format like: Sep 21, 2021, 4:30:15 PM
    } catch {
      return dateString; // Return original string if formatting fails
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth scroll="paper">
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Software Catalog Details
        <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        )}
        {error && (
          <Typography color="error" sx={{ p: 2 }}>
            {error}
          </Typography>
        )}
        {!loading && !error && cpeDetail && (
          <Box sx={{ pt: 1 }}>
            <Typography variant="h6" gutterBottom>
              {cpeDetail.title || `${cpeDetail.vendor} ${cpeDetail.product} ${cpeDetail.version}`}
            </Typography>
            <Chip
              icon={<CodeIcon />}
              label={cpeDetail.cpe_name}
              size="small"
              variant="outlined"
              sx={{ mb: 2, fontFamily: 'monospace', maxWidth: '100%' }}
            />
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              {renderDetailItem('Vendor', cpeDetail.vendor)}
              {renderDetailItem('Product', cpeDetail.product)}
              {renderDetailItem('Version', cpeDetail.version)}
              {renderDetailItem('Update', cpeDetail.update)}
              {renderDetailItem('Edition', cpeDetail.edition)}
              {renderDetailItem('Language', cpeDetail.language)}
              {renderDetailItem('Software Edition', cpeDetail.sw_edition)}
              {renderDetailItem('Target Software', cpeDetail.target_sw)}
              {renderDetailItem('Target Hardware', cpeDetail.target_hw)}
              {renderDetailItem('Other Info', cpeDetail.other)}
              {renderDetailItem('Part', cpeDetail.part === 'a' ? 'Application' : cpeDetail.part === 'o' ? 'Operating System' : cpeDetail.part === 'h' ? 'Hardware' : cpeDetail.part)}
              {renderDetailItem('Deprecated', cpeDetail.deprecated)}
              {renderDetailItem('NVD Last Modified', formatDate(cpeDetail.cpe_last_modified_date))}
              {renderDetailItem('Record Created', formatDate(cpeDetail.createdAt))}
              {renderDetailItem('Record Updated', formatDate(cpeDetail.updatedAt))}
            </Grid>
          </Box>
        )}
        {!loading && !error && !cpeDetail && cpeName && (
           <Typography sx={{ p: 2 }}>Details not found for the selected item.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {/* Add other actions if needed, e.g., "Create CI from this" */}
      </DialogActions>
    </Dialog>
  );
};

export default SWCatalogDetail;
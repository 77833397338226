import api from '../api/axios';

const noteService = {
  // Get notes for an entity
  getNotes: async (entityType, entityId, page = 1, limit = 20) => {
    const response = await api.get('/notes', {
      params: {
        entityType,
        entityId,
        page,
        limit
      }
    });
    return response.data;
  },

  // Add a new note
  addNote: async (entityType, entityId, content) => {
    const response = await api.post('/notes', {
      entityType,
      entityId,
      content
    });
    return response.data;
  },

  // Delete a note
  deleteNote: async (noteId) => {
    const response = await api.delete(`/notes/${noteId}`);
    return response.data;
  }
};

export default noteService;
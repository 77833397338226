import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  MenuItem,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import holidayService, { 
  CreateHolidayDto, 
  Holiday 
} from '../../../services/holidayService';
import aiHolidayService from '../../../services/aiHolidayService';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Calendar } from '../../../types/calendar';
import calendarService from '../../../services/calendarService';

// List of countries for AI holiday generation
const COUNTRIES = [
  'Turkey', 'United States', 'United Kingdom', 'Germany', 
  'France', 'Canada', 'Australia', 'Japan', 'China', 'India'
];

interface AIHolidayGeneratorProps {
  open: boolean;
  onClose: () => void;
  onHolidayGenerated: (holidays: CreateHolidayDto[]) => void;
}

const AIHolidayGenerator: React.FC<AIHolidayGeneratorProps> = ({ 
  open, 
  onClose, 
  onHolidayGenerated 
}) => {
  const [country, setCountry] = useState<string | null>(null);
  const [year, setYear] = useState<number | null>(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleGenerate = async () => {
    if (!country || !year) {
      setError('Please select a country and year');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const generatedHolidays = await aiHolidayService.generateHolidays({ country, year });
      onHolidayGenerated(generatedHolidays);
      onClose();
    } catch (err) {
      console.error('Error generating holidays:', err);
      setError('Failed to generate holidays. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Generate Holidays with AI</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
          <Autocomplete
            options={COUNTRIES}
            value={country}
            onChange={(_, newValue: string | null) => setCountry(newValue)}
            renderInput={(params) => (
              <TextField 
                {...params} 
                label="Country" 
                variant="outlined" 
                required 
              />
            )}
          />
          <TextField
            label="Year"
            type="number"
            value={year}
            onChange={(e) => setYear(Number(e.target.value))}
            variant="outlined"
            required
            inputProps={{ min: 2000, max: 2050 }}
          />
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button 
          onClick={handleGenerate} 
          color="primary" 
          disabled={isLoading || !country || !year}
        >
          {isLoading ? 'Generating...' : 'Generate Holidays'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface CreateHolidayModalProps {
  open: boolean;
  onClose: () => void;
  onHolidayCreated: () => void;
  holiday?: Partial<Holiday>;
}

interface CreateHolidayFormValues {
  name: string;
  date: Date;
  calendar: string;
}

const CreateHolidayModal: React.FC<CreateHolidayModalProps> = ({
  open,
  onClose,
  onHolidayCreated,
  holiday,
}) => {
  const [calendars, setCalendars] = useState<Calendar[]>([]);
  const [selectedCalendar, setSelectedCalendar] = useState<string>('');
  const [isAIModalOpen, setIsAIModalOpen] = useState(false);
  const [error, setError] = useState('');

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    date: Yup.date().required('Date is required'),
    calendar: Yup.string().required('Calendar is required'),
  });

  const initialValues: CreateHolidayFormValues = {
    name: holiday?.name || '',
    date: holiday?.date ? new Date(holiday.date) : new Date(),
    calendar: (holiday?.calendar as { _id: string })?.['_id'] || '',
  };

  const handleAIGenerate = async (generatedHolidays: CreateHolidayDto[]) => {
    // Add selected calendar to generated holidays
    const holidaysWithCalendar = generatedHolidays.map(holiday => ({
      ...holiday,
      calendar: selectedCalendar
    }));

    try {
      // Create multiple holidays
      await Promise.all(
        holidaysWithCalendar.map(holiday => 
          holidayService.create(holiday)
        )
      );
      
      onHolidayCreated();
      setIsAIModalOpen(false);
    } catch (err) {
      console.error('Error creating AI-generated holidays:', err);
      setError('Failed to create holidays');
    }
  };

  const handleSubmit = async (values: CreateHolidayFormValues, { setSubmitting }: FormikHelpers<CreateHolidayFormValues>) => {
    try {
      const newHoliday = holiday
        ? ({ 
            ...(holiday),
            name: values.name,
            date: values.date.toISOString(),
            calendar: values.calendar,
          })
        : ({
            name: values.name,
            date: values.date.toISOString(),
            calendar: values.calendar,
          });

      if (holiday && '_id' in holiday) {
        await holidayService.update(holiday._id as string, newHoliday);
      } else {
        await holidayService.create(newHoliday as CreateHolidayDto);
      }
      onHolidayCreated();
      onClose();
    } catch (err: any) {
      setError(err.message || 'Failed to create holiday');
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    const fetchCalendars = async () => {
      try {
        const data = await calendarService.getAll();
        setCalendars(data);
        if (data.length > 0) {
          setSelectedCalendar(data[0]._id || '');
        }
      } catch (err) {
        console.error('Error fetching calendars:', err);
      }
    };

    if (open) {
      fetchCalendars();
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {holiday ? 'Edit Holiday' : 'Create Holiday'}
          <Button 
            variant="outlined" 
            color="primary" 
            sx={{ ml: 2 }}
            onClick={() => setIsAIModalOpen(true)}
          >
            Create with AI
          </Button>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <Box component="form" sx={{ mt: 2 }}>
                  <Field
                    as={TextField}
                    autoFocus
                    margin="dense"
                    label="Holiday Name"
                    fullWidth
                    name="name"
                    error={Boolean(error)}
                    helperText={error}
                    sx={{ mb: 2 }}
                  />

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Field
                      as={DatePicker}
                      label="Holiday Date"
                      value={new Date()}
                      onChange={(newValue: Date | null) => {
                        if (newValue) {
                          setFieldValue('date', newValue);
                        }
                      }}
                      sx={{ width: '100%', mb: 2 }}
                    />
                  </LocalizationProvider>

                  <FormControl fullWidth>
                    <InputLabel>Calendar</InputLabel>
                    <Field
                      as={Select}
                      value={selectedCalendar}
                      label="Calendar"
                      name="calendar"
                    >
                      {calendars.map((cal) => (
                        <MenuItem key={cal._id} value={cal._id}>
                          {cal.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Box>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    {holiday ? 'Save Changes' : 'Create'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <AIHolidayGenerator
        open={isAIModalOpen}
        onClose={() => setIsAIModalOpen(false)}
        onHolidayGenerated={handleAIGenerate}
      />
    </>
  );
};

export default CreateHolidayModal;

// frontend/src/components/CMDB/SWCatalog/SWCatalogList.tsx

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Button,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Divider,
  Tab,
  Tabs,
  SelectChangeEvent,
  Menu,
  ListItemIcon,
  ListItemText,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip
} from '@mui/material';
import {
  Search as SearchIcon,
  FilterList as FilterIcon,
  Visibility as VisibilityIcon,
  Add as AddIcon,
  Code as CodeIcon,
  List as ListIcon,
  MoreVert as MoreVertIcon,
  Refresh as RefreshIcon,
  FilterAlt as FilterAltIcon,
  Apps as AppsIcon,
  Computer as ComputerIcon,
  Memory as MemoryIcon,
  SettingsApplications as FirmwareIcon,
  Router as DeviceIcon
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import api from '../../../services/api';
import SWCatalogDetail from './SWCatalogDetail';

// Interface for CPE data
interface CPEData {
  _id: string;
  cpe_name: string;
  part: string;
  vendor: string;
  product: string;
  version: string;
  title: string;
  update: string;
  edition: string;
  language: string;
  sw_edition: string;
  target_sw: string;
  target_hw: string;
  other: string;
  deprecated: boolean;
  cpe_last_modified_date: string;
}

// Backend pagination response
interface PaginatedResponse {
  data: CPEData[];
  total: number;
  page: number;
  limit: number;
  totalPages: number;
}

// Interface for product summary
interface ProductSummary {
  product: string;
  count: number;
  versions: string[];
}

// Interface for vendor summary
interface VendorSummary {
  vendor: string;
  count: number;
  products: number;
}

// CPE part types
const partTypes = [
  { value: 'a', label: 'Applications', icon: <AppsIcon /> },
  { value: 'o', label: 'Operating Systems', icon: <ComputerIcon /> },
  { value: 'h', label: 'Hardware', icon: <MemoryIcon /> },
  { value: 'f', label: 'Firmware', icon: <FirmwareIcon /> },
  { value: 'd', label: 'Devices', icon: <DeviceIcon /> }
];

const CPECatalogList: React.FC = () => {
  // State for data and loading
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [catalogData, setCatalogData] = useState<CPEData[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  
  // State for vendors and products (summaries)
  const [vendorSummaries, setVendorSummaries] = useState<VendorSummary[]>([]);
  const [productSummaries, setProductSummaries] = useState<ProductSummary[]>([]);
  
  // Pagination state
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  
  // Filter state
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [vendorFilter, setVendorFilter] = useState<string>('');
  const [productFilter, setProductFilter] = useState<string>('');
  const [partFilter, setPartFilter] = useState<string>('a'); // Default to applications
  const [viewMode, setViewMode] = useState<string>('list');
  const [tabValue, setTabValue] = useState<number>(0);
  
  // Detail dialog state
  const [detailDialogOpen, setDetailDialogOpen] = useState<boolean>(false);
  const [selectedCpeName, setSelectedCpeName] = useState<string | null>(null);
  
  // Menu state
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // Get the title for the current part type
  const getPartTypeTitle = () => {
    const partType = partTypes.find(pt => pt.value === partFilter);
    return partType ? partType.label : 'Software Catalog';
  };

  // Load data with pagination and filters
  const loadCatalogData = useCallback(async (pageIndex = 0, filters = { 
    search: searchTerm, 
    vendor: vendorFilter, 
    product: productFilter,
    part: partFilter 
  }) => {
    try {
      setLoading(true);
      setError(null);
      
      // Build query parameters
      const queryParams = new URLSearchParams({
        page: String(pageIndex + 1), // Convert to 1-based for API
        limit: String(rowsPerPage),
        part: filters.part,
        ...(filters.search ? { search: filters.search } : {}),
        ...(filters.vendor ? { vendor: filters.vendor } : {}),
        ...(filters.product ? { product: filters.product } : {})
      });
      
      // Fetch data with pagination
      const response = await api.get(`/cpe/catalog?${queryParams}`);
      const result = response.data as PaginatedResponse;
      
      setCatalogData(result.data);
      setTotalCount(result.total);
      setPage(pageIndex);
      
    } catch (error) {
      console.error('Error loading catalog data:', error);
      setError('Failed to load catalog data');
      enqueueSnackbar('Failed to load catalog data', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [searchTerm, vendorFilter, productFilter, partFilter, rowsPerPage, enqueueSnackbar]);

  // Load vendor and product summaries (separately from main data)
  const loadSummaries = useCallback(async () => {
    try {
      // Fetch vendor summaries for the current part type
      const vendorResponse = await api.get(`/cpe/vendors/summary?part=${partFilter}`);
      setVendorSummaries(vendorResponse.data);
      
      // If a vendor is selected, fetch product summaries for that vendor
      if (vendorFilter) {
        const productResponse = await api.get(`/cpe/products/summary?part=${partFilter}&vendor=${vendorFilter}`);
        setProductSummaries(productResponse.data);
      } else {
        setProductSummaries([]);
      }
    } catch (error) {
      console.error('Error loading summaries:', error);
      // Don't show error notification for summaries - non-critical
    }
  }, [vendorFilter, partFilter]);

  // Initial load
  useEffect(() => {
    loadCatalogData();
    loadSummaries();
  }, [loadCatalogData, loadSummaries]);

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    loadCatalogData(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    loadCatalogData(0, { 
      search: searchTerm, 
      vendor: vendorFilter, 
      product: productFilter,
      part: partFilter 
    });
  };

  // Handle vendor filter change
  const handleVendorFilterChange = (event: SelectChangeEvent) => {
    const newVendor = event.target.value;
    setVendorFilter(newVendor);
    setProductFilter('');
    setPage(0);
    loadCatalogData(0, { 
      search: searchTerm, 
      vendor: newVendor, 
      product: '',
      part: partFilter
    });
  };

  // Handle product filter change
  const handleProductFilterChange = (event: SelectChangeEvent) => {
    const newProduct = event.target.value;
    setProductFilter(newProduct);
    setPage(0);
    loadCatalogData(0, { 
      search: searchTerm, 
      vendor: vendorFilter, 
      product: newProduct,
      part: partFilter 
    });
  };

  // Handle part type filter change
  const handlePartFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newPartType: string | null
  ) => {
    if (newPartType !== null) {
      setPartFilter(newPartType);
      setPage(0);
      
      // Reset vendor and product filters when changing part type
      setVendorFilter('');
      setProductFilter('');
      
      loadCatalogData(0, { 
        search: searchTerm, 
        vendor: '', 
        product: '',
        part: newPartType 
      });
    }
  };

  // Handle search
  const handleSearch = () => {
    setPage(0);
    loadCatalogData(0, { 
      search: searchTerm, 
      vendor: vendorFilter, 
      product: productFilter,
      part: partFilter
    });
  };

  // Handle clear filters
  const handleClearFilters = () => {
    setSearchTerm('');
    setVendorFilter('');
    setProductFilter('');
    setPage(0);
    loadCatalogData(0, { 
      search: '', 
      vendor: '', 
      product: '',
      part: partFilter 
    });
  };

  // Handle view tab change
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setViewMode(newValue === 0 ? 'list' : 'grouped');
  };

  // Handle menu open
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  // Handle menu close
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  // Handle view details
  const handleViewDetails = (cpe: CPEData) => {
    setSelectedCpeName(cpe.cpe_name);
    setDetailDialogOpen(true);
  };

  // Handle create CI
  const handleCreateCI = (cpe: CPEData) => {
    navigate(`/cmdb/new?cpe=${encodeURIComponent(cpe.cpe_name)}`);
  };

  // Format vendor name for display
  const formatVendorName = (encodedVendor: string) => {
    if (!encodedVendor) return '';
    
    try {
      // Try to decode the URI component
      const decodedVendor = decodeURIComponent(encodedVendor);
      
      // Format the vendor name
      return decodedVendor
        .replace(/_project$/, '') // Remove "_project" suffix
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    } catch (error) {
      // If decoding fails, use the original string
      return encodedVendor
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
  };

  // Get icon for part type
  const getPartTypeIcon = (part: string) => {
    const foundPart = partTypes.find(pt => pt.value === part);
    return foundPart ? foundPart.icon : <AppsIcon />;
  };

  // Get part type display name
  const getPartTypeName = (part: string) => {
    switch (part) {
      case 'a': return 'Application';
      case 'o': return 'Operating System';
      case 'h': return 'Hardware';
      case 'f': return 'Firmware';
      case 'd': return 'Device';
      default: return 'Unknown';
    }
  };

  // Render list view (paginated from backend)
  const renderListView = () => (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width="30px">Type</TableCell>
            <TableCell>Vendor</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Version</TableCell>
            <TableCell>Title</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && catalogData.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <CircularProgress size={30} />
              </TableCell>
            </TableRow>
          ) : catalogData.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <Typography variant="body2" color="textSecondary">
                  No items found matching your criteria.
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            catalogData.map((cpe) => (
              <TableRow key={cpe._id} hover>
                <TableCell>
                  <Tooltip title={getPartTypeName(cpe.part)}>
                    {getPartTypeIcon(cpe.part)}
                  </Tooltip>
                </TableCell>
                <TableCell>{formatVendorName(cpe.vendor)}</TableCell>
                <TableCell>{cpe.product}</TableCell>
                <TableCell>{cpe.version || '*'}</TableCell>
                <TableCell>{cpe.title || `${formatVendorName(cpe.vendor)} ${cpe.product} ${cpe.version || ''}`}</TableCell>
                <TableCell align="right">
                  <IconButton size="small" onClick={() => handleViewDetails(cpe)}>
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                  <IconButton size="small" onClick={() => handleCreateCI(cpe)}>
                    <AddIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100, 250]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );

  // Render grouped view (using summaries)
  const renderGroupedView = () => (
    <Box sx={{ mt: 2 }}>
      {loading && vendorSummaries.length === 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
          <CircularProgress />
        </Box>
      ) : vendorSummaries.length === 0 ? (
        <Typography variant="body2" color="textSecondary" sx={{ py: 4, textAlign: 'center' }}>
          No vendor data available.
        </Typography>
      ) : (
        <Grid container spacing={2}>
          {vendorSummaries
            .filter(vendor => !vendorFilter || vendor.vendor === vendorFilter)
            .slice(0, 100) // Limit to top 100 vendors for performance
            .map(vendor => (
              <Grid item xs={12} md={6} lg={4} key={vendor.vendor}>
                <Paper 
                  elevation={1}
                  sx={{ 
                    p: 2, 
                    height: '100%',
                    '&:hover': { 
                      boxShadow: 2,
                      borderColor: 'primary.main' 
                    } 
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {formatVendorName(vendor.vendor)}
                    </Typography>
                    <Chip 
                      label={vendor.count} 
                      size="small" 
                      color="primary"
                    />
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    {vendor.products} unique products
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button 
                      size="small" 
                      variant="outlined"
                      onClick={() => {
                        setVendorFilter(vendor.vendor);
                        setProductFilter('');
                        setTabValue(0);
                        setViewMode('list');
                        loadCatalogData(0, { 
                          search: searchTerm, 
                          vendor: vendor.vendor, 
                          product: '',
                          part: partFilter
                        });
                      }}
                    >
                      Browse Products
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            ))}
        </Grid>
      )}
    </Box>
  );

  // Render main component
  return (
    <Box sx={{ p: 3 }}>
      {/* Header with title and actions */}
      <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, mb: 2 }}>
        <Typography variant="h6" component="div" sx={{ flex: '1 1 100%' }}>
          {getPartTypeTitle()}
          {loading && (
            <CircularProgress size={20} sx={{ ml: 2, verticalAlign: 'middle' }} />
          )}
        </Typography>
        
        <IconButton onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton>
        
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => { 
            handleMenuClose(); 
            loadCatalogData(page); 
            loadSummaries(); 
          }}>
            <ListItemIcon>
              <RefreshIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Refresh</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => { 
            handleMenuClose(); 
            handleClearFilters(); 
          }}>
            <ListItemIcon>
              <FilterAltIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Clear Filters</ListItemText>
          </MenuItem>
        </Menu>
      </Toolbar>

      {/* Part type toggle buttons */}
      <Paper sx={{ p: 2, mb: 2 }}>
        <ToggleButtonGroup
          value={partFilter}
          exclusive
          onChange={handlePartFilterChange}
          aria-label="CPE part type"
          sx={{ mb: 2 }}
        >
          {partTypes.map((part) => (
            <ToggleButton 
              key={part.value} 
              value={part.value}
            >
              {part.icon}
              <Typography variant="body2" sx={{ ml: 1 }}>
                {part.label}
              </Typography>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>

        {/* Search and filter controls */}
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={handleSearch}>
                      <SearchIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              placeholder={`Search ${getPartTypeTitle().toLowerCase()}...`}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Vendor</InputLabel>
              <Select
                value={vendorFilter}
                label="Vendor"
                onChange={handleVendorFilterChange}
              >
                <MenuItem value="">All Vendors</MenuItem>
                {vendorSummaries.slice(0, 100).map((vendor) => (
                  <MenuItem key={vendor.vendor} value={vendor.vendor}>
                    {formatVendorName(vendor.vendor)} ({vendor.count})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth size="small" disabled={!vendorFilter}>
              <InputLabel>Product</InputLabel>
              <Select
                value={productFilter}
                label="Product"
                onChange={handleProductFilterChange}
              >
                <MenuItem value="">All Products</MenuItem>
                {productSummaries.map((product) => (
                  <MenuItem key={product.product} value={product.product}>
                    {product.product} ({product.count})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button 
              variant="outlined" 
              startIcon={<FilterIcon />}
              onClick={handleClearFilters}
              fullWidth
              disabled={!searchTerm && !vendorFilter && !productFilter}
            >
              Clear Filters
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* View toggle */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab icon={<ListIcon />} label="List View" />
          <Tab icon={<CodeIcon />} label="Vendor View" />
        </Tabs>
      </Box>

      {/* Content based on view mode */}
      {viewMode === 'list' ? renderListView() : renderGroupedView()}

      {/* Detail Dialog */}
      <SWCatalogDetail
        open={detailDialogOpen}
        onClose={() => {
          setDetailDialogOpen(false);
          setSelectedCpeName(null);
        }}
        cpeName={selectedCpeName}
      />
    </Box>
  );
};

export default CPECatalogList;
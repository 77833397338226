import React, { createContext, useState, useContext, ReactNode, useMemo } from 'react';

interface LayoutContextProps {
  isSidebarCollapsed: boolean;
  setIsSidebarCollapsed: (isCollapsed: boolean) => void;
  toggleSidebarCollapse: () => void;
}

// Create the context with a default undefined value
const LayoutContext = createContext<LayoutContextProps | undefined>(undefined);

// Create the provider component
export const LayoutProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // TODO: Consider persisting this state (e.g., localStorage) if needed
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false); // Default: not collapsed

  const toggleSidebarCollapse = () => {
    setIsSidebarCollapsed(prev => !prev);
  };

  // Memoize the context value to prevent unnecessary re-renders
  const value = useMemo(() => ({
    isSidebarCollapsed,
    setIsSidebarCollapsed,
    toggleSidebarCollapse
  }), [isSidebarCollapsed]);

  return (
    <LayoutContext.Provider value={value}>
      {children}
    </LayoutContext.Provider>
  );
};

// Create a custom hook for easy consumption
export const useLayout = (): LayoutContextProps => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
};
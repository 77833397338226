import api from '../api/axios';

export interface Team {
  _id: string;
  name: string;
  description: string;
  members: string[];
  isActive: boolean;
}

const teamService = {
  getAll: async (): Promise<Team[]> => {
    const response = await api.get('/teams');
    return response.data;
  },

  getById: async (id: string): Promise<Team> => {
    const response = await api.get(`/teams/${id}`);
    return response.data;
  }
};

export default teamService;

import React, { useState, useEffect } from 'react';
import {
  // Remove Dialog related imports
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  Autocomplete,
  Chip,
  CircularProgress,
  Paper, // Add Paper import
} from '@mui/material';
import { Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import api from '../../api/axios';

interface User {
  _id: string;
  name: string;
  email: string;
}

interface Team {
  _id: string;
  name: string;
  description: string;
  members: User[];
}

interface TeamDetailProps {
  // Remove 'open' prop
  teamId: string; // Make teamId required as it won't render without it
  onClose: () => void;
  onTeamUpdated: () => void;
}

interface FormValues {
  name: string;
  description: string;
  members: User[];
}

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  members: yup.array().min(1, 'At least one member is required').required('Members are required'),
});

const TeamDetail: React.FC<TeamDetailProps> = ({ teamId, onClose, onTeamUpdated }) => { // Remove 'open'
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [isEditing, setIsEditing] = useState(false);

  const formik = useFormik<FormValues>({
    initialValues: {
      name: '',
      description: '',
      members: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await api.put(`/teams/${teamId}`, values);
        onTeamUpdated();
        setIsEditing(false);
      } catch (error) {
        console.error('Error updating team:', error);
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!teamId) return; // Remove 'open' check
      
      setLoading(true);
      try {
        const [teamResponse, usersResponse] = await Promise.all([
          api.get(`/teams/${teamId}`),
          api.get('/users')
        ]);

        const teamData = teamResponse.data;
        setUsers(usersResponse.data);

        formik.setValues({
          name: teamData.name,
          description: teamData.description,
          members: teamData.members,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [teamId]); // Remove 'open' dependency

  const handleClose = () => {
    setIsEditing(false);
    formik.resetForm();
    onClose();
  };

  // Remove the Dialog wrapper, render content directly
  return (
    <Paper elevation={0} sx={{ p: 0 }}> {/* Use Paper or Box as container */}
      {/* Header */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}
           sx={{ borderBottom: 1, borderColor: 'divider', pb: 1 }}>
        <Typography variant="h6">
          {isEditing ? 'Edit Team' : 'Team Details'}
        </Typography>
        <Box>
          {!isEditing && (
            <IconButton onClick={() => setIsEditing(true)} size="small" title="Edit Team">
              <EditIcon />
            </IconButton>
          )}
          {/* Add a close/back button if needed, calling onClose */}
           <IconButton onClick={handleClose} size="small" title="Close Details">
             <CloseIcon />
           </IconButton>
        </Box>
      </Box>

      {/* Content */}
      {loading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : (
        <Box component="form" onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            disabled={!isEditing}
            variant={isEditing ? "outlined" : "standard"} // Change appearance based on mode
            InputProps={{ disableUnderline: !isEditing }} // Hide underline in view mode
          />
          <TextField
            fullWidth
            margin="normal"
            label="Description"
            name="description"
            multiline
            rows={isEditing ? 4 : undefined} // Adjust rows based on mode
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
            disabled={!isEditing}
            variant={isEditing ? "outlined" : "standard"}
            InputProps={{ disableUnderline: !isEditing }}
          />
          <Autocomplete
            multiple
            options={users}
            getOptionLabel={(option) => option.name}
            value={formik.values.members}
            onChange={(_, value) => formik.setFieldValue('members', value)}
            readOnly={!isEditing} // Use readOnly for Autocomplete
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option.name}
                  {...getTagProps({ index })}
                  // disabled={!isEditing} // Chip doesn't have disabled, rely on Autocomplete readOnly
                  sx={{ m: 0.5 }}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Members"
                margin="normal"
                variant={isEditing ? "outlined" : "standard"}
                InputProps={{
                    ...params.InputProps,
                    disableUnderline: !isEditing,
                    readOnly: !isEditing, // Make input field readOnly too
                  }}
                error={formik.touched.members && Boolean(formik.errors.members)}
                helperText={formik.touched.members && (formik.errors.members as string)}
              />
            )}
            // disabled={!isEditing} // Use readOnly instead
          />

          {/* Action Buttons */}
          {isEditing && (
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
              <Button onClick={() => {
                  setIsEditing(false);
                  // Optionally reset form to original values if needed
                  // fetchData(); // Re-fetch original data
                  formik.resetForm(); // Or just reset to last fetched values
              }}>Cancel</Button>
              <Button type="submit" variant="contained" color="primary">
                Save Changes
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Paper>
  );
};

export default TeamDetail;

import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Button,
  Typography,
  Chip,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Tabs,
  Tab
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import { 
  WorkflowTemplate, 
  TaskDefinition,
  PriorityType,
  User,
  AssigneeType
} from '../../types/workflow';
import workflowTemplateService from '../../services/workflowTemplateService';
import NewWorkflowTemplate from './NewWorkflowTemplate';
import WorkflowDiagram from './WorkflowDiagram';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`workflow-tabpanel-${index}`}
      aria-labelledby={`workflow-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `workflow-tab-${index}`,
    'aria-controls': `workflow-tabpanel-${index}`,
  };
}

interface WorkflowTemplateDetailProps {
  templateId: string;
  onClose: () => void;
  onTemplateUpdated?: () => void;
}

const WorkflowTemplateDetail: React.FC<WorkflowTemplateDetailProps> = ({
  templateId,
  onClose,
  onTemplateUpdated,
}) => {
  const [template, setTemplate] = useState<WorkflowTemplate | null>(null);
  const [users, setUsers] = useState<Record<string, User>>({});
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const fetchTemplate = useCallback(async () => {
    try {
      if (!templateId) return;
      const template = await workflowTemplateService.getWorkflowTemplate(templateId);
      setTemplate(template);
    } catch (error) {
      console.error('Error fetching template:', error);
      enqueueSnackbar('Failed to fetch template details', { variant: 'error' });
    }
  }, [templateId, enqueueSnackbar]);

  const fetchUsers = useCallback(async () => {
    try {
      const usersList = await workflowTemplateService.getUsers();
      const usersMap = usersList.reduce((acc, user) => {
        acc[user._id] = user;
        return acc;
      }, {} as Record<string, User>);
      setUsers(usersMap);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }, []);

  useEffect(() => {
    if (templateId) {
      fetchTemplate();
    }
    fetchUsers();
  }, [templateId, fetchTemplate, fetchUsers]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleDelete = async () => {
    if (!template?._id) return;
    
    try {
      await confirm({
        title: 'Delete Template',
        description: 'Are you sure you want to delete this workflow template?',
      });
      await workflowTemplateService.deleteWorkflowTemplate(template._id);
      onTemplateUpdated?.();
      onClose();
      enqueueSnackbar('Template deleted successfully', { variant: 'success' });
    } catch (error) {
      if (error !== 'CANCELLED') {
        console.error('Error deleting template:', error);
        enqueueSnackbar('Failed to delete template', { variant: 'error' });
      }
    }
  };

  const handleEditSuccess = () => {
    setIsEditing(false);
    fetchTemplate();
    onTemplateUpdated?.();
    enqueueSnackbar('Template updated successfully', { variant: 'success' });
  };

  const handleEditClose = () => {
    setIsEditing(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const getPriorityColor = (priority: PriorityType) => {
    switch (priority) {
      case PriorityType.HIGH:
        return 'error';
      case PriorityType.MEDIUM:
        return 'warning';
      case PriorityType.LOW:
        return 'success';
      default:
        return 'default';
    }
  };

  const getUserDisplayName = (userId: string) => {
    const user = users[userId];
    return user ? `${user.firstName} ${user.lastName}` : userId;
  };

  const renderAssigneeInfo = (task: TaskDefinition) => {
    if (!task.assigneeId) return null;

    const displayName = task.assigneeType === AssigneeType.USER
      ? getUserDisplayName(task.assigneeId)
      : `${task.assigneeType}: ${task.assigneeId}`;

    return (
      <Chip
        size="small"
        variant="outlined"
        label={displayName}
        sx={{ mt: 0.5 }}
      />
    );
  };

  if (!template) {
    return null;
  }

  return (
    <Dialog open maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Workflow Template Details</Typography>
          <Box>
            <IconButton onClick={handleEdit} size="small">
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleDelete} size="small">
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="workflow template tabs">
              <Tab label="SLA & Business Case" {...a11yProps(0)} />
              <Tab label="Tasks" {...a11yProps(1)} />
              <Tab label="Required Fields" {...a11yProps(2)} />
              <Tab label="Flow Diagram" {...a11yProps(3)} />
            </Tabs>
          </Box>

          <TabPanel value={activeTab} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  SLA & Business Case
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, mb: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Category
                      </Typography>
                      <Typography>{template.category}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Version
                      </Typography>
                      <Typography>{template.version}</Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper sx={{ p: 2, mb: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    SLA Configuration
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                          Duration
                        </Typography>
                        <Chip label={`${template.sla.duration} hours`} size="small" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                          Priority
                        </Typography>
                        <Chip 
                          label={template.sla.priority} 
                          color={getPriorityColor(template.sla.priority)}
                          size="small" 
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Business Case
                  </Typography>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Description
                    </Typography>
                    <Typography paragraph sx={{ mb: 2 }}>
                      {template.businessCase.description}
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                          Priority
                        </Typography>
                        <Chip label={template.businessCase.priority} color="info" size="small" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                          Keywords
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                          {template.businessCase.keywords.map((keyword, index) => (
                            <Chip key={index} label={keyword} size="small" variant="outlined" />
                          ))}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={activeTab} index={1}>
            <Box sx={{ '& > *:not(:last-child)': { mb: 2 } }}>
              <Typography variant="h6" gutterBottom>
                Tasks
              </Typography>
              {template.tasks.map((task, index) => (
                <Paper key={task.id} variant="outlined" sx={{ p: 1.5 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {task.name}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 0.5 }}>
                      <Chip size="small" label={task.type} color="primary" />
                      <Chip size="small" label={task.priority} color="warning" />
                    </Box>
                  </Box>
                  
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                    {task.description}
                  </Typography>

                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    <Box>
                      <Typography variant="caption" color="text.secondary" display="block">
                        Assignee
                      </Typography>
                      {renderAssigneeInfo(task)}
                    </Box>

                    <Box>
                      <Typography variant="caption" color="text.secondary" display="block">
                        Duration
                      </Typography>
                      <Chip
                        size="small"
                        variant="outlined"
                        label={`${task.duration} hours`}
                        sx={{ mt: 0.5 }}
                      />
                    </Box>

                    {task.relationship.parentTasks && task.relationship.parentTasks.length > 0 && (
                      <Box>
                        <Typography variant="caption" color="text.secondary" display="block">
                          Parent Tasks
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 0.5, mt: 0.5 }}>
                          {task.relationship.parentTasks.map((parentId) => (
                            <Chip
                              key={parentId}
                              size="small"
                              variant="outlined"
                              label={template.tasks.find(t => t.id === parentId)?.name || parentId}
                            />
                          ))}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Paper>
              ))}
            </Box>
          </TabPanel>

          <TabPanel value={activeTab} index={2}>
            <Grid container spacing={2}>
              <Typography variant="h6" gutterBottom>
                Required Fields
              </Typography>
              {template.customFields?.map((field) => (
                <Grid item xs={12} key={field.id}>
                  <Paper sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle2" color="text.secondary">
                          Field Name
                        </Typography>
                        <Typography>{field.name}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle2" color="text.secondary">
                          Label
                        </Typography>
                        <Typography>{field.label}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle2" color="text.secondary">
                          Type
                        </Typography>
                        <Typography>{field.type}</Typography>
                      </Grid>
                      {field.description && (
                        <Grid item xs={12}>
                          <Typography variant="subtitle2" color="text.secondary">
                            Description
                          </Typography>
                          <Typography>{field.description}</Typography>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                          {field.required && (
                            <Chip label="Required" color="primary" size="small" />
                          )}
                          {field.readOnly && (
                            <Chip label="Read Only" color="default" size="small" />
                          )}
                        </Box>
                      </Grid>
                      {field.type === 'select' && field.options && field.options.length > 0 && (
                        <Grid item xs={12}>
                          <Typography variant="subtitle2" color="text.secondary">
                            Options
                          </Typography>
                          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                            {field.options.map((option) => (
                              <Chip
                                key={option.value}
                                label={option.label}
                                size="small"
                                variant="outlined"
                              />
                            ))}
                          </Box>
                        </Grid>
                      )}
                      {field.type === 'reference' && field.referenceConfig && (
                        <Grid item xs={12}>
                          <Typography variant="subtitle2" color="text.secondary">
                            Reference Configuration
                          </Typography>
                          <Typography>
                            Type: {field.referenceConfig.objectType}, Display Field: {field.referenceConfig.displayField}
                          </Typography>
                        </Grid>
                      )}
                      {field.validation && (
                        <Grid item xs={12}>
                          <Typography variant="subtitle2" color="text.secondary">
                            Validation Rules
                          </Typography>
                          <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                            {field.validation.min !== undefined && (
                              <Typography variant="body2">Min: {field.validation.min}</Typography>
                            )}
                            {field.validation.max !== undefined && (
                              <Typography variant="body2">Max: {field.validation.max}</Typography>
                            )}
                            {field.validation.minLength !== undefined && (
                              <Typography variant="body2">Min Length: {field.validation.minLength}</Typography>
                            )}
                            {field.validation.maxLength !== undefined && (
                              <Typography variant="body2">Max Length: {field.validation.maxLength}</Typography>
                            )}
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </TabPanel>

          <TabPanel value={activeTab} index={3}>
            <Box sx={{ height: 500, border: '1px solid #e0e0e0', borderRadius: 1 }}>
              <WorkflowDiagram 
                tasks={template.tasks} 
                readonly={true}
                onTasksChange={undefined}
              />
            </Box>
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>

      {isEditing && (
        <Dialog
          open={isEditing}
          onClose={handleEditClose}
          maxWidth="md"
          fullWidth
        >
          <NewWorkflowTemplate
            open={isEditing}
            onClose={handleEditClose}
            onSuccess={handleEditSuccess}
            template={template}
          />
        </Dialog>
      )}
    </Dialog>
  );
};

export default WorkflowTemplateDetail;

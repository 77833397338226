import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Alert
} from '@mui/material';
import workflowTemplateService from '../../services/workflowTemplateService';
import workflowInstanceService from '../../services/workflowInstanceService';
import { WorkflowTemplate } from '../../types/workflow';

interface CreateWorkflowModalProps {
  open: boolean;
  onClose: () => void;
  requestId: string;
  requestNumber: string;
  onSuccess?: () => void;
}

const CreateWorkflowModal: React.FC<CreateWorkflowModalProps> = ({
  open,
  onClose,
  requestId,
  requestNumber,
  onSuccess
}) => {
  const [templates, setTemplates] = useState<WorkflowTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    priority: 'medium',
    dueDate: ''
  });

  useEffect(() => {
    if (open) {
      loadTemplates();
    }
  }, [open]);

  const loadTemplates = async () => {
    try {
      setLoading(true);
      const templates = await workflowTemplateService.getWorkflowTemplates();
      setTemplates(templates);
    } catch (error) {
      setError('Failed to load workflow templates');
      console.error('Error loading templates:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTemplateChange = (templateId: string) => {
    setSelectedTemplate(templateId);
    const template = templates.find(t => t._id === templateId);
    if (template) {
      setFormData(prev => ({
        ...prev,
        name: `${template.name} for Request #${requestNumber}`,
        description: template.description || ''
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await workflowInstanceService.createInstance({
        templateId: selectedTemplate,
        requestId,
        name: formData.name,
        description: formData.description,
        priority: formData.priority as 'low' | 'medium' | 'high' | 'critical',
        dueDate: formData.dueDate ? new Date(formData.dueDate) : null
      });

      if (onSuccess) {
        onSuccess();
      }
      onClose();
    } catch (error) {
      setError('Failed to create workflow instance');
      console.error('Error creating workflow instance:', error);
    } finally {
      setLoading(false);
    }
  };

  const selectedTemplateDetails = templates.find(t => t._id === selectedTemplate);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Create Workflow Instance</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Workflow Template</InputLabel>
              <Select
                value={selectedTemplate}
                onChange={(e) => handleTemplateChange(e.target.value as string)}
                label="Workflow Template"
              >
                {templates.map((template) => (
                  <MenuItem key={template._id} value={template._id}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {selectedTemplateDetails && (
            <Grid item xs={12}>
              <Box sx={{ p: 2, bgcolor: 'grey.100', borderRadius: 1 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Template Details
                </Typography>
                <Typography variant="body2">
                  {selectedTemplateDetails.description}
                </Typography>
                <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                  Tasks: {selectedTemplateDetails.tasks?.length || 0}
                </Typography>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              multiline
              rows={3}
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Priority</InputLabel>
              <Select
                value={formData.priority}
                onChange={(e) => setFormData({ ...formData, priority: e.target.value })}
                label="Priority"
              >
                <MenuItem value="low">Low</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="high">High</MenuItem>
                <MenuItem value="critical">Critical</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Due Date"
              type="datetime-local"
              value={formData.dueDate}
              onChange={(e) => setFormData({ ...formData, dueDate: e.target.value })}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!selectedTemplate || loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Create Workflow'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateWorkflowModal;

import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Typography,
  Toolbar,
  CircularProgress,
  Chip,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Tooltip,
  Alert,
  Popover, // Import Popover
  Checkbox, // Import Checkbox for boolean filters
  FormControlLabel, // Import FormControlLabel
  Select, // Import Select for dropdown filters
  MenuItem, // Import MenuItem
  InputLabel, // Import InputLabel
  FormControl // Import FormControl
} from '@mui/material';
import TableFilters from './TableFilters'; // Import the TableFilters component
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as ViewIcon,
  Search as SearchIcon,
  Refresh as RefreshIcon,
  Settings as SettingsIcon,
  FilterList as FilterListIcon // Import Filter icon
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import tableService from '../../services/tableService';
import { TableMetadata, TableField, TableRecord, FilterValue } from '../../types/table';

// Registry of special behaviors for specific tables
const tableRegistry: Record<string, {
  customListActions?: React.ReactNode;
  getStatusColor?: (status: string) => "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
  formatField?: (field: TableField, value: any) => React.ReactNode;
  canDelete?: (record: any) => boolean;
  canEdit?: (record: any) => boolean;
  recordLabel?: (record: any) => string;
}> = {
  'requests': {
    getStatusColor: (status: string) => {
      switch (status.toLowerCase()) {
        case 'new': return 'info';
        case 'in_progress': return 'warning';
        case 'resolved': return 'success';
        case 'closed': return 'default';
        default: return 'default';
      }
    },
    formatField: (field: TableField, value: any) => {
      if (field.name === 'priority') {
        const color = 
          value === 'critical' ? 'error' :
          value === 'high' ? 'warning' :
          value === 'medium' ? 'info' :
          value === 'low' ? 'success' : 'default';
        return <Chip label={value} color={color} size="small" />;
      }
      if (field.name === 'status') {
        return <Chip label={value} color="primary" size="small" />;
      }
      return value?.toString() || '-';
    }
  },
  'teams': {
    formatField: (field: TableField, value: any) => {
      if (field.name === 'members' && Array.isArray(value)) {
        return `${value.length} members`;
      }
      return value?.toString() || '-';
    }
  }
};

interface GenericListProps {
  tableName?: string; // Optional if using useParams
}

const GenericList: React.FC<GenericListProps> = ({ tableName: propTableName }) => {
  const { tableName: paramTableName } = useParams<{ tableName: string }>();
  const tableName = propTableName || paramTableName;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  
  const [tableMetadata, setTableMetadata] = useState<TableMetadata | null>(null);
  const [records, setRecords] = useState<TableRecord[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecord, setSelectedRecord] = useState<TableRecord | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLButtonElement | null>(null); // State for Popover anchor
  const [filterValues, setFilterValues] = useState<Record<string, FilterValue>>({}); // State to hold active filters

  // Get table registry config for this table
  const tableConfig = tableRegistry[tableName || ''] || {};

  // Fetch table metadata
  const fetchTableMetadata = useCallback(async () => {
    if (!tableName) return;
    
    try {
      setLoading(true);
      setError(null);
      
      const response = await tableService.getTableByName(tableName);
      if (!response.data) {
        throw new Error(`Table ${tableName} not found`);
      }
      
      const metadata = response.data;
      console.log('Fetched Table Metadata:', JSON.stringify(metadata, null, 2)); // Log fetched metadata
      setTableMetadata(metadata);
      return metadata;
    } catch (error) {
      console.error('Error fetching table metadata:', error);
      setError(`Failed to load table metadata: ${error instanceof Error ? error.message : 'Unknown error'}`);
      return null;
    }
  }, [tableName]);

  // Fetch records based on table type
  const fetchRecords = useCallback(async (metadata?: TableMetadata) => {
    if (!tableName) return;
    
    const tableData = metadata || tableMetadata;
    if (!tableData) return;
    
    try {
      setLoading(true);
      setError(null);
      
      let response;
      const params = {
        page: page + 1,
        limit: rowsPerPage,
        search: searchQuery || undefined,
        filters: Object.keys(filterValues).length > 0 ? JSON.stringify(filterValues) : undefined
      };
      
      if (tableName === 'user' || tableName === 'users') {
        // Special handling for users table
        response = await tableService.getUsersTableRecords(params);
      } else if (tableData.type === 'system') {
        response = await tableService.getSystemTableRecords(tableName, params);
      } else if (tableData.type === 'custom') {
        response = await tableService.getCustomTableRecords(tableName, params);
      } else if (tableData.type === 'cmdb_collection') {
        response = await tableService.getCmdbCollectionRecords(tableName, params);
      } else {
        throw new Error(`Unsupported table type: ${tableData.type}`);
      }
      
      // Handle different response formats
      let recordsData = [];
      let total = 0;
      
      if (response.data?.data && Array.isArray(response.data.data)) {
        recordsData = response.data.data;
        total = response.data.total || recordsData.length;
      } else if (Array.isArray(response.data)) {
        recordsData = response.data;
        total = recordsData.length;
      } else {
        recordsData = response.data?.records || [];
        total = response.data?.total || recordsData.length;
      }
      
      setRecords(recordsData);
      setTotalRecords(total);
    } catch (error) {
      console.error('Error fetching records:', error);
      setError(`Failed to load records: ${error instanceof Error ? error.message : 'Unknown error'}`);
      setRecords([]);
    } finally {
      setLoading(false);
    }
  }, [tableName, page, rowsPerPage, searchQuery, filterValues]); // Add filterValues dependency

  // Load data on component mount and when dependencies change
  useEffect(() => {
    const loadData = async () => {
      const metadata = await fetchTableMetadata();
      if (metadata) {
        await fetchRecords(metadata);
      }
    };
    
    loadData();
  }, [fetchTableMetadata, fetchRecords]);

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle search
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  // Handle applying filters
  const handleApplyFilters = (filters: Record<string, FilterValue>) => {
    setFilterValues(filters);
    setFilterAnchorEl(null); // Close the filter popover
    setPage(0); // Reset to first page when filters change
  };

  // Handle clearing filters
  const handleClearFilters = () => {
    setFilterValues({});
    setPage(0); // Reset to first page
  };

  // Handle refresh (pass current metadata if available)
  const handleRefresh = () => {
    fetchRecords(tableMetadata || undefined);
  };

  // Handle add new record
  const handleAddRecord = () => {
    navigate(`/form/${tableName}`);
  };

  // Handle edit record
  const handleEditRecord = (record: TableRecord) => {
    navigate(`/form/${tableName}/${record._id}`);
  };

  // Handle view record
  const handleViewRecord = (record: TableRecord) => {
    setSelectedRecord(record);
    setViewDialogOpen(true);
  };

  // Handle delete record
  const handleDeleteClick = (record: TableRecord) => {
    setSelectedRecord(record);
    setDeleteDialogOpen(true);
  };

  // Confirm delete
  const handleDeleteConfirm = async () => {
    if (!selectedRecord || !tableName || !tableMetadata) return;
    
    try {
      setLoading(true);
      
      if (tableName === 'user' || tableName === 'users') {
        // Special handling for users table
        await tableService.deleteUser(selectedRecord._id!);
      } else if (tableMetadata.type === 'system') {
        // For system tables, we need to get the table ID first
        const tableResponse = await tableService.getTableByName(tableName);
        await tableService.deleteRecord(tableResponse.data._id, selectedRecord._id!);
      } else if (tableMetadata.type === 'custom') {
        await tableService.deleteCustomTableRecord(tableName, selectedRecord._id!);
      } else if (tableMetadata.type === 'cmdb_collection') {
        // For CMDB collections, use the appropriate method
        await tableService.deleteRecord(tableMetadata._id, selectedRecord._id!);
      } else {
        throw new Error(`Unsupported table type: ${tableMetadata.type}`);
      }
      
      enqueueSnackbar('Record deleted successfully', { variant: 'success' });
      setDeleteDialogOpen(false);
      fetchRecords();
    } catch (error) {
      console.error('Error deleting record:', error);
      enqueueSnackbar(`Failed to delete record: ${error instanceof Error ? error.message : 'Unknown error'}`, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // Format field value for display
  const formatFieldValue = (field: TableField, value: any): React.ReactNode => {
    // Use custom formatter if available
    if (tableConfig.formatField) {
      return tableConfig.formatField(field, value);
    }
    
    // Default formatting based on field type
    switch (field.type) {
      case 'boolean':
        return value ? 'Yes' : 'No';
      case 'date':
        return value ? new Date(value).toLocaleDateString() : '-';
      case 'datetime':
        return value ? new Date(value).toLocaleString() : '-';
      case 'select':
      case 'multiselect':
        if (Array.isArray(value)) {
          return value.join(', ') || '-';
        }
        return value?.toString() || '-';
      default:
        return value?.toString() || '-';
    }
  };

  // Get visible fields for the table
  const getVisibleFields = (): TableField[] => {
    if (!tableMetadata) return [];
    return tableMetadata.fields.filter(field => field.displayInList !== false);
  };

  // Render loading state
  if (loading && !tableMetadata) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  // Render error state
  if (error && !tableMetadata) {
    return (
      <Box p={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  // Get visible fields
  const visibleFields = getVisibleFields();

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      {/* Header */}
      <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, mb: 2 }}>
        <Typography variant="h5" component="div" sx={{ flex: '1 1 100%' }}>
          {tableMetadata?.label || tableName}
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 1 }}>
          {/* Display active filters as chips */}
          {Object.keys(filterValues).length > 0 && (
            <Box sx={{ display: 'flex', gap: 0.5, mr: 2, flexWrap: 'wrap' }}>
              {Object.keys(filterValues).map(fieldName => {
                const field = tableMetadata?.fields.find(f => f.name === fieldName);
                return (
                  <Chip 
                    key={fieldName}
                    size="small"
                    label={`${field?.label || fieldName}: ${filterValues[fieldName].value}`}
                    onDelete={() => {
                      const newFilters = { ...filterValues };
                      delete newFilters[fieldName];
                      setFilterValues(newFilters);
                      setPage(0);
                    }}
                  />
                );
              })}
              <Chip
                size="small"
                label="Clear All"
                onClick={handleClearFilters}
                color="secondary"
              />
            </Box>
          )}

          {/* Search */}
          <TextField
            size="small"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mr: 2 }}
          />
          
          {/* Refresh button */}
          <Tooltip title="Refresh">
            <IconButton onClick={handleRefresh}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>

          {/* Filter button */}
          <Tooltip title="Filters">
            <IconButton onClick={(event) => setFilterAnchorEl(event.currentTarget)}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>

          {/* Add button */}
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddRecord}
          >
            Add New
          </Button>
        </Box>
      </Toolbar>

      {/* Error message */}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {/* Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {visibleFields.map((field) => (
                <TableCell key={field.name}>{field.label}</TableCell>
              ))}
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && records.length === 0 ? (
              <TableRow>
                <TableCell colSpan={visibleFields.length + 1} align="center">
                  <CircularProgress size={24} sx={{ my: 2 }} />
                </TableCell>
              </TableRow>
            ) : records.length === 0 ? (
              <TableRow>
                <TableCell colSpan={visibleFields.length + 1} align="center">
                  No records found
                </TableCell>
              </TableRow>
            ) : (
              records.map((record) => (
                <TableRow
                  key={record._id}
                  hover
                  onClick={() => handleViewRecord(record)}
                  sx={{ cursor: 'pointer' }}
                >
                  {visibleFields.map((field) => (
                    <TableCell key={field.name}>
                      {formatFieldValue(field, record[field.name])}
                    </TableCell>
                  ))}
                  <TableCell align="right" onClick={(e) => e.stopPropagation()}>
                    <Tooltip title="View">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleViewRecord(record);
                        }}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Tooltip>
                    
                    {(tableConfig.canEdit === undefined || tableConfig.canEdit(record)) && (
                      <Tooltip title="Edit">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditRecord(record);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    
                    {(tableConfig.canDelete === undefined || tableConfig.canDelete(record)) && (
                      <Tooltip title="Delete">
                        <IconButton
                          size="small"
                          color="error"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteClick(record);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        
        {/* Pagination */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {/* View Record Dialog */}
      {selectedRecord && tableMetadata && (
        <Dialog
          open={viewDialogOpen}
          onClose={() => setViewDialogOpen(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">
                {tableConfig.recordLabel 
                  ? tableConfig.recordLabel(selectedRecord)
                  : `${tableMetadata.label} Details`}
              </Typography>
              <IconButton onClick={() => setViewDialogOpen(false)} size="small">
                <DeleteIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Box sx={{ p: 2 }}>
              {tableMetadata.fields.map((field) => (
                <Box key={field.name} sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    {field.label}
                  </Typography>
                  <Typography variant="body1">
                    {formatFieldValue(field, selectedRecord[field.name])}
                  </Typography>
                </Box>
              ))}
            </Box>
          </DialogContent>
          <DialogActions>
            {(tableConfig.canEdit === undefined || tableConfig.canEdit(selectedRecord)) && (
              <Button 
                onClick={() => {
                  setViewDialogOpen(false);
                  handleEditRecord(selectedRecord);
                }}
              >
                Edit
              </Button>
            )}
            <Button onClick={() => setViewDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Record</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this record? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleDeleteConfirm} 
            color="error" 
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Filter Popover */}
      <Popover
        open={Boolean(filterAnchorEl)}
        anchorEl={filterAnchorEl}
        onClose={() => setFilterAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {tableMetadata && (
          <TableFilters
            fields={tableMetadata.fields}
            onApplyFilters={handleApplyFilters}
            onClearFilters={handleClearFilters}
            initialFilters={filterValues}
          />
        )}
      </Popover>
      {/* End Filter Popover */}
    </Box>
  );
};

export default GenericList;
import api from '../api/axios';

interface ReportTemplate {
  _id: string;
  name: string;
  description: string;
  category: string;
  visualization: {
    type: string;
    options: Record<string, any>;
  };
  query: {
    collections: string[];
    aggregation: any[];
    filters: Array<{
      field: string;
      type: string;
      label: string;
      defaultValue: any;
      options?: any[];
    }>;
  };
}

const reportService = {
  // Get all report templates
  getTemplates: async () => {
    const response = await api.get('/reports/templates');
    return response.data;
  },

  // Get a single report template by ID
  getTemplate: async (id: string) => {
    const response = await api.get(`/reports/templates/${id}`);
    return response.data;
  },

  // Create a new report template
  createTemplate: async (template: Omit<ReportTemplate, '_id'>) => {
    const response = await api.post('/reports/templates', template);
    return response.data;
  },

  // Update an existing report template
  updateTemplate: async (id: string, template: Partial<ReportTemplate>) => {
    const response = await api.put(`/reports/templates/${id}`, template);
    return response.data;
  },

  // Delete a report template
  deleteTemplate: async (id: string) => {
    const response = await api.delete(`/reports/templates/${id}`);
    return response.data;
  },

  // Execute a report
  executeReport: async (id: string, filters?: Record<string, any>) => {
    const response = await api.post(`/reports/execute/${id}`, { filters });
    return response.data?.data || []; // Return the data array or empty array if not found
  },

  // Generate a report using AI
  generateReport: async (prompt: string, name?: string) => {
    const response = await api.post('/reports/templates/ai', { 
      prompt,
      name: name || prompt, // Use provided name or fall back to prompt
      description: prompt
    });
    return response.data;
  }
};

export default reportService;
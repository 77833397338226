import api from './api';
import { 
  WorkflowTemplate, 
  User,
  Team,
  Role,
  CustomField
} from '../types/workflow';

const getUsers = async (): Promise<User[]> => {
  const response = await api.get('/users');
  return response.data;
};

const getTeams = async (): Promise<Team[]> => {
  const response = await api.get('/teams');
  return response.data;
};

const getRoles = async (): Promise<Role[]> => {
  const response = await api.get('/roles');
  return response.data;
};

const getWorkflowTemplates = async (): Promise<WorkflowTemplate[]> => {
  const response = await api.get('/workflow-templates');
  return response.data;
};

const getWorkflowTemplate = async (id: string): Promise<WorkflowTemplate> => {
  const response = await api.get(`/workflow-templates/${id}`);
  return response.data;
};

const createWorkflowTemplate = async (template: Omit<WorkflowTemplate, '_id'>): Promise<WorkflowTemplate> => {
  try {
    console.log('Creating workflow template with data:', JSON.stringify(template, null, 2));
    const response = await api.post('/workflow-templates', template);
    console.log('Create response:', response.data);
    return response.data;
  } catch (error: any) {
    console.error('Create error details:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
      config: error.config
    });
    throw error;
  }
};

const updateWorkflowTemplate = async (id: string, template: Partial<WorkflowTemplate>): Promise<WorkflowTemplate> => {
  const response = await api.put(`/workflow-templates/${id}`, template);
  return response.data;
};

const deleteWorkflowTemplate = async (id: string): Promise<void> => {
  await api.delete(`/workflow-templates/${id}`);
};

const validateCustomFields = async (customFields: CustomField[]): Promise<string[]> => {
  const response = await api.post('/workflow-templates/validate-fields', { customFields });
  return response.data;
};

const workflowTemplateService = {
  getUsers,
  getTeams,
  getRoles,
  getWorkflowTemplates,
  getWorkflowTemplate,
  createWorkflowTemplate,
  updateWorkflowTemplate,
  deleteWorkflowTemplate,
  validateCustomFields
};

export default workflowTemplateService;

/**
 * Manages agent capabilities and their handlers
 */
class CapabilityManager {
  constructor() {
    this.capabilities = new Map();
  }

  /**
   * Register a new capability
   * @param {string} name - The name of the capability
   * @param {Object} config - Configuration for the capability
   */
  register(name, config) {
    this.capabilities.set(name, {
      name,
      ...config,
      active: true
    });
  }

  /**
   * Get a capability by name
   * @param {string} name - The name of the capability
   * @returns {Object|null} The capability or null if not found
   */
  getCapability(name) {
    return this.capabilities.get(name) || null;
  }

  /**
   * Get all registered capabilities
   * @returns {Array} Array of capabilities
   */
  getAllCapabilities() {
    return Array.from(this.capabilities.values());
  }

  /**
   * Check if a capability exists
   * @param {string} name - The name of the capability
   * @returns {boolean} True if the capability exists
   */
  hasCapability(name) {
    return this.capabilities.has(name);
  }

  /**
   * Enable a capability
   * @param {string} name - The name of the capability
   */
  enableCapability(name) {
    const capability = this.capabilities.get(name);
    if (capability) {
      capability.active = true;
      this.capabilities.set(name, capability);
    }
  }

  /**
   * Disable a capability
   * @param {string} name - The name of the capability
   */
  disableCapability(name) {
    const capability = this.capabilities.get(name);
    if (capability) {
      capability.active = false;
      this.capabilities.set(name, capability);
    }
  }
}

export { CapabilityManager };
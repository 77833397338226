import api from './api';
import { CIRelationship, CIRelationType, CIRelationshipCreate } from '../types/cmdb';

class CIRelationshipService {
  private defaultRelationshipTypes: Partial<CIRelationType>[] = [
    {
      name: 'Depends On',
      description: 'This CI depends on the target CI',
      bidirectional: false,
      sourceLabel: 'Depends On',
      targetLabel: 'Depended By',
      validSourceTypes: [],
      validTargetTypes: []
    },
    {
      name: 'Connected To',
      description: 'This CI is connected to the target CI',
      bidirectional: true,
      sourceLabel: 'Connected To',
      targetLabel: 'Connected To',
      validSourceTypes: [],
      validTargetTypes: []
    },
    {
      name: 'Runs On',
      description: 'This CI runs on the target CI',
      bidirectional: false,
      sourceLabel: 'Runs On',
      targetLabel: 'Hosts',
      validSourceTypes: [],
      validTargetTypes: []
    },
    {
      name: 'Part Of',
      description: 'This CI is part of the target CI',
      bidirectional: false,
      sourceLabel: 'Part Of',
      targetLabel: 'Contains',
      validSourceTypes: [],
      validTargetTypes: []
    }
  ];

  async getRelationshipTypes() {
    const response = await api.get('/cmdb/relationship-types');
    return response.data;
  }

  async createRelationshipType(data: Partial<CIRelationType>) {
    const response = await api.post('/cmdb/relationship-types', data);
    return response.data;
  }

  async getRelationships(params?: {
    sourceId?: string;
    targetId?: string;
    typeId?: string;
  }) {
    try {
      // TEMPORARY FIX: Use the generic relationships endpoint instead of CI-specific ones
      // which are returning 404 errors
      const endpoint = '/cmdb/relationships';
      
      console.log('[ciRelationshipService] Getting relationships with params:', JSON.stringify(params));
      console.log('[ciRelationshipService] Using generic endpoint:', endpoint);
      
      const response = await api.get(endpoint, { params });
      
      console.log('[ciRelationshipService] Relationship response status:', response.status);
      
      // Log actual relationships
      if (Array.isArray(response.data)) {
        console.log(`[ciRelationshipService] Found ${response.data.length} relationships`);
      } else {
        console.log('[ciRelationshipService] Response data is not an array:', typeof response.data);
      }
      
      return {
        relationships: response.data || []
      };
    } catch (error) {
      console.error('[ciRelationshipService] Error fetching relationships:', error);
      return {
        relationships: []
      };
    }
  }

  async getRelationshipById(id: string) {
    const response = await api.get(`/cmdb/relationships/${id}`);
    return response.data;
  }
  // Add this to your ciRelationshipService.ts
  async getFilteredRelationships(params: {
    ciId: string;
    includeServerRelations?: boolean;
    includeAppRelations?: boolean;
  }) {
    const queryParams = new URLSearchParams();
    queryParams.append('ciId', params.ciId);
    
    if (params.includeServerRelations !== undefined) {
      queryParams.append('includeServerRelations', params.includeServerRelations.toString());
    }
    
    if (params.includeAppRelations !== undefined) {
      queryParams.append('includeAppRelations', params.includeAppRelations.toString());
    }
    
    const response = await api.get(`/cmdb/relationships/filtered?${queryParams}`);
    return response.data;
  }
  async createRelationship(data: CIRelationshipCreate) {
    const response = await api.post('/cmdb/relationships', data);
    return response.data;
  }

  async create(data: CIRelationshipCreate) {
    return this.createRelationship(data);
  }

  async updateRelationship(id: string, data: Partial<CIRelationship>) {
    const response = await api.put(`/cmdb/relationships/${id}`, data);
    return response.data;
  }

  async deleteRelationship(id: string) {
    const response = await api.delete(`/cmdb/relationships/${id}`);
    return response.data;
  }

  async ensureDefaultRelationshipTypes() {
    try {
      const types = await this.getRelationshipTypes();
      if (!types || types.length === 0) {
        console.log('Creating default relationship types...');
        await Promise.all(
          this.defaultRelationshipTypes.map(type => 
            this.createRelationshipType(type)
          )
        );
        console.log('Default relationship types created successfully');
      }
    } catch (error) {
      console.error('Error ensuring default relationship types:', error);
    }
  }
}

export const ciRelationshipService = new CIRelationshipService();
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Login from './components/Auth/Login';
import { LayoutProvider } from './contexts/LayoutContext'; // Import LayoutProvider
import RequestList from './components/Requests/RequestList';
import RuleList from './components/Rules/RuleList';
// import TeamList from './components/Teams/TeamList'; // No longer used directly in routing
import TeamsPage from './components/Teams/TeamsPage'; // Import the new parent component
// import UserList from './components/Users/UserList'; // Keep existing component but don't use for routing
import GenericList from './components/common/list'; // Import GenericList
import GenericForm from './components/common/form'; // Import GenericForm
import KnowledgeBaseList from './components/KnowledgeBase/KnowledgeBaseList';
import WorkflowTemplateList from './components/WorkflowTemplates/WorkflowTemplateList';
import NewWorkflowTemplate from './components/WorkflowTemplates/NewWorkflowTemplate';
import WorkflowInstanceList from './components/WorkflowInstances/WorkflowInstanceList';
import WorkflowInstanceDetails from './components/WorkflowInstances/WorkflowInstanceDetails';
import TaskList from './components/Tasks/TaskList';
import TaskDetails from './components/Tasks/TaskDetails';
import theme from './theme';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';
import Layout from './components/Layout/Layout';
import TablesLayout from './components/Tables/TablesLayout';
import TableDetail from './components/Tables/TableDetail';
import CustomTableRecords from './components/Tables/CustomTableRecords';
import AllTables from './components/Tables/AllTables';
import { CIList } from './components/CMDB/CIList';
import { CIForm } from './components/CMDB/CIForm';
import { CIDetails } from './components/CMDB/CIDetails';
import SWCatalogList from './components/CMDB/SWCatalog/SWCatalogList'; // Import SWCatalogList
import { CITypeSettings } from './components/CMDB/CITypeSettings';
import ReportList from './components/Reports/ReportList';
import Dashboard from './components/Dashboard/Dashboard';
import CalendarList from './components/Settings/Calendar/CalendarList';
import CalendarDetail from './components/Settings/Calendar/CalendarDetail';
import HolidayList from './components/Settings/Holidays/HolidayList';
import HolidayDetail from './components/Settings/Holidays/HolidayDetail';
import SLAList from './components/SLA/SLAList';
import ScanDataList from './components/Discovery/ScanDataList';
import ScanDataDetail from './components/Discovery/ScanDataDetail';
import ImportToCMDB from './components/Discovery/ImportToCMDB';
import TokenManagement from './components/Discovery/TokenManagement';
import ScriptExecution from './components/Settings/ScriptLibrary/ScriptExecution';
import HelpersManager from './components/Settings/ScriptLibrary/HelpersManager';

const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, isLoading } = useAuth();
  
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return user ? <>{children}</> : <Navigate to="/login" />;
};

const AppContent = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  if (!user) {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/login" element={<Navigate to="/" replace />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        <Route index element={<Navigate to="/dashboard" replace />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="requests" element={<RequestList />} />
        <Route path="workflow-templates" element={<WorkflowTemplateList />} />
        <Route 
          path="workflow-templates/new" 
          element={
            <NewWorkflowTemplate 
              onClose={() => window.history.back()}
              onSuccess={() => window.location.href = '/workflow-templates'}
            />
          } 
        />
        {/* Workflow Instance Routes */}
        <Route path="workflow-instances" element={<WorkflowInstanceList />} />
        <Route path="workflow-instances/:id" element={<WorkflowInstanceDetails />} />
        
        {/* Task Routes - Use GenericList/Form */}
        {/* <Route path="tasks" element={<TaskList />} /> */}
        {/* <Route path="tasks/:id" element={<TaskDetails />} /> */}
        <Route path="list/task" element={<GenericList tableName="task" />} />
        <Route path="form/task" element={<GenericForm tableName="task" />} />
        <Route path="form/task/:recordId" element={<GenericForm tableName="task" />} />
        
        {/* Report Routes */}
        <Route path="reports" element={<ReportList />} />
        
        <Route path="rules" element={<RuleList />} />
        <Route path="teams" element={<TeamsPage />} /> {/* Use TeamsPage for the route */}
        {/* Use GenericList for the 'user' table */}
        <Route path="list/user" element={<GenericList tableName="user" />} />
        {/* Add routes for GenericForm for the 'user' table */}
        <Route path="form/user" element={<GenericForm tableName="user" />} />
        <Route path="form/user/:recordId" element={<GenericForm tableName="user" />} />
        <Route path="knowledge-base" element={<KnowledgeBaseList />} />
        <Route path="tables" element={<TablesLayout />}>
          <Route index element={<AllTables />} />
          <Route path="custom/:tableName/*" element={<CustomTableRecords />} />
          <Route path=":id" element={<TableDetail />} />
        </Route>
        <Route path="cmdb" element={<CIList />} />
        <Route path="cmdb/new" element={<CIForm />} />
        <Route path="cmdb/edit/:id" element={<CIForm mode="edit" />} />
        <Route path="cmdb/swcatalog" element={<SWCatalogList />} /> {/* Add specific route for swcatalog */}
        <Route path="cmdb/:id" element={
          <CIDetails 
            open={true} 
            onClose={() => navigate('/cmdb')} 
          />
        } />
        <Route path="cmdb/settings" element={<CITypeSettings />} />
        <Route path="cmdb/discovery/scan-data" element={<ScanDataList />} />
        <Route path="cmdb/discovery/scan-data/:id" element={<ScanDataDetail />} />
        <Route path="cmdb/discovery/import" element={<ImportToCMDB />} />
        <Route path="cmdb/discovery/import/:id" element={<ImportToCMDB />} />
        <Route path="cmdb/discovery/token-management" element={<TokenManagement />} />
        <Route path="settings/calendar" element={<CalendarList />} />
        <Route path="settings/calendar/:id" element={<CalendarDetail />} />
        <Route path="settings/holidays" element={<HolidayList />} />
        <Route path="settings/holidays/:id" element={<HolidayDetail />} />
        <Route path="settings/script-execution" element={<ScriptExecution />} />
        <Route path="settings/helpers" element={<HelpersManager />} />
        <Route path="sla" element={<SLAList />} />
      </Route>
    </Routes>
  );
};

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider maxSnack={3}>
            <ConfirmProvider>
              <AuthProvider>
                <LayoutProvider> {/* Wrap AppContent with LayoutProvider */}
                  <AppContent />
                </LayoutProvider>
              </AuthProvider>
            </ConfirmProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Toolbar
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { Outlet, useLocation } from 'react-router-dom';
import { TableMetadataBase } from '../../types/table';
import tableService from '../../services/tableService';
import CreateTableDialog from './CreateTableDialog';
import { useNavigate } from 'react-router-dom';

const TablesLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const handleCreateTable = async (tableData: TableMetadataBase) => {
    try {
      const response = await tableService.createTable(tableData);
      if (response.data.type === 'custom') {
        navigate(`/tables/custom/${response.data.name}`);
      } else {
        navigate(`/tables/${response.data.id || response.data._id}`);
      }
    } catch (error) {
      console.error('Failed to create table:', error);
    }
  };

  // Only show the header on the main tables page
  const showHeader = location.pathname === '/tables';

  return (
    <Box sx={{ width: '100%' }}>
      {showHeader && (
        <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
          <Typography variant="h6" component="div" sx={{ flex: '1 1 100%' }}>
            Tables
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setCreateDialogOpen(true)}
          >
            Create Table
          </Button>
        </Toolbar>
      )}

      <Box sx={{ mt: 2 }}>
        <Outlet />
      </Box>

      <CreateTableDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onSubmit={handleCreateTable}
      />
    </Box>
  );
};

export default TablesLayout;

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Disable ResizeObserver loop error in development
// This prevents the "ResizeObserver loop completed with undelivered notifications" error
const originalError = window.console.error;
window.console.error = (...args) => {
  if (args[0]?.includes?.('ResizeObserver loop completed with undelivered notifications')) {
    // Ignore this specific error
    return;
  }
  originalError(...args);
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

import api from '../api/axios';

export interface DashboardStats {
  stats: {
    activeRequests: number;
    pendingTasks: number;
    activeWorkflows: number;
  };
  recentActivity: Array<{
    _id: string;
    title: string;
    status: string;
    updatedAt: string;
  }>;
}

const dashboardService = {
  getStats: async (): Promise<DashboardStats> => {
    const response = await api.get('/dashboard/stats');
    return response.data;
  }
};

export default dashboardService;
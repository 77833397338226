import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  Switch,
  FormControlLabel
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TableField, TableRecord } from '../../types/table';

interface CreateRecordDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: any) => Promise<void>;
  fields: TableField[];
  initialData?: TableRecord;
  isEdit?: boolean;
}

const CreateRecordDialog: React.FC<CreateRecordDialogProps> = ({
  open,
  onClose,
  onSubmit,
  fields,
  initialData,
  isEdit = false
}) => {
  // Create validation schema based on fields
  const validationSchema = Yup.object().shape(
    fields.reduce((acc: Record<string, any>, field) => {
      let validator;

      switch (field.type) {
        case 'text':
        case 'email':
        case 'url':
        case 'phone':
          validator = Yup.string();
          if (field.type === 'email') {
            validator = validator.email('Invalid email format');
          }
          if (field.type === 'url') {
            validator = validator.url('Invalid URL format');
          }
          break;
        case 'number':
          validator = Yup.number()
            .typeError('Must be a number');
          break;
        case 'boolean':
          validator = Yup.boolean();
          break;
        case 'date':
          validator = Yup.date()
            .typeError('Must be a valid date');
          break;
        default:
          validator = Yup.string();
      }

      if (field.required) {
        validator = validator.required('This field is required');
      }

      acc[field.name] = validator;
      return acc;
    }, {})
  );

  // Create initial values based on fields
  const initialValues = fields.reduce<Record<string, any>>((acc, field) => {
    acc[field.name] = initialData?.[field.name] ?? field.defaultValue ?? '';
    return acc;
  }, {});

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
        onClose();
      } catch (error) {
        console.error('Failed to submit record:', error);
      }
    },
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{isEdit ? 'Edit Record' : 'Create New Record'}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {fields.map((field) => {
              switch (field.type) {
                case 'boolean':
                  return (
                    <FormControlLabel
                      key={field.name}
                      control={
                        <Switch
                          name={field.name}
                          checked={formik.values[field.name]}
                          onChange={formik.handleChange}
                        />
                      }
                      label={field.label || field.name}
                    />
                  );
                case 'select':
                case 'multiselect':
                  return (
                    <FormControl
                      key={field.name}
                      fullWidth
                      error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                    >
                      <InputLabel>{field.label || field.name}</InputLabel>
                      <Select
                        name={field.name}
                        value={formik.values[field.name]}
                        onChange={formik.handleChange}
                        multiple={field.type === 'multiselect'}
                        label={field.label || field.name}
                      >
                        {field.options?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {formik.touched[field.name] && formik.errors[field.name] ? 
                          String(formik.errors[field.name]) : ''}
                      </FormHelperText>
                    </FormControl>
                  );
                default:
                  return (
                    <TextField
                      key={field.name}
                      fullWidth
                      name={field.name}
                      label={field.label || field.name}
                      type={field.type === 'number' ? 'number' : 'text'}
                      value={formik.values[field.name]}
                      onChange={formik.handleChange}
                      error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                      helperText={
                        formik.touched[field.name] && formik.errors[field.name] 
                          ? String(formik.errors[field.name]) 
                          : ''
                      }
                      required={field.required}
                    />
                  );
              }
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">
            {isEdit ? 'Save Changes' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateRecordDialog;

// Improved nlp-processor.js
const botLogger = require('../utils/bot-logger');

class NLPProcessor {
    constructor() {
      // Initialize with empty intent and entity recognizers
      this.intentPatterns = {};
      this.entityExtractors = {};
      
      // Set up basic intent patterns
      this.setupIntentPatterns();
      
      // Set up entity extractors
      this.setupEntityExtractors();
      
      // Add greeting patterns
      this.greetingPatterns = [
        'hello', 'hi', 'hey', 'good morning', 'good afternoon', 'good evening', 
        'greetings', 'howdy', 'what\'s up', 'sup'
      ];
      
      // Add help patterns
      this.helpPatterns = [
        'help', 'help me', 'what can you do', 'how does this work', 'show me',
        'menu', 'options', 'commands', 'features', 'capabilities'
      ];
    }
    
    setupIntentPatterns() {
      // Request management intents
      this.intentPatterns.createRequest = [
        'create a request',
        'create request',
        'new request',
        'open a request',
        'raise a request',
        'submit a request',
        'log a request',
        'make a request',
        'file a new request',
        'I need to create a request',
        'I want to create a request',
        'I need to report a problem',
        'report an issue',
        'log an issue',
        'create incident',
        'raise ticket',
        'create ticket',
        'submit ticket',
        'open ticket',
        'report problem',
        'broken',
        'not working'
      ];
      
      this.intentPatterns.updateRequest = [
        'update request',
        'update a request',
        'modify request',
        'change request',
        'edit request',
        'alter request',
        'amend request',
        'update ticket',
        'change the status',
        'modify ticket'
      ];
      
      // Knowledge management intents
      this.intentPatterns.suggestKnowledge = [
        'suggest article',
        'suggest knowledge article',
        'find article',
        'find knowledge',
        'knowledge about',
        'article about',
        'documentation for',
        'help doc',
        'knowledge base entry',
        'how to',
        'how do I',
        'guide for',
        'tutorial on',
        'find information on',
        'get information'
      ];
      
      this.intentPatterns.createKnowledge = [
        'create article',
        'create knowledge article',
        'new article',
        'new knowledge article',
        'add to knowledge base',
        'document this',
        'create documentation',
        'add article',
        'write documentation',
        'publish article'
      ];
      
      // Note intents
      this.intentPatterns.addNote = [
        'add note',
        'add a note',
        'create note',
        'make a note',
        'append note',
        'jot down',
        'add comment',
        'comment on',
        'leave a note'
      ];
      
      // Solution suggestion intents
      this.intentPatterns.suggestSolution = [
        'suggest solution',
        'recommend solution',
        'how to fix',
        'how to solve',
        'solve this',
        'resolve issue',
        'fix problem',
        'what should I do about',
        'how do I fix',
        'help me fix',
        'solution for',
        'troubleshoot',
        'resolve problem'
      ];
      
      // Retrieval intents
      this.intentPatterns.retrieveRequests = [
        'get requests',
        'find requests',
        'show requests',
        'list requests',
        'find tickets',
        'show tickets',
        'my requests',
        'search requests',
        'view my tickets',
        'show all tickets',
        'find my incidents',
        'open tickets',
        'pending tickets',
        'ticket status'
      ];
      
      // Request info intent
      this.intentPatterns.getRequestInfo = [
        'what is the request id',
        'what is the status of my request',
        'request id',
        'request status',
        'ticket number',
        'ticket id',
        'request details'
      ];
    }
    
    setupEntityExtractors() {
      // Request entities
      this.entityExtractors.type = (text) => {
        const types = [
          'incident', 'service request', 'change request', 'problem', 
          'request', 'ticket', 'issue', 'bug', 'defect', 'feature'
        ];
        
        for (const type of types) {
          if (text.toLowerCase().includes(type)) {
            return type;
          }
        }
        
        // Check for printer-related issues and categorize as 'incident'
        const printerIndicators = ['printer', 'print', 'printing', 'paper'];
        for (const indicator of printerIndicators) {
          if (text.toLowerCase().includes(indicator)) {
            return 'incident';
          }
        }
        
        // Default to 'incident' if no type is specified but we can infer it's a request
        const requestIndicators = ['broken', 'not working', 'issue', 'problem', 'fix', 'error'];
        for (const indicator of requestIndicators) {
          if (text.toLowerCase().includes(indicator)) {
            return 'incident';
          }
        }
        
        return null;
      };
      
      this.entityExtractors.priority = (text) => {
        const priorities = [
          'critical', 'high', 'medium', 'low', 'urgent', 'normal', 'p1', 'p2', 'p3', 'p4'
        ];
        
        for (const priority of priorities) {
          if (text.toLowerCase().includes(priority)) {
            // Normalize priority values
            if (priority === 'urgent' || priority === 'p1') return 'critical';
            if (priority === 'normal' || priority === 'p3') return 'medium';
            if (priority === 'p2') return 'high';
            if (priority === 'p4') return 'low';
            return priority;
          }
        }
        
        return null;
      };
      
      this.entityExtractors.requestId = (text) => {
        // Look for request IDs in format REQ12345 or #12345
        const match = text.match(/\b(REQ\d{4,}|#\d{4,})\b/i);
        return match ? match[0] : null;
      };
      
      // Extract description - improved to better identify the main problem
      this.entityExtractors.description = (text) => {
        // First check for explicit descriptions
        const descriptionIndicators = ['description:', 'about:', 'regarding:', 'details:'];
        for (const indicator of descriptionIndicators) {
          const index = text.toLowerCase().indexOf(indicator);
          if (index !== -1) {
            return text.substring(index + indicator.length).trim();
          }
        }
        
        // Extract description from patterns like "create request for X" or "create request X"
        const requestPatterns = [
          /create (?:a )?(?:new )?(?:request|ticket|incident) (?:for|about) (.*?)(?:$|type|priority)/i,
          /create (?:a )?(?:new )?(?:request|ticket|incident) (.*?)(?:$|type|priority)/i,
          /(?:new|open) (?:request|ticket|incident) (?:for|about) (.*?)(?:$|type|priority)/i,
          /(.*?) not working/i,
          /(.*?) broken/i,
          /(.*?) issue/i,
          /(.*?) problem/i
        ];
        
        for (const pattern of requestPatterns) {
          const match = text.match(pattern);
          if (match && match[1] && match[1].trim()) {
            return match[1].trim();
          }
        }
        
        // If no specific pattern matches, return the whole text as description
        // for create requests if we have intent but no description
        return null;
      };
      
      // Extract problem for solution suggestions
      this.entityExtractors.problem = (text) => {
        // First check for explicit problem descriptions
        const problemIndicators = ['problem:', 'issue:', 'error:', 'trouble with:', 'help with:'];
        for (const indicator of problemIndicators) {
          const index = text.toLowerCase().indexOf(indicator);
          if (index !== -1) {
            return text.substring(index + indicator.length).trim();
          }
        }
        
        // Extract problem from patterns
        const problemPatterns = [
          /(?:having|have|got|experiencing|facing) (?:a|an|some)? (.*?) (?:issue|problem|error|trouble)/i,
          /(.*?) (?:isn't|is not|won't|will not|doesn't|does not|can't|cannot) work(?:ing)?/i,
          /(.*?) (?:broke|broken|failed|stopped working)/i
        ];
        
        for (const pattern of problemPatterns) {
          const match = text.match(pattern);
          if (match && match[1] && match[1].trim()) {
            return match[1].trim();
          }
        }
        
        // If no specific pattern matches but we have issue indicators,
        // use the entire text as the problem description
        const issueIndicators = ['issue', 'problem', 'error', 'not working', 'broken', 'won\'t work', 'doesn\'t work', 'failed', 'trouble'];
        for (const indicator of issueIndicators) {
          if (text.toLowerCase().includes(indicator)) {
            // Remove intent-related phrases to clean up the problem description
            let cleanText = text.toLowerCase()
              .replace(/suggest solution/g, '')
              .replace(/recommend solution/g, '')
              .replace(/how to fix/g, '')
              .replace(/how to solve/g, '')
              .replace(/solve this/g, '')
              .replace(/resolve issue/g, '')
              .replace(/fix problem/g, '')
              .trim();
            
            return cleanText || text; // Return cleaned text or original if cleaning removed everything
          }
        }
        
        // If nothing else works, return the whole text as the problem
        return text;
      };
    }
    
    async process(text, context = {}) {
      botLogger.info('Processing message', { message: text, userId: context.userId || 'unknown' });
      
      // Step 1: Check for special cases like greetings
      const isGreeting = this.isGreeting(text);
      if (isGreeting) {
        botLogger.info('Detected greeting', { message: text });
        return {
          originalText: text,
          intent: 'greeting',
          entities: {},
          confidence: 0.95
        };
      }
      
      // Step 2: Check for help requests
      const isHelp = this.isHelpRequest(text);
      if (isHelp) {
        botLogger.info('Detected help request', { message: text });
        return {
          originalText: text,
          intent: 'help',
          entities: {},
          confidence: 0.95
        };
      }

      // Step 3: Identify the intent
      const intent = this.identifyIntent(text);
      
      // Step 4: Extract entities
      const entities = this.extractEntities(text);
      
      // Special case for createRequest
      if (intent === 'createRequest' && !entities.description) {
        // If we're creating a request and didn't extract a description via patterns,
        // use the entire text as the description after removing intent-related words
        let cleanText = text.toLowerCase()
          .replace(/create\s+(a\s+)?(new\s+)?request/g, '')
          .replace(/new\s+request/g, '')
          .replace(/open\s+(a\s+)?request/g, '')
          .replace(/log\s+(a\s+)?request/g, '')
          .replace(/submit\s+(a\s+)?request/g, '')
          .replace(/report\s+(a\s+)?problem/g, '')
          .replace(/type\s+\w+/g, '') // Remove type mentions
          .replace(/priority\s+\w+/g, '') // Remove priority mentions
          .trim();
        
        if (cleanText) {
          entities.description = cleanText;
        }
      }
      
      // Step 5: Apply context to resolve ambiguities or fill missing entities
      const enrichedEntities = this.applyContext(entities, context);
      
      // Log the processing result
      botLogger.logNlpProcessing(
        context.userId || 'unknown', 
        text, 
        { intent, entities: enrichedEntities, confidence: this.calculateConfidence(text, intent) }
      );
      
      // Step 6: Return the NLP result
      return {
        originalText: text,
        intent,
        entities: enrichedEntities,
        confidence: this.calculateConfidence(text, intent)
      };
    }
    
    isGreeting(text) {
      const normalizedText = text.toLowerCase().trim();
      return this.greetingPatterns.some(pattern => normalizedText.includes(pattern));
    }
    
    isHelpRequest(text) {
      const normalizedText = text.toLowerCase().trim();
      return this.helpPatterns.some(pattern => normalizedText.includes(pattern));
    }
    
    identifyIntent(text) {
      const normalizedText = text.toLowerCase();
      
      // Check for request info intent first - this is a high priority intent
      // that should be recognized when users ask about request IDs or status
      const requestInfoPatterns = [
        'what is the request id',
        'what is the status of my request',
        'request id',
        'request status',
        'ticket number',
        'ticket id',
        'request details'
      ];
      
      for (const pattern of requestInfoPatterns) {
        if (normalizedText.includes(pattern)) {
          return 'getRequestInfo';
        }
      }
      
      // Check for greeting intent
      if (this.isGreeting(normalizedText)) {
        return 'greeting';
      }
      
      // Check for help intent
      if (this.isHelpRequest(normalizedText)) {
        return 'help';
      }
      
      let bestIntent = null;
      let highestScore = 0;
      
      for (const [intent, patterns] of Object.entries(this.intentPatterns)) {
        // Calculate a score based on pattern matches
        const score = this.calculatePatternScore(text, patterns);
        
        if (score > highestScore) {
          highestScore = score;
          bestIntent = intent;
        }
      }
      
      // If confidence is too low, return unknown intent
      if (highestScore < 0.4) {
        return 'unknown';
      }
      
      return bestIntent;
    }
    
    calculatePatternScore(text, patterns) {
      let maxScore = 0;
      const normalizedText = text.toLowerCase();
      
      for (const pattern of patterns) {
        if (normalizedText.includes(pattern.toLowerCase())) {
          // Direct match
          const score = pattern.length / normalizedText.length;
          maxScore = Math.max(maxScore, score);
        } else {
          // Fuzzy match - simplified for demonstration
          // In a real implementation, you might use something like Levenshtein distance
          const words = pattern.toLowerCase().split(' ');
          let matchedWords = 0;
          
          for (const word of words) {
            if (normalizedText.includes(word) && word.length > 3) {
              matchedWords++;
            }
          }
          
          const score = matchedWords / words.length * 0.8; // 80% of exact match
          maxScore = Math.max(maxScore, score);
        }
      }
      
      return maxScore;
    }
    
    extractEntities(text) {
      const entities = {};
      
      // Extract entities using the registered extractors
      for (const [entityName, extractor] of Object.entries(this.entityExtractors)) {
        const value = extractor(text);
        if (value) {
          entities[entityName] = value;
        }
      }
      
      return entities;
    }
    
    applyContext(entities, context) {
      // Merge with entities from context if they're missing in current entities
      const enrichedEntities = { ...entities };
      
      if (context.lastEntities) {
        for (const [key, value] of Object.entries(context.lastEntities)) {
          if (!enrichedEntities[key]) {
            enrichedEntities[key] = value;
          }
        }
      }
      
      // Special case: if we're referring to the last request
      if (
        !enrichedEntities.requestId && 
        context.lastResult && 
        context.lastResult.requestId && 
        (context.currentText && (
          context.currentText.toLowerCase().includes('it') || 
          context.currentText.toLowerCase().includes('that request')
        ))
      ) {
        enrichedEntities.requestId = context.lastResult.requestId;
      }
      
      return enrichedEntities;
    }
    
    calculateConfidence(text, intent) {
      if (intent === 'unknown') {
        return 0.3; // Base confidence for unknown intent
      }
      
      const patterns = this.intentPatterns[intent] || [];
      return this.calculatePatternScore(text, patterns);
    }
}

module.exports = { NLPProcessor };
import React, { useState, useEffect } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  IconButton,
  CircularProgress,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  Link as MuiLink // Rename MUI Link to avoid conflict
} from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { CI, AssociatedCollection } from '../../types/cmdb';
import { collectionService } from '../../services/collectionService';
import { ciTypeService } from '../../services/ciTypeService';
import { useSnackbar } from 'notistack';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`collection-tabpanel-${index}`}
      aria-labelledby={`collection-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

interface SchemaField {
  type: string;
  required: boolean;
  enum?: string[];
  default?: any;
  label?: string;
}

interface SchemaDefinition {
  [key: string]: SchemaField;
}

interface CollectionSchema {
  model: string;
  schema: SchemaDefinition;
}

interface CIDetailCollectionsProps {
  ci: CI;
  onUpdate?: () => void;
}

interface IPAddress {
  ip_address: string;
  subnet_mask?: string;
  address_family: 'IPv4' | 'IPv6';
}

export const CIDetailCollections: React.FC<CIDetailCollectionsProps> = ({ ci, onUpdate }) => {
  const [collections, setCollections] = useState<AssociatedCollection[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [collectionItems, setCollectionItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const [editItemId, setEditItemId] = useState<string | null>(null);
  const [schemaDefinition, setSchemaDefinition] = useState<CollectionSchema | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate(); // Initialize navigate

  // Load CI Type and its associated collections
  useEffect(() => {
    const fetchData = async () => {
      if (!ci || !ci.type || !ci.type._id) return;
      
      try {
        setLoading(true);
        const ciTypeData = await ciTypeService.getType(ci.type._id);
        
        // Sort collections by display order
        const sortedCollections = [...(ciTypeData.associatedCollections || [])].sort(
          (a, b) => a.displayOrder - b.displayOrder
        ).filter(c => c.enabled);
        
        setCollections(sortedCollections);
      } catch (error) {
        console.error('Error loading CI Type:', error);
        enqueueSnackbar('Failed to load collections', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, [ci, enqueueSnackbar]);

  // Load collection items and schema when tab changes
  useEffect(() => {
    const fetchItems = async () => {
      if (!ci || !ci._id || collections.length === 0) return;
      
      const currentCollection = collections[tabIndex];
      if (!currentCollection) return;
      
      try {
        setLoading(true);
        
        // Get schema definition for the collection model
        console.log(`Fetching schema for ${currentCollection.model}`);
        const schema = await collectionService.getCollectionSchema(currentCollection.model);
        console.log('Schema received:', schema);
        setSchemaDefinition(schema);
        
        // Get collection items
        console.log(`Fetching items for ${currentCollection.model}`);
        const items = await collectionService.getCollectionItems(ci._id, currentCollection.model);
        console.log('Items received:', items);
        setCollectionItems(items);
      } catch (error: any) {
        console.error(`Error loading ${currentCollection.model} data:`, error);
        
        // Log more detailed error information
        if (error.response) {
          console.error('Error details:', error.response.data);
        }
        
        // Show a more detailed error message to the user
        const errorMessage = error.response?.data?.message || `Failed to load ${currentCollection.label} data`;
        enqueueSnackbar(errorMessage, { 
          variant: 'error',
          autoHideDuration: 6000
        });
        
        // Clear schema definition to prevent form rendering with old data
        setSchemaDefinition(null);
      } finally {
        setLoading(false);
      }
    };
    
    fetchItems();
  }, [tabIndex, collections, ci, enqueueSnackbar]);

  // Handle tab change
  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  // Handle form input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name as string]: value
    });
  };

  // Handle select change
  const handleSelectChange = (event: SelectChangeEvent<any>, _: React.ReactNode) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name as string]: value
    });
  };

  // Reset form data
  const resetForm = () => {
    setFormData({});
    setEditItemId(null);
  };

  // Open add/edit modal
  const openModal = (item?: any) => {
    if (item) {
      setFormData({ ...item });
      setEditItemId(item._id);
    } else {
      resetForm();
    }
    setModalOpen(true);
  };

  // Add or update a collection item
  const handleSaveItem = async () => {
    if (!ci || !ci._id || collections.length === 0) return;
    
    const currentCollection = collections[tabIndex];
    
    try {
      setLoading(true);
      
      // Validate required fields
      if (schemaDefinition) {
        const requiredFields = Object.entries(schemaDefinition.schema)
          .filter(([key, field]) => field.required && key !== 'ci_id')
          .map(([key]) => key);
        
        console.log('Required fields:', requiredFields);
        
        const missingFields = requiredFields.filter(field => !formData[field]);
        if (missingFields.length > 0) {
          const missingFieldLabels = missingFields.map(field => {
            const fieldDef = schemaDefinition.schema[field];
            return fieldDef.label || field;
          });
          
          enqueueSnackbar(`Please fill in required fields: ${missingFieldLabels.join(', ')}`, { variant: 'error' });
          setLoading(false);
          return;
        }
      }
      
      // Add CI ID to form data
      const itemData = {
        ...formData,
        ci_id: ci._id
      };
      
      console.log('Saving item data:', itemData);
      
      if (editItemId) {
        // Update existing item
        console.log(`Updating item ${editItemId} for collection ${currentCollection.model}`);
        const updatedItem = await collectionService.updateCollectionItem(
          ci._id,
          currentCollection.model,
          editItemId,
          itemData
        );
        
        // Update the items list
        setCollectionItems(
          collectionItems.map(item => (item._id === editItemId ? updatedItem : item))
        );
        
        enqueueSnackbar('Item updated successfully', { variant: 'success' });
      } else {
        // Add new item
        console.log(`Adding new item for collection ${currentCollection.model}`);
        const newItem = await collectionService.addCollectionItem(
          ci._id,
          currentCollection.model,
          itemData
        );
        
        // Add to the items list
        setCollectionItems([...collectionItems, newItem]);
        
        enqueueSnackbar('Item added successfully', { variant: 'success' });
      }
      
      setModalOpen(false);
      resetForm();
      
      // Call onUpdate if provided
      if (onUpdate) {
        onUpdate();
      }
    } catch (error: any) {
      console.error('Error saving item:', error);
      const errorMessage = error.response?.data?.message || 'Failed to save item';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // Delete a collection item
  const handleDeleteItem = async (itemId: string) => {
    if (!ci || !ci._id || collections.length === 0) return;
    
    const currentCollection = collections[tabIndex];
    
    try {
      setLoading(true);
      await collectionService.deleteCollectionItem(ci._id, currentCollection.model, itemId);
      
      // Remove from the items list
      setCollectionItems(collectionItems.filter(item => item._id !== itemId));
      
      enqueueSnackbar('Item deleted successfully', { variant: 'success' });
      
      // Call onUpdate if provided
      if (onUpdate) {
        onUpdate();
      }
    } catch (error) {
      console.error('Error deleting item:', error);
      enqueueSnackbar('Failed to delete item', { variant: 'error' });
      console.error('Error details:', error);
    } finally {
      setLoading(false);
    }
  };

  // Get table columns based on the schema definition
  const getColumns = () => {
    if (!schemaDefinition) return [];
    
    // Filter out internal fields and ci_id
    const columns = Object.keys(schemaDefinition.schema)
      .filter(key => key !== 'ci_id' && key !== '_id')
      .map(key => ({
        id: key,
        label: schemaDefinition.schema[key].label || key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
        type: schemaDefinition.schema[key].type
      }));
    
    // Add actions column
    columns.push({ id: 'actions', label: 'Actions', type: 'actions' });
    
    return columns;
  };

  // Render cell content based on column type and value
  const renderCellContent = (column: string, value: any) => {
    if (!schemaDefinition || !schemaDefinition.schema[column]) {
      return value || 'N/A';
    }
    
    const fieldType = schemaDefinition.schema[column].type;
    
    // Handle null or undefined values
    if (value === null || value === undefined) {
      return 'N/A';
    }
    
    // Handle arrays
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return 'None';
      }
      
      // If array contains objects (like ip_addresses)
      if (typeof value[0] === 'object' && value[0] !== null) {
        return value.map((item, index) => {
          if (item.ip_address) {
            // Special handling for IP address objects
            return (
              <div key={index}>
                {item.ip_address} {item.subnet_mask ? `(${item.subnet_mask})` : ''}
              </div>
            );
          } else {
            // Generic object handling
            return (
              <div key={index}>
                {Object.entries(item)
                  .map(([key, val]) => `${key}: ${val}`)
                  .join(', ')}
              </div>
            );
          }
        });
      }
      
      // Simple array of primitives
      return value.join(', ');
    }
    
    // Handle objects that are not in arrays
    if (typeof value === 'object') {
      return JSON.stringify(value);
    }

    // --- START Link Rendering Logic for softwareInstanceCiId ---
    if (column === 'softwareInstanceCiId' && typeof value === 'string' && value) {
      // Render the ID as a clickable link
      return (
        <MuiLink
          component="button" // Render as button for onClick
          variant="body2"
          onClick={() => handleSoftwareInstanceClick(value)}
          sx={{ cursor: 'pointer' }}
          underline="hover"
        >
          {value} {/* Display the ID */}
        </MuiLink>
      );
    }
    // --- END Link Rendering Logic ---

    switch (fieldType) {
      case 'Boolean':
        return value ? 'Yes' : 'No';
      case 'Date':
        return value ? new Date(value).toLocaleString() : 'N/A';
      case 'Number':
        return value.toString();
      default:
        // Ensure value is a string or number before returning
        return (typeof value === 'string' || typeof value === 'number') ? value : JSON.stringify(value);
    }
  };

  // Dynamically render form fields based on schema
  const renderFormFields = () => {
    if (!schemaDefinition) {
      console.log('No schema definition available');
      return (
        <Typography color="error">
          Schema definition not available. Please try again.
        </Typography>
      );
    }
    
    console.log('Rendering form fields with schema:', schemaDefinition);
    
    return Object.keys(schemaDefinition.schema)
      .filter(key => key !== 'ci_id' && key !== '_id')
      .map(fieldName => {
        const field = schemaDefinition.schema[fieldName];
        const label = field.label || fieldName.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        
        console.log(`Rendering field: ${fieldName}, type: ${field.type}`);
        
        switch (field.type) {
          case 'String':
            if (field.enum && field.enum.length > 0) {
              return (
                <FormControl key={fieldName} fullWidth sx={{ mb: 2 }}>
                  <InputLabel>{label}</InputLabel>
                  <Select
                    name={fieldName}
                    value={formData[fieldName] || ''}
                    onChange={handleSelectChange}
                    label={label}
                    required={field.required}
                  >
                    {field.enum.map(option => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                  {field.required && (
                    <FormHelperText>Required</FormHelperText>
                  )}
                </FormControl>
              );
            } else {
              return (
                <TextField
                  key={fieldName}
                  fullWidth
                  margin="dense"
                  name={fieldName}
                  label={label}
                  value={formData[fieldName] || ''}
                  onChange={handleInputChange}
                  required={field.required}
                  sx={{ mb: 2 }}
                />
              );
            }
          case 'Number':
            return (
              <TextField
                key={fieldName}
                fullWidth
                margin="dense"
                name={fieldName}
                label={label}
                type="number"
                value={formData[fieldName] || ''}
                onChange={handleInputChange}
                required={field.required}
                sx={{ mb: 2 }}
              />
            );
          case 'Boolean':
            return (
              <FormControlLabel
                key={fieldName}
                control={
                  <Checkbox
                    name={fieldName}
                    checked={!!formData[fieldName]}
                    onChange={(e) => setFormData({
                      ...formData,
                      [fieldName]: e.target.checked
                    })}
                  />
                }
                label={label}
                sx={{ mb: 2 }}
              />
            );
          case 'Date':
            return (
              <TextField
                key={fieldName}
                fullWidth
                margin="dense"
                name={fieldName}
                label={label}
                type="datetime-local"
                value={formData[fieldName] ? new Date(formData[fieldName]).toISOString().slice(0, 16) : ''}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                required={field.required}
                sx={{ mb: 2 }}
              />
            );
          case 'Array':
            // Special handling for ip_addresses array
            if (fieldName === 'ip_addresses') {
              const ipAddresses = formData.ip_addresses || [] as IPAddress[];
              
              return (
                <Box key={fieldName} sx={{ mb: 2, border: '1px solid #e0e0e0', p: 2, borderRadius: 1 }}>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>{label}</Typography>
                  
                  {ipAddresses.map((ip: IPAddress, index: number) => (
                    <Box key={index} sx={{ display: 'flex', gap: 1, mb: 1 }}>
                      <TextField
                        size="small"
                        label="IP Address"
                        value={ip.ip_address || ''}
                        onChange={(e) => {
                          const newIpAddresses = [...ipAddresses];
                          newIpAddresses[index] = { ...newIpAddresses[index], ip_address: e.target.value };
                          setFormData({ ...formData, ip_addresses: newIpAddresses });
                        }}
                        sx={{ flex: 2 }}
                      />
                      <TextField
                        size="small"
                        label="Subnet Mask"
                        value={ip.subnet_mask || ''}
                        onChange={(e) => {
                          const newIpAddresses = [...ipAddresses];
                          newIpAddresses[index] = { ...newIpAddresses[index], subnet_mask: e.target.value };
                          setFormData({ ...formData, ip_addresses: newIpAddresses });
                        }}
                        sx={{ flex: 2 }}
                      />
                      <FormControl size="small" sx={{ flex: 1 }}>
                        <InputLabel>Family</InputLabel>
                        <Select
                          value={ip.address_family || 'IPv4'}
                          label="Family"
                          onChange={(e) => {
                            const newIpAddresses = [...ipAddresses];
                            newIpAddresses[index] = { ...newIpAddresses[index], address_family: e.target.value };
                            setFormData({ ...formData, ip_addresses: newIpAddresses });
                          }}
                        >
                          <MenuItem value="IPv4">IPv4</MenuItem>
                          <MenuItem value="IPv6">IPv6</MenuItem>
                        </Select>
                      </FormControl>
                      <IconButton 
                        color="error" 
                        onClick={() => {
                          const newIpAddresses = [...ipAddresses];
                          newIpAddresses.splice(index, 1);
                          setFormData({ ...formData, ip_addresses: newIpAddresses });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                  
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      const newIpAddresses = [...ipAddresses, { ip_address: '', subnet_mask: '', address_family: 'IPv4' }];
                      setFormData({ ...formData, ip_addresses: newIpAddresses });
                    }}
                    size="small"
                    sx={{ mt: 1 }}
                  >
                    Add IP Address
                  </Button>
                </Box>
              );
            }
            
            // Generic array handling for other array types
            return (
              <TextField
                key={fieldName}
                fullWidth
                margin="dense"
                name={fieldName}
                label={`${label} (comma separated)`}
                value={Array.isArray(formData[fieldName]) ? formData[fieldName].join(', ') : ''}
                onChange={(e) => {
                  const value = e.target.value.split(',').map(item => item.trim());
                  setFormData({ ...formData, [fieldName]: value });
                }}
                required={field.required}
                sx={{ mb: 2 }}
              />
            );
          default:
            return (
              <TextField
                key={fieldName}
                fullWidth
                margin="dense"
                name={fieldName}
                label={label}
                value={formData[fieldName] || ''}
                onChange={handleInputChange}
                required={field.required}
                sx={{ mb: 2 }}
              />
            );
        }
      });
  };

  if (collections.length === 0) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="body1" color="text.secondary">
          No associated collections found for this CI type.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={tabIndex} 
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {collections.map((collection) => (
            <Tab key={collection._id} label={collection.label} />
          ))}
        </Tabs>
      </Box>

      {collections.map((collection, index) => (
        <TabPanel key={collection._id} value={tabIndex} index={index}>
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">{collection.label}</Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => openModal()}
              disabled={loading}
            >
              Add {collection.label}
            </Button>
          </Box>

          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            <TableContainer component={Paper} variant="outlined">
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {getColumns().map((column) => (
                      <TableCell key={column.id}>{column.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {collectionItems.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={getColumns().length} align="center">
                        No items found
                      </TableCell>
                    </TableRow>
                  ) : (
                    collectionItems.map((item) => (
                      <TableRow key={item._id}>
                        {getColumns()
                          .filter(column => column.id !== 'actions')
                          .map((column) => (
                            <TableCell key={`${item._id}-${column.id}`}>
                              {renderCellContent(column.id, item[column.id])}
                            </TableCell>
                          ))}
                        <TableCell>
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <IconButton
                              size="small"
                              onClick={() => openModal(item)}
                              disabled={loading}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              size="small"
                              color="error"
                              onClick={() => handleDeleteItem(item._id)}
                              disabled={loading}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </TabPanel>
      ))}

      {/* Add/Edit Item Dialog */}
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editItemId ? 'Edit' : 'Add'} {collections[tabIndex]?.label}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2 }}>
            {renderFormFields()}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} disabled={loading}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSaveItem}
            disabled={loading}
          >
            {editItemId ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );

  // --- START Navigation Handler ---
  function handleSoftwareInstanceClick(ciId: string) {
    if (ciId) {
      // Navigate to the CI details page/route for the clicked ID
      // Adjust the path if your route structure is different
      navigate(`/cmdb/ci/${ciId}`);
      // If CIDetails is always a modal, you might need a different way
      // to trigger opening it with the new ID instead of navigating.
      // For now, assuming navigation is the desired behavior.
    }
  }
  // --- END Navigation Handler ---
};
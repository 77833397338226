import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Button,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Schedule as ScheduleIcon,
} from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import calendarService from '../../../services/calendarService';
import { Calendar, WorkingDay } from '../../../types/calendar';

const CalendarDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [calendar, setCalendar] = useState<Calendar | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const loadCalendar = useCallback(async () => {
    if (!id) return;

    try {
      setLoading(true);
      const data = await calendarService.getById(id);
      setCalendar(data);
    } catch (err) {
      setError('Failed to load calendar');
      console.error('Error loading calendar:', err);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    loadCalendar();
  }, [id, loadCalendar]);

  const getScheduleLabel = (calendar: Calendar) => {
    switch (calendar.scheduleType) {
      case '24/7':
        return '24/7 Operation';
      case '8/5':
        return '8/5 Operation';
      case 'custom':
        return 'Custom Schedule';
      default:
        return 'Unknown Schedule';
    }
  };

  if (loading) {
    return <Typography>Loading calendar...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!calendar) {
    return <Typography>Calendar not found</Typography>;
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={3}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/settings/calendar')}
          sx={{ mr: 2 }}
        >
          Back to Calendars
        </Button>
        <Typography variant="h5">{calendar.name}</Typography>
      </Box>

      <Paper sx={{ mb: 3, p: 2 }}>
        <Box display="flex" alignItems="center" mb={2}>
          <ScheduleIcon sx={{ mr: 1 }} />
          <Typography variant="h6" component="span" sx={{ mr: 2 }}>
            Schedule Configuration
          </Typography>
          <Chip
            label={getScheduleLabel(calendar)}
            color={calendar.scheduleType === '24/7' ? 'success' : 'primary'}
          />
        </Box>

        {calendar.description && (
          <Typography color="textSecondary" paragraph>
            {calendar.description}
          </Typography>
        )}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Day</TableCell>
                <TableCell>Working Day</TableCell>
                <TableCell>Working Hours</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {calendar.workingDays.map((day: WorkingDay) => (
                <TableRow key={day.day}>
                  <TableCell>{day.day.charAt(0).toUpperCase() + day.day.slice(1)}</TableCell>
                  <TableCell>
                    <Chip
                      label={day.isWorking ? 'Working' : 'Non-working'}
                      color={day.isWorking ? 'success' : 'default'}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {day.isWorking && day.workingHours ? `${day.workingHours.start} - ${day.workingHours.end}` : '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default CalendarDetail;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Switch,
  FormControlLabel,
  Tooltip,
  Chip,
  CircularProgress,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Grid
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Refresh as RefreshIcon,
  ContentCopy as CopyIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@mui/icons-material';
import { discoveryService } from '../../services/discoveryService';
import { DiscoveryToken } from '../../types/discovery';
import { formatDistanceToNow } from 'date-fns';

const TokenManagement: React.FC = () => {
  const [tokens, setTokens] = useState<DiscoveryToken[]>([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openRegenerateDialog, setOpenRegenerateDialog] = useState(false);
  const [currentToken, setCurrentToken] = useState<DiscoveryToken | null>(null);
  const [newToken, setNewToken] = useState({
    name: '',
    description: '',
    enabled: true
  });
  const [showToken, setShowToken] = useState<Record<string, boolean>>({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error' | 'info' | 'warning'
  });

  useEffect(() => {
    loadTokens();
  }, []);

  const loadTokens = async () => {
    setLoading(true);
    try {
      // Try to get tokens, but don't fail if there's an authentication error
      let response;
      try {
        response = await discoveryService.getAllTokens();
      } catch (error) {
        console.warn('Failed to load tokens, might be an authentication issue:', error);
        // Set empty array if there's an error
        response = [];
      }
      
      setTokens(response);
      // Initialize visibility state for each token
      const visibilityState: Record<string, boolean> = {};
      response.forEach((token: DiscoveryToken) => {
        visibilityState[token._id] = false;
      });
      setShowToken(visibilityState);
    } catch (error) {
      console.error('Failed to load tokens:', error);
      setSnackbar({
        open: true,
        message: 'Failed to load tokens',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCreateToken = async () => {
    try {
      // Use the debug method for testing
      await discoveryService.createTokenDebug({
        name: newToken.name,
        description: newToken.description
      });
      setSnackbar({
        open: true,
        message: 'Token created successfully',
        severity: 'success'
      });
      setOpenDialog(false);
      setNewToken({
        name: '',
        description: '',
        enabled: true
      });
      loadTokens();
    } catch (error) {
      console.error('Failed to create token:', error);
      setSnackbar({
        open: true,
        message: 'Failed to create token',
        severity: 'error'
      });
    }
  };

  const handleUpdateToken = async (id: string, enabled: boolean) => {
    try {
      await discoveryService.updateToken(id, { enabled });
      setSnackbar({
        open: true,
        message: `Token ${enabled ? 'enabled' : 'disabled'} successfully`,
        severity: 'success'
      });
      loadTokens();
    } catch (error) {
      console.error('Failed to update token:', error);
      setSnackbar({
        open: true,
        message: 'Failed to update token',
        severity: 'error'
      });
    }
  };

  const handleDeleteToken = async () => {
    if (!currentToken) return;
    
    try {
      await discoveryService.deleteToken(currentToken._id);
      setSnackbar({
        open: true,
        message: 'Token deleted successfully',
        severity: 'success'
      });
      setOpenDeleteDialog(false);
      setCurrentToken(null);
      loadTokens();
    } catch (error) {
      console.error('Failed to delete token:', error);
      setSnackbar({
        open: true,
        message: 'Failed to delete token',
        severity: 'error'
      });
    }
  };

  const handleRegenerateToken = async () => {
    if (!currentToken) return;
    
    try {
      const response = await discoveryService.regenerateToken(currentToken._id);
      setSnackbar({
        open: true,
        message: 'Token regenerated successfully',
        severity: 'success'
      });
      setOpenRegenerateDialog(false);
      setCurrentToken(null);
      loadTokens();
    } catch (error) {
      console.error('Failed to regenerate token:', error);
      setSnackbar({
        open: true,
        message: 'Failed to regenerate token',
        severity: 'error'
      });
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setSnackbar({
          open: true,
          message: 'Token copied to clipboard',
          severity: 'success'
        });
      },
      (err) => {
        console.error('Could not copy text: ', err);
        setSnackbar({
          open: true,
          message: 'Failed to copy token',
          severity: 'error'
        });
      }
    );
  };

  const toggleTokenVisibility = (id: string) => {
    setShowToken(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const formatDate = (dateString?: string) => {
    if (!dateString) return 'Never';
    return formatDistanceToNow(new Date(dateString), { addSuffix: true });
  };

  // Debug function to check API status
  const checkApiStatus = async () => {
    try {
      const status = await discoveryService.checkApiStatus();
      setSnackbar({
        open: true,
        message: `API is working: ${status.message} at ${status.timestamp}`,
        severity: 'success'
      });
    } catch (error) {
      console.error('API status check failed:', error);
      setSnackbar({
        open: true,
        message: 'API status check failed',
        severity: 'error'
      });
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5" component="h1">
          Discovery API Tokens
        </Typography>
        <Box>
          <Button
            variant="outlined"
            color="info"
            onClick={checkApiStatus}
            sx={{ mr: 2 }}
          >
            Check API Status
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
          >
            Create New Token
          </Button>
        </Box>
      </Box>

      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            About API Tokens
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            API tokens are used to authenticate external scanners with the Discovery API. 
            Each token should be assigned to a specific scanner or integration.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>Security Note:</strong> Tokens are displayed only once when created. 
            Make sure to copy and store them securely.
          </Typography>
        </CardContent>
      </Card>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : tokens.length === 0 ? (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="body1" color="text.secondary">
            No tokens found. Create a new token to get started.
          </Typography>
        </Paper>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Token</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Last Used</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tokens.map((token) => (
                <TableRow key={token._id}>
                  <TableCell>{token.name}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2" sx={{ fontFamily: 'monospace', mr: 1 }}>
                        {showToken[token._id] ? token.token : '••••••••••••••••••••••••••'}
                      </Typography>
                      <IconButton 
                        size="small" 
                        onClick={() => toggleTokenVisibility(token._id)}
                        aria-label={showToken[token._id] ? "Hide token" : "Show token"}
                      >
                        {showToken[token._id] ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
                      </IconButton>
                      <IconButton 
                        size="small" 
                        onClick={() => copyToClipboard(token.token)}
                        aria-label="Copy token"
                      >
                        <CopyIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={token.enabled}
                          onChange={(e) => handleUpdateToken(token._id, e.target.checked)}
                          color="primary"
                        />
                      }
                      label={
                        <Chip 
                          label={token.enabled ? "Enabled" : "Disabled"} 
                          color={token.enabled ? "success" : "default"}
                          size="small"
                        />
                      }
                    />
                  </TableCell>
                  <TableCell>{formatDate(token.createdAt)}</TableCell>
                  <TableCell>{formatDate(token.lastUsed)}</TableCell>
                  <TableCell>{token.description || '-'}</TableCell>
                  <TableCell>
                    <Tooltip title="Regenerate Token">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setCurrentToken(token);
                          setOpenRegenerateDialog(true);
                        }}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Token">
                      <IconButton
                        color="error"
                        onClick={() => {
                          setCurrentToken(token);
                          setOpenDeleteDialog(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Create Token Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Create New API Token</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Create a new API token for external scanners to authenticate with the Discovery API.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Token Name"
            fullWidth
            variant="outlined"
            value={newToken.name}
            onChange={(e) => setNewToken({ ...newToken, name: e.target.value })}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            label="Description (Optional)"
            fullWidth
            variant="outlined"
            value={newToken.description}
            onChange={(e) => setNewToken({ ...newToken, description: e.target.value })}
            multiline
            rows={2}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleCreateToken} 
            variant="contained" 
            color="primary"
            disabled={!newToken.name}
          >
            Create Token
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Token Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Delete Token</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the token "{currentToken?.name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteToken} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Regenerate Token Dialog */}
      <Dialog open={openRegenerateDialog} onClose={() => setOpenRegenerateDialog(false)}>
        <DialogTitle>Regenerate Token</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to regenerate the token "{currentToken?.name}"? The current token will be invalidated and any systems using it will need to be updated.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRegenerateDialog(false)}>Cancel</Button>
          <Button onClick={handleRegenerateToken} color="warning" variant="contained">
            Regenerate
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TokenManagement;

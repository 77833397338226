import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Chip,
  Paper,
  FormControlLabel,
  Switch,
  Dialog as MuiDialog,
  DialogContentText,
} from '@mui/material';
import { Close as CloseIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Rule } from '../../types/rule';
import api from '../../api/axios';
import NewRule from './NewRule';

interface RuleDetailProps {
  open: boolean;
  onClose: () => void;
  ruleId: string | null;
  onSuccess: () => void;
}

interface RuleAction {
  type: string;
  targetField?: string;
  sourceFields?: string[];
  aiConfig?: {
    prompt: {
      systemMessage?: string;
      template: string;
    };
  };
  manualConfig?: {
    transformation: string;
  };
  scriptConfig?: {
    code?: string;
    inputFields?: string[];
    outputField?: string;
  };
}

const RuleDetail: React.FC<RuleDetailProps> = ({
  open,
  onClose,
  ruleId,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [rule, setRule] = useState<Rule | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [jobStatus, setJobStatus] = useState<any>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const fetchRule = useCallback(async () => {
    if (!ruleId) return;
    setLoading(true);
    try {
      const response = await api.get(`/rules/${ruleId}`);
      setRule(response.data);
    } catch (err) {
      console.error('Error fetching rule:', err);
    } finally {
      setLoading(false);
    }
  }, [ruleId]);

  useEffect(() => {
    if (ruleId && open) {
      fetchRule();
    }
  }, [ruleId, open, fetchRule]);

  const handleToggleActive = async () => {
    if (!rule) return;
    setSaving(true);
    try {
      await api.patch(`/rules/${rule._id}`, {
        isActive: !rule.isActive,
      });
      onSuccess();
    } catch (error) {
      console.error('Error updating rule:', error);
    } finally {
      setSaving(false);
    }
  };

  const handleEditClick = () => {
    setEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
  };

  const handleEditSuccess = () => {
    fetchRule();
    onSuccess();
    setEditDialogOpen(false);
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/rules/${ruleId}`);
      setDeleteDialogOpen(false);
      onSuccess();
      onClose();
    } catch (error: any) {
      console.error('Error deleting rule:', error);
    }
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  if (!rule) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogContent>
          <Box display="flex" justifyContent="center" p={3}>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  const getExecutionColor = (type: string) => {
    switch (type) {
      case 'ai':
        return 'primary';
      case 'manual':
        return 'secondary';
      default:
        return 'default';
    }
  };

  const getModeColor = (mode: string) => {
    switch (mode) {
      case 'sync':
        return 'success';
      case 'async':
        return 'warning';
      default:
        return 'default';
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <Typography variant="h5">{rule.name}</Typography>
              <Box display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Switch
                      checked={rule.isActive}
                      onChange={handleToggleActive}
                      disabled={saving}
                      color={rule.isActive ? "success" : "default"}
                    />
                  }
                  label={
                    <Typography variant="body2" color={rule.isActive ? "success.main" : "text.secondary"}>
                      {saving ? "Updating..." : rule.isActive ? "Active" : "Inactive"}
                    </Typography>
                  }
                />
              </Box>
            </Box>
            <Box>
              <IconButton onClick={handleEditClick} size="small" sx={{ mr: 1 }}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={handleDeleteClick} size="small" sx={{ mr: 1 }} color="error">
                <DeleteIcon />
              </IconButton>
              <IconButton onClick={onClose} size="small">
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <Box display="flex" flexDirection="column" gap={3}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="body1" color="text.secondary">
                {rule.description}
              </Typography>
            </Box>

            <Paper variant="outlined" sx={{ p: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Trigger Configuration
              </Typography>
              <Box display="flex" gap={1}>
                <Chip
                  label={`Object: ${rule.trigger?.objectType || 'unknown'}`}
                  size="small"
                  color="info"
                />
                <Chip
                  label={`Type: ${rule.trigger?.type || 'unknown'}`}
                  size="small"
                  variant="outlined"
                />
              </Box>
            </Paper>

            <Paper variant="outlined" sx={{ p: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Execution Configuration
              </Typography>
              <Box display="flex" gap={1}>
                <Chip
                  label={`Type: ${rule.execution?.type || 'unknown'}`}
                  size="small"
                  color={getExecutionColor(rule.execution?.type || '')}
                />
                <Chip
                  label={`Mode: ${rule.execution?.mode || 'N/A'}`}
                  size="small"
                  color={rule.execution?.mode ? getModeColor(rule.execution.mode) : 'default'}
                />
              </Box>
            </Paper>

            <Paper variant="outlined" sx={{ p: 2 }}>
              <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                Action Configuration
              </Typography>
              {rule.version === 2 ? (
                <Box>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    This rule uses workflow-based actions (v2).
                  </Typography>
                  <Box mt={1}>
                    <Typography variant="body2">
                      {rule.actions?.length || 0} action(s) configured
                    </Typography>
                  </Box>
                </Box>
              ) : rule.action ? (
                <Box display="flex" gap={1}>
                  <Chip
                    label={`Type: ${rule.action?.type || 'unknown'}`}
                    size="small"
                    color="primary"
                  />
                  {rule.action?.type === 'update' && (
                    <Chip
                      label={`Target: ${rule.action?.targetField || 'unknown'}`}
                      size="small"
                      color="secondary"
                    />
                  )}
                  {rule.action?.type === 'script' && rule.action?.scriptConfig?.outputField && (
                    <Chip
                      label={`Output: ${rule.action?.scriptConfig?.outputField}`}
                      size="small"
                      color="secondary"
                    />
                  )}
                </Box>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No action configured
                </Typography>
              )}
            </Paper>
          </Box>
        </DialogContent>

        <DialogActions sx={{ p: 2.5 }}>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Rule Dialog */}
      <NewRule
        open={editDialogOpen}
        onClose={handleEditClose}
        onSuccess={handleEditSuccess}
        rule={rule}
      />

      {/* Delete Confirmation Dialog */}
      <MuiDialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Rule</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this rule? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </MuiDialog>
    </>
  );
};

export default RuleDetail;

import api from '../api/axios';
import { Calendar, CreateCalendarDto } from '../types/calendar';

const BASE_URL = '/calendars';

const calendarService = {
  getAll: async (): Promise<Calendar[]> => {
    const response = await api.get(BASE_URL);
    return response.data;
  },

  getById: async (id: string): Promise<Calendar> => {
    const response = await api.get(`${BASE_URL}/${id}`);
    return response.data;
  },

  create: async (calendarData: CreateCalendarDto): Promise<Calendar> => {
    const response = await api.post(BASE_URL, calendarData);
    return response.data;
  },

  update: async (id: string, calendarData: Partial<CreateCalendarDto>): Promise<Calendar> => {
    console.log('Updating calendar:', { id, calendarData }); // Debug log
    const response = await api.put(`${BASE_URL}/${id}`, calendarData);
    return response.data;
  },

  delete: async (id: string): Promise<void> => {
    await api.delete(`${BASE_URL}/${id}`);
  },
};

export default calendarService;

import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Divider,
  CircularProgress,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Chip,
  IconButton,
  Tooltip,
} from '@mui/material';
import { PlayArrow as RunIcon, Clear as ClearIcon, ExpandMore as ExpandMoreIcon, ContentCopy as CopyIcon, Help as HelpIcon } from '@mui/icons-material';
import api from '../../../api/axios';

// Monaco Editor for code editing
import Editor from '@monaco-editor/react';

const ScriptExecution: React.FC = () => {
  const [script, setScript] = useState<string>('// Write your JavaScript code here\n// Available variables: mongoose, models, logger\n\nlogger.info("Script started");\n\n// Example: Query data\n// const results = await models.RawScanData.find({ status: "pending" }).limit(5);\n// logger.info(`Found ${results.length} pending scan records`);\n\nlogger.info("Script completed");');
  const [output, setOutput] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [scriptType, setScriptType] = useState<string>('standalone');
  const [expandedExample, setExpandedExample] = useState<string | false>(false);

  const handleScriptChange = (value: string | undefined) => {
    if (value !== undefined) {
      setScript(value);
    }
  };

  const handleRun = async () => {
    setIsLoading(true);
    setOutput('');
    setError(null);

    try {
      // Remove the /api prefix since it's already in the baseURL
      const response = await api.post('/scripts/execute', {
        code: script,
        type: scriptType
      });

      setOutput(response.data.output.join('\n'));
    } catch (err: any) {
      console.error('Script execution error:', err);
      setError(err.response?.data?.message || 'An error occurred while executing the script');
      setOutput(err.response?.data?.output?.join('\n') || '');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = () => {
    setOutput('');
    setError(null);
  };

  const handleCopyExample = (exampleCode: string) => {
    setScript(exampleCode);
  };

  // Example scripts
  const examples = [
    {
      id: 'query-basic',
      title: 'Basic Query',
      description: 'Find all users with pagination',
      code: `// Query all users with pagination
const page = 1;
const limit = 10;
const skip = (page - 1) * limit;

const users = await models.User.find({})
  .select('username email isAdmin')
  .skip(skip)
  .limit(limit)
  .lean();

logger.info(\`Found \${users.length} users\`);
logger.info(JSON.stringify(users, null, 2));`,
      tags: ['query', 'find', 'pagination']
    },
    {
      id: 'query-filter',
      title: 'Query with Filters',
      description: 'Find items with specific criteria',
      code: `// Find all active servers with specific OS
const servers = await models.Ci.find({
  __t: 'Server',
  status: 'active',
  'attributes.os': { $regex: 'Linux', $options: 'i' }
})
.select('name ipAddress attributes')
.limit(10)
.lean();

logger.info(\`Found \${servers.length} Linux servers\`);
logger.info(JSON.stringify(servers, null, 2));`,
      tags: ['query', 'filter', 'regex']
    },
    {
      id: 'query-aggregate',
      title: 'Aggregation Pipeline',
      description: 'Group and aggregate data',
      code: `// Aggregate requests by status
const statusCounts = await models.Request.aggregate([
  { $group: { _id: '$status', count: { $sum: 1 } } },
  { $sort: { count: -1 } }
]);

logger.info('Request status distribution:');
logger.info(JSON.stringify(statusCounts, null, 2));`,
      tags: ['aggregate', 'group', 'statistics']
    },
    {
      id: 'create-document',
      title: 'Create Document',
      description: 'Create a new record in the database',
      code: `// Create a new knowledge base article
const newArticle = new models.KnowledgeBase({
  title: 'How to use Script Execution',
  content: 'This is a guide on using the Script Execution feature...',
  tags: ['scripts', 'howto', 'admin'],
  status: 'published',
  createdBy: '60d21b4667d0d8992e610c85' // Replace with actual user ID
});

// Save the new article
await newArticle.save();

logger.info('New article created:');
logger.info(JSON.stringify(newArticle.toObject(), null, 2));`,
      tags: ['create', 'insert', 'save']
    },
    {
      id: 'update-document',
      title: 'Update Document',
      description: 'Update existing records',
      code: `// Update multiple documents at once
const updateResult = await models.Task.updateMany(
  { status: 'pending', dueDate: { $lt: new Date() } },
  { 
    $set: { 
      status: 'overdue',
      updatedAt: new Date()
    },
    $push: {
      statusHistory: {
        status: 'overdue',
        timestamp: new Date(),
        comment: 'Automatically marked as overdue by script'
      }
    }
  }
);

logger.info(\`Updated \${updateResult.modifiedCount} tasks to overdue status\`);`,
      tags: ['update', 'modify', 'batch']
    },
    {
      id: 'delete-document',
      title: 'Delete Document',
      description: 'Remove records from the database',
      code: `// Delete old audit logs
const oneMonthAgo = new Date();
oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

const deleteResult = await models.AuditLog.deleteMany({
  createdAt: { $lt: oneMonthAgo },
  severity: 'info'
});

logger.info(\`Deleted \${deleteResult.deletedCount} old audit logs\`);`,
      tags: ['delete', 'remove', 'cleanup']
    },
    {
      id: 'relations-populate',
      title: 'Working with Relations',
      description: 'Query related documents with populate',
      code: `// Find requests with populated user and team data
const requests = await models.Request.find({ status: 'open' })
  .populate('createdBy', 'username email')
  .populate('assignedTeam', 'name members')
  .limit(5)
  .lean();

logger.info(\`Found \${requests.length} open requests with user and team data\`);
logger.info(JSON.stringify(requests, null, 2));`,
      tags: ['relations', 'populate', 'join']
    },
    {
      id: 'transaction',
      title: 'Using Transactions',
      description: 'Perform multiple operations atomically',
      code: `// Use transaction to ensure data consistency
const session = await mongoose.startSession();
session.startTransaction();

try {
  // Create a new team
  const newTeam = new models.Team({
    name: 'New Support Team',
    description: 'Team created via script'
  });
  await newTeam.save({ session });
  
  // Add users to the team
  const users = await models.User.find({ 
    department: 'Support',
    isActive: true
  }).limit(5);
  
  const userIds = users.map(user => user._id);
  
  // Update the team with members
  newTeam.members = userIds;
  await newTeam.save({ session });
  
  // Update each user to reference the team
  await models.User.updateMany(
    { _id: { $in: userIds } },
    { $push: { teams: newTeam._id } },
    { session }
  );
  
  // Commit the transaction
  await session.commitTransaction();
  logger.info(\`Created new team with \${userIds.length} members\`);
  logger.info(JSON.stringify(newTeam.toObject(), null, 2));
  
} catch (error) {
  // Abort transaction on error
  await session.abortTransaction();
  throw error;
} finally {
  // End session
  session.endSession();
}`,
      tags: ['transaction', 'atomic', 'consistency']
    }
  ];

  // Group examples by category
  const exampleCategories = [
    { title: 'Querying Data', ids: ['query-basic', 'query-filter', 'query-aggregate'] },
    { title: 'Creating & Modifying Data', ids: ['create-document', 'update-document', 'delete-document'] },
    { title: 'Advanced Operations', ids: ['relations-populate', 'transaction'] }
  ];

  return (
    <Box sx={{ p: 3, maxWidth: '100%' }}>
      <Typography variant="h4" gutterBottom>
        Script Execution
      </Typography>
      <Typography variant="body1" color="text.secondary" paragraph>
        Write and execute JavaScript code to interact with your database and perform operations.
      </Typography>

      {/* Help Section */}
      <Accordion sx={{ mb: 3 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="script-help-content"
          id="script-help-header"
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <HelpIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6">Script Examples & Help</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            Use these examples to learn how to interact with the database using scripts. Click the copy button to use an example.
          </Typography>
          
          <Typography variant="subtitle1" sx={{ mt: 2, mb: 1, fontWeight: 'bold' }}>
            Available Objects and Variables:
          </Typography>
          <List dense>
            <ListItem>
              <ListItemText 
                primary="mongoose" 
                secondary="The Mongoose ORM instance for advanced database operations"
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="models" 
                secondary="All database models (User, Request, Task, etc.)"
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="logger" 
                secondary="Logging utility (logger.info, logger.error, etc.)"
              />
            </ListItem>
          </List>
          
          <Divider sx={{ my: 2 }} />
          
          {exampleCategories.map((category) => (
            <Box key={category.title} sx={{ mb: 3 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                {category.title}
              </Typography>
              
              {category.ids.map((id) => {
                const example = examples.find(ex => ex.id === id);
                if (!example) return null;
                
                return (
                  <Accordion 
                    key={example.id}
                    expanded={expandedExample === example.id}
                    onChange={() => setExpandedExample(expandedExample === example.id ? false : example.id)}
                    sx={{ mb: 1 }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="subtitle2">{example.title}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {example.description}
                        </Typography>
                        <Box sx={{ mt: 0.5, display: 'flex', gap: 0.5 }}>
                          {example.tags.map(tag => (
                            <Chip 
                              key={tag} 
                              label={tag} 
                              size="small" 
                              variant="outlined" 
                              sx={{ height: 20, fontSize: '0.7rem' }}
                            />
                          ))}
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ position: 'relative', bgcolor: '#f5f5f5', p: 2, borderRadius: 1 }}>
                        <Tooltip title="Copy to editor">
                          <IconButton 
                            size="small" 
                            sx={{ position: 'absolute', top: 8, right: 8 }}
                            onClick={() => handleCopyExample(example.code)}
                          >
                            <CopyIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Box component="pre" sx={{ 
                          whiteSpace: 'pre-wrap', 
                          fontFamily: 'monospace',
                          fontSize: '0.9rem',
                          overflow: 'auto',
                          maxHeight: '300px',
                          mt: 1
                        }}>
                          {example.code}
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>

      <Box sx={{ mb: 3 }}>
        <FormControl sx={{ minWidth: 200, mb: 2 }}>
          <InputLabel>Script Type</InputLabel>
          <Select
            value={scriptType}
            label="Script Type"
            onChange={(e) => setScriptType(e.target.value)}
          >
            <MenuItem value="standalone">Standalone Script</MenuItem>
            <MenuItem value="rule">Rule Script</MenuItem>
            <MenuItem value="workflow">Workflow Script</MenuItem>
          </Select>
        </FormControl>
        
        <Paper elevation={3} sx={{ mb: 2 }}>
          <Box sx={{ height: 400, border: '1px solid #e0e0e0' }}>
            <Editor
              height="400px"
              defaultLanguage="javascript"
              value={script}
              onChange={handleScriptChange}
              options={{
                minimap: { enabled: false },
                scrollBeyondLastLine: false,
                fontSize: 14,
                automaticLayout: true,
              }}
            />
          </Box>
        </Paper>

        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <Button 
            variant="contained" 
            color="primary" 
            startIcon={<RunIcon />}
            onClick={handleRun}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Run Script'}
          </Button>
          <Button 
            variant="outlined" 
            startIcon={<ClearIcon />}
            onClick={handleClear}
            disabled={isLoading || (!output && !error)}
          >
            Clear Output
          </Button>
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {output && (
        <Paper elevation={3} sx={{ p: 2, mb: 3, maxHeight: '400px', overflow: 'auto' }}>
          <Typography variant="h6" gutterBottom>
            Output
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Box component="pre" sx={{ 
            whiteSpace: 'pre-wrap', 
            fontFamily: 'monospace',
            fontSize: '0.9rem',
            backgroundColor: '#f5f5f5',
            p: 1,
            borderRadius: 1
          }}>
            {output}
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default ScriptExecution;
export enum TaskType {
  ACTION = 'action',
  APPROVAL = 'approval',
  NOTIFICATION = 'notification'
}

export enum FieldType {
  TEXT = 'text',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  DATE = 'date',
  DATETIME = 'datetime',
  SELECT = 'select',
  REFERENCE = 'reference',
  ATTACHMENT = 'attachment'
}

export enum PriorityType {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  CRITICAL = 'critical'
}

export enum AssigneeType {
  USER = 'USER',
  TEAM = 'TEAM',
  ROLE = 'ROLE'
}

export interface User {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  active: boolean;
}

export interface Team {
  _id: string;
  name: string;
  description?: string;
  members: string[];
}

export interface Role {
  _id: string;
  name: string;
  permissions: string[];
}

export interface CustomField {
  id: string;
  name: string;
  label: string;
  type: FieldType;
  description?: string;
  required: boolean;
  readOnly: boolean;
  validation?: {
    min?: number;
    max?: number;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
  };
  options?: {
    value: string;
    label: string;
  }[];
  referenceConfig?: {
    objectType: string;
    displayField: string;
  };
}

export interface TaskRelationship {
  parentTasks: string[];
  childTasks: string[];
}

export interface TaskDefinition {
  id: string;
  name: string;
  description: string;
  type: string;
  assigneeType: AssigneeType;
  assigneeId: string;
  relationship: TaskRelationship;
  customFields?: CustomField[];
  status?: string;
  dueDate?: Date;
  priority?: PriorityType;
  duration: number;
}

export interface SLA {
  duration: number;
  priority: PriorityType;
}

export interface BusinessCase {
  description: string;
  priority: number;
  keywords: string[];
  examples: string[];
  conditions: string[];
}

export interface WorkflowTemplate {
  _id?: string;
  id?: string;
  name: string;
  description: string;
  version: string;
  category: string;
  sla: SLA;
  businessCase: BusinessCase;
  tasks: TaskDefinition[];
  customFields?: CustomField[];
  createdAt?: Date;
  updatedAt?: Date;
}

export type FormWorkflowTemplate = Omit<WorkflowTemplate, '_id'>;

export interface Task {
  _id: string;
  workflowInstanceId: string;
  templateTaskId: string;
  name: string;
  description?: string;
  type: 'approval' | 'action' | 'notification';
  status: 'pending' | 'approved' | 'rejected' | 'draft' | 'assigned' | 'in_progress' | 'waiting_for' | 'completed' | 'sent' | 'failed';
  assignee: {
    type: 'user' | 'team';
    value: string;
  };
  duration: number;
  priority: 'low' | 'medium' | 'high' | 'critical';
  startDate?: Date;
  dueDate?: Date;
  completedDate?: Date;
  relationship?: {
    parentTasks: string[];
    childTasks: string[];
    conditions?: {
      parentTaskId: string;
      condition: {
        field: string;
        operator: string;
        value: any;
      };
    }[];
  };
  result?: {
    decision?: 'approved' | 'rejected' | 'completed';
    comment?: string;
    attachments?: string[];
    waitingFor?: {
      type: string;
      description: string;
    };
  };
  metadata?: Record<string, any>;
  createdBy: string;
  updatedBy?: string;
}

export interface WorkflowInstance {
  _id: string;
  templateId: string;
  requests: string[];
  status: 'active' | 'completed' | 'cancelled' | 'failed';
  name: string;
  description?: string;
  priority: 'low' | 'medium' | 'high' | 'critical';
  startDate: Date;
  endDate?: Date;
  dueDate?: Date;
  progress: number;
  tasks: Task[];
  customFields?: Record<string, any>;
  metadata?: Record<string, any>;
  createdBy: string;
  updatedBy?: string;
}

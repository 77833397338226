import api from './api';
import axios from 'axios';
import { CI } from '../types/cmdb';

class CIService {
  async getAllCIs() {
    const response = await api.get('/cmdb/items');
    return response.data;
  }

  async getCI(id: string) {
    const response = await api.get(`/cmdb/items/${id}`);
    return response.data;
  }

  async createCI(data: Partial<CI>) {
    const response = await api.post('/cmdb/items', data);
    return response.data;
  }

  async updateCI(id: string, data: Partial<CI>) {
    const response = await api.put(`/cmdb/items/${id}`, data);
    return response.data;
  }

  async deleteCI(id: string) {
    const response = await api.delete(`/cmdb/items/${id}`);
    return response.data;
  }
  
  async updateAIInsights(id: string, aiInsights: string) {
    const response = await api.put(`/cmdb/items/${id}/ai-insights`, { aiInsights });
    return response.data;
  }

  async search(query: string) {
    const response = await api.get(`/cmdb/items/search?q=${encodeURIComponent(query)}`);
    return response.data;
  }
}

export const ciService = new CIService();

export const getCIById = async (id: string): Promise<CI> => {
  const response = await api.get(`/cmdb/items/${id}`);
  return response.data;
};

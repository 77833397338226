import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import slaService from '../../services/slaService';
import SLAForm from './SLAForm';

interface SLA {
  _id: string;
  type: 'SLA' | 'OLA';
  category?: string;
  priority?: string;
  team?: string;
  resolutionDuration: number;
  responseDuration: number;
  calendar: string;
  targetResolutionDate?: string;
  targetResponseDate?: string;
  isActive?: boolean;
}

const SLAList: React.FC = () => {
  const [slas, setSLAs] = useState<SLA[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedSLA, setSelectedSLA] = useState<SLA | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchSLAs = async () => {
    try {
      setLoading(true);
      const data = await slaService.getAll();
      setSLAs(data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch SLAs');
      console.error('Error fetching SLAs:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSLAs();
  }, []);

  const handleFormSuccess = () => {
    fetchSLAs();
  };

  const handleFormClose = () => {
    setIsFormOpen(false);
    setSelectedSLA(null);
  };

  const formatDuration = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ height: '100%', width: '100%', p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h5" component="h1">
          SLA Management
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setSelectedSLA(null);
            setIsFormOpen(true);
          }}
        >
          Create SLA
        </Button>
      </Box>

      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Category / Team</TableCell>
                <TableCell>Priority</TableCell>
                <TableCell>Response Time</TableCell>
                <TableCell>Resolution Time</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? slas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : slas
              ).map((sla) => (
                <TableRow key={sla._id}>
                  <TableCell>
                    <Chip 
                      label={sla.type} 
                      color={sla.type === 'SLA' ? 'primary' : 'secondary'} 
                    />
                  </TableCell>
                  <TableCell>{sla.type === 'SLA' ? sla.category : sla.team}</TableCell>
                  <TableCell>
                    {sla.priority && (
                      <Chip 
                        label={sla.priority} 
                        color={
                          sla.priority === 'critical' ? 'error' :
                          sla.priority === 'high' ? 'warning' :
                          sla.priority === 'medium' ? 'info' : 'default'
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell>{formatDuration(sla.responseDuration)}</TableCell>
                  <TableCell>{formatDuration(sla.resolutionDuration)}</TableCell>
                  <TableCell>
                    <Chip 
                      label={sla.isActive ? 'Active' : 'Inactive'} 
                      color={sla.isActive ? 'success' : 'default'} 
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setSelectedSLA(sla);
                        setIsFormOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={slas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}

      <SLAForm
        open={isFormOpen}
        onClose={handleFormClose}
        onSuccess={handleFormSuccess}
        sla={selectedSLA || undefined}
      />
    </Box>
  );
};

export default SLAList;

import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Grid,
  Chip,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TableMetadata, TableRecord } from '../../types/table';
import tableService from '../../services/tableService';
import { useSnackbar } from 'notistack';

interface TableDetailDialogProps {
  open: boolean;
  onClose: () => void;
  table: TableMetadata;
  onEdit?: () => void;
}

const TableDetailDialog: React.FC<TableDetailDialogProps> = ({
  open,
  onClose,
  table,
  onEdit,
}) => {
  const [records, setRecords] = useState<TableRecord[]>([]);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const loadRecords = useCallback(async () => {
    if (!table?._id && !table?.id) return;

    setLoading(true);
    try {
      let response;
      if (table.type === 'system') {
        response = await tableService.getSystemTableRecords(table.name);
      } else if (table.type === 'cmdb_collection') {
        response = await tableService.getCmdbCollectionRecords(table.name);
      } else {
        response = await tableService.getCustomTableRecords(table.name);
      }
      // Service layer now normalizes responses to always have data as an array
setRecords(response.data.slice(0, 5)); // Show only first 5 records in preview
    } catch (error) {
      console.error('Error loading records:', error);
      enqueueSnackbar('Error loading table records', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [table, enqueueSnackbar]);

  useEffect(() => {
    if (open && table) {
      loadRecords();
    }
  }, [open, table, loadRecords]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: { height: '80vh' }
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Table Details: {table.name}</Typography>
          <Chip
            label={table.type}
            color={table.type === 'system' ? 'primary' : 'secondary'}
            size="small"
          />
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              General Information
            </Typography>
            <Box sx={{ mb: 3 }}>
              <Typography variant="body2" color="text.secondary">
                Label: {table.label}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Description: {table.description || 'No description'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Number of Fields: {table.fields.length}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Fields
            </Typography>
            <TableContainer component={Paper} variant="outlined">
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Label</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Required</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {table.fields.map((field) => (
                    <TableRow key={field.name}>
                      <TableCell>{field.name}</TableCell>
                      <TableCell>{field.label}</TableCell>
                      <TableCell>{field.type}</TableCell>
                      <TableCell>
                        <Chip
                          label={field.required ? 'Yes' : 'No'}
                          color={field.required ? 'primary' : 'default'}
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Recent Records (Preview)
            </Typography>
            {loading ? (
              <Box display="flex" justifyContent="center" p={3}>
                <CircularProgress />
              </Box>
            ) : records.length > 0 ? (
              <TableContainer component={Paper} variant="outlined">
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {table.fields.map((field) => (
                        <TableCell key={field.name}>{field.label || field.name}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {records.map((record, index) => (
                      <TableRow key={record._id || index}>
                        {table.fields.map((field) => (
                          <TableCell key={field.name}>
                            {record[field.name]?.toString() || '-'}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No records found
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {table.type !== 'system' && onEdit && (
          <Button onClick={onEdit} color="primary">
            Edit Table
          </Button>
        )}
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TableDetailDialog;

// frontend/src/components/Settings/ScriptLibrary/HelpersManager.tsx
import React, { useState, useEffect, ReactNode } from 'react';
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import axios from 'axios';
import CodeEditor from '../../../components/common/CodeEditor';
import HelperDetail, { Helper, Example } from './HelperDetail';

interface FormDataType {
  name: string;
  category: string;
  description: string;
  code: string;
  scriptFormat: 'class' | 'function';
  examples: Example[];
  dependencies?: string[];
}

const HelpersManager = () => {
  const [helpers, setHelpers] = useState<Helper[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [selectedHelper, setSelectedHelper] = useState<Helper | null>(null);
  const [showTestModal, setShowTestModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [testParams, setTestParams] = useState<string>('[]');
  const [testResult, setTestResult] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [formData, setFormData] = useState<FormDataType>({
    name: '',
    category: '',
    description: '',
    code: '',
    scriptFormat: 'class',
    examples: []
  });

  useEffect(() => {
    fetchHelpers();
  }, []);

  const fetchHelpers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('/api/helpers');
      setHelpers(response.data.helpers);
      setCategories(response.data.categories);
      
      // Set initial category if available
      if (response.data.categories.length > 0 && !selectedCategory) {
        setSelectedCategory(response.data.categories[0]);
      }
    } catch (error) {
      console.error('Error fetching helpers:', error);
      if (axios.isAxiosError(error) && error.response) {
        setError(error.response.data?.message || 'Failed to fetch helpers');
      } else {
        setError('Failed to fetch helpers');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category);
  };

  const handleHelperClick = async (helper: Helper) => {
    try {
      setIsLoading(true);
      // Fetch the complete helper data including code
      const response = await axios.get(`/api/helpers/${helper.id}`);
      setSelectedHelper({
        ...helper,
        code: response.data.code
      });
      setShowDetailModal(true);
    } catch (error) {
      console.error('Error fetching helper details:', error);
      if (axios.isAxiosError(error) && error.response) {
        setError(error.response.data?.message || 'Failed to fetch helper details');
      } else {
        setError('Failed to fetch helper details');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleTestHelper = (helper: Helper) => {
    setSelectedHelper(helper);
    setTestParams(helper.examples.length > 0 ? JSON.stringify(helper.examples[0].params) : '[]');
    setTestResult('');
    setShowTestModal(true);
  };

  const handleEditHelper = (helper: Helper) => {
    setSelectedHelper(helper);
    setFormData({
      name: helper.name,
      category: helper.category,
      description: helper.description,
      code: helper.code,
      scriptFormat: 'class',
      examples: [...helper.examples]
    });
    setShowEditModal(true);
    
    // Log to confirm we're editing the helper
    console.log('Editing helper:', helper);
  };

  const handleCreateHelper = () => {
    setSelectedHelper(null);
    setFormData({
      name: '',
      category: selectedCategory || '',
      description: '',
      code: getDefaultTemplate('class'),
      scriptFormat: 'class',
      examples: []
    });
    setShowEditModal(true);
  };

  const getDefaultTemplate = (format: 'class' | 'function') => {
    if (format === 'class') {
      return `class MyHelper {
  constructor() {
    // Initialize your helper
  }
  
  // Main execute method - this will be called when using the helper
  execute(param1, param2) {
    logger.info('MyHelper execute called with:', param1, param2);
    
    // Your implementation here
    const result = this.processData(param1, param2);
    
    return result;
  }
  
  // Private helper methods
  processData(param1, param2) {
    // Process data and return results
    return {
      status: 'success',
      data: { param1, param2 }
    };
  }
}`;
    } else {
      return `// Legacy function format
async function myHelper(param1, param2) {
  logger.info('myHelper called with:', param1, param2);
  
  // Your implementation here
  
  return {
    status: 'success',
    data: { param1, param2 }
  };
}`;
    }
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target as { name?: string; value: unknown };
    if (name) {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleScriptFormatChange = (e: SelectChangeEvent) => {
    const format = e.target.value as 'class' | 'function';
    setFormData({
      ...formData,
      scriptFormat: format,
      code: formData.code === getDefaultTemplate(formData.scriptFormat === 'class' ? 'class' : 'function')
        ? getDefaultTemplate(format)
        : formData.code
    });
  };

  const handleCodeChange = (value: string) => {
    setFormData({
      ...formData,
      code: value
    });
  };

  const updateExample = (index: number, field: string, value: any) => {
    const updatedExamples = [...formData.examples];
    updatedExamples[index] = {
      ...updatedExamples[index],
      [field]: field === 'params' ? JSON.parse(value) : value
    };

    setFormData({
      ...formData,
      examples: updatedExamples
    });
  };

  const removeExample = (index: number) => {
    const updatedExamples = [...formData.examples];
    updatedExamples.splice(index, 1);
    
    setFormData({
      ...formData,
      examples: updatedExamples
    });
  };

  const addExample = () => {
    setFormData({
      ...formData,
      examples: [
        ...formData.examples,
        { name: 'Example', params: [] }
      ]
    });
  };

  const runTest = async () => {
    if (!selectedHelper) return;
    
    setIsLoading(true);
    setTestResult('');
    
    try {
      let params;
      try {
        params = JSON.parse(testParams);
      } catch (err) {
        setTestResult('Error: Invalid JSON parameters');
        return;
      }
      
      // Use the helper name instead of ID for testing
      const response = await axios.post(`/api/helpers/${selectedHelper.name}/test`, { params });
      setTestResult(JSON.stringify(response.data, null, 2));
    } catch (error) {
      console.error('Error testing helper:', error);
      if (axios.isAxiosError(error) && error.response) {
        setTestResult(`Error: ${error.response.data?.message || 'Test failed'}`);
      } else if (error instanceof Error) {
        setTestResult(`Error: ${error.message}`);
      } else {
        setTestResult('Error: Test failed');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const saveHelper = async () => {
    setIsLoading(true);
    setError('');
    
    console.log('Saving helper:', selectedHelper ? selectedHelper.id : 'new helper', formData);
    
    try {
      if (selectedHelper) {
        // Update existing helper
        console.log(`Updating helper with ID: ${selectedHelper.id}`);
        await axios.put(`/api/helpers/${selectedHelper.id}`, formData);
      } else {
        // Create new helper
        console.log('Creating new helper');
        await axios.post('/api/helpers', formData);
      }
      
      fetchHelpers();
      setShowEditModal(false);
    } catch (error) {
      console.error('Error saving helper:', error);
      if (axios.isAxiosError(error) && error.response) {
        setError(error.response.data?.message || 'Failed to save helper');
      } else if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('Failed to save helper');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const deleteHelper = async () => {
    if (!selectedHelper) return;
    
    if (!window.confirm(`Are you sure you want to delete the helper "${selectedHelper.name}"?`)) {
      return;
    }
    
    try {
      await axios.delete(`/api/helpers/${selectedHelper?.id}`);
      fetchHelpers();
      setShowEditModal(false);
      setShowDetailModal(false);
    } catch (error) {
      console.error('Error deleting helper:', error);
      if (axios.isAxiosError(error) && error.response) {
        setError(error.response.data?.message || 'Failed to delete helper');
      } else {
        setError('Failed to delete helper');
      }
    }
  };

  const filteredHelpers = helpers.filter(helper => helper.category === selectedCategory);

  return (
    <Box sx={{ p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Helpers Library</Typography>
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<AddIcon />}
          onClick={handleCreateHelper}
        >
          Create New Helper
        </Button>
      </Box>
      
      <Typography variant="body1" paragraph>
        Use these helpers in your scripts and rules to simplify common operations.
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {isLoading && !showTestModal && !showEditModal ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Tabs
            value={selectedCategory}
            onChange={(e, newValue) => setSelectedCategory(newValue)}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ mb: 2 }}
          >
            {categories.map(category => (
              <Tab key={category} label={category} value={category} />
            ))}
          </Tabs>

          <TableContainer component={Paper} sx={{ mb: 4 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredHelpers.map(helper => (
                  <TableRow key={helper.id}>
                    <TableCell>{helper.name}</TableCell>
                    <TableCell>{helper.description}</TableCell>
                    <TableCell>
                      <Chip 
                        label={helper.isBuiltIn ? "Built-in" : "Custom"} 
                        color={helper.isBuiltIn ? "primary" : "default"} 
                        size="small" 
                      />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton 
                        size="small" 
                        onClick={() => handleHelperClick(helper)}
                        title="View Details"
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton 
                        size="small" 
                        onClick={() => handleEditHelper(helper)}
                        title="Edit Helper"
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      {!helper.isBuiltIn && (
                        <IconButton 
                          size="small" 
                          onClick={() => { setSelectedHelper(helper); deleteHelper(); }}
                          title="Delete Helper"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      
      {/* Helper Detail Dialog */}
      <HelperDetail
        open={showDetailModal}
        onClose={() => setShowDetailModal(false)}
        helper={selectedHelper}
        onEdit={handleEditHelper}
        onTest={handleTestHelper}
      />

      {/* Test Modal */}
      <Dialog
        open={showTestModal}
        onClose={() => setShowTestModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Test Helper: {selectedHelper?.name}
        </DialogTitle>
        <DialogContent dividers>
          <Box mb={2}>
            <Typography variant="subtitle1" gutterBottom>Parameters (JSON format)</Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={testParams}
              onChange={(e) => setTestParams(e.target.value)}
              variant="outlined"
            />
          </Box>
          
          <Box display="flex" justifyContent="center" mb={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={runTest}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Run Test'}
            </Button>
          </Box>
          
          {testResult && (
            <Box>
              <Typography variant="subtitle1" gutterBottom>Result</Typography>
              <Paper variant="outlined" sx={{ p: 2, bgcolor: '#f5f5f5' }}>
                <pre style={{ margin: 0, whiteSpace: 'pre-wrap' }}>
                  {testResult}
                </pre>
              </Paper>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTestModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit/Create Modal */}
      <Dialog
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          {selectedHelper ? `Edit Helper: ${selectedHelper.name}` : 'Create New Helper'}
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleFormChange}
              required
              margin="normal"
              helperText="Use camelCase or PascalCase (e.g., UserValidator)"
            />
            
            <TextField
              fullWidth
              label="Category"
              name="category"
              value={formData.category}
              onChange={handleFormChange}
              required
              margin="normal"
              helperText="Group similar helpers together"
            />
            
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleFormChange}
              multiline
              rows={2}
              required
              margin="normal"
              helperText="Describe what this helper does and how to use it"
            />
            
            <FormControl fullWidth margin="normal">
              <InputLabel id="script-format-label">Script Format</InputLabel>
              <Select
                labelId="script-format-label"
                name="scriptFormat"
                value={formData.scriptFormat}
                onChange={handleScriptFormatChange}
                label="Script Format"
              >
                <MenuItem value="class">Class (ServiceNow-style)</MenuItem>
                <MenuItem value="function">Function (Legacy)</MenuItem>
              </Select>
            </FormControl>
            
            <Box mb={2} mt={2}>
              <Typography variant="subtitle1" gutterBottom>Code</Typography>
              <Paper variant="outlined" sx={{ p: 1 }}>
                <CodeEditor
                  value={formData.code}
                  onChange={handleCodeChange}
                  language="javascript"
                  height="300px"
                />
              </Paper>
              <Typography variant="caption" color="textSecondary">
                {formData.scriptFormat === 'class' ? 
                  "Define a class with an 'execute' method that will be called when using this helper. Access other helpers with 'new global.helper.OtherHelper()'." :
                  "Define a function that accepts parameters and returns a value or Promise. This is the legacy format."}
              </Typography>
            </Box>
            
            <Box mb={2}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <Typography variant="subtitle1">Examples</Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={addExample}
                >
                  Add Example
                </Button>
              </Box>
              
              {formData.examples.map((example, index) => (
                <Paper key={index} variant="outlined" sx={{ p: 2, mb: 2 }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <TextField
                      label="Example Name"
                      value={example.name}
                      onChange={(e) => updateExample(index, 'name', e.target.value)}
                      sx={{ flexGrow: 1, mr: 2 }}
                    />
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() => removeExample(index)}
                    >
                      Remove
                    </Button>
                  </Box>
                  
                  <TextField
                    fullWidth
                    label="Parameters (JSON array)"
                    value={JSON.stringify(example.params)}
                    onChange={(e) => updateExample(index, 'params', e.target.value)}
                    multiline
                    rows={2}
                  />
                </Paper>
              ))}
              
              {formData.examples.length === 0 && (
                <Typography variant="body2" color="textSecondary">
                  No examples added yet
                </Typography>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {selectedHelper && !selectedHelper.isBuiltIn && (
              <Button onClick={deleteHelper} color="error">
                Delete
              </Button>
            )}
          </div>
          <div>
            <Button 
              onClick={() => setShowEditModal(false)} 
              color="inherit" 
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              onClick={saveHelper}
              color="primary"
              variant="contained"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Save Changes'}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default HelpersManager;
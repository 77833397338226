import api from './api';
import { AssociatedCollection, ServerNetworkConnection, ServerUserAccount, ServerProcess, ServerDisk ,ServerNetworkAdapter } from '../types/cmdb';

class CollectionService {
  // Get all available collections
  async getAvailableCollections() {
    const response = await api.get('/cmdb/collections');
    return response.data;
  }

  // Associate a collection with a CI Type
  async associateCollection(ciTypeId: string, collection: Partial<AssociatedCollection>) {
    const response = await api.post(`/cmdb/citypes/${ciTypeId}/collections`, collection);
    return response.data;
  }

  // Update an associated collection
  async updateAssociatedCollection(ciTypeId: string, collectionId: string, data: Partial<AssociatedCollection>) {
    const response = await api.put(`/cmdb/citypes/${ciTypeId}/collections/${collectionId}`, data);
    return response.data;
  }

  // Remove an associated collection
  async removeAssociatedCollection(ciTypeId: string, collectionId: string) {
    const response = await api.delete(`/cmdb/citypes/${ciTypeId}/collections/${collectionId}`);
    return response.data;
  }

  // Get collection items for a CI
  async getCollectionItems(ciId: string, collectionName: string) {
    const response = await api.get(`/cmdb/cis/${ciId}/collections/${collectionName}`);
    return response.data;
  }

  // Add an item to a collection
  async addCollectionItem(ciId: string, collectionName: string, item: any) {
    const response = await api.post(`/cmdb/cis/${ciId}/collections/${collectionName}`, item);
    return response.data;
  }

  // Update a collection item
  async updateCollectionItem(ciId: string, collectionName: string, itemId: string, item: any) {
    const response = await api.put(`/cmdb/cis/${ciId}/collections/${collectionName}/${itemId}`, item);
    return response.data;
  }

  // Delete a collection item
  async deleteCollectionItem(ciId: string, collectionName: string, itemId: string) {
    const response = await api.delete(`/cmdb/cis/${ciId}/collections/${collectionName}/${itemId}`);
    return response.data;
  }

  // Get all network connections for a CI
  async getNetworkConnections(ciId: string) {
    const response = await api.get(`/cmdb/cis/${ciId}/networkconnections`);
    return response.data;
  }

  // Add a network connection to a CI
  async addNetworkConnection(ciId: string, networkConnection: ServerNetworkConnection) {
    const response = await api.post(`/cmdb/cis/${ciId}/networkconnections`, networkConnection);
    return response.data;
  }

  // Update a network connection
  async updateNetworkConnection(ciId: string, networkConnectionId: string, networkConnection: ServerNetworkConnection) {
    const response = await api.put(`/cmdb/cis/${ciId}/networkconnections/${networkConnectionId}`, networkConnection);
    return response.data;
  }

  // Delete a network connection
  async deleteNetworkConnection(ciId: string, networkConnectionId: string) {
    const response = await api.delete(`/cmdb/cis/${ciId}/networkconnections/${networkConnectionId}`);
    return response.data;
  }

  // Get all user accounts for a CI
  async getUserAccounts(ciId: string) {
    const response = await api.get(`/cmdb/cis/${ciId}/useraccounts`);
    return response.data;
  }

  // Add a user account to a CI
  async addUserAccount(ciId: string, userAccount: ServerUserAccount) {
    const response = await api.post(`/cmdb/cis/${ciId}/useraccounts`, userAccount);
    return response.data;
  }

  // Update a user account
  async updateUserAccount(ciId: string, userAccountId: string, userAccount: ServerUserAccount) {
    const response = await api.put(`/cmdb/cis/${ciId}/useraccounts/${userAccountId}`, userAccount);
    return response.data;
  }

  // Delete a user account
  async deleteUserAccount(ciId: string, userAccountId: string) {
    const response = await api.delete(`/cmdb/cis/${ciId}/useraccounts/${userAccountId}`);
    return response.data;
  }

  // Get all processes for a CI
  async getProcesses(ciId: string) {
    const response = await api.get(`/cmdb/cis/${ciId}/processes`);
    return response.data;
  }

  // Add a process to a CI
  async addProcess(ciId: string, process: ServerProcess) {
    const response = await api.post(`/cmdb/cis/${ciId}/processes`, process);
    return response.data;
  }

  // Update a process
  async updateProcess(ciId: string, processId: string, process: ServerProcess) {
    const response = await api.put(`/cmdb/cis/${ciId}/processes/${processId}`, process);
    return response.data;
  }

  // Delete a process
  async deleteProcess(ciId: string, processId: string) {
    const response = await api.delete(`/cmdb/cis/${ciId}/processes/${processId}`);
    return response.data;
  }

  // Get all disks for a CI
  async getDisks(ciId: string) {
    const response = await api.get(`/cmdb/cis/${ciId}/disks`);
    return response.data;
  }

  // Add a disk to a CI
  async addDisk(ciId: string, disk: ServerDisk) {
    const response = await api.post(`/cmdb/cis/${ciId}/disks`, disk);
    return response.data;
  }

  // Update a disk
  async updateDisk(ciId: string, diskId: string, disk: ServerDisk) {
    const response = await api.put(`/cmdb/cis/${ciId}/disks/${diskId}`, disk);
    return response.data;
  }

  // Delete a disk
  async deleteDisk(ciId: string, diskId: string) {
    const response = await api.delete(`/cmdb/cis/${ciId}/disks/${diskId}`);
    return response.data;
  }

  // Get schema definition for a collection model
  async getCollectionSchema(modelName: string) {
    const response = await api.get(`/cmdb/collections/${modelName}/schema`);
    return response.data;
  }
}

export const collectionService = new CollectionService();
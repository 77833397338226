import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Chip,
  Divider,
  Tab,
  Tabs,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import TaskList from '../Tasks/TaskList';
import workflowInstanceService from '../../services/workflowInstanceService';
import { formatDate } from '../../utils/dateUtils';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface WorkflowInstance {
  _id: string;
  templateId: string;
  requests: string[];
  name: string;
  description?: string;
  status: 'active' | 'completed' | 'cancelled' | 'failed';
  priority: 'low' | 'medium' | 'high' | 'critical';
  startDate: Date;
  endDate?: Date;
  dueDate?: Date;
  progress: number;
  createdBy: string;
  updatedBy?: string;
  tasks: any[];
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const WorkflowInstanceDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [instance, setInstance] = useState<WorkflowInstance | null>(null);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  const loadInstance = useCallback(async () => {
    try {
      const data = await workflowInstanceService.getWorkflowInstance(id!);
      setInstance(data);
    } catch (error) {
      console.error('Error loading workflow instance:', error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    loadInstance();
  }, [loadInstance]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleClose = () => {
    navigate(-1);
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'active': return 'primary';
      case 'completed': return 'success';
      case 'cancelled': return 'error';
      case 'failed': return 'error';
      default: return 'default';
    }
  };

  if (loading) {
    return (
      <Dialog open fullWidth maxWidth="md">
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  if (!instance) {
    return (
      <Dialog open fullWidth maxWidth="md">
        <DialogContent>
          <Box p={3}>
            <Typography>Workflow instance not found</Typography>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Workflow Instance Details</Typography>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ p: 3, mb: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  {instance.name}
                </Typography>
                <Typography color="textSecondary" paragraph>
                  {instance.description}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" justifyContent="flex-end" gap={1}>
                  <Chip 
                    label={instance.status} 
                    color={getStatusColor(instance.status)} 
                  />
                  <Chip label={`${instance.progress}%`} variant="outlined" />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="subtitle2">Start Date</Typography>
                <Typography>{instance.startDate ? formatDate(instance.startDate) : '-'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="subtitle2">Due Date</Typography>
                <Typography>{instance.dueDate ? formatDate(instance.dueDate) : '-'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="subtitle2">Priority</Typography>
                <Typography>{instance.priority}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="subtitle2">Created By</Typography>
                <Typography>{instance.createdBy || '-'}</Typography>
              </Grid>
            </Grid>
          </Paper>

          <Paper sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Tasks" />
                <Tab label="Timeline" />
                <Tab label="Details" />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <TaskList workflowInstanceId={instance._id} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              {/* Timeline component */}
              <Typography color="textSecondary">Timeline view coming soon</Typography>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2">Template ID</Typography>
                  <Typography>{instance.templateId}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2">Progress</Typography>
                  <Typography>{instance.progress}%</Typography>
                </Grid>
                {instance.endDate && (
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2">End Date</Typography>
                    <Typography>{formatDate(instance.endDate)}</Typography>
                  </Grid>
                )}
                {instance.updatedBy && (
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2">Last Updated By</Typography>
                    <Typography>{instance.updatedBy}</Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Associated Requests</Typography>
                  {instance.requests.length > 0 ? (
                    <Box sx={{ mt: 1 }}>
                      {instance.requests.map((requestId) => (
                        <Chip
                          key={requestId}
                          label={`Request ${requestId}`}
                          sx={{ mr: 1, mb: 1 }}
                          onClick={() => navigate(`/requests/${requestId}`)}
                        />
                      ))}
                    </Box>
                  ) : (
                    <Typography color="textSecondary">No requests associated</Typography>
                  )}
                </Grid>
              </Grid>
            </TabPanel>
          </Paper>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WorkflowInstanceDetails;

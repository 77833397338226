import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  SelectChangeEvent,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { parseISO, format } from 'date-fns';
import calendarService from '../../../services/calendarService';
import type { Calendar, WorkingDay } from '../../../types/calendar';

interface CalendarFormProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  calendar?: Calendar;
}

const defaultWorkingDays: WorkingDay[] = [
  { day: 'monday', isWorking: true, workingHours: { start: '09:00', end: '17:00' } },
  { day: 'tuesday', isWorking: true, workingHours: { start: '09:00', end: '17:00' } },
  { day: 'wednesday', isWorking: true, workingHours: { start: '09:00', end: '17:00' } },
  { day: 'thursday', isWorking: true, workingHours: { start: '09:00', end: '17:00' } },
  { day: 'friday', isWorking: true, workingHours: { start: '09:00', end: '17:00' } },
  { day: 'saturday', isWorking: false, workingHours: { start: '09:00', end: '17:00' } },
  { day: 'sunday', isWorking: false, workingHours: { start: '09:00', end: '17:00' } }
];

const get24x7WorkingDays = (): WorkingDay[] => defaultWorkingDays.map(day => ({
  ...day,
  isWorking: true,
  workingHours: { start: '00:00', end: '23:59' }
}));

const get8x5WorkingDays = (): WorkingDay[] => defaultWorkingDays.map(day => ({
  ...day,
  isWorking: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'].includes(day.day),
  workingHours: { start: '09:00', end: '17:00' }
}));

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string(),
  scheduleType: yup.string().oneOf(['24/7', '8/5', 'custom']).required('Schedule type is required'),
});

const CalendarForm: React.FC<CalendarFormProps> = ({
  open,
  onClose,
  onSuccess,
  calendar,
}) => {
  const [error, setError] = useState<string | null>(null);
  const isEditMode = Boolean(calendar);

  const formik = useFormik({
    initialValues: {
      name: calendar?.name || '',
      description: calendar?.description || '',
      scheduleType: calendar?.scheduleType || '8/5',
      workingDays: calendar?.workingDays || defaultWorkingDays,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const workingDays = values.scheduleType === 'custom' 
          ? values.workingDays
          : values.scheduleType === '24/7' 
            ? get24x7WorkingDays()
            : get8x5WorkingDays();

        const calendarData = {
          name: values.name,
          description: values.description,
          scheduleType: values.scheduleType,
          workingDays,
          timezone: calendar?.timezone || 'UTC',
          isActive: calendar?.isActive ?? true,
        };

        console.log('calendarData being sent to API:', calendarData);

        if (isEditMode && calendar?._id) {
          const trimmedId = calendar._id.trim();
          await calendarService.update(trimmedId, calendarData);
        } else {
          await calendarService.create(calendarData);
        }
        
        onSuccess();
        onClose();
        formik.resetForm();
      } catch (err: any) {
        console.error('Error details:', {
          message: err.message,
          response: err.response?.data,
          status: err.response?.status,
          config: err.config
        });
        setError(`Failed to save calendar: ${err.message}`);
      }
    },
    enableReinitialize: true,
  });

  const handleTypeChange = (event: SelectChangeEvent) => {
    const newType = event.target.value;
    formik.setFieldValue('scheduleType', newType);
    
    if (newType === '24/7') {
      formik.setFieldValue('workingDays', get24x7WorkingDays());
    } else if (newType === '8/5') {
      formik.setFieldValue('workingDays', get8x5WorkingDays());
    }
  };

  const handleClose = () => {
    formik.resetForm();
    setError(null);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {isEditMode ? 'Edit Calendar' : 'Create Calendar'}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="description"
                name="description"
                label="Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                multiline
                rows={3}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="type-label">Schedule Type</InputLabel>
                <Select
                  id="scheduleType"
                  name="scheduleType"
                  value={formik.values.scheduleType}
                  onChange={handleTypeChange}
                  label="Schedule Type"
                >
                  <MenuItem value="24/7">24/7 Operation</MenuItem>
                  <MenuItem value="8/5">8/5 Operation</MenuItem>
                  <MenuItem value="custom">Custom Schedule</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {formik.values.scheduleType === 'custom' && (
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Working Days
                </Typography>
                {formik.values.workingDays.map((day, index) => (
                  <Box key={day.day} sx={{ mb: 2 }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={day.isWorking}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const workingDays = [...formik.values.workingDays];
                                workingDays[index].isWorking = e.target.checked;
                                formik.setFieldValue('workingDays', workingDays);
                              }}
                            />
                          }
                          label={day.day.charAt(0).toUpperCase() + day.day.slice(1)}
                        />
                      </Grid>
                      {day.isWorking && (
                        <>
                          <Grid item xs={4}>
                            <TimePicker
                              label="Start Time"
                              value={parseISO(`2000-01-01T${day.workingHours.start}`)}
                              onChange={(newValue) => {
                                if (newValue) {
                                  const workingDays = [...formik.values.workingDays];
                                  workingDays[index].workingHours.start = format(newValue, 'HH:mm');
                                  formik.setFieldValue('workingDays', workingDays);
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TimePicker
                              label="End Time"
                              value={parseISO(`2000-01-01T${day.workingHours.end}`)}
                              onChange={(newValue) => {
                                if (newValue) {
                                  const workingDays = [...formik.values.workingDays];
                                  workingDays[index].workingHours.end = format(newValue, 'HH:mm');
                                  formik.setFieldValue('workingDays', workingDays);
                                }
                              }}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Box>
                ))}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">
            {isEditMode ? 'Save Changes' : 'Create Calendar'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CalendarForm;

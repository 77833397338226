import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, useTheme, Drawer } from '@mui/material';
import { Outlet } from 'react-router-dom';
import MainMenu from './MainMenu';
import ChatWidget from '../../agent/components/chatWidget';
import { useAuth } from '../../contexts/AuthContext';
import { useLayout } from '../../contexts/LayoutContext'; // Import useLayout hook

const DRAWER_WIDTH = 240;
const COLLAPSED_WIDTH = 64;

const Layout: React.FC = () => {
  const theme = useTheme();
  const { user } = useAuth(); // Get the current user
  const isMobile = window.innerWidth < theme.breakpoints.values.sm;
  const [mobileOpen, setMobileOpen] = useState(false);
  // const [isCollapsed, setIsCollapsed] = useState(false); // Remove local state
  const { isSidebarCollapsed, toggleSidebarCollapse } = useLayout(); // Use context state and toggle function

  // Close mobile drawer when screen size changes
  useEffect(() => {
    if (!isMobile) {
      setMobileOpen(false);
    }
  }, [isMobile]);

  const drawerWidth = isSidebarCollapsed ? COLLAPSED_WIDTH : DRAWER_WIDTH; // Use context state

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />

      {/* Mobile drawer */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={() => setMobileOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            width: DRAWER_WIDTH,
            background: '#1e2130',
            borderRadius: 0,
          },
        }}
      >
        <MainMenu onClose={() => setMobileOpen(false)} />
      </Drawer>

      {/* Desktop drawer */}
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          display: { xs: 'none', sm: 'block' },
        }}
      >
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              background: '#1e2130',
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
              overflowX: 'hidden',
              borderRight: '1px solid rgba(255, 255, 255, 0.05)',
              borderRadius: 0,
            },
          }}
          open
        >
          <MainMenu
            // isCollapsed prop is no longer needed, MainMenu will get it from context
            // onToggleCollapse prop is no longer needed, MainMenu will use context function
          />
        </Drawer>
      </Box>

      {/* Main content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          backgroundColor: '#f5f7fa',
        }}
      >
        <Outlet />
      </Box>

      {/* Agent Chat Widget - positioned in bottom right corner */}
      {user && <ChatWidget userId={user.id} position="bottom-right" />}
    </Box>
  );
};

export default Layout;
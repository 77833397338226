import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Switch,
  FormControlLabel,
  CircularProgress,
  Alert,
  Grid,
  Divider,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip
} from '@mui/material';
import { ArrowBack as ArrowBackIcon, Save as SaveIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import tableService from '../../services/tableService';
import { TableMetadata, TableField, TableRecord } from '../../types/table';

// Registry of special behaviors for specific tables
const tableRegistry: Record<string, {
  customFormActions?: React.ReactNode;
  beforeSubmit?: (values: any) => any;
  afterSubmit?: (result: any) => void;
  getValidationSchema?: (fields: TableField[]) => any;
  getInitialValues?: (fields: TableField[], record?: any) => any;
  renderCustomField?: (field: TableField, formik: any) => React.ReactNode;
  customSections?: {
    title: string;
    fields: string[];
  }[];
}> = {
  'requests': {
    beforeSubmit: (values) => {
      // Special processing for requests
      if (!values.subject && values.description) {
        // Auto-generate subject from description if not provided
        values.subject = values.description.substring(0, 50) + (values.description.length > 50 ? '...' : '');
      }
      return values;
    },
    customSections: [
      {
        title: 'Request Information',
        fields: ['description', 'subject', 'type', 'category']
      },
      {
        title: 'Assignment',
        fields: ['team', 'assignedTo', 'priority', 'status']
      }
    ]
  },
  'teams': {
    renderCustomField: (field, formik) => {
      if (field.name === 'members') {
        // Custom rendering for team members field
        return (
          <Box>
            <Typography variant="subtitle2">Team Members</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
              {formik.values.members?.map((member: any) => (
                <Chip 
                  key={member._id} 
                  label={member.name} 
                  onDelete={() => {
                    const newMembers = formik.values.members.filter((m: any) => m._id !== member._id);
                    formik.setFieldValue('members', newMembers);
                  }} 
                />
              ))}
              <Button 
                variant="outlined" 
                size="small"
                onClick={() => {
                  // This would open a user selection dialog in a real implementation
                  alert('User selection would open here');
                }}
              >
                Add Member
              </Button>
            </Box>
          </Box>
        );
      }
      return null;
    }
  }
};

interface GenericFormProps {
  tableName?: string; // Optional if using useParams
  recordId?: string; // Optional if using useParams
}

const GenericForm: React.FC<GenericFormProps> = ({ tableName: propTableName, recordId: propRecordId }) => {
  const { tableName: paramTableName, recordId: paramRecordId } = useParams<{ tableName: string; recordId: string }>();
  const tableName = propTableName || paramTableName;
  const recordId = propRecordId || paramRecordId;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  
  const [tableMetadata, setTableMetadata] = useState<TableMetadata | null>(null);
  const [record, setRecord] = useState<TableRecord | null>(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  // Get table registry config for this table
  const tableConfig = tableRegistry[tableName || ''] || {};
  
  // Fetch table metadata
  const fetchTableMetadata = useCallback(async () => {
    if (!tableName) return;
    
    try {
      setLoading(true);
      setError(null);
      
      const response = await tableService.getTableByName(tableName);
      if (!response.data) {
        throw new Error(`Table ${tableName} not found`);
      }
      
      setTableMetadata(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching table metadata:', error);
      setError(`Failed to load table metadata: ${error instanceof Error ? error.message : 'Unknown error'}`);
      return null;
    } finally {
      setLoading(false);
    }
  }, [tableName]);
  
  // Fetch record if editing
  const fetchRecord = useCallback(async (metadata: TableMetadata) => {
    if (!tableName || !recordId) return;
    
    try {
      setLoading(true);
      setError(null);
      
      let response;
      
      if (metadata.type === 'system') {
        response = await tableService.getSystemTableRecord(tableName, recordId);
      } else if (metadata.type === 'custom') {
        response = await tableService.getCustomTableRecord(tableName, recordId);
      } else if (metadata.type === 'cmdb_collection') {
        response = await tableService.getCmdbCollectionRecord(tableName, recordId);
      } else {
        throw new Error(`Unsupported table type: ${metadata.type}`);
      }
      
      // Handle different response formats
      let recordData;
      
      if (response.data?.data) {
        recordData = response.data.data;
      } else if (response.data) {
        recordData = response.data;
      } else {
        throw new Error('Invalid record data format');
      }
      
      setRecord(recordData);
      return recordData;
    } catch (error) {
      console.error('Error fetching record:', error);
      setError(`Failed to load record: ${error instanceof Error ? error.message : 'Unknown error'}`);
      return null;
    } finally {
      setLoading(false);
    }
  }, [tableName, recordId]);
  
  // Load data on component mount
  useEffect(() => {
    const loadData = async () => {
      const metadata = await fetchTableMetadata();
      if (metadata && recordId) {
        await fetchRecord(metadata);
      }
    };
    
    loadData();
  }, [fetchTableMetadata, fetchRecord, recordId]);
  
  // Generate validation schema based on field definitions
  const generateValidationSchema = (fields: TableField[]) => {
    // Use custom validation schema if provided
    if (tableConfig.getValidationSchema) {
      return tableConfig.getValidationSchema(fields);
    }
    
    // Default validation schema generation
    const schema: Record<string, any> = {};
    
    fields.forEach(field => {
      let validator;
      
      switch (field.type) {
        case 'text':
        case 'rich_text':
          validator = Yup.string();
          break;
        case 'number':
          validator = Yup.number().typeError('Must be a number');
          break;
        case 'boolean':
          validator = Yup.boolean();
          break;
        case 'date':
        case 'datetime':
          validator = Yup.date().typeError('Must be a valid date');
          break;
        case 'email':
          validator = Yup.string().email('Invalid email format');
          break;
        case 'url':
          validator = Yup.string().url('Invalid URL format');
          break;
        case 'select':
          validator = Yup.string();
          break;
        case 'multiselect':
          validator = Yup.array();
          break;
        default:
          validator = Yup.string();
      }
      
      if (field.required) {
        validator = validator.required(`${field.label} is required`);
      }
      
      schema[field.name] = validator;
    });
    
    return Yup.object().shape(schema);
  };
  
  // Generate initial values based on field definitions and record data
  const generateInitialValues = (fields: TableField[], recordData?: TableRecord) => {
    // Use custom initial values if provided
    if (tableConfig.getInitialValues) {
      return tableConfig.getInitialValues(fields, recordData);
    }
    
    // Default initial values generation
    const initialValues: Record<string, any> = {};
    
    fields.forEach(field => {
      if (recordData && recordData[field.name] !== undefined) {
        initialValues[field.name] = recordData[field.name];
      } else {
        // Set default values based on field type
        switch (field.type) {
          case 'boolean':
            initialValues[field.name] = field.defaultValue !== undefined ? field.defaultValue : false;
            break;
          case 'number':
            initialValues[field.name] = field.defaultValue !== undefined ? field.defaultValue : 0;
            break;
          case 'multiselect':
            initialValues[field.name] = field.defaultValue !== undefined ? field.defaultValue : [];
            break;
          default:
            initialValues[field.name] = field.defaultValue !== undefined ? field.defaultValue : '';
        }
      }
    });
    
    return initialValues;
  };
  
  // Handle form submission
  const handleSubmit = async (values: any) => {
    if (!tableName || !tableMetadata) return;
    
    try {
      setSubmitting(true);
      setError(null);
      
      // Apply custom transformations if provided
      let submissionValues = values;
      if (tableConfig.beforeSubmit) {
        submissionValues = tableConfig.beforeSubmit(values);
      }
      
      let response;
      
      if (recordId) {
        // Update existing record
        if (tableMetadata.type === 'system') {
          // For system tables, we need to get the table ID first
          const tableResponse = await tableService.getTableByName(tableName);
          response = await tableService.updateRecord(tableResponse.data._id, recordId, submissionValues);
        } else if (tableMetadata.type === 'custom') {
          response = await tableService.updateCustomTableRecord(tableName, recordId, submissionValues);
        } else if (tableMetadata.type === 'cmdb_collection') {
          // For CMDB collections, use the appropriate method
          const tableResponse = await tableService.getTableByName(tableName);
          response = await tableService.updateRecord(tableResponse.data._id, recordId, submissionValues);
        } else {
          throw new Error(`Unsupported table type: ${tableMetadata.type}`);
        }
        
        enqueueSnackbar('Record updated successfully', { variant: 'success' });
      } else {
        // Create new record
        if (tableMetadata.type === 'system') {
          // For system tables, we need to get the table ID first
          const tableResponse = await tableService.getTableByName(tableName);
          response = await tableService.createRecord(tableResponse.data._id, submissionValues);
        } else if (tableMetadata.type === 'custom') {
          response = await tableService.createCustomTableRecord(tableName, submissionValues);
        } else if (tableMetadata.type === 'cmdb_collection') {
          // For CMDB collections, use the appropriate method
          const tableResponse = await tableService.getTableByName(tableName);
          response = await tableService.createRecord(tableResponse.data._id, submissionValues);
        } else {
          throw new Error(`Unsupported table type: ${tableMetadata.type}`);
        }
        
        enqueueSnackbar('Record created successfully', { variant: 'success' });
      }
      
      // Apply custom after-submit actions if provided
      if (tableConfig.afterSubmit) {
        tableConfig.afterSubmit(response);
      }
      
      // Navigate back to the list view
      navigate(`/list/${tableName}`);
    } catch (error) {
      console.error('Error submitting form:', error);
      setError(`Failed to save record: ${error instanceof Error ? error.message : 'Unknown error'}`);
      enqueueSnackbar(`Failed to save record: ${error instanceof Error ? error.message : 'Unknown error'}`, { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };
  
  // Initialize formik
  const formik = useFormik({
    initialValues: tableMetadata ? generateInitialValues(tableMetadata.fields, record || undefined) : {},
    validationSchema: tableMetadata ? generateValidationSchema(tableMetadata.fields) : Yup.object({}),
    enableReinitialize: true,
    onSubmit: handleSubmit
  });
  
  // Render field based on its type
  const renderField = (field: TableField) => {
    // Check if there's a custom renderer for this field
    if (tableConfig.renderCustomField) {
      const customField = tableConfig.renderCustomField(field, formik);
      if (customField) {
        return customField;
      }
    }
    
    // Default field rendering based on type
    switch (field.type) {
      case 'boolean':
        return (
          <FormControlLabel
            control={
              <Switch
                name={field.name}
                checked={formik.values[field.name] || false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            }
            label={field.label}
          />
        );
        
      case 'select':
        return (
          <FormControl 
            fullWidth
            error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
          >
            <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
            <Select
              labelId={`${field.name}-label`}
              id={field.name}
              name={field.name}
              value={formik.values[field.name] || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={field.label}
            >
              {field.options?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            {formik.touched[field.name] && formik.errors[field.name] && (
              <FormHelperText>{formik.errors[field.name]?.toString()}</FormHelperText>
            )}
          </FormControl>
        );
        
      case 'multiselect':
        return (
          <FormControl 
            fullWidth
            error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
          >
            <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
            <Select
              labelId={`${field.name}-label`}
              id={field.name}
              name={field.name}
              multiple
              value={formik.values[field.name] || []}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={field.label}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {(selected as string[]).map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {field.options?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            {formik.touched[field.name] && formik.errors[field.name] && (
              <FormHelperText>{formik.errors[field.name]?.toString()}</FormHelperText>
            )}
          </FormControl>
        );
        
      case 'rich_text':
        return (
          <TextField
            fullWidth
            id={field.name}
            name={field.name}
            label={field.label}
            value={formik.values[field.name] || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
            helperText={formik.touched[field.name] && formik.errors[field.name]?.toString()}
            multiline
            rows={4}
            required={field.required}
          />
        );
        
      case 'date':
        return (
          <TextField
            fullWidth
            id={field.name}
            name={field.name}
            label={field.label}
            type="date"
            value={formik.values[field.name] || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
            helperText={formik.touched[field.name] && formik.errors[field.name]?.toString()}
            InputLabelProps={{
              shrink: true,
            }}
            required={field.required}
          />
        );
        
      case 'datetime':
        return (
          <TextField
            fullWidth
            id={field.name}
            name={field.name}
            label={field.label}
            type="datetime-local"
            value={formik.values[field.name] || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
            helperText={formik.touched[field.name] && formik.errors[field.name]?.toString()}
            InputLabelProps={{
              shrink: true,
            }}
            required={field.required}
          />
        );
        
      case 'number':
        return (
          <TextField
            fullWidth
            id={field.name}
            name={field.name}
            label={field.label}
            type="number"
            value={formik.values[field.name] || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
            helperText={formik.touched[field.name] && formik.errors[field.name]?.toString()}
            required={field.required}
          />
        );
        
      default:
        return (
          <TextField
            fullWidth
            id={field.name}
            name={field.name}
            label={field.label}
            value={formik.values[field.name] || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
            helperText={formik.touched[field.name] && formik.errors[field.name]?.toString()}
            required={field.required}
          />
        );
    }
  };
  
  // Group fields into sections if custom sections are defined
  const renderFieldsBySection = () => {
    if (!tableMetadata) return null;
    
    // If custom sections are defined, use them
    if (tableConfig.customSections) {
      return tableConfig.customSections.map((section, index) => (
        <Box key={index} sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            {section.title}
          </Typography>
          <Grid container spacing={2}>
            {section.fields.map(fieldName => {
              const field = tableMetadata.fields.find(f => f.name === fieldName);
              if (!field) return null;
              
              return (
                <Grid item xs={12} sm={6} key={field.name}>
                  {renderField(field)}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ));
    }
    
    // Default: render all fields in a single section
    return (
      <Grid container spacing={2}>
        {tableMetadata.fields.map((field) => (
          <Grid item xs={12} sm={6} key={field.name}>
            {renderField(field)}
          </Grid>
        ))}
      </Grid>
    );
  };
  
  // Render loading state
  if (loading && !tableMetadata) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }
  
  // Render error state
  if (error && !tableMetadata) {
    return (
      <Box p={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }
  
  return (
    <Box sx={{ width: '100%', p: 2 }}>
      {/* Header */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <IconButton onClick={() => navigate(`/list/${tableName}`)} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">
          {recordId ? `Edit ${tableMetadata?.label || tableName}` : `New ${tableMetadata?.label || tableName}`}
        </Typography>
      </Box>
      
      {/* Error message */}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      
      {/* Form */}
      <Paper sx={{ p: 3 }}>
        <form onSubmit={formik.handleSubmit}>
          {renderFieldsBySection()}
          
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button 
              variant="outlined" 
              onClick={() => navigate(`/list/${tableName}`)}
              disabled={submitting}
            >
              Cancel
            </Button>
            <Button 
              type="submit" 
              variant="contained" 
              startIcon={submitting ? <CircularProgress size={20} /> : <SaveIcon />}
              disabled={submitting}
            >
              {recordId ? 'Update' : 'Create'}
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default GenericForm;

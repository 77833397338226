import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  IconButton,
  Divider,
  CircularProgress,
  Pagination
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Send as SendIcon
} from '@mui/icons-material';
import { format } from 'date-fns';
import noteService from '../../services/noteService';

interface Note {
  _id: string;
  content: string;
  createdBy: {
    _id: string;
    name: string;
    email: string;
  };
  createdAt: string;
}

interface NotesProps {
  entityType: 'request' | 'task' | 'workflow' | 'ci';
  entityId: string;
}

const Notes: React.FC<NotesProps> = ({ entityType, entityId }) => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [newNote, setNewNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [submitting, setSubmitting] = useState(false);

  const fetchNotes = async () => {
    setLoading(true);
    try {
      const response = await noteService.getNotes(entityType, entityId, page);
      setNotes(response.notes);
      setTotalPages(response.pagination.pages);
    } catch (error) {
      console.error('Error fetching notes:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, [entityType, entityId, page]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newNote.trim()) return;

    setSubmitting(true);
    try {
      const note = await noteService.addNote(entityType, entityId, newNote.trim());
      setNotes(prev => [note, ...prev]);
      setNewNote('');
    } catch (error) {
      console.error('Error adding note:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async (noteId: string) => {
    try {
      await noteService.deleteNote(noteId);
      setNotes(prev => prev.filter(note => note._id !== noteId));
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  };

  return (
    <Box>
      <Paper component="form" onSubmit={handleSubmit} sx={{ p: 2, mb: 2 }}>
        <TextField
          fullWidth
          multiline
          rows={2}
          placeholder="Add a note..."
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          disabled={submitting}
          sx={{ mb: 1 }}
        />
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!newNote.trim() || submitting}
            startIcon={submitting ? <CircularProgress size={20} /> : <SendIcon />}
          >
            Add Note
          </Button>
        </Box>
      </Paper>

      {loading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {notes.map((note) => (
            <Paper key={note._id} sx={{ p: 2, mb: 2 }}>
              <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                <Box>
                  <Typography variant="subtitle2" color="text.secondary">
                    {note.createdBy.name}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {format(new Date(note.createdAt), 'MMM d, yyyy HH:mm')}
                  </Typography>
                </Box>
                <IconButton
                  size="small"
                  onClick={() => handleDelete(note._id)}
                  sx={{ ml: 1 }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
              <Divider sx={{ my: 1 }} />
              <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                {note.content}
              </Typography>
            </Paper>
          ))}

          {totalPages > 1 && (
            <Box display="flex" justifyContent="center" mt={2}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={(_, value) => setPage(value)}
                color="primary"
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Notes;
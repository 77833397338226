// frontend/src/agent/utils/bot-logger.js
const logger = {
  logNlpProcessing: (userId, message, result) => {
    console.log(`[NLP] Processed message from ${userId}: ${message.substring(0,50)}`);
    console.log(`[NLP] Result: Intent=${result.intent}, Confidence=${result.confidence}`);
    console.log(`[NLP] Entities:`, JSON.stringify(result.entities, null, 2));
  },
  info: (message, metadata = {}) => {
    console.info(`[INFO] ${message}`, metadata);
  },
  error: (message, error) => {
    console.error(`[ERROR] ${message}`, error);
    console.error(`[ERROR] Stack:`, error?.stack);
  },
  warn: (message, metadata = {}) => {
    console.warn(`[WARN] ${message}`, metadata);
  },
  debug: (message, data = {}) => {
    console.debug(`[DEBUG] ${message}`, data);
  }
};

module.exports = logger;
import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Chip,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Alert
} from '@mui/material';
import {
  Visibility as ViewIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Share as ShareIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { format } from 'date-fns';
import reportService from '../../services/reportService';
import ReportForm from './ReportForm';
import ReportChart from './ReportChart'; // Assuming ReportChart is a separate component

interface ReportTemplate {
  _id: string;
  name: string;
  description: string;
  category: string;
  visualization: {
    type: string;
    options: Record<string, any>;
  };
  query: {
    collections: string[];
    aggregation: any[];
    filters: Array<{
      field: string;
      type: string;
      label: string;
      defaultValue: any;
      options?: any[];
    }>;
  };
  type: 'predefined' | 'custom';
  aiGenerated: boolean;
  createdAt: string;
}

const ReportList: React.FC = () => {
  const [templates, setTemplates] = useState<ReportTemplate[]>([]);
  const [selectedReport, setSelectedReport] = useState<ReportTemplate | null>(null);
  const [reportData, setReportData] = useState<any[]>([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAIMode, setIsAIMode] = useState(false);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      setIsLoading(true);
      const data = await reportService.getTemplates();
      setTemplates(data);
      setErrorMessage('');
    } catch (error) {
      console.error('Error fetching templates:', error);
      setErrorMessage('Failed to load report templates');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await reportService.deleteTemplate(id);
      setTemplates(templates.filter(template => template._id !== id));
      setErrorMessage('');
    } catch (error) {
      console.error('Error deleting template:', error);
      setErrorMessage('Failed to delete report template');
    }
  };

  const handleCreateClick = (useAI: boolean = false) => {
    setIsAIMode(useAI);
    setIsCreateModalOpen(true);
  };

  const handleEditClick = (template: ReportTemplate) => {
    setSelectedReport(template);
    setIsEditModalOpen(true);
  };

  const handleViewClick = async (template: ReportTemplate) => {
    try {
      setIsLoading(true);
      setSelectedReport(template);
      const data = await reportService.executeReport(template._id);
      
      console.log('Raw data from backend:', data);
      
      // Transform data to have proper labels
      const transformedData = (data || []).map((item: any) => {
        console.log('Processing item:', item);
        
        // If item already has label and value fields, use them
        if (item.label && item.value) {
          console.log('Using existing label/value:', item);
          return item;
        }
        
        // For category-based reports
        if (item.category !== undefined) {
          const result = {
            ...item,
            label: item.category || 'Uncategorized',
            value: item.count || 0
          };
          console.log('Category-based transformation:', result);
          return result;
        }
        
        // For type-based reports
        if (item.type !== undefined) {
          const result = {
            ...item,
            label: item.type.toUpperCase(), // Capitalize type for better display
            value: item.count || 0
          };
          console.log('Type-based transformation:', result);
          return result;
        }
        
        // For category-based reports with _id
        if (item._id && typeof item._id === 'string') {
          const result = {
            ...item,
            label: item._id,
            value: item.count || 0
          };
          console.log('ID-based transformation:', result);
          return result;
        }
        
        // For grouped reports (e.g., by month)
        if (item._id && typeof item._id === 'object') {
          const label = Object.values(item._id).join('-');
          const result = {
            ...item,
            label,
            value: item.count || 0
          };
          console.log('Group-based transformation:', result);
          return result;
        }
        
        // Default case
        const result = {
          ...item,
          label: 'Unknown',
          value: item.count || 0
        };
        console.log('Default transformation:', result);
        return result;
      });
      
      console.log('Final transformed data:', transformedData);
      
      // Enhance the visualization options with proper labels
      const enhancedTemplate = {
        ...template,
        visualization: {
          ...template.visualization,
          options: {
            ...template.visualization.options,
            xAxisKey: 'label',
            yAxisKey: 'value',
            xAxisLabel: template.name.includes('type') ? 'Request Type' : 'Category',
            yAxisLabel: 'Number of Requests',
          }
        }
      };
      
      console.log('Enhanced template:', enhancedTemplate);
      
      setSelectedReport(enhancedTemplate);
      setReportData(transformedData);
      setIsViewModalOpen(true);
      setErrorMessage('');
    } catch (error) {
      console.error('Error executing report:', error);
      setErrorMessage('Failed to execute report');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box p={3}>
      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}
      
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h5" component="h1">
          Report Templates
        </Typography>
        <Box>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => handleCreateClick(true)}
            sx={{ mr: 1 }}
          >
            AI Generate
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleCreateClick(false)}
          >
            Create Report
          </Button>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templates.map((template) => (
              <TableRow key={template._id}>
                <TableCell>
                  <Box>
                    <Typography variant="body1">{template.name}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {template.description}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Chip label={template.category} size="small" />
                </TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Chip
                      label={template.type}
                      size="small"
                      color={template.type === 'predefined' ? 'primary' : 'default'}
                    />
                    {template.aiGenerated && (
                      <Chip label="AI Generated" size="small" color="secondary" />
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  {format(new Date(template.createdAt), 'MMM d, yyyy')}
                </TableCell>
                <TableCell>
                  <Tooltip title="View Report">
                    <IconButton
                      onClick={() => handleViewClick(template)}
                      size="small"
                    >
                      <ViewIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit Template">
                    <IconButton
                      onClick={() => handleEditClick(template)}
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Share Report">
                    <IconButton
                      size="small"
                    >
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Template">
                    <IconButton
                      onClick={() => handleDelete(template._id)}
                      size="small"
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Create Modal */}
      <Dialog 
        open={isCreateModalOpen} 
        onClose={() => setIsCreateModalOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">
              {isAIMode ? 'Generate Report with AI' : 'Create New Report'}
            </Typography>
            <IconButton onClick={() => setIsCreateModalOpen(false)} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ReportForm
            onClose={() => setIsCreateModalOpen(false)}
            onSuccess={() => {
              setIsCreateModalOpen(false);
              fetchTemplates();
            }}
            useAI={isAIMode}
          />
        </DialogContent>
      </Dialog>

      {/* Edit Modal */}
      <Dialog 
        open={isEditModalOpen} 
        onClose={() => setIsEditModalOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Edit Report</Typography>
            <IconButton onClick={() => setIsEditModalOpen(false)} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {selectedReport && (
            <ReportForm
              initialData={selectedReport}
              onClose={() => setIsEditModalOpen(false)}
              onSuccess={fetchTemplates}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* View Modal */}
      <Dialog 
        open={isViewModalOpen} 
        onClose={() => setIsViewModalOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">
              {selectedReport?.name}
              <Typography variant="body2" color="textSecondary">
                {selectedReport?.description}
              </Typography>
            </Typography>
            <IconButton onClick={() => setIsViewModalOpen(false)} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
              <CircularProgress />
            </Box>
          ) : errorMessage ? (
            <Alert severity="error">{errorMessage}</Alert>
          ) : selectedReport && (
            <ReportChart
              type={selectedReport.visualization.type}
              data={reportData}
              options={selectedReport.visualization.options}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ReportList;